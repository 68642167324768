import React, { Component } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "../sass/Calendar.scss";
import axios from "axios";
import DropdownDashboard from "./Dropdown/DropdownDashboard";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../redux";

const localizer = momentLocalizer(moment);

function Event({ event }) {
  return (
    <span
      style={{
        fontWeight: "400",
        fontSize: "14px",
      }}
    >
      {event.title}
    </span>
  );
}
class TasksCalendar extends Component {
  state = {
    view: "week",
    tasks: [],
    loadingTasks: false,
    userId: this.props.authUser.id,
    userName: this.props.authUser.name,
    displayMenu: false,
    displayUserName: this.props.authUser.name,
    showStep: 30,
  };
  changeStep = (e) => {
    const { value } = e.target;
    if (value === "7.5") {
      this.setState({ showStep: 7.5 });
    } else if (value === "15") {
      this.setState({ showStep: 15 });
    } else {
      this.setState({ showStep: 30 });
    }
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  onSelectSlot = (slotInfo) => {
    // use moment(slotInfo.end) as selected day!
    console.log("slotInfo", slotInfo);
  };
  componentDidMount() {
    const startDate = moment()
        .startOf("week")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate = moment().endOf("week").endOf("month").format("YYYY-MM-DD");
    this.fetchTasks(startDate, endDate);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.userId !== this.state.userId) {
      const startDate = moment()
          .startOf("week")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate = moment().endOf("week").endOf("month").format("YYYY-MM-DD");
      this.fetchTasks(startDate, endDate);
    }
  }
  handleUserId = (userId, userName) => {
    this.setState({
      userId,
      displayMenu: !this.state.displayMenu,
      displayUserName: userName,
    });
  };

  onNavigate = (...abc) => {
    console.log("calendar nav", abc);
  };

  onRangeChange = (data, view = this.state.view) => {
    let startDate, endDate;
    this.setState({ tasks: [], loadingTasks: true });
    switch (view) {
      case "day":
        startDate = moment(data[0]).startOf("month").format("YYYY-MM-DD");
        endDate = moment(data[0]).endOf("month").format("YYYY-MM-DD");
        break;
      case "week":
        startDate = moment(data[0]).startOf("month").format("YYYY-MM-DD");
        endDate = moment(data[6]).endOf("month").format("YYYY-MM-DD");
        break;
      case "month":
        startDate = moment(data.start).startOf("month").format("YYYY-MM-DD");
        endDate = moment(data.end).endOf("month").format("YYYY-MM-DD");
        break;
      case "agenda":
        startDate = moment(data.start).startOf("month").format("YYYY-MM-DD");
        endDate = moment(data.end).endOf("month").format("YYYY-MM-DD");
        break;

      default:
        break;
    }
    // console.log("calendar range", view, startDate, endDate);
    this.fetchTasks(startDate, endDate);
  };

  onViewChange = (view) => {
    this.setState({
      view,
    });
  };

  fetchTasks = (startDate, endDate) => {
    axios({
      method: "GET",
      url: `/tasks/calendar?start_date=${startDate}&end_date=${endDate}&user_ids=${this.state.userId}`,
    }).then((res) =>
      this.setState({
        loadingTasks: false,
        tasks: res.data.tasks.map((data) => {
          let task = {
            title: data.name,
            status: data.status,
            id: data.account_id,
            name: data.account_name,
            oppId: data.opportunity_id,
            oppName: data.opportunity_name,
            conName: data.contact_name,
            conId: data.contact_id,
          };
          if (data.start_time === null) {
            task.start = moment(data.date).toDate();
            task.end = moment(data.date).toDate();
            task.allDay = true;
          } else {
            task.start = data.date + " " + data.start_time;
            task.end = data.date + " " + data.end_time;
          }
          task.start = new Date(task.start);
          task.end = new Date(task.end);
          console.log("Task calendar test", task);
          return task;
        }),
      })
    );
  };
  eventStyleGetter = (event, start, end, isSelected) => {
    var style = {
      color: event.status === "closed" ? "#ffffff" : "#ffffff",
      background: event.status === "closed" ? "#A6A6B0" : "#54C595",
      display: "block",
      borderRadius: "5px",
      marginBottom: "1px",
    };
    return {
      style: style,
    };
  };
  tooltipAccessor = (event) => {
    var tooltip = event.oppName
      ? "Deal: " + event.oppName
      : event.conName
      ? "Contact: " + event.conName
      : "Account: " + event.name;
    return `Task: ${event.title}   ${tooltip}`;
  };
  render() {
    return (
      <div>
        <div
          style={{
            textAlign: "right",
            fontSize: "15px",
            lineHeight: "15px",
            textDecoration: "none",
            color: "#4A9876",
            marginRight: "30px",
          }}
        >
          <span style={{ marginRight: "40px" }}>
            Time Interval{"   "}
            <select
              className="time-interval-select"
              value={this.state.showStep}
              onChange={this.changeStep}
            >
              <option value="7.5">15 min</option>
              <option value="15">30 min</option>
              <option value="30">60 min</option>
            </select>
          </span>
          <span className="view-as-dropdown-calendar">
            <DropdownDashboard
              handleUserId={this.handleUserId}
              user_ids={this.state.userId}
              userName={this.state.userName}
              userId={this.props.authUser.id}
              displayMenu={this.state.displayMenu}
              showDropdownMenu={this.showDropdownMenu}
              displayUserName={this.state.displayUserName}
            />
          </span>
        </div>
        {this.state.loadingTasks && <p>loading...</p>}
        <Calendar
          localizer={localizer}
          events={this.state.tasks}
          eventPropGetter={this.eventStyleGetter}
          startAccessor="start"
          endAccessor="end"
          selectable={true}
          onSelectSlot={this.onSelectSlot}
          style={{ height: "75vh" }}
          onNavigate={this.onNavigate}
          onRangeChange={this.onRangeChange}
          onView={this.onViewChange}
          defaultView={Views.WEEK}
          views={["day", "week", "month", "agenda"]}
          scrollToTime={new Date()}
          tooltipAccessor={this.tooltipAccessor}
          step={this.state.showStep}
          onSelectEvent={(event) => {
            this.props.setActiveTabIndex(this.props.totalTabs + 1);
            this.props.setTab(
              event.oppId
                ? {
                    type: "opportunity",
                    id: event.oppId,
                    name: event.oppName,
                  }
                : event.conId
                ? {
                    type: "contact",
                    id: event.conId,
                    name: event.conName,
                  }
                : {
                    type: "account",
                    id: event.id,
                    name: event.name,
                  }
            );
            this.props.history.push("/active-tabs");
          }}
          components={{
            event: Event,
          }}
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authToken: state.login.authToken,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(TasksCalendar);
