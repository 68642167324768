export const muiModalStyle = {
    // Modal content positioning and styling
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      padding: "2rem",
      borderRadius: "4px",
      outline: "none",
    },
    // Backdrop styling
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)", // semi-transparent black
      zIndex: 10000002,
    },
  };