import { useState, useEffect } from "react";
import OnboardingVideoSidebar from "./OnboardingVideoSidebar";
import "./OnboardingVideo.scss";
import { YOUTUBE_VIDEOS } from "../../../constants/youtubeVideos";
import axios from "axios";
import ReusableModal from "../../Reusables/ReusableModal";
// Redux stuff
import { connect } from "react-redux";
import { authUserSet } from "../../../redux";
import MuiButton from "../../Reusables/Button/MuiButton";
import CloseIcon from "@mui/icons-material/Close";

function OnboardingVideoTutorials(props) {
  const onlyYoutubeVideos = YOUTUBE_VIDEOS.map((vid) => vid.videos).flat();
  const [selectedVideo, setSelectedVideo] = useState({
    title: "Import Spreadsheet Data",
    src: "https://www.youtube.com/embed/dUY5d0OzQSw",
    id: 1,
  });
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const selectNextVideo = () => {
    const currentVideo = onlyYoutubeVideos.find(
      (vid) => vid.id === selectedVideo.id + 1
    );
    setSelectedVideo(currentVideo);
  };
  const skipTour = () => {
    setLoading(true);
    axios({
      method: "PATCH",
      url: `/users/tour-completed`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        tour_completed: true,
      },
    })
      .then((res) => {
        props.authUserSet({ ...props.authUser, tour_completed: true });
        localStorage.setItem(
          "authUser",
          JSON.stringify({ ...props.authUser, tour_completed: true })
        );
        setLoading(false);
        setShowCompleteModal(false);
      })
      .catch((err) => setLoading(false));
  };
  return (
    <div className="onboarding-video">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
        }}
      >
        {showSidebar && (
          <div className="onboarding-video-sidebar-mobile">
            <OnboardingVideoSidebar
              setSelectedVideo={setSelectedVideo}
              selectedVideo={selectedVideo}
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
          </div>
        )}
        <div className="onboarding-video-header-grid">
          <button
            type="button"
            className="onboarding-video-sidebar-mobile-button"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          <h2 style={{ marginBottom: "10px", marginLeft: "20px" }}>
            Salesdash Guide
          </h2>
          <MuiButton
            type="button"
            className="onboarding-video-tour-close-button"
            style={{ marginTop: "-10px"  , width:"160px" }}
            onClick={() => setShowCompleteModal(true)}
            endIcon={<CloseIcon fontSize="small" style={{ color: "#62CA9D" }} />}
          >
            Close Guide
          </MuiButton>
          {/* <button
            type="button"
            className="onboarding-video-tour-close-button"
            onClick={() => setShowCompleteModal(true)}
          >
            Close this guide
          </button> */}
        </div>
      </div>
      <ReusableModal
        showModal={showCompleteModal}
        handleCloseModal={() => setShowCompleteModal(false)}
        submitHandler={skipTour}
        modalHeading={`Close Guide`}
        modalPara={`Our video tutorials will always be available for you to watch by clicking your settings at the top right of your dashboard.`}
        modalHeight={`30`}
        modalTop={`25`}
        loading={loading}
      />
      <div className="onboarding-video-grid">
        <OnboardingVideoSidebar
          setSelectedVideo={setSelectedVideo}
          selectedVideo={selectedVideo}
          setShowSidebar={setShowSidebar}
        />
        <div className="onboarding-video-player">
          <h3 className="onboarding-video-player-title">
            {selectedVideo.title}
          </h3>
          <iframe
            width={windowWidth < 920 ? "100%" : "650"}
            height="368"
            src={`${selectedVideo.src}?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
          {onlyYoutubeVideos.length === selectedVideo.id ? (
            <MuiButton
              type="button"
              className="onboarding-video-skip-button"
              onClick={() => setShowCompleteModal(true)}
            >
              Complete Tour
            </MuiButton>
          ) : (
            <button
              type="button"
              onClick={() => selectNextVideo()}
              className="onboarding-video-skip-button"
            >
              Next Video
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
  };
};
const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(OnboardingVideoTutorials);
