import React, { Component } from "react";
import "../sass/CustomerIntro.scss";
import DealsIcon from "../images/DealsIcon.svg";
import axios from "axios";
import Edit from "../images/EditIcon.svg";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "../sass/Notifications.scss";
import Select from "react-select";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowIcon from "../images/ArrowDown.svg";
import ArrowIconUp from "../images/ArrowUp.svg";
import Collapsible from "react-collapsible";
import CreateContact from "./Contacts/CreateContact";
import Contact from "./Contacts/Contact";
import ContactsExistingModal from "./Contacts/ContactsExistingModal";
import TaskCreateModal from "./TaskCreateModal";
import SendEmailIcon from "../images/AccountEmailIconBlack.svg";
import ContactsNavIcon from "../images/AccountContactIconNew.svg";
import AccountTaskIcon from "../images/AccountTaskIconNew.svg";
import AccountActivityIcon from "../images/ActivityAccountIcon.svg";
import DocumentIcon from "../images/DocumentIcon.svg";
import DeleteIcon from "../images/DeleteIcon.svg";
import DocumentQuickAccessIcon from "../images/DocumentQuickAccessIconNew.svg";
import Autocomplete from "react-autocomplete";
import AccountIcon from "../images/AccountIcon.svg";
import InvoiceIcon from "../images/invoice.png";
import invoiceIconDollar from "../images/invoiceIconDollarNew.svg";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
  setStoredRecordCollapsible,
  removeStoredRecordCollapsible,
  setStoredRecordSelectable,
  removeStoredRecordSelectable,
  fetchAllAccountSources,
} from "../redux";
import ReactTooltip from "react-tooltip";
import SendEmailModal from "./SendEmail/SendEmailModal";
import IntegrationRedirectModal from "./Integration/IntegrationRedirectModal";
import SaveButtonAccount from "../images/SaveButtonAccount.svg";
import EditIcon from "../images/EditIcon.svg";
import CrossIcon from "../images/CrossIcon.svg";
import { withRouter } from "react-router";
import DeleteAttachmentModal from "./SendEmail/DeleteAttachmentModal";
import StageReasonModal from "./Deals/StageReasonModal";
import InvoicingModal from "./Invoices/InvoicingModal";
import EditInvoicingModal from "./Invoices/EditInvoicingModal";
import CreateContactModal from "./CreateModal/CreateContactModal";
import SourceChangeModal from "./Accounts/SourceChangeModal";
import { CurrencyList } from "../constants/currencylist";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../utils/Helper/reusableFunctions";
import Carrier from "./Carrier/Carrier";
import TagCarrierModal from "./Deals/TagCarrierModal";
import { Plus } from "lucide-react";

class OpportunitiesSection extends Component {
  state = {
    opportunityData: {
      custom_fields: {},
    },
    editing: false,
    stages: [],
    name: "",
    revenue: "",
    stage_id: "",
    close_date: "",
    description: "",
    email: "",
    formatted_phone: "",
    website: "",
    account: "",
    source: null,
    showSourceModal: false,
    opportunity_owner_id: null,
    close_reason: null,
    stage_type: "Won",
    allFields: [],
    customFields: {},
    customFieldsForAPI: {},
    close_reason_value: null,
    showOpportunityModal: false,
    accountId: "",
    accountName: "",
    stage_name: "",
    currentStageType: "",
    opportunity_owner: null,
    errorMessage: false,
    showContactModal: false,
    showContactCreateModal: false,
    showNewContactCreateModal: false,
    windowWidth: window.innerWidth,
    tasks: [],
    users: [],
    showEmailModal: false,
    showRedirectModal: false,
    integration: false,
    showInputField: null,
    showButtonModal: false,
    isLoading: false,
    showInfo: true,
    showInfoOf: "",
    documents: [],
    showDeleteAttachmentModal: false,
    documentId: null,
    showAddDocumentButton: true,
    showAddInvoiceButton: true,
    contactEmailsArray: [],
    searchItems: [],
    searchValue: "",
    funnels: [],
    funnel_id: "",
    postMarkId: null,
    postMarkVerified: false,
    showSaveEmailModal: false,
    showAllSequenceModal: false,
    emailValidationError: false,
    showInvoicingModal: false,
    customFieldRevenueTypeCheck: [],
    allInvoices: [],
    invoiceData: {},
    showEditInvoicingModal: false,
    carriers: [],
    showTagCarrierModal: false,
    tagCarrierLoader: false,
    allUntaggedCarriers: [],
  };
  fileInput = React.createRef();
  handleOpenModal = () => {
    this.setState({
      showOpportunityModal: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showOpportunityModal: false,
    });
  };
  handleOpenTagCarrierModal = () => {
    this.setState({
      showTagCarrierModal: true,
    });
  };

  handleCloseCarrierModal = () => {
    this.setState({
      showTagCarrierModal: false,
    });
  };
  handleOpenSourceModal = () => {
    this.setState({
      showSourceModal: true,
    });
  };
  handleCloseSourceModal = () => {
    this.setState({
      showSourceModal: false,
    });
  };
  handleOpenInvoicingModal = () => {
    this.setState({
      showInvoicingModal: true,
    });
  };
  handleCloseInvoicingModal = () => {
    this.setState({
      showInvoicingModal: false,
    });
  };
  handleOpenEditInvoicingModal = () => {
    this.setState({
      showEditInvoicingModal: true,
    });
  };
  handleCloseEditInvoicingModal = () => {
    this.setState({
      showEditInvoicingModal: false,
    });
  };
  handleOpenTaskModal = () => {
    this.props.setShowTaskModal(true);
  };

  handleCloseTaskModal = () => {
    this.props.setShowTaskModal(false);
  };
  handleOpenDeleteAttachmentModal = (id) => {
    this.setState({
      showDeleteAttachmentModal: true,
      documentId: id,
    });
  };

  handleCloseDeleteAttachmentModal = () => {
    this.getDocuments();
    this.setState({
      showDeleteAttachmentModal: false,
    });
  };
  handleOpenSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: true,
    });
  };

  handleCloseSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: false,
    });
  };
  handleOpenAllSequenceModal = () => {
    if (this.state.email === "" || this.state.email === null) {
      this.handleOpenSaveEmailModal();
    } else {
      this.setState({
        showSaveEmailModal: false,
        showAllSequenceModal: true,
      });
    }
  };
  handleCloseAllSequenceModal = () => {
    this.setState({
      showAllSequenceModal: false,
    });
  };
  deleteAttachmentHandler = (id) => {
    axios({
      method: "DELETE",
      url: `/attachments/${id}`,
    })
      .then((res) => {
        this.handleCloseDeleteAttachmentModal();
        NotificationManager.success("Document deleted successfully.");
      })
      .catch((err) => NotificationManager.error("Failed to delete document."));
  };
  getTasks = () => {
    axios({
      method: "GET",
      url: `/opportunities/${this.props.opportunityId}/tasks`,
    }).then((res) => {
      this.setState({ tasks: res.data.tasks });
    });
  };
  getAllUnTaggedCarriers = debounce((name) => {
    let url = `/opportunities/${this.props.opportunityId}/untagged-carriers`;
    if (name) {
      url += `?name=${encodeURIComponent(name)}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      this.setState({
        allUntaggedCarriers: res.data.carriers,
      });
    });
  }, 500);
  tagCarriersToDeals = (carrier_ids) => {
    this.setState({
      tagCarrierLoader: true,
    });
    axios({
      method: "POST",
      url: `/opportunities/${this.props.opportunityId}/carriers`,
      data: {
        carrier_ids,
      },
    })
      .then((res) => {
        this.setState({
          tagCarrierLoader: false,
          showTagCarrierModal: false,
        });
        this.getAllCarriers();
        this.getAllUnTaggedCarriers();
        NotificationManager.success("Carrier(s) tagged successfully.");
      })
      .catch((err) => {
        NotificationManager.error("Error tagging carrier");
        this.setState({
          tagCarrierLoader: false,
        });
      });
  };
  getUsers = () => {
    axios({
      method: "GET",
      url: `/admin/users?opportunity_id=${this.props.opportunityId}`,
    }).then((response) => {
      this.setState({
        users: response.data.users?.slice()?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Ignore case for comparison
          const nameB = b.name.toUpperCase(); // Ignore case for comparison

          if (nameA < nameB) {
            return -1; // Negative value: a should come before b
          }
          if (nameA > nameB) {
            return 1; // Positive value: b should come before a
          }
          return 0; // Return 0 for elements considered equal
        }),
      });
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  handleOpenEmailModal = () => {
    this.setState({
      showEmailModal: true,
      showInputField: null,
    });
  };

  handleCloseEmailModal = () => {
    this.setState({
      showEmailModal: false,
      showInputField: null,
    });
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
      showInputField: null,
    });
  };
  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
      showInputField: null,
    });
  };
  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };

  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenContactCreateModal = () => {
    this.setState({
      showContactCreateModal: true,
    });
  };

  handleCloseContactCreateModal = () => {
    this.setState({
      showContactCreateModal: false,
    });
  };
  handleOpenNewContactCreateModal = () => {
    this.setState({
      showNewContactCreateModal: true,
    });
  };

  handleCloseNewContactCreateModal = () => {
    this.setState({
      showNewContactCreateModal: false,
    });
  };
  getAllInvoices = () => {
    axios({
      method: "GET",
      url: `/opportunities/${this.props.opportunityId}/invoice`,
    }).then((res) =>
      this.setState({
        allInvoices:
          res.data.invoice === undefined || res.data.invoice === null
            ? []
            : [res.data.invoice],
        invoiceData: res.data.invoice,
      })
    );
  };
  getContactsEmailArray = () => {
    axios({
      url: `/opportunities/${this.props.opportunityId}/contacts?_limit=100`,
      method: "GET",
    }).then((res) => {
      this.props.setContactEmailsArray(
        res.data.contacts?.filter((contact) => contact.email !== null)
      );
      this.setState({
        contactEmailsArray: res.data.contacts?.filter(
          (contact) => contact.email !== null
        ),
      });
    });
  };
  getAllFunnels = (id) => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(id) && funnel;
        })
        .filter((funnel) => funnel !== false);
      this.setState({
        funnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages,
      });
    });
  };
  fetchOpportunitiesData = () => {
    axios({
      method: "GET",
      url: `/opportunities/${this.props.opportunityId}`,
    }).then((res) => {
      let { close_date } = res.data.opportunity;
      close_date = new Date(close_date);
      const offsetDate =
        close_date.getTimezoneOffset() < 0
          ? new Date(
              close_date.getTime() - close_date.getTimezoneOffset() * 60000
            )
          : new Date(
              close_date.getTime() + close_date.getTimezoneOffset() * 60000
            );
      this.setState({
        opportunityData: res.data.opportunity,
        revenue: res.data.opportunity.revenue,
        email: res.data.opportunity.email,
        formatted_phone: res.data.opportunity.formatted_phone,
        website: res.data.opportunity.website,
        close_date: offsetDate,
        source: res.data.opportunity.source,
        description: res.data.opportunity.description,
        stage_id: res.data.opportunity.stage_id,
        funnel_id: res.data.opportunity.funnel_id,
        accountId: res.data.opportunity.account_id,
        accountName: res.data.opportunity.account_name,
        stage_name: res.data.opportunity.stage_name,
        opportunity_owner: res.data.opportunity.opportunity_owner,
        opportunity_owner_id: res.data.opportunity.opportunity_owner_id,
        showInputField: null,
        searchValue: res.data.opportunity.account_name,
        close_reason_value: res.data.opportunity.close_reason_id,
        emailValidationError: false,
      });
      this.getAllFunnels(res.data.opportunity.funnel_id);
      this.customFieldInitializer(res.data.opportunity.custom_fields);
    });
  };
  getAllCarriers = () => {
    axios({
      method: "GET",
      url: `/opportunities/${this.props.opportunityId}/carriers?_page=1&_limit=100`,
    }).then((res) => {
      this.setState({
        carriers: res.data.carriers,
      });
    });
  };
  fetchShowInfoOf = () => {
    this.setState({
      showInfoOf: this.props.storedRecordSelectable.deal,
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.fetchShowInfoOf();
    this.getTasks();
    this.getUsers();
    this.getDocuments();
    this.getContactsEmailArray();
    this.getAllInvoices();
    this.getAllCarriers();
    this.getAllUnTaggedCarriers();
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      this.props.setIntegration(res.data.user.mail_provider);
      this.setState({
        integration: res.data.user.mail_provider,
        postMarkId: res.data.user.postmark_signature_id,
        postMarkVerified: res.data.user.postmark_signature_verified,
      });
    });
    this.props.fetchAllContactsOpportunities(this.props.opportunityId);
    let one = `/opportunities/${this.props.opportunityId}`;
    let three = `/funnels`;
    let four = `/close-reasons`;

    const requestOne = axios.get(one);
    const requestThree = axios.get(three);
    const requestFour = axios.get(four);
    axios.all([requestOne, requestThree, requestFour]).then(
      axios.spread((...responses) => {
        const responseOne = responses[0];
        const responesThree = responses[1];
        const responesFour = responses[2];
        // use/access the results
        let { close_date } = responseOne.data.opportunity;
        close_date = new Date(close_date);
        const offsetDate =
          close_date.getTimezoneOffset() < 0
            ? new Date(
                close_date.getTime() - close_date.getTimezoneOffset() * 60000
              )
            : new Date(
                close_date.getTime() + close_date.getTimezoneOffset() * 60000
              );
        const selectedFunnel = responesThree.data.funnels
          ?.map((funnel) => {
            return (
              funnel.id === parseInt(responseOne.data.opportunity.funnel_id) &&
              funnel
            );
          })
          .filter((funnel) => funnel !== false);
        this.props.setOpportunityEmail(responseOne.data.opportunity.email);
        this.props.setAccountData(responseOne.data.opportunity);
        this.setState({
          opportunityData: responseOne.data.opportunity,
          name: responseOne.data.opportunity.name,
          revenue: responseOne.data.opportunity.revenue,
          close_date: offsetDate,
          description: responseOne.data.opportunity.description,
          email: responseOne.data.opportunity.email,
          formatted_phone: responseOne.data.opportunity.formatted_phone,
          source: responseOne.data.opportunity.source,
          website: responseOne.data.opportunity.website,
          stage_id: responseOne.data.opportunity.stage_id,
          funnel_id: responseOne.data.opportunity.funnel_id,
          accountId: responseOne.data.opportunity.account_id,
          accountName: responseOne.data.opportunity.account_name,
          searchValue: responseOne.data.opportunity.account_name,
          stage_name: responseOne.data.opportunity.stage_name,
          close_reason_value: responseOne.data.opportunity.close_reason_id,
          opportunity_owner: responseOne.data.opportunity.opportunity_owner,
          opportunity_owner_id:
            responseOne.data.opportunity.opportunity_owner_id,
          stages: selectedFunnel[0].stages,
          funnels: responesThree.data.funnels,
          close_reason: responesFour.data.reasons,
        });
        this.customFieldInitializer(responseOne.data.opportunity.custom_fields);
      })
    );
  }
  customFieldInitializer = (initialValue) => {
    this.setState({
      allFields: this.props.allDealCustomFields,
    });
    const customField = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField?.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged?.filter((m) => m !== false);
    const obj = customFieldArray?.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const finalObject = { ...obj, ...initialValue };
    const customFieldTypeCheck = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.props.setAccountCustomFields(finalObject);
    this.setState((prevState) => ({
      customFields: finalObject,
      customFieldsForAPI: finalObject,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  editStart = () => {
    this.setState(
      {
        editing: !this.state.editing,
        emailValidationError: false,
      },
      () => {
        if (!this.state.editing) {
          this.fetchOpportunitiesData();
        }
      }
    );
  };
  editCancel = () => {
    this.fetchOpportunitiesData();
    this.setState({
      editing: false,
      emailValidationError: false,
    });
  };
  searchValueChangeHandler = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/accounts/search?query=${value}`,
    }).then((res) => {
      this.setState({ searchItems: res.data.accounts });
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "stage_id") {
      const newStage = this.state.stages.find(
        (stage) => stage.id === parseInt(value)
      );
      this.setState({
        [name]: value,
        currentStageType: newStage.type,
      });
    } else {
      this.setState({
        [name]: value === "" ? null : value,
      });
    }
  };
  handlePhoneChangeHandler = (e) => {
    const { name, value } = e.target;
    const formatter = new AsYouType("US"); // Initialize AsYouType instance
    const formattedValue = formatter.input(value); // Format the input value
    console.log(formattedValue);
    this.setState({
      [name]: formattedValue !== "" ? formattedValue : null,
    });
  };
  closeDateChangeHandler = (type, date) => {
    this.setState({
      [type]: date,
    });
  };
  changeRevenueHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState({
        [name]: value,
      });
    }
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [name]: value,
      },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (this.state.name === null || this.state.name === "") {
      this.setState({ errorMessage: true, isLoading: false });
      return;
    }
    if (
      (this.state.currentStageType === "won" ||
        this.state.currentStageType === "lost") &&
      (this.state.close_reason_value === "" ||
        this.state.close_reason_value === null)
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const calculatedRevenue = getCurrencyValue(this.state.revenue);
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/opportunities/${this.props.opportunityId}`,
      data: {
        opportunityData: this.state?.opportunityData,
        ...(this.state.accountId && { account_id: this.state.accountId }),
        name: this.state?.name,
        revenue: calculatedRevenue,
        stage_id: this.state?.stage_id,
        funnel_id: this.state?.funnel_id,
        close_date: this.state.close_date,
        description: this.state.description,
        opportunity_owner_id: this.state.opportunity_owner_id,
        close_reason: this.state?.close_reason_value,
        custom_fields: customField,
        source: this.state.source,
        email: this.state.email,
        formatted_phone:
          this.state.formatted_phone === undefined ||
          this.state.formatted_phone === "undefined"
            ? null
            : this.state.formatted_phone !== null
            ? this.state.formatted_phone.toString()
            : this.state.formatted_phone,
        website:
          this.state.website === null
            ? null
            : this.state.website.startsWith("https://") ||
              this.state.website.startsWith("http://")
            ? this.state.website
            : "https://" + this.state.website,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "opportunity",
          id: this.props.opportunityId,
          name: this.state.name,
        };
        this.props.setTab({ ...newTabData, edited: true });
        NotificationManager.success("Deal edited successfully.");
      })
      .then((response) => this.fetchOpportunitiesData())
      .then((response) =>
        this.setState({
          editing: false,
          showButtonModal: false,
          showInputField: null,
          isLoading: false,
          errorMessage: false,
          emailValidationError: false,
        })
      )
      .catch((error) => {
        NotificationManager.error("Error editing deal.");
        this.setState({ isLoading: false });
      });
  };
  saveEmailSubmitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.name === null ||
      this.state.revenue === null ||
      this.state.close_date === null ||
      this.state.name === "" ||
      this.state.revenue === "" ||
      this.state.close_date === ""
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return;
    }
    if (
      (this.state.currentStageType === "won" ||
        this.state.currentStageType === "lost") &&
      (this.state.close_reason_value === "" ||
        this.state.close_reason_value === null)
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const calculatedRevenue = getCurrencyValue(this.state.revenue);
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/opportunities/${this.props.opportunityId}`,
      data: {
        opportunityData: this.state?.opportunityData,
        ...(this.state.accountId && { account_id: this.state.accountId }),
        name: this.state?.name,
        revenue: calculatedRevenue,
        stage_id: this.state?.stage_id,
        funnel_id: this.state?.funnel_id,
        close_date: this.state.close_date,
        description: this.state.description,
        opportunity_owner_id: this.state.opportunity_owner_id,
        close_reason: this.state?.close_reason_value,
        source: this.state.source,
        custom_fields: customField,
        email: this.state.email,
        formatted_phone:
          this.state.formatted_phone === undefined ||
          this.state.formatted_phone === "undefined"
            ? null
            : this.state.formatted_phone !== null
            ? this.state.formatted_phone.toString()
            : this.state.formatted_phone,
        website:
          this.state.website === null
            ? null
            : this.state.website.startsWith("https://") ||
              this.state.website.startsWith("http://")
            ? this.state.website
            : "https://" + this.state.website,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "opportunity",
          id: this.props.opportunityId,
          name: this.state.name,
        };
        this.props.setTab({ ...newTabData, edited: true });
        NotificationManager.success("Deal edited successfully.");
      })
      .then((response) => this.fetchOpportunitiesData())
      .then((response) =>
        this.setState({
          editing: false,
          showButtonModal: false,
          showInputField: null,
          isLoading: false,
          errorMessage: false,
          emailValidationError: false,
          showSaveEmailModal: false,
          showAllSequenceModal: true,
        })
      )
      .catch((error) => {
        NotificationManager.error("Error editing deal.");
        this.setState({ isLoading: false });
      });
  };

  stageSubmitHandler = (e) => {
    e.preventDefault();
    this.setState({
      stage_id: e.target.value,
    });
    const newStage = this.state.stages.find(
      (stage) => stage.id === parseInt(e.target.value)
    );
    this.setState({
      currentStageType: newStage.type,
    });
    const calculatedRevenue = getCurrencyValue(this.state.revenue);
    newStage.type === "won" || newStage.type === "lost"
      ? this.handleOpenModal()
      : axios({
          method: "PUT",
          url: `/opportunities/${this.props.opportunityId}`,
          data: {
            opportunityData: this.state?.opportunityData,
            ...(this.state.accountId && { account_id: this.state.accountId }),
            name: this.state?.opportunityData.name,
            revenue: calculatedRevenue,
            stage_id: e.target.value,
            funnel_id: this.state?.funnel_id,
            close_date: this.state.close_date,
            description: this.state.description,
            opportunity_owner_id: this.state.opportunity_owner_id,
            close_reason: null,
            source: this.state.source,
            custom_fields: this.state.customFieldsForAPI,
            email: this.state.email,
            formatted_phone:
              this.state.formatted_phone === null ||
              this.state.formatted_phone === undefined ||
              this.state.formatted_phone === "undefined"
                ? null
                : this.state.formatted_phone.toString(),
            website:
              this.state.website === null
                ? null
                : this.state.website.startsWith("https://") ||
                  this.state.website.startsWith("http://")
                ? this.state.website
                : "https://" + this.state.website,
          },
        })
          .then((response) =>
            NotificationManager.success("Stage successfully changed.")
          )
          .then((response) => this.fetchOpportunitiesData())
          .then((response) =>
            this.setState({ editing: false, showInputField: null })
          )
          .catch((error) => {
            NotificationManager.error("Error changing stage.");
          });
  };
  getDocuments = () => {
    axios({
      method: "GET",
      url: `/attachments?opportunity_id=${this.props.opportunityId}`,
    }).then((res) => this.setState({ documents: res.data.attachments }));
  };
  openFileSelect = () => this.fileInput.current.click();
  handleInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    this.submitAttachment(event.target.files[0]);
  };
  submitAttachment = (selectedFile) => {
    const { opportunityId } = this.props;
    const formData = new FormData();
    formData.append("attachment", selectedFile);
    formData.append("opportunity_id", opportunityId);
    axios({
      method: "POST",
      url: `/attachments`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((res) => {
      this.getDocuments();
      NotificationManager.success("Document added successfully.");
    });
  };
  showInput = (fieldName, fieldLabel, fieldType, fieldChoices, singleEdit) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldRevenueChangeHandler}
        />
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        ></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="number"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <select
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        >
          <option selected disabled>
            -Select-
          </option>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return <option key={id}>{value}</option>;
          })}
        </select>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div>
          <input
            type="checkbox"
            onFocus={() =>
              this.setState(singleEdit && { showInputField: fieldName })
            }
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  type="radio"
                  onFocus={() =>
                    this.setState(singleEdit && { showInputField: fieldName })
                  }
                  style={{ display: "inline-block", textAlign: "left" }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                />
                <label
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          selected={Date.parse(this.state.customFields[fieldName])}
          value={Date.parse(this.state.customFields[fieldName])}
          onChange={(date) =>
            this.customFieldDateChangeHandler(fieldName, date)
          }
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      const colourStyles = {
        control: (styles) => ({
          ...styles,
          backgroundColor: "white",
          width: "280px",
          outline: "#C0EAD8",
          minHeight: "30px",
          padding: "5px auto",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? "#C0EAD8"
              : null,
            cursor: isDisabled ? "not-allowed" : "default",

            ":active": {
              ...styles[":active"],
              backgroundColor:
                !isDisabled && (isSelected ? data.color : "#C0EAD8"),
            },
          };
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "#C0EAD8",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "black",
          fontSize: "13px",
        }),
      };
      inputType = (
        <Select
          autoFocus={singleEdit}
          className={
            singleEdit
              ? "customerIntro-inputField basic-multi-select"
              : "basic-multi-select"
          }
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          closeMenuOnSelect={false}
          styles={colourStyles}
          value={this.state.customFields[fieldName]}
          isMulti
          name={fieldName}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value: value, label: value };
          })}
          classNamePrefix="select"
          onChange={(selectedOption) =>
            this.customFieldMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    }
    return inputType;
  };
  changeFunnelWithoutMarkingWon = () => {
    this.setState(
      {
        showOpportunityModal: true,
      },
      () => this.clickStageFunnelChild()
    );
  };
  checkDeletedUserHandle = (userIds, selectedUser) => {
    if (!userIds.includes(this.state.account_owner_id) && !selectedUser) {
      return (
        <option value={this.state.account_owner_id} selected disabled>
          {this.state.account_owner}
        </option>
      );
    }
  };
  render() {
    const {
      name,
      description,
      close_date,
      revenue,
      close_reason,
      stage_name,
      email,
      funnel_name,
    } = this.state?.opportunityData;
    const stages = this.state.stages;
    const { contacts } = this.props;
    const { showInputField } = this.state;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const userIds = this.props.allUsers?.map((user) => user.id);
    const selectedUser = this.props.allUsers?.find(
      (user) => parseInt(user.id) === parseInt(this.state.account_owner_id)
    );
    return (
      <div className="customerInfo">
        <div className="customerInfo__Intro">
          <div
            className="customerInfo-icon"
            style={{ background: "#F9E1E1", transform: "translateY(-18px)" }}
          >
            <img
              src={DealsIcon}
              alt="Icon"
              width="17px"
              style={{ transform: "translateY(11px)" }}
            />
          </div>
          <div className="customerInfo-info">
            <h2 className="c-type">
              Deal{" "}
              {this.props.authUser.role === "SUPERADMIN" ||
              this.props.authUser.permissions.includes(
                "crm:closed:deal:editor"
              ) ? (
                <select
                  className={
                    this.state?.stage_name === "Won"
                      ? "c-icon-won"
                      : this.state?.stage_name === "Lost"
                      ? "c-icon-lost"
                      : "c-icon-3"
                  }
                  name="stage_id"
                  value={this.state?.stage_id}
                  onChange={(e) => {
                    this.stageSubmitHandler(e);
                  }}
                >
                  {stages?.map((stage) => {
                    const { name, id } = stage;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              ) : this.state?.stage_name === "Won" ||
                this.state?.stage_name === "Lost" ? (
                <div style={{ display: "inline-block" }}>
                  <p
                    className={
                      this.state?.stage_name === "Won" ? "c-icon-4" : "c-icon-5"
                    }
                  >
                    {stage_name}
                  </p>
                </div>
              ) : (
                <select
                  className="c-icon-3"
                  name="stage_id"
                  value={this.state?.stage_id}
                  onChange={(e) => {
                    this.stageSubmitHandler(e);
                  }}
                >
                  {stages?.map((stage) => {
                    const { name, id } = stage;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              )}
              <button
                onClick={this.editStart}
                className="account-editButton-pencil"
              >
                <img src={Edit} alt="Edit" height="13" />
              </button>
            </h2>
            <h1 className="c-name">
              {this.state.editing ? (
                <input
                  type="text"
                  name="name"
                  value={this.state?.name}
                  onChange={this.changeHandler}
                  className="c-name-input"
                  autoComplete="off"
                  style={{ width: "120%" }}
                />
              ) : name && name.length > 30 ? (
                name.substring(0, 30) + "..."
              ) : (
                name
              )}
            </h1>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              (this.state.name === null || this.state.name === "")
                ? "Name is required"
                : null}
            </div>
          </div>
          {/*deal_owner*/}
          <div
            className="c-hoverDesc-div"
            onClick={() =>
              this.state.opportunity_owner &&
              this.setState({ showInputField: "deal_owner" })
            }
          >
            <h5 className="c-title-act">Deal Owner</h5>
            {showInputField === "deal_owner" ? (
              <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                <select
                  autoFocus
                  className="customerIntro-inputField"
                  onFocus={() =>
                    this.setState({ showInputField: "deal_owner" })
                  }
                  name="opportunity_owner_id"
                  value={this.state.opportunity_owner_id}
                  onChange={this.changeHandler}
                >
                  {this.checkDeletedUserHandle(userIds, selectedUser)}
                  {this.props.allUsers.map((user) => {
                    const { id, name } = user;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={this.fetchOpportunitiesData}
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.opportunity_owner === null
                  ? "Unassigned Leads"
                  : this.state.opportunity_owner}
                <button
                  className="c-hoverDesc-button"
                  onClick={() =>
                    this.state.opportunity_owner &&
                    this.setState({
                      showInputField: "deal_owner",
                    })
                  }
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          <ReactTooltip multiline={true} />
          <div className="accountsActionButtonDiv">
            {ReactTooltip.rebuild()}
            <label
              htmlFor="DealActivityForm"
              className="account-Logactivity-button"
              data-tip="Add Activity"
            >
              <img src={AccountActivityIcon} alt="" style={{ width: "16px" }} />
            </label>
            <button
              type="button"
              onClick={this.handleOpenTaskModal}
              data-tip="Add Task"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={AccountTaskIcon} alt="" style={{ width: "16px" }} />
              <div>Task</div>
            </button>
            <button
              type="button"
              onClick={this.handleOpenContactModal}
              data-tip="Add Contact"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={ContactsNavIcon} alt="" style={{ width: "16px" }} />
              <div>Contact</div>
            </button>
            <button
              type="button"
              for="file-upload"
              onClick={this.openFileSelect}
              data-tip="Add Document"
              className="accountsActionButtonDiv-buttons"
            >
              <img
                src={DocumentQuickAccessIcon}
                alt=""
                style={{ width: "16px", height: "17px" }}
              />
              <div>Document</div>
            </button>
            <button
              type="button"
              onClick={
                this.state.allInvoices.length === 0
                  ? this.handleOpenInvoicingModal
                  : this.handleOpenEditInvoicingModal
              }
              data-tip={
                this.state.allInvoices.length === 0 ? "Add Quote" : "Edit Quote"
              }
              className="accountsActionButtonDiv-buttons"
            >
              <img src={invoiceIconDollar} alt="" style={{ width: "16px" }} />
              <div>Quote</div>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={
                this.state.integration !== null ||
                (this.state.postMarkId !== null && this.state.postMarkVerified)
                  ? this.handleOpenEmailModal
                  : this.handleOpenRedirectModal
              }
              className="button-md button-outline-green button-outline-black-hover"
              style={{
                height: "36px",
                padding: "0 16px",
                fontWeight: "500",
                background: "#ffffff",
                color: "#000",
                border: "1px solid #000",
                width: "100%",
                borderRadius: "50px",
              }}
            >
              <img
                src={SendEmailIcon}
                alt=""
                style={{ width: "16px", transform: "translateY(2px)" }}
              />
              &nbsp;Send Email
            </button>
          </div>
          <div
            className="customer-shortcut"
            style={{
              display: "grid",
              gridTemplateColumns:
                this.props.companyOnboarding?.industry === "Freight Brokerage"
                  ? "33% 33% 33%"
                  : "22% 24% 31% 23%",
            }}
          >
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "contact"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "contacts" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "deal",
                        id: "contacts",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "deal",
                        id: "contacts",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "contacts" &&
                      this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "contacts",
                  };
                })
              }
            >
              Contacts ({contacts.length})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "carrier"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "carrier" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "deal",
                        id: "carrier",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "deal",
                        id: "carrier",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "carrier" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "carrier",
                  };
                })
              }
            >
              Carriers ({this.state.carriers.length})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "docs"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "docs" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "deal",
                        id: "docs",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "deal",
                        id: "docs",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "docs" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "docs",
                  };
                })
              }
            >
              Documents ({this.state.documents.length})
            </span>
            {this.props.companyOnboarding?.industry !== "Freight Brokerage" && (
              <span
                className={
                  this.state.showInfo && this.state.showInfoOf === "invoices"
                    ? "customer-shortcut-label-active"
                    : "customer-shortcut-label"
                }
                onClick={() =>
                  this.setState(() => {
                    this.state.showInfoOf === "invoices" && this.state.showInfo
                      ? this.props.removeStoredRecordSelectable({
                          type: "deal",
                          id: "invoices",
                        })
                      : this.props.setStoredRecordSelectable({
                          type: "deal",
                          id: "invoices",
                        });
                    return {
                      showInfo:
                        this.state.showInfoOf === "invoices" &&
                        this.state.showInfo
                          ? false
                          : true,
                      showInfoOf: "invoices",
                    };
                  })
                }
              >
                Quotes ({this.state.allInvoices.length})
              </span>
            )}
          </div>
        </div>
        {this.state.showInfo && this.state.showInfoOf === "contacts" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Contacts ({contacts.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Contacts ({contacts.length})
                  </h4>
                  <button
                    onClick={this.handleOpenContactModal}
                    className="button-sm"
                    style={{
                      transform: "translateY(-6px)",
                    }}
                  >
                    Add Contact
                    <Plus
                      size={14}
                      strokeWidth={3}
                      color="#62CA9D"
                      style={{
                        transform: "translateY(2px)",
                        marginLeft: "5px",
                      }}
                    />
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              {contacts.length > 2 ? (
                <div style={{ height: "200px", overflowY: "auto" }}>
                  {contacts?.map((contact) => {
                    return (
                      <Contact
                        customerId={this.props.opportunityId}
                        name={contact.name}
                        detail={contact.role}
                        type={contact.stage_name}
                        key={contact.id}
                        {...contact}
                      />
                    );
                  })}
                </div>
              ) : (
                <div>
                  {contacts?.map((contact) => {
                    return (
                      <Contact
                        customerId={this.props.customerId}
                        name={contact.name}
                        detail={contact.role}
                        type={contact.stage_name}
                        key={contact.id}
                        {...contact}
                      />
                    );
                  })}
                </div>
              )}
            </Collapsible>
          </div>
        )}
        {/* Carrier */}
        {this.state.showInfo && this.state.showInfoOf === "carrier" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Carriers ({this.state.carriers.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Carriers ({this.state.carriers.length})
                  </h4>
                  <button
                    onClick={this.handleOpenTagCarrierModal}
                    className="button-sm"
                    style={{
                      transform: "translateY(-6px)",
                    }}
                  >
                    Add Carrier
                    <Plus
                      size={14}
                      strokeWidth={3}
                      color="#62CA9D"
                      style={{
                        transform: "translateY(2px)",
                        marginLeft: "5px",
                      }}
                    />
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              {this.state.carriers.length > 2 ? (
                <div style={{ height: "200px", overflowY: "auto" }}>
                  {this.state.carriers?.map((carrier) => {
                    return (
                      <Carrier
                        customerId={carrier.id}
                        name={carrier.name}
                        detail={carrier.mc_number}
                        key={carrier.id}
                        {...carrier}
                      />
                    );
                  })}
                </div>
              ) : (
                <div>
                  {this.state.carriers?.map((carrier) => {
                    return (
                      <Carrier
                        customerId={carrier.id}
                        name={carrier.name}
                        detail={carrier.mc_number}
                        key={carrier.id}
                        {...carrier}
                      />
                    );
                  })}
                </div>
              )}
            </Collapsible>
          </div>
        )}
        {/*Documnets */}
        {this.state.showInfo && this.state.showInfoOf === "docs" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              triggerDisabled={this.state.documents.length === 0 ? true : false}
              onOpen={() => this.setState({ showAddDocumentButton: true })}
              onClose={() => this.setState({ showAddDocumentButton: false })}
              trigger={
                <div>
                  Documents ({this.state.documents.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Documents ({this.state.documents.length})
                  </h4>
                  {this.state.showAddDocumentButton && (
                    <button
                      type="button"
                      onClick={this.openFileSelect}
                      for="file-upload"
                      className="button-sm"
                      style={{
                        transform: "translateY(-6px)",
                      }}
                    >
                      Add Documents
                      <Plus
                        size={14}
                        strokeWidth={3}
                        color="#62CA9D"
                        style={{
                          transform: "translateY(2px)",
                          marginLeft: "5px",
                        }}
                      />
                    </button>
                  )}
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <div
                style={
                  this.state.documents.length > 2
                    ? { height: "200px", overflowY: "auto" }
                    : {}
                }
              >
                {this.state.documents?.map((doc) => (
                  <div className="documentStorage" key={doc.id}>
                    <a
                      href={doc.s3_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={doc.s3_url}
                    >
                      <div className="schedule__items-1" data-tip={doc.name}>
                        <ReactTooltip />
                        <span
                          className="com-2"
                          style={{ background: "#D2DCFF" }}
                        >
                          <img
                            src={DocumentIcon}
                            alt=""
                            style={{
                              transform: "translate(0.5px,0px)",
                              background: "#D2DCFF",
                              width: "18px",
                            }}
                          />
                        </span>
                        <div className="schedule__heading">
                          <span className="name-opp">
                            {doc.name.length > 25
                              ? doc.name.substring(0, 25) + "..."
                              : doc.name}
                          </span>
                        </div>
                        <div className="schedule__desc">
                          {doc.created_at &&
                            moment(doc.created_at).format("MMMM DD, YYYY")}{" "}
                          .{" "}
                          {doc.size > 1024
                            ? (doc.size / 1024).toFixed(2) + "MB"
                            : doc.size + "KB"}
                        </div>
                      </div>
                    </a>
                    <button
                      className="schedule__delete"
                      type="button"
                      onClick={() =>
                        this.handleOpenDeleteAttachmentModal(doc.id)
                      }
                    >
                      <img src={DeleteIcon} alt="x" width="16px" />
                    </button>
                  </div>
                ))}
              </div>
            </Collapsible>
          </div>
        )}
        {/*Invoices */}
        {this.state.showInfo && this.state.showInfoOf === "invoices" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              triggerDisabled={
                this.state.allInvoices.length === 0 ? true : false
              }
              onOpen={() => this.setState({ showAddInvoiceButton: true })}
              onClose={() => this.setState({ showAddInvoiceButton: false })}
              trigger={
                <div>
                  Quotes ({this.state.allInvoices.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Quotes ({this.state.allInvoices.length})
                  </h4>
                  {this.state.showAddInvoiceButton &&
                    this.state.allInvoices.length === 0 && (
                      <button
                        type="button"
                        onClick={this.handleOpenInvoicingModal}
                        className="button-sm"
                        style={{
                          transform: "translateY(-6px)",
                        }}
                      >
                        Add Quotes
                        <Plus
                          size={14}
                          strokeWidth={3}
                          color="#62CA9D"
                          style={{
                            transform: "translateY(2px)",
                            marginLeft: "5px",
                          }}
                        />
                      </button>
                    )}
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <div
                style={
                  this.state.allInvoices.length > 2
                    ? { height: "200px", overflowY: "auto" }
                    : {}
                }
              >
                {this.state.allInvoices?.map((doc) => (
                  <div
                    className="documentStorage"
                    key={doc.id}
                    onClick={this.handleOpenEditInvoicingModal}
                  >
                    <div className="schedule__items-1" data-tip={doc.name}>
                      <ReactTooltip />
                      <span className="com-2" style={{ background: "#bfe9d7" }}>
                        <img
                          src={InvoiceIcon}
                          alt=""
                          style={{
                            transform: "translate(0.5px,0px)",
                            background: "#bfe9d7",
                            width: "18px",
                          }}
                        />
                      </span>
                      <div className="schedule__heading">
                        <span className="name-opp">
                          {doc.name.length > 40
                            ? doc.name.substring(0, 40) + "..."
                            : doc.name}
                        </span>
                      </div>
                      <div className="schedule__desc">
                        {doc.description.length > 40
                          ? doc.description.substring(0, 40) + "..."
                          : doc.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Collapsible>
          </div>
        )}
        <input
          style={{ display: "none" }}
          id="file-upload"
          type="file"
          ref={this.fileInput}
          onChange={this.handleInputChange}
        />
        <SendEmailModal
          showOpportunityModal={this.state.showEmailModal}
          setShowOpportunityModal={this.handleOpenEmailModal}
          handleCloseModal={this.handleCloseEmailModal}
          email={this.state.email}
          accountId={this.props.customerId}
          carrierId={this.props.carrierId}
          opportunityId={this.props.opportunityId}
          loadActivities={this.props.loadActivities}
          contactEmailsArray={this.state.contactEmailsArray}
          replyToEmail={this.state.email ? [this.state.email] : []}
          fromEmail={this.props.authUser.email}
          idSequence={this.props.opportunityId}
          type={"opportunity_id"}
          companyId={this.props.authUser.company_id}
          integration={this.state.integration}
          recordChangeHandler={this.changeHandler}
          recordSubmitHandler={this.saveEmailSubmitHandler}
          recorderLoading={this.state.isLoading}
          emailValidationError={this.state.emailValidationError}
          showSaveEmailModal={this.state.showSaveEmailModal}
          showAllSequenceModal={this.state.showAllSequenceModal}
          handleOpenAllSequenceModal={this.handleOpenAllSequenceModal}
          handleCloseAllSequenceModal={this.handleCloseAllSequenceModal}
          handleOpenSaveEmailModal={this.handleOpenSaveEmailModal}
          handleCloseSaveEmailModal={this.handleCloseSaveEmailModal}
          allUsers={this.props.allUsers}
          recordName={this.state.name}
        />
        {this.state.showRedirectModal && (
          <IntegrationRedirectModal
            showOpportunityModal={this.state.showRedirectModal}
            setShowOpportunityModal={this.handleOpenRedirectModal}
            handleCloseModal={this.handleCloseRedirectModal}
          />
        )}
        {this.props.showTaskModal && (
          <TaskCreateModal
            accountId={this.props.customerId}
            showTaskModal={this.props.showTaskModal}
            setShowTaskModal={this.handleOpenTaskModal}
            handleCloseModal={this.handleCloseTaskModal}
            customerName={this.props.customerName}
            contacts={contacts}
            getTasks={this.getTasks}
            opportunityId={this.props.opportunityId}
            opportunityName={this.props.opportunityName}
            users={this.state.users}
            existingUser={this.props.authUser.id}
            keyValue="OpportunitiesSection"
          />
        )}
        {this.state.showOpportunityModal && (
          <StageReasonModal
            showOpportunityModal={this.state.showOpportunityModal}
            setShowOpportunityModal={this.handleOpenModal}
            handleCloseModal={this.handleCloseModal}
            stage_id={this.state?.stage_id}
            opportunityId={this.props.opportunityId}
            customerId={this.state.accountId}
            contactId={this.props.contactId}
            state={this.state}
            currentStageType={this.state.currentStageType}
            fetchOpportunitiesData={this.fetchOpportunitiesData}
            getConfetti={this.props.getConfetti}
            funnels={this.state.funnels}
            funnelId={this.state.funnel_id}
            users={this.state.users}
            ownerId={this.state.opportunity_owner_id}
            setStageFunnelClick={(click) =>
              (this.clickStageFunnelChild = click)
            }
          />
        )}
        {this.state.showInvoicingModal && (
          <InvoicingModal
            showModal={this.state.showInvoicingModal}
            handleCloseModal={this.handleCloseInvoicingModal}
            modalHeight={`97`}
            modalWidth={`75`}
            modalTop={`0`}
            modalLeft={`10`}
            dateFormat={this.props.companyOnboarding?.date_format}
            opportunityId={this.props.opportunityId}
            opportunityName={this.state.name}
            getAllInvoices={this.getAllInvoices}
            accountName={this.state.accountName}
            customerEmail={this.state.email}
            customerMobile={this.state.formatted_phone}
            companyName={this.props.companyOnboarding?.name}
            companyCountry={this.props.companyOnboarding?.country}
            logo={this.props.companyOnboarding?.logo}
            companyEmail={this.props.companyOnboarding?.email}
            companyMobile={this.props.companyOnboarding?.formatted_phone}
          />
        )}
        {this.state.showEditInvoicingModal && (
          <EditInvoicingModal
            showModal={this.state.showEditInvoicingModal}
            handleCloseModal={this.handleCloseEditInvoicingModal}
            modalHeight={`97`}
            modalWidth={`75`}
            modalTop={`0`}
            modalLeft={`10`}
            dateFormat={this.props.companyOnboarding?.date_format}
            opportunityId={this.props.opportunityId}
            opportunityName={this.state.name}
            getAllInvoices={this.getAllInvoices}
            invoiceData={this.state.invoiceData}
          />
        )}
        <NotificationContainer />
        {this.state.editing ? (
          <form onSubmit={this.submitHandler}>
            <div
              className="customerInfo__Info"
              style={{ height: "53vh", overflowY: "auto" }}
            >
              <div className="collapseHeaderAccount-edit">
                <h4
                  className="c-title-main"
                  style={{ marginTop: "10px", marginBottom: "11px" }}
                >
                  Deal Info ({funnel_name})
                </h4>
                <h5 className="c-title-act">Stage</h5>
                <div className="customerInfo__AddInfo-icon">
                  {this.state?.stage_name === "Won" ||
                  this.state?.stage_name === "Lost" ? (
                    <p className="c-desc">{stage_name}</p>
                  ) : (
                    <select
                      className="c-icon-1"
                      style={{ background: "#EEF6F2", color: "black" }}
                      name="stage_id"
                      value={this.state?.stage_id}
                      onChange={this.changeHandler}
                    >
                      {stages?.map((stage) => {
                        const { name, id } = stage;
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {this.state?.currentStageType === "won" ||
                  this.state?.currentStageType === "lost" ? (
                    <div>
                      <h5 className="c-title-act">Close Reason</h5>
                      {this.state?.currentStageType === "won" ? (
                        <select
                          name="close_reason_value"
                          value={this.state.close_reason_value}
                          onChange={this.changeHandler}
                        >
                          <option value="" selected disabled>
                            -Select-
                          </option>
                          {this.state?.close_reason?.map((reason) => {
                            const { name, id, type } = reason;
                            let options;
                            options =
                              type === "won" ? (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ) : null;
                            return options;
                          })}
                        </select>
                      ) : (
                        <select
                          name="close_reason_value"
                          value={this.state.close_reason_value}
                          onChange={this.changeHandler}
                        >
                          <option value="" selected disabled>
                            -Select-
                          </option>
                          {this.state?.close_reason?.map((reason) => {
                            const { name, id, type } = reason;
                            let options;
                            options =
                              type === "lost" ? (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ) : null;
                            return options;
                          })}
                        </select>
                      )}
                    </div>
                  ) : null}
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.errorMessage === true &&
                    (this.state.currentStageType === "won" ||
                      this.state.currentStageType === "lost") &&
                    (this.state.close_reason_value === "" ||
                      this.state.close_reason_value === null)
                      ? "Close Reason is required when stage is Won or Lost"
                      : null}
                  </div>
                </div>
                <h5 className="c-title-act">Account</h5>
                <Autocomplete
                  inputProps={{
                    style: {
                      border: "1px solid #c5c5d3",
                      borderRadius: "4px",
                      padding: "5px",
                      width: "90%",
                      margin: "5px 0",
                    },
                    placeholder: "Search Account",
                  }}
                  items={this.state?.searchItems}
                  menuStyle={{
                    borderRadius: "3px",
                    display: "block",
                    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                    background: "#252a2d",
                    padding: "3px 0",
                    position: "fixed",
                    overflow: "auto",
                    maxHeight: "250px",
                    zIndex: "2147483647",
                  }}
                  getItemValue={(item) => item.name}
                  renderItem={(item, highlighted) => {
                    const { id } = item;
                    return (
                      <div
                        key={`${item.id}`}
                        style={{
                          backgroundColor: highlighted ? "#3B4043" : "#252a2d",
                          color: "#CFCFD8",
                          zIndex: "2147483647",
                          fontSize: "16px",
                          cursor: "pointer",
                          borderBottom: "1px solid black",
                          padding: "5px 0",
                        }}
                      >
                        <div style={{ paddingLeft: "15px" }}>
                          <img
                            src={AccountIcon}
                            alt=""
                            width="13"
                            key={id}
                            style={{ transform: "translateY(9px)" }}
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              marginLeft: "15px",
                            }}
                          >
                            {item.name.length > 23
                              ? item.name.substring(0, 22)
                              : item.name}
                          </span>
                        </div>
                        <div
                          style={{
                            marginLeft: "45px",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "300",
                            color: "#A5A1AC",
                          }}
                        >
                          Account
                        </div>
                      </div>
                    );
                  }}
                  value={this.state.searchValue}
                  onChange={this.searchValueChangeHandler}
                  onSelect={(value, item) => {
                    this.setState({
                      accountId: item.id,
                      searchValue: item.name,
                    });
                  }}
                />
                {this.state.opportunity_owner && (
                  <h5 className="c-title-act">Deal Owner</h5>
                )}
                {this.state.opportunity_owner && (
                  <select
                    name="opportunity_owner_id"
                    value={this.state.opportunity_owner_id}
                    onChange={this.changeHandler}
                  >
                    {this.checkDeletedUserHandle(userIds, selectedUser)}
                    {this.props.allUsers.map((user) => {
                      const { id, name } = user;
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                )}
                <h5 className="c-title-act">Revenue</h5>
                <input
                  name="revenue"
                  type="number"
                  value={this.state.revenue}
                  pattern="^[0-9]*$"
                  min="0"
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Close Date</h5>
                <DatePicker
                  name="close_date"
                  selected={Date.parse(this.state.close_date)}
                  value={Date.parse(this.state.close_date)}
                  onChange={(date) =>
                    this.closeDateChangeHandler("close_date", date)
                  }
                  dateFormat={
                    this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/MM/yyyy"
                      : "MM/dd/yyyy"
                  }
                  placeholderText={
                    this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/mm/yyyy"
                      : "mm/dd/yyyy"
                  }
                  autoComplete="off"
                />
                <h5 className="c-title-act">Email</h5>
                <input
                  name="email"
                  type="text"
                  value={this.state.email}
                  onChange={this.changeHandler}
                />
                {this.state.emailValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid email
                  </div>
                )}
                <h5 className="c-title-act">Phone</h5>
                <input
                  name="formatted_phone"
                  type="text"
                  value={this.state.formatted_phone}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Website</h5>
                <input
                  name="website"
                  type="text"
                  value={this.state.website}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Source</h5>
                <div className="customerInfo__AddInfo-icon">
                  <select
                    name="source"
                    value={this.state.source}
                    onChange={this.changeHandler}
                  >
                    <option hidden>-select-</option>
                    <option selected disabled>
                      -select-
                    </option>
                    {this.props.allAccountSources.map((source) => (
                      <option key={source.id} value={source.name}>
                        {source.name}
                      </option>
                    ))}
                  </select>
                </div>
                <h5 className="c-title-act">Description</h5>
                <textarea
                  name="description"
                  value={this.state.description}
                  onChange={this.changeHandler}
                  style={{ whiteSpace: "pre-wrap" }}
                ></textarea>
              </div>
              {this.state?.allFields?.map((field) => {
                const { label, id, fields, meta } = field;
                return (
                  <div key={id}>
                    {meta === null && (
                      <div className="collapseHeaderAccount-edit">
                        <h4 className="c-title-main-1">{label}</h4>
                        <div>
                          {fields.map((data) => {
                            const { id, label, type, choices, name } = data;
                            return (
                              <div key={id}>
                                <h5 className="c-title-act">{label}</h5>
                                <div key={id}>
                                  {this.showInput(name, label, type, choices)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="customerIntroStateChange">
              <p>Save changes to fields?</p>
              <button
                type="button"
                onClick={this.editCancel}
                className="modal__clear-1 customerIntroStateChange-cancel"
              >
                Cancel
              </button>
              <button
                disabled={this.state.isLoading}
                type="button"
                onClick={this.submitHandler}
                className="modal__addButton-1 customerIntroStateChange-save"
              >
                {this.state.isLoading ? "Saving" : "Save"}
              </button>
            </div>
          </form>
        ) : (
          <div className="customerInfo__Info">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              onOpening={() =>
                this.props.setStoredRecordCollapsible({
                  type: "deal",
                  id: "default",
                })
              }
              onClosing={() =>
                this.props.removeStoredRecordCollapsible({
                  type: "deal",
                  id: "default",
                })
              }
              open={this.props.storedRecordCollapsible.deal.includes("default")}
              trigger={
                <div>
                  Deal Info ({funnel_name})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div>
                  Deal Info ({funnel_name})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "4%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIconUp} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              {/*funnel*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "funnel" })}
              >
                <h5 className="c-title-act">Pipeline Name</h5>
                <p className="c-desc c-hoverDesc">
                  {funnel_name}
                  {this.state.funnels.length > 1 && (
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState(
                          {
                            showInputField: "funnel",
                          },
                          () => this.changeFunnelWithoutMarkingWon()
                        )
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  )}
                </p>
              </div>
              {/*stage*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "stage_id" })}
              >
                <h5 className="c-title-act">Stage</h5>
                {showInputField === "stage_id" ? (
                  this.props.authUser.role === "SUPERADMIN" ||
                  this.props.authUser.permissions.includes(
                    "crm:closed:deal:editor"
                  ) ? (
                    <select
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "stage_id" })
                      }
                      name="stage_id"
                      value={this.state?.stage_id}
                      onChange={(e) => {
                        this.stageSubmitHandler(e);
                      }}
                    >
                      <option selected disabled>
                        -Select-
                      </option>
                      {stages?.map((stage) => {
                        const { name, id } = stage;
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  ) : this.state?.stage_name !== "Won" ||
                    this.state?.stage_name !== "Lost" ? (
                    <select
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "stage_id" })
                      }
                      name="stage_id"
                      value={this.state?.stage_id}
                      onChange={(e) => {
                        this.stageSubmitHandler(e);
                      }}
                    >
                      <option selected disabled>
                        -Select-
                      </option>
                      {stages?.map((stage) => {
                        const { name, id } = stage;
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <p className="c-desc c-hoverDesc">
                      {stage_name}
                      <button
                        className="c-hoverDesc-button"
                        onClick={() =>
                          this.setState({
                            showInputField: "revenue",
                          })
                        }
                      >
                        <img src={EditIcon} alt="Edit" width="13" />
                      </button>
                    </p>
                  )
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {stage_name}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "revenue",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {close_reason !== null ? (
                <div>
                  <h5 className="c-title-act">Close Reason</h5>
                  <p className="c-desc">{close_reason}</p>
                </div>
              ) : null}
              <h5 className="c-title-act">Account</h5>
              {this.state.accountName ? (
                <p
                  className="c-desc"
                  onClick={() => {
                    this.props.setActiveTabIndex(this.props.totalTabs + 1);
                    this.props.setTab({
                      type: "account",
                      id: this.state.accountId,
                      name: this.state.accountName,
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#4A9876",
                    textDecoration: "underline",
                  }}
                >
                  {this.state.accountName}
                </p>
              ) : (
                <p className="c-desc"> - </p>
              )}
              {/*revenue*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "revenue" })}
              >
                <h5 className="c-title-act">Revenue</h5>
                {showInputField === "revenue" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "revenue" })
                      }
                      name="revenue"
                      type="text"
                      value={this.state.revenue}
                      onChange={this.changeRevenueHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchOpportunitiesData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {currencySymbol.symbol}
                    {revenue?.toLocaleString("en-US")}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "revenue",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*close_date*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "close_date" })}
              >
                <h5 className="c-title-act">Close Date</h5>
                {showInputField === "close_date" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <DatePicker
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "close_date" })
                      }
                      name="close_date"
                      selected={Date.parse(this.state.close_date)}
                      value={Date.parse(this.state.close_date)}
                      onChange={(date) =>
                        this.closeDateChangeHandler("close_date", date)
                      }
                      dateFormat={
                        this.props.companyOnboarding?.date_format ===
                        "DD/MM/YYYY"
                          ? "dd/MM/yyyy"
                          : "MM/dd/yyyy"
                      }
                      placeholderText={
                        this.props.companyOnboarding?.date_format ===
                        "DD/MM/YYYY"
                          ? "dd/mm/yyyy"
                          : "mm/dd/yyyy"
                      }
                      autoComplete="off"
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchOpportunitiesData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {close_date && moment(close_date).format(dateFormat)}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "close_date",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*email*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "email" })}
              >
                <h5 className="c-title-act">Email</h5>
                {showInputField === "email" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "email" })}
                      name="email"
                      type="text"
                      value={this.state.email}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchOpportunitiesData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {email !== null ? (
                      <span
                        style={{
                          color: "#4A9876",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={
                          this.state.integration !== null ||
                          (this.state.postMarkId !== null &&
                            this.state.postMarkVerified)
                            ? this.handleOpenEmailModal
                            : this.handleOpenRedirectModal
                        }
                      >
                        {email}
                      </span>
                    ) : (
                      email
                    )}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "email",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {this.state.emailValidationError && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid email
                </div>
              )}
              {/*formatted_phone*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "formatted_phone" })
                }
              >
                <h5 className="c-title-act">Phone</h5>
                {showInputField === "formatted_phone" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "formatted_phone" })
                      }
                      name="formatted_phone"
                      type="text"
                      value={this.state.formatted_phone}
                      onChange={this.handlePhoneChangeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchOpportunitiesData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    <a
                      target="_blank"
                      data-stringify-link={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      delay="150"
                      data-sk="tooltip_parent"
                      href={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      rel="noopener noreferrer"
                      tabindex="-1"
                      data-remove-tab-index="true"
                    >
                      {this.state.formatted_phone}
                    </a>
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "formatted_phone",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*website*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "website" })}
              >
                <h5 className="c-title-act">Website</h5>
                {showInputField === "website" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "website" })
                      }
                      type="text"
                      name="website"
                      value={this.state.website}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchOpportunitiesData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.website === null ? null : (
                      <a
                        href={this.state.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-desc"
                        style={{ cursor: "pointer", color: "#4A9876" }}
                      >
                        {this.state.website && this.state.website.length > 40
                          ? this.state.website.substring(0, 39) + "..."
                          : this.state.website}
                      </a>
                    )}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "website" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*source*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "source" })}
              >
                <h5 className="c-title-act">
                  Source{" "}
                  <button
                    type="button"
                    onClick={this.handleOpenSourceModal}
                    className="account-sourceButton"
                    data-tip="Add a new source to track where a client came from."
                  >
                    +
                  </button>
                </h5>
                {showInputField === "source" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <div className="customerInfo__AddInfo-icon">
                      <select
                        className="customerIntro-inputField"
                        onFocus={() =>
                          this.setState({ showInputField: "source" })
                        }
                        name="source"
                        value={this.state.source}
                        onChange={this.changeHandler}
                      >
                        <option hidden>-select-</option>
                        <option selected disabled>
                          -select-
                        </option>
                        {this.props.allAccountSources.map((source) => (
                          <option key={source.id} value={source.name}>
                            {source.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={() =>
                          this.setState(
                            { showInputField: null },
                            this.fetchContactsData
                          )
                        }
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.source}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "source" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*description*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "description" })}
              >
                <h5 className="c-title-act">Description</h5>
                {showInputField === "description" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <textarea
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "description" })
                      }
                      name="description"
                      value={this.state.description}
                      onChange={this.changeHandler}
                      style={{ whiteSpace: "pre-wrap" }}
                    ></textarea>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchOpportunitiesData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p
                    className="c-desc c-hoverDesc"
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      marginBottom: "15px",
                      whiteSpace: "pre-wrap",
                      height: "auto",
                    }}
                  >
                    {description}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "description",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
            </Collapsible>
            {this.state?.allFields?.map((field) => {
              const { label, id, fields } = field;
              return (
                <div key={id}>
                  <Collapsible
                    className="collapseHeaderAccount"
                    classParentString="collapseHeaderAccount"
                    overflowWhenOpen="initial"
                    onOpening={() =>
                      this.props.setStoredRecordCollapsible({
                        type: "deal",
                        id,
                      })
                    }
                    onClosing={() =>
                      this.props.removeStoredRecordCollapsible({
                        type: "deal",
                        id,
                      })
                    }
                    open={this.props.storedRecordCollapsible.deal.includes(id)}
                    trigger={
                      <div>
                        {label}
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            padding: "3px 10px",
                            border: "none",
                            borderRadius: "5px",
                            color: "white",
                            outline: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "30%",
                            right: "2%",
                          }}
                        >
                          <img src={ArrowIcon} alt="" height="10px" />
                        </button>
                      </div>
                    }
                    triggerWhenOpen={
                      <div>
                        {label}
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            padding: "3px 10px",
                            border: "none",
                            borderRadius: "5px",
                            color: "white",
                            outline: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "4%",
                            right: "2%",
                          }}
                        >
                          <img src={ArrowIconUp} alt="" height="10px" />
                        </button>
                      </div>
                    }
                    triggerClassName="c-title-main"
                    triggerOpenedClassName="c-title-main"
                  >
                    <div>
                      {fields?.map((data) => {
                        const { id, label, type, name, choices } = data;
                        return type === "date" ? (
                          <div
                            key={id}
                            className="c-hoverDesc-div"
                            onClick={() =>
                              this.setState({ showInputField: name })
                            }
                          >
                            <h5 className="c-title-act">{label}</h5>
                            {showInputField === name ? (
                              <form
                                onSubmit={this.submitHandler}
                                style={{ display: "flex" }}
                              >
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  true
                                )}
                                {!this.state.isLoading && (
                                  <button
                                    type="button"
                                    className="c-hoverDesc-button-close"
                                    onClick={this.fetchOpportunitiesData}
                                  >
                                    <img src={CrossIcon} alt="Save" width="8" />
                                  </button>
                                )}
                                {this.state.isLoading ? (
                                  <div class="spinning-loader"></div>
                                ) : (
                                  <button
                                    type="submit"
                                    className="c-hoverDesc-button-save"
                                    disabled={this.state.isLoading}
                                  >
                                    <img
                                      src={SaveButtonAccount}
                                      alt="Save"
                                      width="20"
                                    />
                                  </button>
                                )}
                              </form>
                            ) : (
                              <p className="c-desc c-hoverDesc">
                                {this.state?.opportunityData?.custom_fields[
                                  name
                                ] === null ||
                                this.state?.opportunityData?.custom_fields[
                                  name
                                ] === undefined
                                  ? ""
                                  : moment(
                                      this?.state?.opportunityData
                                        ?.custom_fields[name]
                                    ).format(dateFormat)}
                                <button
                                  className="c-hoverDesc-button"
                                  onClick={() =>
                                    this.setState({
                                      showInputField: name,
                                    })
                                  }
                                >
                                  <img src={EditIcon} alt="Edit" width="13" />
                                </button>
                              </p>
                            )}
                          </div>
                        ) : type === "multi_select_dropdown" ? (
                          <div
                            key={id}
                            className="c-hoverDesc-div"
                            onClick={() =>
                              this.setState({ showInputField: name })
                            }
                          >
                            <h5 className="c-title-act">{label}</h5>
                            {showInputField === name ? (
                              <form
                                onSubmit={this.submitHandler}
                                style={{ display: "flex" }}
                              >
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  true
                                )}
                                {!this.state.isLoading && (
                                  <button
                                    type="button"
                                    className="c-hoverDesc-button-close"
                                    onClick={this.fetchOpportunitiesData}
                                  >
                                    <img src={CrossIcon} alt="Save" width="8" />
                                  </button>
                                )}
                                {this.state.isLoading ? (
                                  <div class="spinning-loader"></div>
                                ) : (
                                  <button
                                    type="submit"
                                    className="c-hoverDesc-button-save"
                                    disabled={this.state.isLoading}
                                  >
                                    <img
                                      src={SaveButtonAccount}
                                      alt="Save"
                                      width="20"
                                    />
                                  </button>
                                )}
                              </form>
                            ) : (
                              <p className="c-desc c-hoverDesc">
                                {this?.state?.opportunityData?.custom_fields[
                                  name
                                ] === null
                                  ? ""
                                  : this?.state?.opportunityData?.custom_fields[
                                      name
                                    ] &&
                                    this?.state?.opportunityData?.custom_fields[
                                      name
                                    ]
                                      .replace(/;/g, ", ")
                                      .substr(1)
                                      .slice(0, -2)}
                                <button
                                  className="c-hoverDesc-button"
                                  onClick={() =>
                                    this.setState({
                                      showInputField: name,
                                    })
                                  }
                                >
                                  <img src={EditIcon} alt="Edit" width="13" />
                                </button>
                              </p>
                            )}
                          </div>
                        ) : type === "hyperlink" ? (
                          <div
                            key={id}
                            className="c-hoverDesc-div"
                            onClick={() =>
                              this.setState({ showInputField: name })
                            }
                          >
                            <h5 className="c-title-act">{label}</h5>
                            {showInputField === name ? (
                              <form
                                onSubmit={this.submitHandler}
                                style={{ display: "flex" }}
                              >
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  true
                                )}
                                {!this.state.isLoading && (
                                  <button
                                    type="button"
                                    className="c-hoverDesc-button-close"
                                    onClick={this.fetchOpportunitiesData}
                                  >
                                    <img src={CrossIcon} alt="Save" width="8" />
                                  </button>
                                )}
                                {this.state.isLoading ? (
                                  <div class="spinning-loader"></div>
                                ) : (
                                  <button
                                    type="submit"
                                    className="c-hoverDesc-button-save"
                                    disabled={this.state.isLoading}
                                  >
                                    <img
                                      src={SaveButtonAccount}
                                      alt="Save"
                                      width="20"
                                    />
                                  </button>
                                )}
                              </form>
                            ) : (
                              <p
                                className="c-desc c-hoverDesc"
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                  marginBottom: "15px",
                                  whiteSpace: "pre-wrap",
                                  height: "auto",
                                }}
                              >
                                {this?.state?.opportunityData?.custom_fields[
                                  name
                                ] === null ? (
                                  ""
                                ) : (
                                  <a
                                    href={
                                      this?.state?.opportunityData?.custom_fields[
                                        name
                                      ]?.startsWith("https://") ||
                                      this?.state?.opportunityData?.custom_fields[
                                        name
                                      ]?.startsWith("http://")
                                        ? this?.state?.opportunityData
                                            ?.custom_fields[name]
                                        : "https://" +
                                          this?.state?.opportunityData
                                            ?.custom_fields[name]
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="c-desc"
                                    style={{
                                      cursor: "pointer",
                                      color: "#4A9876",
                                    }}
                                  >
                                    {this?.state?.opportunityData
                                      ?.custom_fields[name] &&
                                    this?.state?.opportunityData?.custom_fields[
                                      name
                                    ].length > 40
                                      ? this?.state?.opportunityData?.custom_fields[
                                          name
                                        ].substring(0, 39) + "..."
                                      : this?.state?.opportunityData
                                          ?.custom_fields[name]}
                                  </a>
                                )}{" "}
                                <button
                                  className="c-hoverDesc-button"
                                  onClick={() =>
                                    this.setState({
                                      showInputField: name,
                                    })
                                  }
                                >
                                  <img src={EditIcon} alt="Edit" width="13" />
                                </button>
                              </p>
                            )}
                          </div>
                        ) : type === "paragraph" ? (
                          <div
                            key={id}
                            className="c-hoverDesc-div"
                            onClick={() =>
                              this.setState({ showInputField: name })
                            }
                          >
                            <h5 className="c-title-act">{label}</h5>
                            {showInputField === name ? (
                              <form
                                onSubmit={this.submitHandler}
                                style={{ display: "flex" }}
                              >
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  true
                                )}
                                {!this.state.isLoading && (
                                  <button
                                    type="button"
                                    className="c-hoverDesc-button-close"
                                    onClick={this.fetchOpportunitiesData}
                                  >
                                    <img src={CrossIcon} alt="Save" width="8" />
                                  </button>
                                )}
                                {this.state.isLoading ? (
                                  <div class="spinning-loader"></div>
                                ) : (
                                  <button
                                    type="submit"
                                    className="c-hoverDesc-button-save"
                                    disabled={this.state.isLoading}
                                  >
                                    <img
                                      src={SaveButtonAccount}
                                      alt="Save"
                                      width="20"
                                    />
                                  </button>
                                )}
                              </form>
                            ) : (
                              <p
                                className="c-desc c-hoverDesc"
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                  marginBottom: "15px",
                                  whiteSpace: "pre-wrap",
                                  height: "auto",
                                }}
                              >
                                {this?.state?.opportunityData?.custom_fields[
                                  name
                                ] === null
                                  ? ""
                                  : this?.state?.opportunityData?.custom_fields[
                                      name
                                    ]}{" "}
                                <button
                                  className="c-hoverDesc-button"
                                  onClick={() =>
                                    this.setState({
                                      showInputField: name,
                                    })
                                  }
                                >
                                  <img src={EditIcon} alt="Edit" width="13" />
                                </button>
                              </p>
                            )}
                          </div>
                        ) : type === "revenue" ? (
                          <div
                            key={id}
                            className="c-hoverDesc-div"
                            onClick={() =>
                              this.setState({ showInputField: name })
                            }
                          >
                            <h5 className="c-title-act">{label}</h5>
                            {showInputField === name ? (
                              <form
                                onSubmit={this.submitHandler}
                                style={{ display: "flex" }}
                              >
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  true
                                )}
                                {!this.state.isLoading && (
                                  <button
                                    type="button"
                                    className="c-hoverDesc-button-close"
                                    onClick={this.fetchOpportunitiesData}
                                  >
                                    <img src={CrossIcon} alt="Save" width="8" />
                                  </button>
                                )}
                                {this.state.isLoading ? (
                                  <div class="spinning-loader"></div>
                                ) : (
                                  <button
                                    type="submit"
                                    className="c-hoverDesc-button-save"
                                    disabled={this.state.isLoading}
                                  >
                                    <img
                                      src={SaveButtonAccount}
                                      alt="Save"
                                      width="20"
                                    />
                                  </button>
                                )}
                              </form>
                            ) : (
                              <p className="c-desc c-hoverDesc">
                                {this?.state?.opportunityData?.custom_fields[
                                  name
                                ] === null
                                  ? ""
                                  : currencySymbol.symbol +
                                    getCurrencyValue(
                                      this?.state?.opportunityData
                                        ?.custom_fields[name]
                                    )?.toLocaleString("en-US")}
                                <button
                                  className="c-hoverDesc-button"
                                  onClick={() =>
                                    this.setState({
                                      showInputField: name,
                                    })
                                  }
                                >
                                  <img src={EditIcon} alt="Edit" width="13" />
                                </button>
                              </p>
                            )}
                          </div>
                        ) : (
                          <div
                            key={id}
                            className="c-hoverDesc-div"
                            onClick={() =>
                              this.setState({ showInputField: name })
                            }
                          >
                            <h5 className="c-title-act">{label}</h5>
                            {showInputField === name ? (
                              <form
                                onSubmit={this.submitHandler}
                                style={{ display: "flex" }}
                              >
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  true
                                )}
                                {!this.state.isLoading && (
                                  <button
                                    type="button"
                                    className="c-hoverDesc-button-close"
                                    onClick={this.fetchOpportunitiesData}
                                  >
                                    <img src={CrossIcon} alt="Save" width="8" />
                                  </button>
                                )}
                                {this.state.isLoading ? (
                                  <div class="spinning-loader"></div>
                                ) : (
                                  <button
                                    type="submit"
                                    className="c-hoverDesc-button-save"
                                    disabled={this.state.isLoading}
                                  >
                                    <img
                                      src={SaveButtonAccount}
                                      alt="Save"
                                      width="20"
                                    />
                                  </button>
                                )}
                              </form>
                            ) : (
                              <p className="c-desc c-hoverDesc">
                                {this?.state?.opportunityData?.custom_fields[
                                  name
                                ] === null
                                  ? ""
                                  : this?.state?.opportunityData?.custom_fields[
                                      name
                                    ]}
                                <button
                                  className="c-hoverDesc-button"
                                  onClick={() =>
                                    this.setState({
                                      showInputField: name,
                                    })
                                  }
                                >
                                  <img src={EditIcon} alt="Edit" width="13" />
                                </button>
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Collapsible>
                </div>
              );
            })}
            {this.state.showContactModal && (
              <ContactsExistingModal
                showOpportunityModal={this.state.showContactModal}
                setShowOpportunityModal={this.handleOpenContactModal}
                handleCloseModal={this.handleCloseContactModal}
                opportunityId={this.props.opportunityId}
                contacts={contacts}
                accountId={this.state.accountId}
                showContactCreateModal={this.state.showContactCreateModal}
                handleOpenContactCreateModal={
                  this.props.isContactFieldsModified
                    ? this.handleOpenNewContactCreateModal
                    : this.handleOpenContactCreateModal
                }
                handleCloseContactCreateModal={
                  this.props.isContactFieldsModified
                    ? this.handleCloseNewContactCreateModal
                    : this.handleCloseContactCreateModal
                }
              />
            )}
            {this.state.showTagCarrierModal && (
              <TagCarrierModal
                showModal={this.state.showTagCarrierModal}
                handleCloseModal={this.handleCloseCarrierModal}
                submitHandler={this.tagCarriersToDeals}
                getAllUnTaggedCarriers={this.getAllUnTaggedCarriers}
                loading={this.state.tagCarrierLoader}
                allUntaggedCarriers={this.state.allUntaggedCarriers}
              />
            )}
            {this.state.showContactCreateModal && (
              <CreateContact
                showOpportunityModal={this.state.showContactCreateModal}
                setShowOpportunityModal={this.handleOpenContactCreateModal}
                handleCloseModal={this.handleCloseContactCreateModal}
                opportunityId={this.props.opportunityId}
              />
            )}
            {this.state.showNewContactCreateModal && (
              <CreateContactModal
                showOpportunityModal={this.state.showNewContactCreateModal}
                setShowOpportunityModal={this.handleOpenNewContactCreateModal}
                handleCloseModal={this.handleCloseNewContactCreateModal}
                opportunityId={this.props.opportunityId}
              />
            )}
            {this.state.showDeleteAttachmentModal && (
              <DeleteAttachmentModal
                showOpportunityModal={this.state.showDeleteAttachmentModal}
                setShowOpportunityModal={this.handleOpenDeleteAttachmentModal}
                handleCloseModal={this.handleCloseDeleteAttachmentModal}
                deleteAttachmentHandler={this.deleteAttachmentHandler}
                documentId={this.state?.documentId}
              />
            )}
            {this.state.showSourceModal && (
              <SourceChangeModal
                showOpportunityModal={this.state.showSourceModal}
                setShowOpportunityModal={this.handleOpenSourceModal}
                handleCloseModal={this.handleCloseSourceModal}
                fetchAccountData={this.fetchOpportunitiesData}
                getSources={this.props.fetchAllAccountSources}
                allAccountSources={this.props.allAccountSources}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    isContactFieldsModified: state.allContactFields.isModified,
    storedRecordCollapsible: state.storedRecordCollapsible.fields,
    storedRecordSelectable: state.storedRecordSelectable.fields,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    setStoredRecordCollapsible: (data) =>
      dispatch(setStoredRecordCollapsible(data)),
    setStoredRecordSelectable: (data) =>
      dispatch(setStoredRecordSelectable(data)),
    removeStoredRecordCollapsible: (data) =>
      dispatch(removeStoredRecordCollapsible(data)),
    removeStoredRecordSelectable: (data) =>
      dispatch(removeStoredRecordSelectable(data)),
    fetchAllAccountSources: (data) => dispatch(fetchAllAccountSources(data)),
  };
};

export default connect(MSP, MDP)(withRouter(OpportunitiesSection));
