import React, { useEffect, useState } from "react";
import "../../sass/Reports.scss";
import "../EmailAnalytics/EmailAnalytics.scss";
import axios from "axios";
import Pagination from "react-js-pagination";
import { debounce } from "lodash";
import { Col, Row } from "react-grid-system";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import LineChartAnalytics from "../EmailAnalytics/LineGraphAnalytics";
import DropdownAnalyticsFilter from "../Dropdown/DropdownAnalyticsFilter";
import DropdownCustomRangeFilter from "../Dropdown/DropdownCustomRangeFilter";
import { truncateDecimals } from "../../utils/Helper/reusableFunctions";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
//images
import clickEmailIcon from "../../images/clickEmailIcon.png";
import openEmailIcon from "../../images/openEmailIcon.png";
import replyEmailIcon from "../../images/replyEmailIcon.png";
import spamEmailIcon from "../../images/spamEmailIcon.png";
import totalSentIcon from "../../images/totalSentIcon.png";
import undeliveredEmailIcon from "../../images/undeliveredEmailIcon.png";
import unsubscribeEmailIcon from "../../images/unsubscribe.png";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";

const Campaign = (props) => {
  const [reportData, setReportData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sortDirection, setSortDirection] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(["active"]);
  const [selectedDateFilter, setSelectedDateFilter] = useState("last_4_weeks");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [analyticsMode, setAnalyticsMode] = useState("table");
  const [chartSeries, setChartSeries] = useState([]);
  const [user_ids, setUser_ids] = useState(props.authUser.id);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.authUser.name);
  const [metrics, setMetrics] = useState({
    bounced: { count: 0, rate: 0 },
    clicked: { count: 0, rate: 0 },
    replied: { count: 0, rate: 0 },
    opened: { count: 0, rate: 0 },
    sent: 0,
    spam: { count: 0, rate: 0 },
    unsubscribed: { count: 0, rate: 0 },
  });
  // constants
  const grids = [
    {
      name: "total sent",
      img: totalSentIcon,
      matrix: metrics.sent,
      matrixPercentage: null,
      type: "sent",
    },
    {
      name: "opens",
      img: openEmailIcon,
      matrix: metrics.opened.count,
      matrixPercentage: metrics.opened.rate,
      type: "opened",
    },
    {
      name: "clicks",
      img: clickEmailIcon,
      matrix: metrics.clicked.count,
      matrixPercentage: metrics.clicked.rate,
      type: "clicked",
    },
    {
      name: "replies",
      img: replyEmailIcon,
      matrix: metrics.replied.count,
      matrixPercentage: metrics.replied.rate,
      type: "replied",
    },
    {
      name: "bounces",
      img: undeliveredEmailIcon,
      matrix: metrics.bounced.count,
      matrixPercentage: metrics.bounced.rate,
      type: "bounced",
    },
    {
      name: "spam",
      img: spamEmailIcon,
      matrix: metrics.spam.count,
      matrixPercentage: metrics.spam.rate,
      type: "spam",
    },
    {
      name: "unsubscribes",
      img: unsubscribeEmailIcon,
      matrix: metrics.unsubscribed.count,
      matrixPercentage: metrics.unsubscribed.rate,
      type: "unsubscribed",
    },
  ];
  const dateFilters = [
    { label: "Last 7 days", value: "last_7_days" },
    { label: "Month to date", value: "this_month" },
    { label: "Last 4 weeks", value: "last_4_weeks" },
    { label: "Last 3 months", value: "last_3_months" },
    { label: "Last 6 months", value: "last_6_months" },
    { label: "Last 12 months", value: "last_12_months" },
    { label: "All time", value: "all_time" },
  ];
  const filters = ["active", "paused", "completed"];
  // functions
  // const filterHandler = (e) => {
  //   const { value } = e.target;
  //   if (e.target.checked) {
  //     const newFilters = [...selectedFilters, value];
  //     setSelectedFilters(newFilters);
  //   } else {
  //     const removeFilter = selectedFilters.filter((filter) => filter !== value);
  //     setSelectedFilters(removeFilter);
  //   }
  // };
  const dateChangeHandler = (date, name) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    name === "toDate" ? setToDate(offsetDate) : setFromDate(offsetDate);
  };
  const dateFilterChangeHandler = (value) => {
    console.log(value);
    setSelectedDateFilter(value);
    setToDate("");
    setFromDate("");
  };
  const applyCustomDateFilter = () => {
    setSelectedDateFilter("custom_range");
    fetchReports(1, null, user_ids);
  };

  const fetchReports = debounce(
    (pageParam = 1, sortKey = "created_at", user_ids = props.authUser.id) => {
      let url1 = `/email-campaigns/list?_limit=30&_page=${pageParam}`;
      let url2 = `/email-campaigns/stats-charts?_limit=30&_page=${pageParam}`;
      let url3 = `/email-campaigns/stats?_limit=30&_page=${pageParam}`;
      if (sortKey) {
        const dir = sortDirection === true ? "desc" : "asc";
        url1 += `&sort_key=${sortKey}&sort_dir=${dir}`;
        url2 += `&sort_key=${sortKey}&sort_dir=${dir}`;
        url3 += `&sort_key=${sortKey}&sort_dir=${dir}`;
      }
      if (selectedDateFilter === "custom_range") {
        url1 += `&start_date_from=${moment(fromDate).format(
          "YYYY-MM-DD"
        )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
        url2 += `&start_date_from=${moment(fromDate).format(
          "YYYY-MM-DD"
        )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
        url3 += `&start_date_from=${moment(fromDate).format(
          "YYYY-MM-DD"
        )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
      } else {
        url1 += `&time=${selectedDateFilter}`;
        url2 += `&time=${selectedDateFilter}`;
        url3 += `&time=${selectedDateFilter}`;
      }
      if (user_ids) {
        url1 += `&user_ids=${user_ids}`;
        url2 += `&user_ids=${user_ids}`;
        url3 += `&user_ids=${user_ids}`;
      }
      setLoading(true);
      const requestOne = axios.get(url1);
      const requestTwo = axios.get(url2);
      const requestThree = axios.get(url3);
      axios
        .all([requestOne, requestTwo, requestThree])
        .then(
          axios.spread((...responses) => {
            const responseOne = responses[0];
            const responesTwo = responses[1];
            const responesThree = responses[2];
            setReportData(responseOne.data.campaigns);
            setTotal(responseOne.data.total);
            setChartSeries(responesTwo.data.chart_data);
            setMetrics(responesThree.data.stats);
            setLoading(false);
          })
        )
        .catch((err) => {
          setLoading(false);
        });
    },
    500
  );
  const handleUserId = (user_ids, userName) => {
    setUser_ids(user_ids);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    fetchReports(1, null, user_ids);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };
  useEffect(() => {
    fetchReports(1, null, user_ids);
  }, [selectedDateFilter]);
  const tableHeaders = [
    { key: "name", title: "Name", sort: true },
    { key: "enrolled", title: "Records Enrolled", sort: true },
    { key: "delivered", title: "Delivered", sort: true },
    { key: "opened", title: "Opened", sort: true },
    { key: "replied", title: "Replied", sort: true },
    { key: "bounced", title: "Bounced", sort: true },
    { key: "clicked", title: "Clicked", sort: true },
    { key: "spam", title: "Spam", sort: true },
    { key: "unsubscribed", title: "Unsubscribed", sort: true },
    { key: "status", title: "Status", sort: false },
    { key: "created_at", title: "Sent at", sort: true },
  ];

  return (
    <div className="reports">
      <ReactTooltip multiline={true} id="beta-tooltip" place="right" />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>Bulk Email</h2>
          <p> </p>
        </div>
        <div style={{ float: "right" }}>
          <DropdownDashboard
            handleUserId={handleUserId}
            user_ids={user_ids}
            userName={props.authUser.name}
            userId={props.authUser.id}
            displayMenu={displayMenu}
            showDropdownMenu={showDropdownMenu}
            displayUserName={displayUserName}
            userRole={props.authUser.role}
          />
        </div>
      </div>
      <div className="email-analytics-filters">
        <h3>Filters: </h3>
        <div style={{ display: "flex" }}>
          <div className="email-analytics-range" style={{ marginLeft: "0" }}>
            <DropdownAnalyticsFilter
              selectedDateFilter={selectedDateFilter}
              dateFilters={dateFilters}
              dateFilterChangeHandler={dateFilterChangeHandler}
              toDate={toDate}
              fromDate={fromDate}
            />
          </div>
          <div style={{ margin: "0 20px 0 0" }}>
            <DropdownCustomRangeFilter
              toDate={toDate}
              fromDate={fromDate}
              dateChangeHandler={dateChangeHandler}
              submitHandler={applyCustomDateFilter}
            />
          </div>
        </div>
      </div>
      <div className="email-analytics-grid">
        {grids.map((grid) => {
          let newTabData = {
            type: "emailCampaignMetrices",
            id: grid.type,
            name: grid.name,
            campaignType: grid.type,
            time: selectedDateFilter,
            start_time: fromDate,
            end_time: toDate,
          };
          console.log("grid", grid);
          return (
            <div
              className="email-analytics-metrics"
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({ ...newTabData, blank: true });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab(newTabData);
                  props.history.push("/active-tabs");
                }
              }}
            >
              <img src={grid.img} />
              <div>
                <div className="email-analytics-amount">
                  {grid.matrixPercentage === null
                    ? grid?.matrix
                    : `${truncateDecimals(grid?.matrixPercentage, 1)}%`}
                </div>
                <div className="email-analytics-infoname">{grid.name}</div>
                <div className="email-analytics-info">
                  {grid.matrixPercentage !== null &&
                    `${grid?.matrix} ${grid.name}`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="reports-tab-container">
        <button
          className="reports-tab"
          type="button"
          onClick={() => setAnalyticsMode("table")}
          data-tip="Tabular view"
        >
          <i className="fa fa-table" aria-hidden="true"></i>
        </button>
        <button
          className="reports-tab"
          type="button"
          onClick={() => setAnalyticsMode("line")}
          data-tip="Line chart"
        >
          <i className="fa fa-line-chart" aria-hidden="true"></i>
        </button>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : loading === false && total === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No campaign found
        </div>
      ) : analyticsMode === "table" ? (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <thead>
            <tr>
              {tableHeaders.map((header) => (
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      {header.title}
                    </Col>
                    {header.sort && (
                      <Col lg={3} xl={3}>
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            color: "#657885",
                          }}
                          onClick={() => {
                            setSortDirection(!sortDirection);
                            fetchReports(activePage, header.key, user_ids);
                          }}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                          <i className="fa fa-long-arrow-down"></i>
                        </button>
                      </Col>
                    )}
                  </Row>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {reportData?.map((report) => {
              let newTabData = {
                type: "campaign",
                id: report.id,
                name: report.name,
              };
              const {
                id,
                name,
                opened,
                spam,
                unsubscribed,
                created_at,
                delivered,
                replied,
                bounced,
                clicked,
                status,
                enrolled,
              } = report;
              return (
                <tr key={id}>
                  <td
                    style={{ cursor: "pointer", color: "#4A9876" }}
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        props.setTab({ ...newTabData, blank: true });
                      } else {
                        props.setActiveTabIndex(props.totalTabs + 1);
                        props.setTab(newTabData);
                        props.history.push("/active-tabs");
                      }
                    }}
                  >
                    {name}
                  </td>
                  <td>{enrolled}</td>
                  <td>{delivered}</td>
                  <td>{opened}</td>
                  <td>{replied}</td>
                  <td>{bounced}</td>
                  <td>{clicked}</td>
                  <td>{spam}</td>
                  <td>{unsubscribed}</td>
                  <td>{status}</td>
                  <td>{moment(created_at).format("LLL")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <LineChartAnalytics series={chartSeries} />
      )}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          fetchReports(page, null, user_ids);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(Campaign);
