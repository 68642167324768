import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";

const SDSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#4A9876",
    "&:hover": {
      backgroundColor: alpha("#4A9876", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#4A9876",
  },
}));

export default function SalesdashSwitch({ onChange, checked, label }) {
  return (
    <FormControlLabel
      style={{
        color: "#525B69",
      }}
      control={
        <SDSwitch
          checked={checked}
          onChange={onChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={label}
    />
  );
}
