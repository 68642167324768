import React, { Component } from "react";
import "../../sass/PaymentCards.scss";
import PaymentForm from "../Pricing/PaymentForm";
import axios from "axios";
import { BASE_URL } from "../../config";
import { Container, Row, Col } from "react-grid-system";
import moment from "moment";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import PlanChangeModal from "./PlanChangeModal";
import ChangeBillingModal from "./ChangeBillingModal";
import CancelSubscriptionConfirmModal from "./CancelSubscriptionConfirmModal";
import PlanChangeSuccessModal from "./PlanChangeSuccessModal";
import FreePlanModal from "./FreePlanModal";
import ReactTooltip from "react-tooltip";
import BillingCreditModal from "../Reports/BillingCreditModal";
import { NotificationManager } from "react-notifications";
import ReusableModal from "../Reusables/ReusableModal";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";
import {
  fortyNineUsdCompanies,
  thirtyThreeUsdCompanies,
} from "./constants/companiesOverride";

class Cards extends Component {
  state = {
    plan: "monthly",
    package: null,
    showForm: false,
    customerId: "",
    subscriptionDetails: {},
    paymentMethods: [],
    customerPlans: {},
    showCancelModal: false,
    showCancelConfirmModal: false,
    showPlanModal: false,
    showChangeSuccessModal: false,
    showBillingModal: false,
    showCreditsModal: false,
    isLoading: false,
    showConfirmModal: false,
    showFreePlanModal: false,
    plans: [],
    emailCredits: 0,
    stripePortalLink: "",
    stripePortalLoading: false,
    selectedPlan: {},
    recordsLimit: 0,
    recordsCreated: 0,
    showRecordsAddonModal: false,
    recordsAddonLoader: false,
    addonPlans: [],
    activePlans: [],
    primaryPlanInfo: {
      name: null,
      price: null,
    },
    recordsAddonPlanInfo: {
      name: null,
      price: null,
    },
    planLoader: true,
  };
  handleOpenConfirmModal = () => {
    this.setState({
      showConfirmModal: true,
    });
  };

  handleCloseConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
    });
  };
  handleOpenRecordsAddonModal = () => {
    this.setState({
      showRecordsAddonModal: true,
    });
  };

  handleCloseRecordsAddonModal = () => {
    this.setState({
      showRecordsAddonModal: false,
    });
  };

  handleOpenFreePlanModal = () => {
    this.setState({ showFreePlanModal: true });
  };
  handleCloseFreePlanModal = () => {
    this.setState({ showFreePlanModal: false });
  };
  handleOpenModal = () => {
    this.setState({ showCancelModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showCancelModal: false });
  };
  handleCreditsOpenModal = () => {
    this.setState({
      showCreditsModal: true,
    });
  };
  handleCreditsCloseModal = () => {
    this.setState({
      showCreditsModal: false,
    });
  };
  handleOpenChangeSuccessModal = () => {
    this.setState({ showChangeSuccessModal: true });
  };
  handleCloseChangeSuccessModal = () => {
    this.setState({ showChangeSuccessModal: false });
  };
  handleOpenCancelConfirmModal = () => {
    this.setState({ showCancelConfirmModal: true });
  };
  handleCloseCancelConfirmModal = () => {
    this.setState({ showCancelConfirmModal: false });
  };
  handleOpenPlanModal = () => {
    this.setState({ showPlanModal: true });
  };
  handleClosePlanModal = () => {
    this.setState({ showPlanModal: false });
  };
  handleOpenBillingModal = () => {
    this.setState({ showBillingModal: true });
  };
  handleCloseBillingModal = () => {
    this.setState({ showBillingModal: false });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.subscriptionDetails !== this.state.subscriptionDetails) {
      if (
        this.state.paymentMethods.length !== 0 &&
        this.state.subscriptionDetails.status === "active"
      ) {
        this.retrieveUpcomingInvoice(
          this.state.subscriptionDetails.plan_id,
          this.state.subscriptionDetails.records_plan_id
        );
      }
    }
  }
  checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      this.setState({
        emailCredits: res.data.postmark_email_balance,
      });
    });
  };
  createStripeCustomer = () => {
    axios({
      method: "POST",
      url: `/payments/create-customer`,
    })
      .then((res) => {
        this.getStripePortalLink();
      })
      .catch((err) => {
        NotificationManager.error("Can not fetch stripe portal at the moment.");
      });
  };
  getStripePortalLink = () => {
    axios({
      method: "GET",
      url: `/payments/create-customer-portal-session`,
    })
      .then((res) => {
        const url = res.data.url;
        this.setState({
          stripePortalLink: url,
          stripePortalLoading: false,
        });
        window.open(url, "_blank");
      })
      .catch((err) => {
        NotificationManager.error("Can not fetch stripe portal at the moment.");
      });
  };
  checkIsStripeCustomer = () => {
    this.setState({
      stripePortalLoading: true,
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/payments/config`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        if (res.data.stripe_cus_id) {
          this.getStripePortalLink();
        } else {
          this.createStripeCustomer();
        }
      })
      .catch((err) => {
        NotificationManager.error("Can not fetch stripe portal at the moment.");
      });
  };
  componentDidMount() {
    this.checkVerification();
    this.getSubscriptionDetails();
    this.getRecordsProgress();
    if (
      this.state.paymentMethods.length !== 0 &&
      this.state.subscriptionDetails.status === "active"
    ) {
      this.retrieveUpcomingInvoice();
    }
    axios({
      method: "GET",
      url: `${BASE_URL}/payments/config`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) => {
      this.setState({ customerId: res.data.stripe_cus_id });
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/payments/method`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) => {
      this.setState({ paymentMethods: res.data.payment_methods });
    });
  }
  getSubscriptionDetails = () => {
    this.setState({
      planLoader: true,
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/payments/subscription`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) => {
      this.getCustomerPlans(
        new Date(res.data.subscription.trial_start_date) >
          new Date("2023-02-06T23:59:59.000Z"),
        new Date(res.data.subscription.trial_start_date) >
          new Date("2023-11-15T23:59:59.000Z"),
        (res.data.subscription.date_subscribed &&
          new Date(res.data.subscription.date_subscribed) >
            new Date("2024-12-15T23:59:59.000Z")) ||
          res.data.subscription.date_subscribed === null,
        res.data.subscription.plan_id,
        res.data.subscription.records_plan_id
      );
      res.data.subscription.plan_id &&
        this.retrieveUpcomingInvoice(res.data.subscription.plan_id);
      this.setState({
        subscriptionDetails: res.data.subscription,
        latestInvoicePaymentIntentStatusState:
          res.data.latest_invoice?.payment_intent_status,
        latestInvoiceId: res.data.latest_invoice?.stripe_invoice_id,
        activePlans: res.data.active_Plans,
        planLoader: false,
      });
    });
  };
  getRecordsProgress = () => {
    axios({
      method: "GET",
      url: `/company/check-records-limit`,
    }).then((res) => {
      this.setState({
        recordsLimit: res.data.records_limit,
        recordsCreated: res.data.total_records_created,
      });
    });
  };
  retrieveUpcomingInvoice = (planId) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/payments/retrieve-upcoming-invoice`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        primary_plan_id: planId,
      },
    }).then((res) => {
      this.setState({
        priceToDisplayInCard:
          res.data?.invoice?.lines.data[0].plan.amount / 100,
        userToDisplayInCard: res.data?.invoice?.lines.data[0].quantity,
        subPriceToDisplayInCard: res.data?.invoice?.subtotal / 100,
        totalPriceToDisplayInCard: res.data?.invoice?.total / 100, //discount_amounts
        discountAmount:
          res.data?.invoice?.lines.data[0].discount_amounts.length > 0
            ? res.data?.invoice?.lines.data[0].discount_amounts[0].amount / 100
            : null,
      });
    });
  };
  planChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      this.setState({ plan: "annually" });
    } else {
      this.setState({ plan: "monthly" });
    }
  };
  changePlan = (planId) => {
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `${BASE_URL}/payments/update-subscription`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        // subscription_id: this.state.subscriptionDetails.id,
        primary_plan_id: planId,
      },
    })
      .then((res) => {
        this.handleCloseConfirmModal();
        this.handleCloseBillingModal();
        this.handleOpenChangeSuccessModal();
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  subscribeAddonPlan = () => {
    this.setState({
      recordsAddonLoader: true,
    });
    const subscribedPlanIds = this.state.activePlans
      ?.filter((plan) => plan.type === "addon")
      ?.map((plan) => plan.plan_id);
    const subscribedPlan = this.state.addonPlans.find((plan) =>
      subscribedPlanIds.includes(plan.id)
    );
    if (subscribedPlan && subscribedPlan.records_limit >= 500000) {
      NotificationManager.error("You have reached the records limit.");
      this.setState({
        recordsAddonLoader: false,
      });
      return false;
    }
    const availablePlans = this.state.addonPlans.filter(
      (plan) => !subscribedPlanIds.includes(plan.id)
    );
    const planToSubscribe = availablePlans?.find((plan) => {
      if (!subscribedPlan) {
        return plan.records_limit === 50000;
      }
      return plan.records_limit === subscribedPlan.records_limit + 50000;
    });
    if (availablePlans.length === 0) {
      NotificationManager.error("You have reached the records limit.");
      this.setState({
        recordsAddonLoader: false,
      });
      return false;
    }
    if (!planToSubscribe) {
      NotificationManager.error("You have reached the records limit.");
      this.setState({
        recordsAddonLoader: false,
      });
      return false;
    }
    axios({
      url: `/payments/add-on-plan`,
      method: "POST",
      data: {
        records_plan_id: planToSubscribe.id,
      },
    })
      .then((res) => {
        NotificationManager.success("Add-on subscribed.");
        this.getRecordsProgress();
        this.getSubscriptionDetails();
        this.retrieveUpcomingInvoice(this.state.subscriptionDetails.plan_id);
        this.setState({
          recordsAddonLoader: false,
          showRecordsAddonModal: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error subscribing add-on");
        this.setState({
          recordsAddonLoader: false,
        });
      });
  };
  cancelSubscription = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/payments/cancel-subscription`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        subscription_id: this.state.subscriptionDetails.id,
      },
    }).then((res) => this.handleOpenCancelConfirmModal());
  };
  setLatestInvoicePaymentIntentStatusState = (
    latestInvoicePaymentIntentStatusState
  ) => {
    this.setState({ latestInvoicePaymentIntentStatusState });
  };
  setLatestInvoiceId = (latestInvoiceId) => {
    this.setState({ latestInvoiceId });
  };
  getCustomerPlans = (
    trialDateLimitExceeded,
    carrierDateLimitExceeded,
    dec15LimitExceeded,
    subPlanId,
    recordsPlanId
  ) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/payments/plans`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) => {
      const selectedPlan = res.data.plans?.find(
        (plan) => plan.id === subPlanId
      );
      const addonPlanInfo = res.data.plans?.find(
        (plan) => plan.id === recordsPlanId
      );
      this.setState({
        plans: res.data.plans?.filter((plan) => {
          if (
            thirtyThreeUsdCompanies.includes(
              this.props.companyOnboarding?.id?.toString()
            )
          ) {
            return (
              (plan.type === "primary" && plan.price === 33) ||
              (plan.type === "primary" && plan.price === 316.8)
            );
          } else if (
            fortyNineUsdCompanies.includes(
              this.props.companyOnboarding?.id?.toString()
            )
          ) {
            return (
              (plan.type === "primary" && plan.price === 49) ||
              (plan.type === "primary" && plan.price === 470.8)
            );
          } else if (dec15LimitExceeded) {
            return (
              (plan.type === "primary" && plan.price === 59) ||
              (plan.type === "primary" && plan.price === 566.4)
            );
          } else if (carrierDateLimitExceeded) {
            console.log("dec 15 nhi hua");
            return (
              (plan.type === "primary" && plan.price === 39) ||
              (plan.type === "primary" && plan.price === 374.4)
            );
          } else if (trialDateLimitExceeded) {
            return (
              (plan.type === "primary" && plan.price === 33) ||
              (plan.type === "primary" && plan.price === 316.8)
            );
          } else {
            return (
              (plan.type === "primary" && plan.price === 20) ||
              (plan.type === "primary" && plan.price === 192)
            );
          }
        }),
        addonPlans: res.data.plans?.filter(
          (plan) =>
            plan.addon_type === "records" &&
            plan.type === "addon" &&
            plan.interval === selectedPlan?.interval
        ),
        primaryPlanInfo: selectedPlan
          ? {
              name: `${selectedPlan.name} ${
                selectedPlan.interval === "year" ? "Annually" : "Monthly"
              }`,
              price: selectedPlan.price,
            }
          : { name: null, price: null },
        recordsAddonPlanInfo: addonPlanInfo
          ? {
              name: addonPlanInfo.name,
              price: addonPlanInfo.price,
            }
          : { name: null, price: null },
        selectedPlan,
        planNameToDisplay: `${selectedPlan?.name} ${
          selectedPlan?.interval === "month" ? "Monthly" : "Annually"
        }`,
      });
      const plans = res.data.plans?.filter((plan) => {
        if (
          thirtyThreeUsdCompanies.includes(
            this.props.companyOnboarding?.id?.toString()
          )
        ) {
          return (
            (plan.type === "primary" && plan.price === 33) ||
            (plan.type === "primary" && plan.price === 316.8)
          );
        } else if (
          fortyNineUsdCompanies.includes(
            this.props.companyOnboarding?.id?.toString()
          )
        ) {
          return (
            (plan.type === "primary" && plan.price === 49) ||
            (plan.type === "primary" && plan.price === 470.4)
          );
        } else if (dec15LimitExceeded) {
          return (
            (plan.type === "primary" && plan.price === 59) ||
            (plan.type === "primary" && plan.price === 566.4)
          );
        } else if (carrierDateLimitExceeded) {
          return (
            (plan.type === "primary" && plan.price === 39) ||
            (plan.type === "primary" && plan.price === 374.4)
          );
        } else if (trialDateLimitExceeded) {
          return (
            (plan.type === "primary" && plan.price === 33) ||
            (plan.type === "primary" && plan.price === 316.8)
          );
        } else {
          return (
            (plan.type === "primary" && plan.price === 20) ||
            (plan.type === "primary" && plan.price === 192)
          );
        }
      });
      if (this.state.plan === "monthly" && this.state.package === "pro") {
        this.setState(
          {
            customerPlans: plans.find(
              (plan) =>
                (plan.name === "Pro" || plan.name === "Pro Monthly") &&
                plan.interval === "month"
            ),
          },
          this.retrieveUpcomingInvoice(
            plans.find(
              (plan) =>
                (plan.name === "Pro" || plan.name === "Pro Monthly") &&
                plan.interval === "month"
            ).id,
            recordsPlanId
          )
        );
      }
      if (this.state.plan === "annually" && this.state.package === "pro") {
        this.setState(
          {
            customerPlans: plans.find(
              (plan) =>
                (plan.name === "Pro" || plan.name === "Pro Annually") &&
                plan.interval === "year"
            ),
          },
          this.retrieveUpcomingInvoice(
            plans.find(
              (plan) =>
                (plan.name === "Pro" || plan.name === "Pro Annually") &&
                plan.interval === "year"
            ).id,
            recordsPlanId
          )
        );
      }
      if (this.state.plan === "monthly" && this.state.package === "premium") {
        this.setState(
          {
            customerPlans: plans.find(
              (plan) => plan.name === "Premium" && plan.interval === "month"
            ),
          },
          this.retrieveUpcomingInvoice(
            plans.find(
              (plan) => plan.name === "Premium" && plan.interval === "month"
            ).id,
            recordsPlanId
          )
        );
      }
      if (this.state.plan === "annually" && this.state.package === "premium") {
        this.setState(
          {
            customerPlans: plans.find(
              (plan) => plan.name === "Premium" && plan.interval === "year"
            ),
          },
          this.retrieveUpcomingInvoice(
            plans.find(
              (plan) => plan.name === "Premium" && plan.interval === "year"
            ).id,
            recordsPlanId
          )
        );
      }
      if (this.state.package === "enterprise") {
        this.setState(
          {
            customerPlans: plans.find(
              (plan) => plan.name === "Enterprise" && plan.interval === "year"
            ),
          },
          this.retrieveUpcomingInvoice(
            plans.find(
              (plan) => plan.name === "Enterprise" && plan.interval === "year"
            ).id,
            recordsPlanId
          )
        );
      }
      if (this.state.package === "free") {
        this.setState(
          {
            customerPlans: plans.find(
              (plan) => plan.name === "Free" && plan.interval === "year"
            ),
          },
          this.retrieveUpcomingInvoice(
            plans.find(
              (plan) => plan.name === "Free" && plan.interval === "year"
            ).id,
            recordsPlanId
          )
        );
      }
    });
  };

  render() {
    const trialDateLimit = new Date("2023-02-06T23:59:59.000Z");
    const carrierDateLimit = new Date("2023-11-15T23:59:59.000Z");
    const dec15DateLimit = new Date("2024-12-15T23:59:59.000Z");
    console.log("this.state", this.state);
    return (
      <div style={{ margin: "0px 50px" }}>
        {this.state.paymentMethods.length !== 0 &&
        (this.state.subscriptionDetails.status === "active" ||
          this.state.subscriptionDetails.status === "past_due") &&
        this.state.subscriptionDetails.plan_id !==
          this.state.plans?.find(
            (plan) => plan.name === "Free" && plan.interval === "year"
          )?.id ? (
          <Container>
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid #E3E3E3",
                marginBottom: "10px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <h2 style={{ marginBottom: "0px" }}>Billing Overview</h2>
                <p>Payment details and plan you’re currently subscribed to</p>
              </div>
              <button
                className="button-md"
                onClick={this.checkIsStripeCustomer}
                disabled={this.state.stripePortalLoading}
                style={{ transform: "translateY(25px)" }}
              >
                {this.state.stripePortalLoading
                  ? "Switching to Stripe Portal..."
                  : "Switch to Stripe Portal"}
              </button>
            </div>
            <Row>
              <Col md={6} lg={6} xl={6}>
                <div
                  style={{
                    boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                    borderRadius: "8px",
                    width: "480px",
                    height: "280px",
                    paddingLeft: "20px",
                    marginTop: "10px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#383A39",
                  }}
                >
                  <Row>
                    <Col>
                      <h4 style={{ fontSize: "20px", color: "#0C0D0D" }}>
                        Plan Details
                      </h4>
                    </Col>
                    <Col>
                      <button
                        type="button"
                        onClick={this.handleOpenPlanModal}
                        style={{
                          border: "none",
                          background: "transparent",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#4A9876",
                          textDecoration: "underline",
                          outline: "none",
                          cursor: "pointer",
                          marginTop: "30px",
                        }}
                      >
                        Change Plan
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ lineHeight: "26px" }}>Plan Name</Col>
                    <Col style={{ lineHeight: "26px" }}>
                      {this.state.primaryPlanInfo.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ lineHeight: "26px" }}>Billing Per User</Col>
                    <Col style={{ lineHeight: "26px" }}>
                      ${this.state.primaryPlanInfo.price}
                    </Col>
                  </Row>
                  {this.state.recordsAddonPlanInfo.name && (
                    <>
                      <Row>
                        <Col style={{ lineHeight: "26px" }}>
                          Records Add-on Plan Name
                        </Col>
                        <Col style={{ lineHeight: "26px" }}>
                          {this.state.recordsAddonPlanInfo.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ lineHeight: "26px" }}>
                          Add-on Cost Per User
                        </Col>
                        <Col style={{ lineHeight: "26px" }}>
                          ${this.state.recordsAddonPlanInfo.price}
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col style={{ lineHeight: "26px" }}>No. of user(s)</Col>
                    <Col style={{ lineHeight: "26px" }}>
                      {this.state.userToDisplayInCard}
                    </Col>
                  </Row>
                  {this.state.discountAmount && (
                    <Row>
                      <Col style={{ lineHeight: "26px" }}>Discount</Col>
                      <Col style={{ lineHeight: "26px" }}>
                        ${this.state.discountAmount}
                      </Col>
                    </Row>
                  )}
                  <div
                    style={{
                      border: "1px solid #E3E3E3",
                      margin: "16px 0",
                    }}
                  ></div>
                  <Row>
                    <Col style={{ lineHeight: "26px" }}>Total Billing</Col>
                    <Col style={{ lineHeight: "26px" }}>
                      $
                      {this.state.totalPriceToDisplayInCard?.toLocaleString(
                        "en-US"
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} lg={6} xl={6}>
                <div
                  style={{
                    boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                    borderRadius: "8px",
                    width: "480px",
                    height: "280px",
                    paddingLeft: "20px",
                    marginTop: "10px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#383A39",
                  }}
                >
                  <Row>
                    <Col>
                      <h4 style={{ fontSize: "20px", color: "#0C0D0D" }}>
                        Subscription Details
                      </h4>
                    </Col>
                    <Col>
                      <button
                        type="button"
                        onClick={this.handleOpenModal}
                        style={{
                          border: "none",
                          background: "transparent",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#D32F2F",
                          textDecoration: "underline",
                          outline: "none",
                          cursor: "pointer",
                          marginTop: "30px",
                        }}
                      >
                        Cancel Subscription
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>Subscription Date:</Col>
                    <Col>
                      {this.state.subscriptionDetails.date_subscribed &&
                        moment(
                          this.state.subscriptionDetails.date_subscribed
                        ).format("LL")}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Next Billing Date:</Col>
                    <Col>
                      {this.state.subscriptionDetails.next_billing_date &&
                        moment(
                          this.state.subscriptionDetails.next_billing_date
                        ).format("LL")}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <Col>
                <div
                  style={{
                    boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                    borderRadius: "8px",
                    width: "480px",
                    height: "280px",
                    paddingLeft: "20px",
                    marginTop: "10px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#383A39",
                  }}
                >
                  <Row>
                    <ReactTooltip />
                    <Col>
                      <h4
                        style={
                          this.state.subscriptionDetails.status === "past_due"
                            ? {
                                fontSize: "20px",
                                color: "#0C0D0D",
                                marginBottom: "15px",
                              }
                            : { fontSize: "20px", color: "#0C0D0D" }
                        }
                      >
                        Billing Details{" "}
                      </h4>
                    </Col>
                    <Col>
                      <button
                        onClick={this.handleOpenBillingModal}
                        style={
                          this.state.subscriptionDetails.status === "past_due"
                            ? {
                                border: "1px solid #4A9876",
                                background: "#4A9876",
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "150%",
                                color: "white",
                                textDecoration: "none",
                                outline: "none",
                                cursor: "pointer",
                                marginTop: "30px",
                                marginBottom: "15px",
                              }
                            : {
                                border: "none",
                                background: "transparent",
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "150%",
                                color: "#4A9876",
                                textDecoration: "underline",
                                outline: "none",
                                cursor: "pointer",
                                marginTop: "30px",
                              }
                        }
                      >
                        {this.state.subscriptionDetails.status === "past_due"
                          ? "Update Billing Info"
                          : "Change billing details"}
                      </button>
                    </Col>
                  </Row>
                  {this.state.subscriptionDetails.status === "past_due" && (
                    <div
                      style={{
                        background: "#FEF1E1",
                        // height: "20px",
                        color: "#D7461F",
                        fontSize: "16px",
                        marginBottom: "10px",
                        padding: "4px",
                      }}
                    >
                      <span
                        data-tip="Change your billing details"
                        style={{
                          border: "1px solid #D7461F",
                          borderRadius: "50%",
                          background: "transparent",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#D7461F",
                          textDecoration: "none",
                          outline: "none",
                          cursor: "pointer",
                          padding: "1px 6px",
                        }}
                      >
                        !
                      </span>{" "}
                      Payment method needs to be updated.
                    </div>
                  )}
                  <Row>
                    <Col>Card Holder:</Col>
                    <Col>
                      {this.state.paymentMethods[0].first_name +
                        " " +
                        this.state.paymentMethods[0].last_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Payment Method:</Col>
                    <Col>
                      {this.state.paymentMethods[0].card_brand} ****{" "}
                      {this.state.paymentMethods[0].card_last_4_digits}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Billing Country:</Col>
                    <Col>{this.state.paymentMethods[0].country}</Col>
                  </Row>
                  <ChangeBillingModal
                    showOpportunityModal={this.state.showBillingModal}
                    setShowOpportunityModal={this.handleOpenBillingModal}
                    handleCloseModal={this.handleCloseBillingModal}
                    priceId={this.state.subscriptionDetails.plan_id}
                    stripeCustomerId={this.state.customerId}
                    updateForm={true}
                    subscriptionDetails={this.state.subscriptionDetails}
                    latestInvoicePaymentIntentStatusState={
                      this.state.latestInvoicePaymentIntentStatusState
                    }
                    setLatestInvoicePaymentIntentStatusState={
                      this.setLatestInvoicePaymentIntentStatusState
                    }
                    latestInvoiceId={this.state.latestInvoiceId}
                    setLatestInvoiceId={this.setLatestInvoiceId}
                    priceToDisplayInCard={this.state.priceToDisplayInCard}
                    totalPriceToDisplayInCard={
                      this.state.totalPriceToDisplayInCard
                    }
                    subPriceToDisplayInCard={this.state.subPriceToDisplayInCard}
                    discountAmount={this.state.discountAmount}
                    userToDisplayInCard={this.state.userToDisplayInCard}
                    planName={this.state.plan}
                    packageName={this.state.package}
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                    borderRadius: "8px",
                    width: "480px",
                    height: "auto",
                    paddingLeft: "20px",
                    marginTop: "10px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#383A39",
                  }}
                >
                  <Row>
                    <h4
                      style={{
                        fontSize: "20px",
                        color: "#0C0D0D",
                        marginBottom: "2px",
                      }}
                    >
                      Add ons
                    </h4>
                  </Row>
                  <Row>
                    <h5
                      style={{
                        fontSize: "16px",
                        color: "#0C0D0D",
                        margin: "5px 0",
                      }}
                    >
                      Email Created
                    </h5>
                  </Row>
                  <Row>
                    <Col>Remaining Balance</Col>
                    <Col>
                      {this.state.emailCredits?.toLocaleString("en-US")}
                    </Col>
                  </Row>
                  <Row style={{ borderBottom: "1px solid #eee" }}>
                    <button
                      className="button-md"
                      style={{
                        margin: "20px auto",
                      }}
                      onClick={this.handleCreditsOpenModal}
                    >
                      Buy 5000 more emails for $10
                    </button>
                  </Row>
                  <Row>
                    <h5
                      style={{
                        fontSize: "16px",
                        color: "#0C0D0D",
                        margin: "10px 0 5px 0",
                      }}
                    >
                      Records Credits
                    </h5>
                  </Row>
                  <p style={{ marginTop: "0", fontSize: "14px" }}>
                    Records in your CRM include Accounts, Contacts, and Deals.
                  </p>
                  <Row>
                    <Col>Records Created</Col>
                    <Col>
                      {this.state.recordsCreated?.toLocaleString("en-US")}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Records Limit</Col>
                    <Col>
                      {this.state.recordsLimit?.toLocaleString("en-US")}
                    </Col>
                  </Row>
                  <Row>
                    <button
                      className="button-md"
                      style={{
                        margin: "20px auto",
                      }}
                      onClick={this.handleOpenRecordsAddonModal}
                    >
                      Buy 50k more records for an additional $5/user monthly
                    </button>
                  </Row>
                </div>
              </Col>
            </Row>
            <CancelSubscriptionModal
              showOpportunityModal={this.state.showCancelModal}
              setShowOpportunityModal={this.handleOpenModal}
              handleCloseModal={this.handleCloseModal}
              cancelSubscription={this.cancelSubscription}
            />
            <ReusableModal
              showModal={this.state.showRecordsAddonModal}
              handleCloseModal={this.handleCloseRecordsAddonModal}
              submitHandler={this.subscribeAddonPlan}
              modalHeading="Buy 50k Additional Records"
              modalPara={`Click to confirm your purchase below for 50k additional records at $5/month per user.`}
              modalHeight={`30`}
              modalTop={`25`}
              loading={this.state.recordsAddonLoader}
            />
            <PlanChangeModal
              showOpportunityModal={this.state.showPlanModal}
              setShowOpportunityModal={this.handleOpenPlanModal}
              handleCloseModal={this.handleClosePlanModal}
              changePlan={this.changePlan}
              getCustomerPlans={this.getCustomerPlans}
              subscriptionDetails={this.state.subscriptionDetails}
              planNameToDisplay={this.state.planNameToDisplay}
              showConfirmModal={this.state.showConfirmModal}
              handleOpenConfirmModal={this.handleOpenConfirmModal}
              handleCloseConfirmModal={this.handleCloseConfirmModal}
              plans={this.state.plans}
              trialDateLimitExceeded={
                new Date(this.state.subscriptionDetails.trial_start_date) >
                trialDateLimit
              }
              carrierDateLimitExceeded={
                new Date(this.state.subscriptionDetails.trial_start_date) >
                carrierDateLimit
              }
              dec15LimitExceeded={
                (this.state.subscriptionDetails.date_subscribed &&
                  new Date(this.state.subscriptionDetails.date_subscribed) >
                    new Date("2024-12-15T23:59:59.000Z")) ||
                this.state.subscriptionDetails.date_subscribed === null
              }
            />
            <CancelSubscriptionConfirmModal
              showOpportunityModal={this.state.showCancelConfirmModal}
              setShowOpportunityModal={this.handleOpenCancelConfirmModal}
              handleCloseModal={this.handleCloseCancelConfirmModal}
            />
            <PlanChangeSuccessModal
              showOpportunityModal={this.state.showChangeSuccessModal}
              setShowOpportunityModal={this.handleOpenChangeSuccessModal}
              handleCloseModal={this.handleCloseChangeSuccessModal}
            />
            <BillingCreditModal
              showOpportunityModal={this.state.showCreditsModal}
              setShowOpportunityModal={this.handleCreditsOpenModal}
              handleCloseModal={this.handleCreditsCloseModal}
              checkUserProfile={this.checkVerification}
            />
          </Container>
        ) : (
          <div>
            {this.state.showForm ? (
              <PaymentForm
                priceId={this.state.customerPlans?.id}
                priceToDisplayInCard={this.state.priceToDisplayInCard}
                totalPriceToDisplayInCard={this.state.totalPriceToDisplayInCard}
                subPriceToDisplayInCard={this.state.subPriceToDisplayInCard}
                discountAmount={this.state.discountAmount}
                userToDisplayInCard={this.state.userToDisplayInCard}
                stripeCustomerId={this.state.customerId}
                subscriptionDetails={this.state.subscriptionDetails}
                latestInvoicePaymentIntentStatusState={
                  this.state.latestInvoicePaymentIntentStatusState
                }
                setLatestInvoicePaymentIntentStatusState={
                  this.setLatestInvoicePaymentIntentStatusState
                }
                setLatestInvoiceId={this.setLatestInvoiceId}
                latestInvoiceId={this.state.latestInvoiceId}
                planName={this.state.plan}
                packageName={this.state.package}
              />
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #E3E3E3",
                    marginBottom: "10px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <h2 style={{ marginBottom: "0px" }}>Pricing & Billing</h2>
                    <p>Select a plan for your team</p>
                  </div>
                  <button
                    className="button-md"
                    onClick={this.checkIsStripeCustomer}
                    disabled={this.state.stripePortalLoading}
                    style={{ transform: "translateY(25px)" }}
                  >
                    {this.state.stripePortalLoading
                      ? "Switching to Stripe Portal..."
                      : "Switch to Stripe Portal"}
                  </button>
                </div>
                {/* <div style={{ margin: "20px 0px" }}>
                  <span className="general-heading">Monthly &nbsp;</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      value={plan}
                      id="checkboxView"
                      onChange={this.planChange}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="general-heading">
                    {" "}
                    &nbsp;Annually (20% Off)
                  </span>
                </div> */}
                <div className="grid-container" style={{ marginTop: "25px" }}>
                  <div className="item1">
                    <h3 className="package-heading">PRO MONTHLY</h3>
                    <h3 className="package-pricing" id="proPricing">
                      $
                      {this.state.planLoader
                        ? "59"
                        : thirtyThreeUsdCompanies.includes(
                            this.props.companyOnboarding?.id?.toString()
                          )
                        ? "33"
                        : fortyNineUsdCompanies.includes(
                            this.props.companyOnboarding?.id?.toString()
                          )
                        ? "49"
                        : (this.state.subscriptionDetails.date_subscribed &&
                            new Date(
                              this.state.subscriptionDetails.date_subscribed
                            ) > new Date("2024-12-15T23:59:59.000Z")) ||
                          this.state.subscriptionDetails.date_subscribed ===
                            null
                        ? "59"
                        : !new Date(
                            this.state.subscriptionDetails.trial_start_date
                          ) > carrierDateLimit
                        ? new Date(
                            this.state.subscriptionDetails.trial_start_date
                          ) < trialDateLimit
                          ? "20"
                          : "33"
                        : "39"}
                    </h3>
                    <p className="package-heading-sub" id="proPricingDetails">
                      Per user, per month. Billed monthly.
                    </p>
                    <p>
                      <i
                        className="fa fa-check-circle"
                        style={{ color: "#3aab7b" }}
                        aria-hidden="true"
                      ></i>
                      &nbsp;&nbsp;50,000 contacts
                    </p>
                    <p>
                      <i
                        className="fa fa-check-circle"
                        style={{ color: "#3aab7b" }}
                        aria-hidden="true"
                      ></i>
                      &nbsp;&nbsp;10GB storage per user
                    </p>
                    <p>Cancel any time.</p>
                    <button
                      type="button"
                      className="try-free-button"
                      onClick={() =>
                        this.setState(
                          { showForm: true, package: "pro", plan: "monthly" },
                          this.getCustomerPlans(
                            new Date(
                              this.state.subscriptionDetails.trial_start_date
                            ) > trialDateLimit,
                            new Date(
                              this.state.subscriptionDetails.trial_start_date
                            ) > carrierDateLimit,
                            (this.state.subscriptionDetails.date_subscribed &&
                              new Date(
                                this.state.subscriptionDetails.date_subscribed
                              ) > dec15DateLimit) ||
                              this.state.subscriptionDetails.date_subscribed ===
                                null,
                            this.state.subscriptionDetails?.plan_id,
                            this.state.subscriptionDetails?.records_plan_id
                          )
                        )
                      }
                    >
                      Choose Plan
                    </button>
                  </div>
                  <div className="item1">
                    <h3 className="package-heading">
                      PRO ANNUALLY&nbsp;
                      <span style={{ color: "#3aab7b" }} id="proPricingYearly">
                        20% Off
                      </span>
                    </h3>
                    <h3 className="package-pricing" id="proPricing">
                      $
                      {this.state.planLoader
                        ? "47.2"
                        : thirtyThreeUsdCompanies.includes(
                            this.props.companyOnboarding?.id?.toString()
                          )
                        ? "26.4"
                        : fortyNineUsdCompanies.includes(
                            this.props.companyOnboarding?.id?.toString()
                          )
                        ? "39.2"
                        : (this.state.subscriptionDetails.date_subscribed &&
                            new Date(
                              this.state.subscriptionDetails.date_subscribed
                            ) > new Date("2024-12-15T23:59:59.000Z")) ||
                          this.state.subscriptionDetails.date_subscribed ===
                            null
                        ? "47.2"
                        : !new Date(
                            this.state.subscriptionDetails.trial_start_date
                          ) > carrierDateLimit
                        ? new Date(
                            this.state.subscriptionDetails.trial_start_date
                          ) < trialDateLimit
                          ? "16"
                          : "26.4"
                        : "31.2"}
                    </h3>
                    <p className="package-heading-sub" id="proPricingDetails">
                      Per user, per month. Billed annually.
                    </p>
                    <p>
                      <i
                        className="fa fa-check-circle"
                        style={{ color: "#3aab7b" }}
                        aria-hidden="true"
                      ></i>
                      &nbsp;&nbsp;50,000 contacts
                    </p>
                    <p>
                      <i
                        className="fa fa-check-circle"
                        style={{ color: "#3aab7b" }}
                        aria-hidden="true"
                      ></i>
                      &nbsp;&nbsp;10GB storage per user
                    </p>
                    <p>Cancel any time.</p>
                    <button
                      type="button"
                      className="try-free-button"
                      onClick={() =>
                        this.setState(
                          { showForm: true, package: "pro", plan: "annually" },
                          this.getCustomerPlans(
                            new Date(
                              this.state.subscriptionDetails.trial_start_date
                            ) > trialDateLimit,
                            new Date(
                              this.state.subscriptionDetails.trial_start_date
                            ) > carrierDateLimit,
                            (this.state.subscriptionDetails.date_subscribed &&
                              new Date(
                                this.state.subscriptionDetails.date_subscribed
                              ) > new Date("2024-12-15T23:59:59.000Z")) ||
                              this.state.subscriptionDetails.date_subscribed ===
                                null,
                            this.state.subscriptionDetails?.plan_id,
                            this.state.subscriptionDetails?.records_plan_id
                          )
                        )
                      }
                    >
                      Choose Plan
                    </button>
                  </div>
                </div>
                <FreePlanModal
                  changePlan={this.changePlan}
                  plans={this.state.plans}
                  subscriptionDetails={this.state.subscriptionDetails}
                  showOpportunityModal={this.state.showFreePlanModal}
                  setShowOpportunityModal={this.handleOpenFreePlanModal}
                  handleCloseModal={this.handleCloseFreePlanModal}
                  stripeCustomerId={this.state.customerId}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(Cards);
