import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import ReactTooltip from "react-tooltip";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";

function HottestFollowupDashboard(props) {
  const [contactsList, setContactsList] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [contactsLoader, setContactsLoader] = useState(false);
  const [accountsLoader, setAccountsLoader] = useState(false);

  const getContactsList = () => {
    setContactsLoader(true);
    axios({
      method: "GET",
      url: `/contacts/hottest-follow-ups?_page=1&_limit=5`,
    }).then((res) => {
      setContactsList(res.data.contacts);
      setContactsLoader(false);
    });
  };
  const getAccountsList = () => {
    setAccountsLoader(true);
    axios({
      method: "GET",
      url: `/accounts/hottest-follow-ups?_limit=5&_page=1`,
    }).then((res) => {
      setAccountsList(res.data.accounts);
      setAccountsLoader(false);
    });
  };
  // const resetLoader = () => {
  //   getContactsList();
  //   getAccountsList();
  // };
  useEffect(() => {
    getContactsList();
    getAccountsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="card">
      <ReactTooltip multiline={true} id="hottest-followup-i-tooltip" />
      <div
        className="funnel-heading"
        // style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4 className="funnel-heading-sub-1">Suggested Follow-ups </h4>
        <span
          data-tip="The most recommended follow-ups based on your data inputs and activity <br/>
            for records in your CRM that do not have a follow-up set, are not marked won/lost,
              <br/>or not marked disqualified."
          style={{
            fontSize: "10px",
            fontWeight: "400",
            color: "#4A9876",
            padding: "0px 5.3px",
            height: "14px",
            borderRadius: "50%",
            border: "1px solid #4A9876",
            marginLeft: "10px",
          }}
          data-multiline={true}
          data-for="hottest-followup-i-tooltip"
        >
          i
        </span>
      </div>
      <h4
        className="funnel-heading-sub-followup"
        onClick={(e) => {
          if (e.metaKey || e.ctrlKey) {
            props.setTab({
              id: `${props.userId}FollowUpReport`,
              name: `Suggested Follow-ups`,
              blank: true,
              userId: props?.userId,
              userName: props.userName,
              type: "followUp",
              followUpType: "contact",
            });
          } else {
            props.setActiveTabIndex(props.totalTabs + 1);
            props.setTab({
              id: `${props.userId}FollowUpReport`,
              name: `Suggested Follow-ups`,
              userId: props?.userId,
              userName: props.userName,
              type: "followUp",
              followUpType: "contact",
            });
            props.history.push("/active-tabs");
          }
        }}
      >
        Contacts
      </h4>
      {contactsLoader ? (
        <div class="load-wrapp-1">
          <div class="load-3">
            <div class="line-1"></div>
            <div class="line-1"></div>
            <div class="line-1"></div>
          </div>
        </div>
      ) : contactsList.length === 0 ? (
        <div style={{ fontSize: "14px" }}>No contacts to show</div>
      ) : (
        <table style={{ paddingLeft: "14px" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th className="funnel-amounts">Status</th>
              <th style={{ textAlign: "right" }}>Total Activities</th>
            </tr>
          </thead>
          <tbody>
            {contactsList?.map((contact) => {
              const { id, name, status, total_activities } = contact;
              let newTabData = {
                type: "contact",
                id: id,
                name: name,
              };
              return (
                <tr key={id}>
                  <td
                    className="funnel-reasons"
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        props.setTab({ ...newTabData, blank: true });
                      } else {
                        props.setActiveTabIndex(props.totalTabs + 1);
                        props.setTab(newTabData);
                        props.history.push("/active-tabs");
                      }
                    }}
                    style={{
                      color: "#3aab7b",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    {name}
                  </td>
                  <td
                    className="funnel-amounts"
                    style={{ textTransform: "capitalize" }}
                  >
                    {status}
                  </td>
                  <td style={{ textAlign: "right" }}>{total_activities}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <h4
        className="funnel-heading-sub-followup"
        style={{ marginTop: "25px" }}
        onClick={(e) => {
          if (e.metaKey || e.ctrlKey) {
            props.setTab({
              id: `${props.userId}FollowUpReportAccount`,
              name: `Suggested Follow-ups`,
              blank: true,
              userId: props?.userId,
              userName: props.userName,
              type: "followUp",
              followUpType: "account",
            });
          } else {
            props.setActiveTabIndex(props.totalTabs + 1);
            props.setTab({
              id: `${props.userId}FollowUpReportAccount`,
              name: `Suggested Follow-ups`,
              userId: props?.userId,
              userName: props.userName,
              type: "followUp",
              followUpType: "account",
            });
            props.history.push("/active-tabs");
          }
        }}
      >
        Accounts
      </h4>
      {accountsLoader ? (
        <div class="load-wrapp-1">
          <div class="load-3">
            <div class="line-1"></div>
            <div class="line-1"></div>
            <div class="line-1"></div>
          </div>
        </div>
      ) : accountsList.length === 0 ? (
        <div style={{ fontSize: "14px" }}>No accounts to show</div>
      ) : (
        <table style={{ paddingLeft: "14px" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th className="funnel-amounts">Status</th>
              <th style={{ textAlign: "right" }}>Total Activities</th>
            </tr>
          </thead>
          <tbody>
            {accountsList?.map((account) => {
              const { id, name, status, total_activities } = account;
              let newTabData = {
                type: "account",
                id: id,
                name: name,
              };
              return (
                <tr key={id}>
                  <td
                    className="funnel-reasons"
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        props.setTab({ ...newTabData, blank: true });
                      } else {
                        props.setActiveTabIndex(props.totalTabs + 1);
                        props.setTab(newTabData);
                        props.history.push("/active-tabs");
                      }
                    }}
                    style={{
                      color: "#3aab7b",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    {name}
                  </td>
                  <td
                    className="funnel-amounts"
                    style={{ textTransform: "capitalize" }}
                  >
                    {status}
                  </td>
                  <td style={{ textAlign: "right" }}>{total_activities}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(HottestFollowupDashboard));
