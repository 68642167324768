import React, { Component } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import SubscriptionCancelConfirm from "../../images/IntegrationSettingsIcon.svg";

class IntegrationRedirectModal extends Component {
  state = {
    showModal: true,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "45vh", top: "20%", textAlign: "center" }}
            >
              <img src={SubscriptionCancelConfirm} alt="" width="100px" />
              <h4
                style={{
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "150%",
                  color: "#0C0D0D",
                }}
              >
                Integration Required
              </h4>
              <button
                onClick={this.props.handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
              <label>
                Please choose one of our providers to send emails from
                Salesdash.
              </label>
              <div style={{ marginTop: "25px" }}>
                <Link
                  to="/integration"
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #4A9876",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "10px 24px",
                    color: "#4A9876",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    textDecoration: "none",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  Go to Integration Page
                </Link>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default IntegrationRedirectModal;
