import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import TaskIcon from "../images/TaskIcon.svg";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../redux";

class TaskCreateModal extends Component {
  state = {
    name: "",
    date: "",
    start_time: null,
    end_time: null,
    category_id: null,
    account_id:
      this.props.accountId === undefined ? null : this.props.accountId,
    carrier_id:
      this.props.carrierId === undefined ? null : this.props.carrierId,
    opportunity_id:
      this.props.opportunityId === undefined ? null : this.props.opportunityId,
    contact_id:
      this.props.contactId === undefined ? null : this.props.contactId,
    errorMessage: false,
    windowWidth: window.innerWidth,
    assignedTo: null,
    isLoading: false,
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  dateChangeHandler = (type, date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      [type]: offsetDate,
    });
  };
  timeChangeHandler = (type, date) => {
    this.setState({
      [type]: date,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      account_id:
        this.props.accountId === undefined ? null : this.props.accountId,
      carrier_id:
        this.props.carrierId === undefined ? null : this.props.carrierId,
      opportunity_id:
        this.props.opportunityId === undefined
          ? null
          : this.props.opportunityId,
      contact_id:
        this.props.contactId === undefined ? null : this.props.contactId,
      assignedTo: this.props.existingUser,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.name || !data.category_id || !data.date) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    } else if (data.start_time && !data.end_time) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/tasks/create`,
      data: {
        name: this.state.name,
        date: this.state.date && moment(this.state.date).format("YYYY-MM-DD"),
        ...(this.props.accountId !== "" &&
          this.props.accountId !== null &&
          this.props.accountId !== undefined && {
            account_id: this.props.accountId,
          }),
        ...(this.props.carrierId !== "" &&
          this.props.carrierId !== null &&
          this.props.carrierId !== undefined && {
            carrier_id: this.props.carrierId,
          }),
        ...(this.state.contact_id !== "" &&
          this.state.contact_id !== null && {
            contact_id: this.state.contact_id,
          }),
        ...(this.state.opportunity_id !== "" &&
          this.state.opportunity_id !== null && {
            opportunity_id: this.state.opportunity_id,
          }),
        category_id: this.state.category_id,
        start_time:
          this.state.start_time != null
            ? this.state.start_time &&
              moment(this.state.start_time).format("HH:mm")
            : null,
        end_time:
          this.state.end_time != null
            ? this.state.end_time && moment(this.state.end_time).format("HH:mm")
            : null,
        assigned_to: this.state.assignedTo,
      },
    })
      .then((response) => {
        this.props.getTasks();
        this.props.handleCloseModal();
      })
      .then((response) =>
        NotificationManager.success("Task created successfully.")
      )
      .then((res) =>
        this.setState({
          name: "",
          date: "",
          start_time: null,
          end_time: null,
          account_id: this.props.accountId,
          carrier_id: this.props.carrierId,
          opportunity_id:
            this.props.opportunityId === undefined
              ? null
              : this.props.opportunityId,
          contact_id:
            this.props.contactId === undefined ? null : this.props.contactId,
          category_id: null,
          errorMessage: false,
          assignedTo: this.props.existingUser,
          isLoading: false,
        })
      )
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 406) {
          NotificationManager.error("Please assign task to a different user.");
        } else {
          NotificationManager.error("Error creating task.");
        }
      });
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showTaskModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="opportunity__content"
              style={{ height: "74vh", top: "9%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "#B8FFDB",
                      transform: "translateY(-18px)",
                    }}
                  >
                    <img
                      src={TaskIcon}
                      alt="Icon"
                      width="17px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Create a task</h2>
                  <p className="modal__heading-desc">
                    Add some information about the task
                  </p>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <NotificationContainer />
              <form onSubmit={this.submitHandler}>
                <label>Account</label>
                {this.props.customerName === null ||
                this.props.customerName === undefined ? (
                  <div style={{ fontSize: "14px", marginBottom: "5px" }}>
                    No account connected to this record
                  </div>
                ) : (
                  <input type="text" value={this.props.customerName} readOnly />
                )}
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "20px" }}>
                    <div>
                      <label>Deal</label>
                    </div>
                    <select
                      name="opportunity_id"
                      onChange={this.changeHandler}
                      style={{
                        width: "auto",
                        minWidth: "70px",
                        maxWidth: "185px",
                      }}
                    >
                      {this.props.opportunityName !== null ? (
                        <option value={this.props.opportunityId}>
                          {this.props.opportunityName}
                        </option>
                      ) : (
                        <option value="" selected disabled>
                          --Select--
                        </option>
                      )}
                      {this.props.opportunities?.map((opportunity) => {
                        const { id, name } = opportunity;

                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <div>
                      <label>Contact</label>
                    </div>
                    <select
                      name="contact_id"
                      onChange={this.changeHandler}
                      style={{
                        width: "auto",
                        minWidth: "70px",
                        maxWidth: "185px",
                      }}
                    >
                      {this.props.contactName !== null ? (
                        <option value={this.props.contactId}>
                          {this.props.contactName}
                        </option>
                      ) : (
                        <option value="" selected disabled>
                          --Select--
                        </option>
                      )}
                      {this.props.contacts?.map((contact) => {
                        const { id, name } = contact;
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <label>Task Owner</label>
                  <select
                    name="assignedTo"
                    value={this.state.assignedTo}
                    onChange={this.changeHandler}
                    style={{ width: "98%" }}
                  >
                    {this.props.users?.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label style={{ display: "inline-block" }}>Task Name</label>
                  <span style={{ color: "#4A9876", display: "inline-block" }}>
                    &nbsp;*
                  </span>
                </div>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div>
                <label
                  style={{ display: "inline-block", marginBottom: "10px" }}
                >
                  Type
                </label>
                <span style={{ color: "#4A9876", display: "inline-block" }}>
                  &nbsp;*
                </span>
                <div className="modal-radioButton">
                  <label
                    htmlFor={"call" + this.props.keyValue}
                    style={
                      this.state.category_id === "1"
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                    }
                  >
                    Call
                  </label>
                  <input
                    type="radio"
                    name="category_id"
                    value={1}
                    id={"call" + this.props.keyValue}
                    className="radio-button"
                    onChange={this.changeHandler}
                  />
                  <label
                    htmlFor={"email" + this.props.keyValue}
                    style={
                      this.state.category_id === "2"
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                    }
                  >
                    Email
                  </label>
                  <input
                    type="radio"
                    name="category_id"
                    value={2}
                    id={"email" + this.props.keyValue}
                    className="radio-button"
                    onChange={this.changeHandler}
                  />
                  <label
                    htmlFor={"text" + this.props.keyValue}
                    style={
                      this.state.category_id === "3"
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                    }
                  >
                    Text
                  </label>
                  <input
                    type="radio"
                    name="category_id"
                    value={3}
                    id={"text" + this.props.keyValue}
                    className="radio-button"
                    onChange={this.changeHandler}
                  />
                  <label
                    htmlFor={"visit" + this.props.keyValue}
                    style={
                      this.state.category_id === "4"
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                    }
                  >
                    Visit
                  </label>
                  <input
                    type="radio"
                    name="category_id"
                    value={4}
                    id={"visit" + this.props.keyValue}
                    className="radio-button"
                    onChange={this.changeHandler}
                  />
                  <label
                    htmlFor={"meeting" + this.props.keyValue}
                    style={
                      this.state.category_id === "5"
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                    }
                  >
                    Meeting
                  </label>
                  <input
                    type="radio"
                    name="category_id"
                    value={5}
                    id={"meeting" + this.props.keyValue}
                    className="radio-button"
                    onChange={this.changeHandler}
                  />
                  <label
                    htmlFor={"notes" + this.props.keyValue}
                    style={
                      this.state.category_id === "6"
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                    }
                  >
                    Notes
                  </label>
                  <input
                    type="radio"
                    name="category_id"
                    // checked={this.state.category_id === "notes"}
                    value={6}
                    id={"notes" + this.props.keyValue}
                    className="radio-button"
                    onChange={this.changeHandler}
                  />
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.category_id === null ||
                    this.state.category_id === "")
                    ? "Please select a category"
                    : null}
                </div>
                <div style={{ display: "flex", marginTop: "15px" }}>
                  <div>
                    <label style={{ display: "block" }}>Date</label>
                    <DatePicker
                      selected={this.state.date}
                      todayButton="Today"
                      value={this.state.date}
                      onChange={(date) => this.dateChangeHandler("date", date)}
                      minDate={moment().toDate()}
                      placeholderText="Date"
                      dateFormat={
                        this.props.companyOnboarding?.date_format ===
                        "DD/MM/YYYY"
                          ? "dd/MM/yyyy"
                          : "MM/dd/yyyy"
                      }
                      autoComplete="off"
                      style={
                        this.state.errorMessage === true &&
                        (this.state.date === null || this.state.date === "")
                          ? {
                              border: "2px solid #F36363",
                              width: "130px",
                            }
                          : { width: "130px" }
                      }
                    />
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {this.state.errorMessage === true &&
                      (this.state.date === null || this.state.date === "")
                        ? "Date is required"
                        : null}
                    </div>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <label style={{ display: "block" }}>Start</label>
                    <DatePicker
                      selected={this.state.start_time}
                      onChange={(date) =>
                        this.timeChangeHandler("start_time", date)
                      }
                      value={this.state.start_time}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Start Time"
                      autoComplete="off"
                      style={{ width: "115px" }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "6px",
                      transform: "translateY(19px)",
                    }}
                  >
                    _
                  </div>
                  <div style={{ marginLeft: "6px" }}>
                    <label style={{ display: "block" }}>End</label>
                    <DatePicker
                      selected={this.state.end_time}
                      autoComplete="off"
                      onChange={(date) =>
                        this.timeChangeHandler("end_time", date)
                      }
                      value={this.state.end_time}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="End Time"
                      style={{ width: "115px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  ((this.state.start_time !== null &&
                    this.state.end_time === null) ||
                    (this.state.start_time !== "" &&
                      this.state.end_time === ""))
                    ? "End Time is required"
                    : null}
                </div>
                <div className="modal__buttons">
                  <button
                    type="reset"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(TaskCreateModal);
