import React, { Component } from "react";
import ReactModal from "react-modal";
import ReactTooltip from "react-tooltip";
import JoditDemo from "./JoditDemo";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
import DropdownVariables from "../Dropdown/DropdownVariables";
import JoditBulkEmail from "./JoditBulkEmail";
import JoditEditor from "jodit-react";
// Redux stuff
import { connect } from "react-redux";

const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Enter Subject",
  zIndex: 2147483640,
  addNewLine: false,
  allowResizeY: false,
};

class EditTemplateModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    label: "",
    subject: "",
    content: "",
    errorMessage: false,
    isLoading: false,
    personalizeVars: [],
    accountCustomFields: [],
    dealCustomFields: [],
    contactCustomFields: [],
    isPrivate: true,
    inputFocus: "content",
    uploaderErrorMessage: false,
  };
  updateSubject = (value) => {
    this.setState({ subject: value });
  };
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  updateContent = (value) => {
    this.setState({ content: value });
  };
  setUploaderErrorMessage = () => {
    this.setState({
      uploaderErrorMessage: true,
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  customFieldAccount = () => {
    this.setState(() => {
      const customField = this.props.allAccountCustomFields
        .map((group) => {
          const { fields, meta } = group;
          return (
            (meta === null || meta.visible_in_form === true) &&
            fields?.map((field) => {
              const { id, label } = field;
              return { key: id, label };
            })
          );
        })
        .filter((m) => m !== false);
      const merged = customField.reduce(function (prev, next) {
        return prev.concat(next);
      }, []);
      return {
        accountCustomFields: merged,
      };
    });
  };
  customFieldDeal = () => {
    this.setState(() => {
      const customField = this.props.allDealCustomFields
        .map((group) => {
          const { fields, meta } = group;
          return (
            (meta === null || meta.visible_in_form === true) &&
            fields?.map((field) => {
              const { id, label } = field;
              return { key: id, label };
            })
          );
        })
        .filter((m) => m !== false);
      const merged = customField.reduce(function (prev, next) {
        return prev.concat(next);
      }, []);
      return {
        dealCustomFields: merged,
      };
    });
  };
  customFieldContact = () => {
    this.setState(() => {
      const customField = this.props.allContactCustomFields
        .map((group) => {
          const { fields, meta } = group;
          return (
            (meta === null || meta.visible_in_form === true) &&
            fields?.map((field) => {
              const { id, label } = field;
              return { key: id, label };
            })
          );
        })
        .filter((m) => m !== false);
      const merged = customField.reduce(function (prev, next) {
        return prev.concat(next);
      }, []);
      return {
        contactCustomFields: merged,
      };
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      label: this.props.templateData?.label,
      subject: this.props.templateData?.subject,
      content: this.props.templateData?.content,
      personalizeVars: this.props.templateData?.personalizeVars,
      isPrivate: this.props.templateData?.isPrivate,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.templateData !== this.props.templateData) {
      let finalContent = this.props.templateData?.content;
      let finalSubject = this.props.templateData?.subject;
      for (
        let i = 0;
        i < this.props.templateData?.personalizeVars.length;
        i++
      ) {
        finalContent = finalContent.includes(
          `{{ ${this.props.templateData?.personalizeVars[i].key} }}`
        )
          ? finalContent.replaceAll(
              `{{ ${this.props.templateData?.personalizeVars[i].key} }}`,
              `<img src="" alt="${this.props.templateData?.personalizeVars[i].label}" class="jodit-variable-img">`
            )
          : finalContent;
        finalSubject = finalSubject.includes(
          `{{ ${this.props.templateData?.personalizeVars[i].key} }}`
        )
          ? finalSubject.replaceAll(
              `{{ ${this.props.templateData?.personalizeVars[i].key} }}`,
              `<img src="" alt="${this.props.templateData?.personalizeVars[i].label}" class="jodit-variable-img">`
            )
          : finalSubject;
      }
      this.setState({
        label: this.props.templateData?.label,
        subject: finalSubject,
        content: finalContent,
        personalizeVars: this.props.templateData?.personalizeVars,
        isPrivate: this.props.templateData?.isPrivate,
      });
    }
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.customFieldAccount();
      this.customFieldContact();
      this.customFieldDeal();
    }
    if (
      this.state.uploaderErrorMessage &&
      this.state.uploaderErrorMessage !== prevState.uploaderErrorMessage
    ) {
      setTimeout(() => {
        this.setState({
          uploaderErrorMessage: false,
        });
      }, 5000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  insertVariable = (key, label) => {
    this.setState((prevState) => ({
      subject:
        this.state.inputFocus === "subject"
          ? prevState.subject +
            `<img src="" alt="${label}" class="jodit-variable-img" />`
          : prevState.subject,
      content:
        this.state.inputFocus !== "subject"
          ? prevState.content +
            `<img src="" alt="${label}" class="jodit-variable-img" />`
          : prevState.content,
      personalizeVars: [...prevState.personalizeVars, { key, label }],
    }));
  };
  editTemplate = (e) => {
    e.preventDefault();
    const { label, subject, content, personalizeVars, isPrivate } = this.state;
    if (!subject || !label) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    let finalContent = content;
    let finalSubject = subject;
    for (let i = 0; i < personalizeVars.length; i++) {
      finalContent = finalContent.includes(
        `<img src="" alt="${personalizeVars[i].label}" class="jodit-variable-img">`
      )
        ? finalContent.replaceAll(
            `<img src="" alt="${personalizeVars[i].label}" class="jodit-variable-img">`,
            `{{ ${personalizeVars[i].key} }}`
          )
        : finalContent;
      finalSubject = finalSubject.includes(
        `<img src="" alt="${personalizeVars[i].label}" class="jodit-variable-img">`
      )
        ? finalSubject.replaceAll(
            `<img src="" alt="${personalizeVars[i].label}" class="jodit-variable-img">`,
            `{{ ${personalizeVars[i].key} }}`
          )
        : finalSubject;
    }
    axios({
      method: "PUT",
      url: `/emails/templates/${this.props.templateData.id}`,
      data: {
        name: label,
        subject: finalSubject === false ? content : finalSubject,
        body: finalContent === false ? content : finalContent,
        is_private: isPrivate,
        personalize_vars: personalizeVars,
      },
    })
      .then((res) => {
        NotificationManager.success("Template edited successfully");
        this.setState({
          errorMessage: false,
          isLoading: false,
          label: "",
          subject: "",
          content: "",
          personalizeVars: [],
          isPrivate: true,
        });
        this.props.getAllTemplates();
        this.props.handleCloseModal();
      })
      .catch((err) => {
        NotificationManager.error("Failed to edit template.");
        this.setState({ errorMessage: false, isLoading: false });
      });
  };
  inputFocusHandler = (inputFocus) => {
    this.setState({ inputFocus });
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={
                this.state.windowWidth < 600
                  ? {
                      height: "75vh",
                      width: "95vw",
                      top: "13%",
                      left: "0%",
                      border: "none",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    }
                  : {
                      height: "64vh",
                      width: "55vw",
                      top: "23%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    }
              }
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "transparent",
                      transform: "translateY(-10px)",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      src={SendEmailIcon}
                      alt="Icon"
                      width="20px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head" style={{ marginLeft: "8px" }}>
                  <h2 className="modal__heading" style={{ fontSize: "18px" }}>
                    Edit Email Template
                  </h2>
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "58%",
                    top: "3%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <label style={{ display: "inline-block" }}>
                    <span className="general-heading">
                      Public Template &nbsp;
                    </span>
                    <label className="switch1">
                      <input
                        type="checkbox"
                        value={this.state.isPrivate}
                        checked={this.state.isPrivate}
                        onChange={() =>
                          this.setState({ isPrivate: !this.state.isPrivate })
                        }
                        style={{ width: "20px", display: "inline-block" }}
                      />
                      <span className="slider1 round1"></span>
                    </label>
                    <span className="general-heading">
                      {" "}
                      &nbsp;Private Template
                    </span>
                  </label>
                  <span
                    data-tip="Public allows all users within your company CRM to use this template.<br />
                Private keeps the template restricted to you only."
                    style={{
                      fontSize: "10px",
                      fontWeight: "400",
                      color: "#4A9876",
                      padding: "0px 5.3px",
                      height: "14px",
                      borderRadius: "50%",
                      border: "1px solid #4A9876",
                      marginLeft: "10px",
                    }}
                    data-multiline={true}
                  >
                    i
                  </span>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div
                className="card-email-modal"
                style={{
                  margin: "10px 0",
                  padding: "4px 10px",
                  height: "auto",
                }}
              >
                <label
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    marginRight: "5px",
                    fontWeight: "500",
                  }}
                >
                  Template Name
                </label>
                <input
                  name="label"
                  type="text"
                  style={{
                    width: "80%",
                    padding: "5px",
                    borderRadius: "4px",
                    border: "none",
                    outline: "none",
                    fontSize: "14px",
                    display: "inline-block",
                  }}
                  value={this.state.label}
                  onChange={this.changeHandler}
                />
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.label === null || this.state.label === "")
                  ? "Template name is required"
                  : null}
              </div>
              <div
                className="card-email-modal"
                style={{
                  margin: "10px 0",
                  padding: "4px 10px",
                  display: "flex",
                  height: "auto",
                }}
              >
                <label
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    marginRight: "5px",
                    fontWeight: "500",
                    transform: "translateY(8px)",
                  }}
                >
                  Subject
                </label>
                <div style={{ width: "100%", fontSize: "14px" }}>
                  <JoditEditor
                    value={this.state.subject}
                    config={config}
                    editorRef={this.setRef}
                    onChange={(content) => {
                      this.updateSubject(content);
                      this.inputFocusHandler("subject");
                    }}
                    onBlur={() => this.inputFocusHandler("subject")}
                  />
                </div>
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.subject === null || this.state.subject === "")
                  ? "Subject is required"
                  : null}
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "14px",
                  marginBottom: "5px",
                }}
              >
                {this.state.uploaderErrorMessage === true
                  ? "Unable to paste image. Please save your image and upload it to the email body."
                  : null}
              </div>
              <div
                className="card-email-modal"
                style={
                  this.state.fullScreen
                    ? { height: "45vh", maxHeight: "45vh", minHeight: "45vh" }
                    : {
                        height: "38vh",
                        maxHeight: "38vh",
                        minHeight: "36.2vh",
                        padding: "4px 10px 17px",
                      }
                }
              >
                {this.props.isBulkEmail ? (
                  <JoditBulkEmail
                    content={this.state.content}
                    updateContent={this.updateContent}
                    triggerInputFile={this.triggerInputFile}
                    accountId={this.props.accountId}
                    carrierId={this.props.carrierId}
                    opportunityId={this.props.opportunityId}
                    contactId={this.props.contactId}
                    isTemplate={true}
                    inputFocusHandler={this.inputFocusHandler}
                    setUploaderErrorMessage={this.setUploaderErrorMessage}
                  />
                ) : (
                  <JoditDemo
                    content={this.state.content}
                    updateContent={this.updateContent}
                    triggerInputFile={this.triggerInputFile}
                    accountId={this.props.accountId}
                    carrierId={this.props.carrierId}
                    opportunityId={this.props.opportunityId}
                    contactId={this.props.contactId}
                    isTemplate={true}
                    inputFocusHandler={this.inputFocusHandler}
                    setUploaderErrorMessage={this.setUploaderErrorMessage}
                  />
                )}
              </div>
              <div
                style={{
                  marginTop: "5px",
                  width: "auto",
                  maxWidth: "420px",
                  height: "auto",
                  maxHeight: "40px",
                  overflow: "auto",
                  position: "absolute",
                  bottom: "3vh",
                }}
              >
                <ReactTooltip />
                <input
                  style={{ display: "none" }}
                  id="file-upload"
                  type="file"
                  ref={this.fileInput}
                  onChange={this.handleInputChange}
                />
                {this.state.fileNames?.map((file) => (
                  <span
                    style={{
                      background: "#C0EAD8",
                      width: "auto",
                      maxWidth: "180px",
                      fontSize: "14px",
                      marginRight: "10px",
                      padding: "2px",
                      borderRadius: "2px",
                    }}
                    data-tip={file.name}
                  >
                    {file.name.length > 20
                      ? file.name.substring(0, 20)
                      : file.name}{" "}
                    <button
                      type="button"
                      onClick={() => this.removeAttachment(file.url)}
                      style={{
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                    >
                      x
                    </button>
                  </span>
                ))}
              </div>
              <div className="modal__buttons">
                <div
                  style={
                    this.state.windowWidth < 600
                      ? { position: "relative", width: "95vw" }
                      : { position: "relative", width: "55vw" }
                  }
                >
                  <div className="modal__addButton modal__addButton-email-personalize">
                    <DropdownVariables
                      insertVariable={this.insertVariable}
                      accountCustomFields={this.state.accountCustomFields}
                      contactCustomFields={this.state.contactCustomFields}
                      dealCustomFields={this.state.dealCustomFields}
                    />
                  </div>
                  <button
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={this.editTemplate}
                    className="button-md modal__confirm-button"
                    style={
                      this.state.windowWidth < 600
                        ? {
                            bottom: "-24px",
                            position: "absolute",
                            left: "0",
                            padding: "2px 17px",
                            width: "80.22px",
                            height: "35px",
                          }
                        : {
                            position: "absolute",
                            bottom: "1px",
                            right: "18px",
                            padding: "2px 17px",
                            width: "80.22px",
                            height: "35px",
                          }
                    }
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allAccountCustomFields: state.allAccountCustomFields.fields,
    allContactCustomFields: state.allContactCustomFields.fields,
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};
const MDP = (dispatch) => {
  return {};
};

export default connect(MSP, MDP)(EditTemplateModal);
