import React, { Component } from "react";
import ReactModal from "react-modal";
import "../sass/CustomerModal.scss";
import axios from "axios";
import OpportunityIcon from "../images/DealsIntroLogo.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-autocomplete";
import AccountIcon from "../images/AccountIcon.svg";
import ContactIcon from "../images/ContactsIcon.svg";
import { colourStyles } from "../constants/selectStyles";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../utils/Helper/reusableFunctions";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../redux";
import { withRouter } from "react-router-dom";
import { MenuItem, Modal } from "@mui/material";
import MuiSelect from "./Reusables/Select/MuiSelect";
import InputField from "./Reusables/InputField/InputField";
import MuiButton from "./Reusables/Button/MuiButton";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import { muiModalStyle } from "../constants/muiModalStyle";


ReactModal.defaultStyles.overlay.zIndex = 10000001;

class OppModal extends Component {
  state = {
    fieldsData: [],
    account_id: this.props.customerId || this.props.accountIdFromContact,
    contactId: this.props.contactId,
    name: "",
    description: null,
    email: null,
    formatted_phone: null,
    website: null,
    source: null,
    revenue: "",
    stages: [],
    stage_id: "",
    stage_name: "",
    close_date: "",
    dealOwner: null,
    showModal: true,
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    close_reason_value: null,
    close_reason: null,
    isLoading: false,
    windowWidth: window.innerWidth,
    searchItems: [],
    searchValue: "",
    currentStageType: "",
    funnel_id: "",
    funnel_name: "",
    funnels: [],
    menuOpen: "bottom",
    phoneValidationError: false,
    emailValidationError: false,
    customFieldRevenueTypeCheck: [],
    requiredFields: {
      name: { required: true },
      revenue: { required: false },
      close_date: { required: false },
      description: { required: false },
    },
    isHovered: false,
  };
  fetchRequiredFields = () => {
    axios({
      method: "GET",
      url: `/admin/settings`,
    }).then((res) =>
      this.setState({
        requiredFields: res.data.settings.opportunity,
      })
    );
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      account_id: this.props.customerId || this.props.accountIdFromContact,
      contactId: this.props.contactId,
      name: "",
      description: null,
      source: null,
      revenue: "",
      stages: [],
      stage_id: "",
      close_date: "",
      showModal: true,
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      close_reason_value: null,
      close_reason: null,
      isLoading: false,
      searchValue: "",
      searchItems: [],
      email: null,
      formatted_phone: null,
      website: null,
      phoneValidationError: false,
      emailValidationError: false,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  searchValueChangeHandler = (e) => {
    this.setState({
      searchValue: e.target.value,
      account_id: undefined,
      contactId: undefined,
    });
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/dashboard/search?query=${value}`,
    }).then((res) => {
      this.setState({ searchItems: res.data.data });
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("mousemove", this.mouseCoordinates, {
      passive: true,
    });
    this.setState({
      email: this.props.email === undefined ? null : this.props.email,
      formatted_phone:
        this.props.formatted_phone === undefined
          ? null
          : this.props.formatted_phone,
      website: this.props.website === undefined ? null : this.props.website,
      dealOwner: this.props.authUser.id,
    });
    this.initialStates();
    this.fetchRequiredFields();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.initialStates();
      this.fetchRequiredFields();
      this.setState({
        dealOwner: this.props.authUser.id,
        email: this.props.email === undefined ? null : this.props.email,
        formatted_phone:
          this.props.formatted_phone === undefined
            ? null
            : this.props.formatted_phone,
        website: this.props.website === undefined ? null : this.props.website,
        account_id: this.props.customerId || this.props.accountIdFromContact,
        contactId: this.props.contactId,
      });
    }
  }
  getAllFunnels = (id) => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(id) && funnel;
        })
        .filter((funnel) => funnel !== false);
      const stage_id = selectedFunnel[0].stages
        .map((item) => item.type === "new" && item)
        .filter((stage) => stage !== false);
      this.setState({
        funnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages,
        stage_id: stage_id[0].id,
        stage_name: stage_id[0].name,
      });
    });
  };
  initialStates = () => {
    this.setState({
      fieldsData: this.props.allDealCustomFields,
    });
    const customField = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));

    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      const stage = selectedFunnel[0].stages
        .map((item) => item.type === "new" && item)
        .filter((stage) => stage !== false);
      this.setState({
        funnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages,
        stage_id: stage[0].id,
        stage_name: stage[0].name,
        funnel_id: selectedFunnel[0].id,
        funnel_name: selectedFunnel[0].name,
      });
    });

    axios({
      method: "GET",
      url: `/close-reasons`,
    }).then((response) => {
      this.setState({
        close_reason: response.data.reasons,
      });
    });
  };
  showModal = () => {
    ReactModal.isOpen = false;
    // this.setState({
    //   showModal: false,
    // });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };

  getFields = () => {
    this.state.fields.fields.map((item, i) => {
      return console.log(item.label);
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "stage_id") {
      const newStage = this.state.stages.find(
        (stage) => stage.id === parseInt(value)
      );
      this.setState({
        [name]: value,
        currentStageType: newStage.type,
      });
    } else {
      this.setState({
        [name]: value === "" ? null : value,
      });
    }
  };
  changeRevenueHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState({
        [name]: value,
      });
    }
  };
  changeHandlerSelect = (name, value) => {
    if (name === "stage_id") {
      const newStage = this.state.stages.find(
        (stage) => stage.id === parseInt(value)
      );
      this.setState({
        [name]: value,
        currentStageType: newStage.type,
        stage_name: newStage.name,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  funnelChangeHandler = (name, value) => {
    this.getAllFunnels(parseInt(value));
    this.setState({
      funnel_id: parseInt(value),
      funnel_name: name,
    });
  };
  closeDateChangeHandler = (type, date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      [type]: offsetDate,
    });
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    // if (
    //   data.name === null ||
    //   data.revenue === null ||
    //   data.close_date === null ||
    //   data.name === "" ||
    //   data.revenue === "" ||
    //   data.close_date === "" ||
    //   (data.account_id === undefined && data.contactId === undefined)
    // ) {
    //   this.setState({ errorMessage: true, isLoading: false });
    //   return;
    // }
    if (
      data.name === null ||
      data.name === "" ||
      (data.account_id === undefined && data.contactId === undefined)
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return;
    }
    if (
      (this.state.currentStageType === "won" ||
        this.state.currentStageType === "lost") &&
      (this.state.close_reason_value === "" ||
        this.state.close_reason_value === null)
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const calculatedRevenue = getCurrencyValue(this.state.revenue);
    axios({
      method: "POST",
      url: `/opportunities/create`,
      data: {
        opportunity_owner_id: this.state.dealOwner,
        ...(this.state.account_id && { account_id: this.state.account_id }),
        ...(this.state.contactId && { contact_id: this.state.contactId }),
        name: this.state.name,
        description:
          this.state.description === "" ? null : this.state.description,
        revenue: calculatedRevenue,
        stages: this.state.stages,
        funnel_id: this.state.funnel_id,
        stage_id: this.state.stage_id,
        close_date: this.state.close_date === "" ? null : this.state.close_date,
        custom_fields: customField,
        close_reason: this.state?.close_reason_value,
        source: this.state.source,
        email: this.state.email === undefined ? null : this.state.email,
        formatted_phone:
          this.state.formatted_phone === undefined ||
          this.state.formatted_phone === "undefined"
            ? null
            : this.state.formatted_phone,
        website:
          this.state.website === null
            ? null
            : this.state.website.startsWith("https://") ||
              this.state.website.startsWith("http://")
            ? this.state.website
            : "https://" + this.state.website,
      },
    })
      .then((response) => {
        this.handleCloseModal();
        this.props.fromRecord && this.props.fetchAllOpportunitiesAgain();
        if (this.props.fromRecord !== true) {
          this.props.setActiveTabIndex(this.props.totalTabs + 1);
          this.props.setTab({
            type: "opportunity",
            id: response.data.opportunity.opportunity_id,
            name: response.data.opportunity.name,
            account_id:
              this.props.customerId === undefined
                ? this.state.account_id
                : this.props.customerId,
          });
          this.props.history.push("/active-tabs");
        }
      })
      .then((response) => {
        this.props.fromRecord && this.props.showDealPortion();
        NotificationManager.success("Deal created successfully!");
      })
      .then((response) => this.initialStates())
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating deal.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired
  ) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldRevenueChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="number"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "5px" }}
        >
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            menuPlacement={this.state.menuOpen}
            value={{
              value: this.state.customFields[fieldName],
              label: this.state.customFields[fieldName],
            }}
            onChange={(selected) =>
              this.customFieldSelectChangeHandler(fieldName, selected.value)
            }
            isClearable={false}
            isSearchable={true}
            name={fieldName}
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value, label: value };
            })}
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px", marginBottom: "15px" }}>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left", marginBottom: "15px" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "5px" }}
        >
          <DatePicker
            name={fieldName}
            className="datePicker"
            selected={Date.parse(this.state.customFields[fieldName])}
            value={Date.parse(this.state.customFields[fieldName])}
            onChange={(date) =>
              this.customFieldDateChangeHandler(fieldName, date)
            }
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            placeholderText={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/mm/yyyy"
                : "mm/dd/yyyy"
            }
            autoComplete="off"
            required={fieldRequired}
            popperPlacement="top"
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "5px" }}
        >
          <Select
            closeMenuOnSelect={false}
            styles={colourStyles}
            value={multiSelectDropdownValue}
            isMulti
            menuPlacement={this.state.menuOpen}
            name={fieldName}
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value: value, label: value };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOption) =>
              this.customFieldMultiDropdownChangeHandler(
                fieldName,
                selectedOption
              )
            }
          />
          <label className="activeLabel">
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    }
    return (
      <div>
        {/* <label>
          {fieldLabel}{" "}
          {fieldRequired && (
            <span
              style={{
                color: "#4A9876",
                fontSize: "17px",
                lineHeight: "16px",
              }}
            >
              *
            </span>
          )}
        </label> */}
        {inputType}
      </div>
    );
  };
  render() {
    const { fieldsData } = this.state;
    const {
      name,
      description,
      revenue,
      stage_id,
      funnel_id,
      funnel_name,
      stage_name,
      stages,
      funnels,
      source,
    } = this.state;

    return (
      <div>
        <Modal
          open={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onClose={this.handleCloseModal}
          ariaHideApp={false}
          style={muiModalStyle}
        >
          <div className="muiModal">
            <div className="modal__content">
              <div className="modal__header">
                <span>
                  {/* <div
                    className="customerInfo-icon"
                    style={{
                      background: "#F8E9E2",
                      transform: "translateY(-18px)",
                    }}
                  >
                    <img
                      src={OpportunityIcon}
                      alt="Icon"
                      width="17px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div> */}
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Create Deal</h2>
                </div>
                <button
                  onClick={this.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    height: "12px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <ClearIcon fontSize="small" />
                </button>
              </div>
              <form
                className="modal__form"
                style={{
                  overflowY: "scroll",
                  maxHeight: "60vh",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <NotificationContainer />

                <div style={{ marginBottom: "0px", marginTop: "15px" }}>
                  <MuiSelect
                    label="Deal Owner *"
                    value={this.state.dealOwner}
                    onChange={this.changeHandler}
                    name="dealOwner"
                    menuItem={this.props.allUsers?.map((user) => {
                      const { id, name } = user;
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  />
                </div>
                {/* <label>
                  Deal Owner{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label>
                <select
                  value={this.state.dealOwner}
                  onChange={this.changeHandler}
                  name="dealOwner"
                  style={{ width: "99%" }}
                >
                  {this.props.allUsers?.map((user) => {
                    const { id, name } = user;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select> */}
                {/* <label>
                  {this.state.account_id === undefined &&
                  this.state.contactId === undefined
                    ? "Account/ Contact"
                    : this.state.contactId === undefined ||
                      this.state.contactId === null
                    ? "Account"
                    : "Contact"}{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label> */}
                {this.props.fromNav === true ? (
                  <Autocomplete
                    inputProps={{
                      style: {
                        border: "1px solid #c5c5d3",
                        borderRadius: "4px",
                        padding: "12px",
                        width: "20vw",
                        margin: "5px 0",
                      },
                      placeholder: "Search Account or Contact",
                    }}
                    items={this.state.searchItems.filter(
                      (item) =>
                        item.type === "account" || item.type === "contact"
                    )}
                    menuStyle={{
                      borderRadius: "3px",
                      // left: "70px",
                      display: "block",
                      boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                      background: "#252a2d",
                      padding: "3px 0",
                      position: "fixed",
                      overflow: "auto",
                      maxHeight: "250px",
                      zIndex: "2147483647",
                    }}
                    getItemValue={(item) => item.name}
                    renderItem={(item, highlighted) => {
                      const { type, id } = item;
                      let logo = "";
                      if (type === "account") {
                        logo = (
                          <img
                            src={AccountIcon}
                            alt=""
                            width="13"
                            key={id + type}
                            style={{ transform: "translateY(9px)" }}
                          />
                        );
                      } else {
                        logo = (
                          <img
                            src={ContactIcon}
                            alt=""
                            width="13"
                            key={id + type}
                            style={{ transform: "translateY(9px)" }}
                          />
                        );
                      }
                      return (
                        <div
                          key={`${item.id}${item.type}`}
                          style={{
                            backgroundColor: highlighted
                              ? "#3B4043"
                              : "#252a2d",
                            color: "#CFCFD8",
                            zIndex: "2147483647",
                            fontSize: "16px",
                            cursor: "pointer",
                            borderBottom: "1px solid black",
                            padding: "5px 0",
                          }}
                        >
                          <div style={{ paddingLeft: "15px" }}>
                            {logo}
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                marginLeft: "15px",
                              }}
                            >
                              {item.name.length > 23
                                ? item.name.substring(0, 22)
                                : item.name}
                            </span>
                          </div>
                          <div
                            style={{
                              marginLeft: "45px",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "300",
                              color: "#A5A1AC",
                            }}
                          >
                            {item.type}
                          </div>
                        </div>
                      );
                    }}
                    value={this.state.searchValue}
                    onChange={this.searchValueChangeHandler}
                    onSelect={(value, item) => {
                      item.type === "account"
                        ? this.setState({
                            account_id: item.id,
                            searchValue: item.name,
                            contactId: undefined,
                          })
                        : this.setState({
                            contactId: item.id,
                            searchValue: item.name,
                            account_id: undefined,
                          });
                    }}
                  />
                ) : (
                  <InputField
                    type="text"
                    value={this.props?.customerName}
                    readOnly={true}
                  />
                )}
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  this.state.account_id === undefined &&
                  this.state.contactId === undefined
                    ? "Please select an account/ contact from the dropdown."
                    : null}
                </div>

                <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                  <InputField
                    label="Deal Name *"
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.changeHandler}
                    error={
                      this.state.errorMessage === true &&
                      (this.state.name === null || this.state.name === "")
                    }
                    helperText="Name is required"
                  />
                </div>
                {/* <label>
                  Deal Name{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div> */}

                <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Revenue"
                    type="text"
                    name="revenue"
                    value={revenue}
                    onChange={this.changeRevenueHandler}
                    required={this.state.requiredFields["revenue"].required}
                  />
                </div>

                {/* <label>
                  Revenue{" "}
                  {this.state.requiredFields["revenue"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="revenue"
                  value={revenue}
                  onChange={this.changeRevenueHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.revenue === null || this.state.revenue === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                  required={this.state.requiredFields["revenue"].required}
                /> */}
                {/* <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.revenue === null || this.state.revenue === "")
                    ? "Revenue is required"
                    : null}
                </div> */}

                {/* <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Close Date"
                    type="date"
                    name="close_date"
                    value={Date.parse(this.state.close_date)}
                    onChange={(date) =>
                      this.closeDateChangeHandler("close_date", date)
                    }
                    required={this.state.requiredFields["close_date"].required}
                  />
                </div> */}
                {/* <label>
                  Close Date{" "}
                  {this.state.requiredFields["close_date"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label> */}

                <div
                  className="controlsDashboard"
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <DatePicker
                    name="close_date"
                    selected={Date.parse(this.state.close_date)}
                    className="datePicker"
                    value={Date.parse(this.state.close_date)}
                    onChange={(date) =>
                      this.closeDateChangeHandler("close_date", date)
                    }
                    dateFormat={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    placeholderText={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/mm/yyyy"
                        : "mm/dd/yyyy"
                    }
                    autoComplete="off"
                    required={this.state.requiredFields["close_date"].required}
                    popperPlacement="top"
                  />

                  <label for="dashboardName" className="activeLabel">
                    <span style={{ fontSize: "12px" }}>Close Date</span>
                  </label>
                </div>

                {/* <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.close_date === null ||
                    this.state.close_date === "")
                    ? "Date is required"
                    : null}
                </div> */}

                <div style={{ marginBottom: "0px", marginTop: "15px" }}>
                  <MuiSelect
                    label="Funnel"
                    className="basic-single"
                    value={funnel_id}
                    onChange={(selected) =>
                      this.funnelChangeHandler(selected.label, selected.value)
                    }
                    menuItem={funnels?.map((stage, index) => (
                      <MenuItem
                        sx={{ fontSize: "14px" }}
                        key={stage.name + index}
                        value={stage.id}
                      >
                        {stage.name}
                      </MenuItem>
                    ))}
                  />
                </div>

                {/* <label>Funnel</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  styles={colourStyles}
                  menuPlacement={this.state.menuOpen}
                  value={{
                    value: funnel_id,
                    label: funnel_name,
                  }}
                  placeholder="-Select-"
                  onChange={(selected) =>
                    this.funnelChangeHandler(selected.label, selected.value)
                  }
                  isClearable={false}
                  isSearchable={true}
                  options={funnels?.map((stage) => {
                    const { name, id } = stage;
                    return {
                      value: id,
                      label: name,
                    };
                  })}
                /> */}

                <div style={{ marginBottom: "0px" }}>
                  <MuiSelect
                    label="Stage"
                    name="stage"
                    className="basic-single"
                    value={stage_id}
                    onChange={(selected) =>
                      this.changeHandlerSelect(
                        "stage_id",
                        selected.target.value
                      )
                    }
                    menuItem={stages?.map((stage, index) => (
                      <MenuItem
                        sx={{ fontSize: "14px" }}
                        key={stage.name + index}
                        value={stage.id}
                      >
                        {stage.name}
                      </MenuItem>
                    ))}
                  />
                </div>

                {/* <label>Stage</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  styles={colourStyles}
                  value={{
                    value: stage_id,
                    label: stage_name,
                  }}
                  menuPlacement={this.state.menuOpen}
                  placeholder="-Select-"
                  onChange={(selected) =>
                    this.changeHandlerSelect("stage_id", selected.value)
                  }
                  isClearable={false}
                  isSearchable={true}
                  options={stages?.map((stage) => {
                    const { name, id } = stage;
                    return {
                      value: id,
                      label: name,
                    };
                  })}
                /> */}

                {this.state?.currentStageType === "won" ||
                this.state?.currentStageType === "lost" ? (
                  <div style={{ marginBottom: "15px" }}>
                    {/* <h5 className="c-title-act">Close Reason</h5> */}
                    {/*<select
                      //   name="close_reason_value"
                      //   value={this.state.close_reason_value}
                      //   onChange={this.changeHandler}
                      //   style={
                      //     this.state.errorMessage === true &&
                      //     (this.state.currentStageType === "won" ||
                      //       this.state.currentStageType === "lost") &&
                      //     (this.state.close_reason_value === "" ||
                      //       this.state.close_reason_value === null)
                      //       ? {
                      //           border: "2px solid #F36363",
                      //         }
                      //       : {}
                      //   }
                      // >
                      //   <option value="" selected disabled>
                      //     -Select-
                      //   </option>
                      //   {this.state?.close_reason?.map((reason) => {
                      //     const { name, id, type } = reason;
                      //     let options;
                      //     options =
                      //       type === "won" ? (
                      //         <option key={id} value={id}>
                      //           {name}
                      //         </option>
                      //       ) : null;
                      //     return options;
                      //   })}
                      // </select>
                      */}

                    {this.state?.currentStageType === "won" ? (
                      <MuiSelect
                        label="Close Reason"
                        name="close_reason_value"
                        className="basic-single"
                        value={this.state.close_reason_value}
                        onChange={this.changeHandler}
                        error={
                          this.state.errorMessage === true &&
                          (this.state.currentStageType === "won" ||
                            this.state.currentStageType === "lost") &&
                          (this.state.close_reason_value === "" ||
                            this.state.close_reason_value === null)
                        }
                        helperText="Close Reason is required"
                        menuItem={this.state?.close_reason?.map((reason) => {
                          const { name, id, type } = reason;
                          let options;
                          options =
                            type === "won" ? (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ) : null;
                          return options;
                        })}
                      />
                    ) : (
                      <MuiSelect
                        label="Close Reason"
                        name="close_reason_value"
                        className="basic-single"
                        value={this.state.close_reason_value}
                        onChange={this.changeHandler}
                        error={
                          this.state.errorMessage === true &&
                          (this.state.currentStageType === "won" ||
                            this.state.currentStageType === "lost") &&
                          (this.state.close_reason_value === "" ||
                            this.state.close_reason_value === null)
                        }
                        helperText="Close Reason is required"
                        menuItem={this.state?.close_reason?.map((reason) => {
                          const { name, id, type } = reason;
                          let options;
                          options =
                            type === "lost" ? (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ) : null;
                          return options;
                        })}
                      />
                      // <select
                      //   name="close_reason_value"
                      //   value={this.state.close_reason_value}
                      //   onChange={this.changeHandler}
                      //   style={
                      //     this.state.errorMessage === true &&
                      //     (this.state.currentStageType === "won" ||
                      //       this.state.currentStageType === "lost") &&
                      //     (this.state.close_reason_value === "" ||
                      //       this.state.close_reason_value === null)
                      //       ? {
                      //           border: "2px solid #F36363",
                      //         }
                      //       : {}
                      //   }
                      // >
                      //   <option value="" selected disabled>
                      //     -Select-
                      //   </option>
                      //   {this.state?.close_reason?.map((reason) => {
                      //     const { name, id, type } = reason;
                      //     let options;
                      //     options =
                      //       type === "lost" ? (
                      //         <option key={id} value={id}>
                      //           {name}
                      //         </option>
                      //       ) : null;
                      //     return options;
                      //   })}
                      // </select>
                    )}
                  </div>
                ) : null}
                {/* <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.currentStageType === "won" ||
                    this.state.currentStageType === "lost") &&
                  (this.state.close_reason_value === "" ||
                    this.state.close_reason_value === null)
                    ? "Close Reason is required"
                    : null}
                </div> */}
                <div style={{ marginBottom: "0px" }}>
                  <MuiSelect
                    label="Source"
                    name="source"
                    className="basic-single"
                    value={source}
                    onChange={(selected) =>
                      this.changeHandlerSelect("source", selected.target.value)
                    }
                    menuItem={this.props.allAccountSources?.map(
                      (source, index) => (
                        <MenuItem
                          sx={{ fontSize: "14px" }}
                          key={source.name + index}
                          value={source.name}
                        >
                          {source.name}
                        </MenuItem>
                      )
                    )}
                  />
                  {/* <label>Source</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    styles={colourStyles}
                    menuPlacement={this.state.menuOpen}
                    value={{
                      value: source,
                      label: source,
                    }}
                    placeholder="-Select-"
                    onChange={(selected) =>
                      this.changeHandlerSelect("source", selected.value)
                    }
                    isClearable={false}
                    isSearchable={true}
                    options={this.props.allAccountSources?.map((source) => ({
                      value: source.name,
                      label: source.name,
                    }))}
                  /> */}
                </div>
                <div style={{ marginBottom: "0px" }}>
                  <InputField
                    label="Description"
                    name="description"
                    value={description}
                    style={{ fontFamily: "inherit" }}
                    onChange={this.changeHandler}
                    required={this.state.requiredFields["description"].required}
                    multiline={true}
                  />
                </div>
                {/* <label>
                  Description{" "}
                  {this.state.requiredFields["description"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <textarea
                  name="description"
                  value={description}
                  style={{ fontFamily: "inherit" }}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["description"].required}
                ></textarea> */}
                {fieldsData.map((field) => {
                  const { label, fields, meta, id } = field;
                  return (
                    <div key={id}>
                      {meta === null ? (
                        <div>
                          <h3 className="modal__heading-sub">{label}</h3>
                          {fields?.map((data) => {
                            const { id, label, type, choices, name, required } =
                              data;
                            return (
                              <div key={id}>
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  required
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </form>
              <div className="modal__buttons">
                <button
                  type="button"
                  style={{ bottom: "10px", left: "5px" }}
                  className="modal__cancel-button"
                  onClick={this.handleCloseModal}
                >
                  Cancel
                </button>
                <MuiButton
                  type="button"
                  className="saveBtn"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "auto",
                    height: "35px",
                    color: "white",
                    backgroundColor: "#252a2d",
                  }}
                  loading={this.state.isLoading}
                  disabled={this.state.isLoading}
                  onClick={this.submitHandler}
                  endIcon={
                    <AddIcon
                      style={{
                        color: this.state.isHovered ? "black" : "#62CA9D",
                      }}
                    />
                  }
                  onMouseEnter={() => {
                    this.setState({ isHovered: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ isHovered: false });
                  }}
                >
                  {this.state.isLoading ? "Creating..." : "Create"}{" "}
                </MuiButton>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(withRouter(OppModal));
