import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateRoleModal from "./CreateRoleModal";
import axios from "axios";
import EditRoleModal from "./EditRoleModal";
import DeleteRoleModal from "./DeleteRoleModal";
import TaskTourRole from "../Onboarding/TaskTourRole";
// Redux stuff
import { connect } from "react-redux";
import { setActiveTabIndex, authUserSet } from "../../redux";
import ShotClockSettingsModal from "./ShotClockSettingsModal";
import SharedUserSetingModal from "./SharedUserSetingModal";

function Roles(props) {
  const [userModal, setUserModal] = useState(false);
  const [userEditModal, setUserEditModal] = useState(false);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [accountLimit, setAccountLimit] = useState(null);
  const [clientDaysLimit, setClientDaysLimit] = useState(null);
  const [activityDaysLimit, setActivityDaysLimit] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [tourComplete, setTourComplete] = useState(false);
  const [showShotClockModal, setShowShotClockModal] = useState(false);
  const [shotClockTime, setShotClockTime] = useState(null);
  const [shotClockTimeZone, setShotClockTimeZone] = useState(null);
  const [showMultipleAccountModal, setShowMultipleAccountModal] =
    useState(false);

  const handleOpenUserModal = () => {
    setUserModal(true);
  };
  const handleCloseUserModal = () => {
    setUserModal(false);
  };
  const handleOpenUserEditModal = (
    name,
    description,
    roleId,
    permissions,
    accountLimit,
    activityDaysLimit,
    clientDaysLimit
  ) => {
    setUserEditModal(true);
    setName(name);
    setDescription(description);
    setRoleId(roleId);
    setPermissions(permissions);
    setAccountLimit(accountLimit);
    setActivityDaysLimit(activityDaysLimit);
    setClientDaysLimit(clientDaysLimit);
  };
  const handleCloseUserEditModal = () => {
    setUserEditModal(false);
  };
  const handleOpenUserDeleteModal = (name, roleId) => {
    setUserDeleteModal(true);
    setName(name);
    setRoleId(roleId);
  };
  const handleCloseUserDeleteModal = () => {
    setUserDeleteModal(false);
  };
  useEffect(() => {
    axios({
      method: "GET",
      url: `/admin/roles`,
    })
      .then((response) => {
        setRoles(response.data.roles);
        setShotClockTime(response.data?.shot_clock_settings?.time);
        setShotClockTimeZone(response.data?.shot_clock_settings?.timezone);
      })
      .catch((error) => console.log("error", error));
  }, []);
  const fetchRoles = () => {
    axios({
      method: "GET",
      url: `/admin/roles`,
    })
      .then((response) => {
        setRoles(response.data.roles);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div style={{ margin: "0px 30px" }}>
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>Roles</h2>
          <p>{roles.length} roles in your company</p>
        </div>
        {props.authUser.permissions.includes("crm:add:user") && (
          <div
            style={{ marginTop: "4vh", float: "right", marginRight: "30px" }}
          >
            <button
              type="button"
              onClick={() => handleOpenUserModal()}
              // className="button-md tour-add-role"
              className="button-md"
            >
              Add Role
            </button>
            <CreateRoleModal
              showOpportunityModal={userModal}
              setShowOpportunityModal={handleOpenUserModal}
              handleCloseModal={handleCloseUserModal}
              fetchRoles={fetchRoles}
              setTourComplete={setTourComplete}
              authUser={props.authUser}
              shotClockTime={shotClockTime}
              shotClockTimeZone={shotClockTimeZone}
            />
            <TaskTourRole
              tourComplete={tourComplete}
              setTourComplete={setTourComplete}
            />
          </div>
        )}
      </div>
      <div>
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Link
            to="/users"
            style={{
              textDecoration: "none",
              color: "black",
              padding: "4px 12px",
            }}
          >
            Users
          </Link>
          <Link
            to="/teams"
            style={{
              textDecoration: "none",
              color: "black",
              padding: "4px 12px",
            }}
          >
            Teams
          </Link>
          <Link
            to="/roles"
            style={{
              textDecoration: "none",
              color: "#4A9876",
              padding: "4px 12px",
              borderBottom: "1px solid #4A9876",
              fontWeight: "500",
            }}
          >
            Roles
          </Link>
          {props.authUser.role === "SUPERADMIN" && (
            <div
              onClick={() => setShowShotClockModal(true)}
              style={{
                textDecoration: "none",
                color: "black",
                padding: "4px 12px",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Shot Clock
            </div>
          )}
          {props.companyOnboarding?.is_multiple_account_owner_enabled && (
            <div
              onClick={() => setShowMultipleAccountModal(true)}
              style={{
                textDecoration: "none",
                color: "black",
                padding: "4px 12px",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Shared Account Owners
            </div>
          )}
        </div>
        <div>
          <div>
            <table className="accountList__table" style={{ width: "93vw" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "11px" }}>Name</th>
                  <th style={{ fontSize: "11px" }}>Description</th>
                  <th style={{ fontSize: "11px" }}>
                    Accounts, Contacts, Deals & Reports
                  </th>
                  <th style={{ fontSize: "11px" }}>Target & Goals</th>
                  <th style={{ fontSize: "11px" }}>Settings</th>
                  {props.authUser.permissions.includes("crm:add:user") && (
                    <th style={{ fontSize: "11px" }}>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {roles?.map((role) => {
                  const {
                    name,
                    id,
                    description,
                    permissions,
                    editable,
                    account_limit,
                    account_status_days_limit,
                    activity_days_limit,
                  } = role;
                  return (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{description}</td>
                      <td>
                        <div>
                          View:{" "}
                          {permissions.map((permission) =>
                            permission.name === "crm:all:viewer"
                              ? "Everything"
                              : permission.name === "crm:team:only:viewer"
                              ? "Team Only"
                              : permission.name === "crm:owned:only:viewer"
                              ? "Owned only"
                              : null
                          )}
                        </div>
                        <div>
                          Edit:{" "}
                          {permissions.map((permission) =>
                            permission.name === "crm:all:editor"
                              ? "Everything"
                              : permission.name === "crm:team:only:editor"
                              ? "Team Only"
                              : permission.name === "crm:owned:only:editor"
                              ? "Owned only"
                              : null
                          )}
                        </div>
                        <div>
                          Communicate:{" "}
                          {permissions.map((permission) =>
                            permission.name === "crm:all:communicator"
                              ? "Everything"
                              : permission.name === "crm:team:only:communicator"
                              ? "Team Only"
                              : permission.name ===
                                "crm:owned:only:communicator"
                              ? "Owned only"
                              : null
                          )}
                        </div>
                      </td>
                      <td>
                        {permissions.map((permission) =>
                          permission.name === "crm:teams:targets-goals:editor"
                            ? "Edit Access"
                            : null
                        )}
                      </td>
                      <td>
                        {permissions.map((permission) =>
                          permission.name === "crm:importer" ? (
                            <div>Import</div>
                          ) : null
                        )}
                        {permissions.map((permission) =>
                          permission.name === "crm:exporter" ? (
                            <div>Export</div>
                          ) : null
                        )}
                        {permissions.map((permission) =>
                          permission.name === "crm:closed:deal:editor" ? (
                            <div>Closed Deal Stage Change</div>
                          ) : null
                        )}
                        {permissions.map((permission) =>
                          permission.name === "crm:bulk:records:delete" ? (
                            <div>Bulk Delete Records</div>
                          ) : null
                        )}
                        {permissions.map((permission) =>
                          permission.name === "crm:unassigned:leads:viewer" ? (
                            <div>Unassigned Leads Access</div>
                          ) : null
                        )}
                        {permissions.map((permission) =>
                          permission.name === "crm:add:user" ? (
                            <div>Add & Edit Users</div>
                          ) : null
                        )}
                        {permissions.map((permission) =>
                          permission.name === "crm:all:customizer" ? (
                            <div>All Customization</div>
                          ) : null
                        )}
                        {account_limit && (
                          <div>Maximum Accounts Limit: {account_limit}</div>
                        )}
                        {activity_days_limit && (
                          <div>
                            Shot Clock Activity Limit: {activity_days_limit}
                          </div>
                        )}
                        {account_status_days_limit && (
                          <div>
                            Shot Clock Clients Limit:{" "}
                            {account_status_days_limit}
                          </div>
                        )}
                      </td>
                      {props.authUser.permissions.includes("crm:add:user") &&
                        (editable === true ? (
                          <td>
                            <button
                              type="button"
                              style={{
                                borderRadius: "9px",
                                padding: "5px 10px",
                                fontSize: "15px",
                                lineHeight: "18px",
                                height: "35px",
                                width: "75px",
                                cursor: "pointer",
                                marginRight: "20px",
                              }}
                              className="button-md"
                              onClick={() =>
                                handleOpenUserEditModal(
                                  name,
                                  description,
                                  id,
                                  permissions,
                                  account_limit,
                                  activity_days_limit,
                                  account_status_days_limit
                                )
                              }
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              style={{
                                border: "1px solid #C5C5D3",
                                borderRadius: "9px",
                                padding: "5px 10px",
                                fontWeight: "500",
                                fontSize: "15px",
                                lineHeight: "18px",
                                color: "#F36363",
                                background: "white",
                                height: "35px",
                                width: "75px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenUserDeleteModal(name, id)
                              }
                            >
                              Delete
                            </button>
                          </td>
                        ) : (
                          <td>{null}</td>
                        ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <EditRoleModal
            showOpportunityModal={userEditModal}
            setShowOpportunityModal={handleOpenUserEditModal}
            handleCloseModal={handleCloseUserEditModal}
            name={name}
            description={description}
            accountLimit={accountLimit}
            clientDaysLimit={clientDaysLimit}
            activityDaysLimit={activityDaysLimit}
            roleId={roleId}
            permissions={permissions}
            key={name}
            fetchRoles={fetchRoles}
            shotClockTime={shotClockTime}
            shotClockTimeZone={shotClockTimeZone}
          />
          <DeleteRoleModal
            showOpportunityModal={userDeleteModal}
            setShowOpportunityModal={handleOpenUserDeleteModal}
            handleCloseModal={handleCloseUserDeleteModal}
            name={name}
            roleId={roleId}
            key={roleId}
            fetchRoles={fetchRoles}
          />
          <ShotClockSettingsModal
            showOpportunityModal={showShotClockModal}
            setShowOpportunityModal={() => setShowShotClockModal(true)}
            handleCloseModal={() => setShowShotClockModal(false)}
          />
          <SharedUserSetingModal
            showOpportunityModal={showMultipleAccountModal}
            setShowOpportunityModal={() => setShowMultipleAccountModal(true)}
            handleCloseModal={() => setShowMultipleAccountModal(false)}
          />
        </div>
      </div>
    </div>
  );
}
const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(Roles);
