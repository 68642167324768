export const accountTerminologies = {
  name: [
    "name",
    "account name",
    "user name",
    "full name",
    "company name",
    "organization name",
    "organization",
    "org name",
    "org",
    "account",
    "company",
    "prospects",
    "prospect",
    "lead",
    "leads",
  ],
  email: [
    "email",
    "email address",
    "user email",
    "account email",
    "company email",
    "organization email",
    "org email",
  ],
  formatted_phone: [
    "phone",
    "contact number",
    "mobile",
    "phone number",
    "telephone number",
    "company phone",
    "company phone number",
    "company contact number",
    "account phone",
    "account phone number",
    "account contact number",
    "organization phone",
    "organization phone number",
    "organization contact number",
    "org phone",
    "org phone number",
    "org contact number",
    "company hq phone",
  ],
  address: [
    "address",
    "location",
    "billing address",
    "shipping address",
    "company address",
    "account address",
    "organization address",
    "org address",
    "full address",
  ],
  primary_owner: [
    "account owner",
    "owner",
    "company owner",
    "organization owner",
  ],
  account_owner_email: [
    "account owner email",
    "owner email",
    "org owner email",
    "company owner email",
    "organization owner email",
  ],
  source: [
    "source",
    "company source",
    "account source",
    "organization source",
    "org source",
  ],
  website: [
    "website",
    "site",
    "web",
    "company website",
    "account website",
    "organization website",
    "org website",
    "website url",
    "company website url",
  ],
  state: [
    "state",
    "province",
    "company state",
    "account state",
    "organization state",
    "org state",
    "company province",
    "account province",
    "organization province",
    "org province",
  ],
  zip: [
    "zip",
    "zip code",
    "postal code",
    "company zip",
    "account zip",
    "organization zip",
    "org zip",
    "company zip code",
    "account zip code",
    "organization zip code",
    "org zip code",
    "company postal code",
    "account postal code",
    "organization postal code",
    "org postal code",
  ],
  city: [
    "city",
    "company city",
    "account city",
    "organization city",
    "org city",
  ],
  linkedin: [
    "linkedin",
    "linkedin address",
    "linkedin url",
    "company linkedin url",
    "account linkedin url",
    "organization linkedin url",
    "org linkedin url",
    "linkedin company profile url",
  ],
  phone_ext: ["phone extension"],
  description: ["description", "notes", "short description"],
  country: [
    "country",
    "company country",
    "account country",
    "organization country",
    "org country",
  ],
  status: ["status"],
  preferred_communication: ["preferred communication"],
  unique_identifier: ["unique identifier", "zoominfo company id"],
  cf_industry: [
    "industry",
    "industries",
    "related industry",
    "primary industry",
  ],
  cf_annual_revenue: ["annual revenue", "revenue"],
  cf_modes: ["modes", "mode"],
  cf_pain_points: ["pain points"],
  cf_average_weekly_volume___shipments: [
    "average weekly volume",
    "weekly volume",
    "volume",
    "avg volume",
    "vol",
  ],
  cf_special_requirements: [
    "special requirements",
    "sp. requirements",
    "special reqs",
    "reqs",
    "requirements",
  ],
  cf_employees: [
    "employees count",
    "employees",
    "no. of employees",
    "#employees",
    "# employees",
  ],
};
