import React, { useEffect, useState } from "react";
import axios from "axios";
import "../sass/ActivityLog.scss";
import Activity from "./Activity";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import "../sass/Animation.scss";
import { v4 as uuidv4 } from "uuid";
import DashboardLoader from "./Loaders/DashboardLoader";

const ActivityLog = (props) => {
  const [expand, setExpand] = useState(false);
  const [mailProvider, setMailProvider] = useState("");
  const [processedLogs, setProcessedLogs] = useState([]); // To hold resolved activities
  const [processedPinnedActivity, setProcessedPinnedActivity] = useState(null); // To hold resolved pinned activity

  const { loadActivities, pinnedActivity, activityLoader } = props;

  const fetchMailProvider = () => {
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      setMailProvider(res.data.user.mail_provider);
    });
  };

  const getEmailData = async (activityId) => {
    const res = await axios({
      method: "GET",
      url: `/activities/${activityId}`,
    });
    return res.data.activity?.email_data || null;
  };

  // Pinned activity
  const processPinnedActivity = async () => {
    if (!pinnedActivity) return;

    const {
      id,
      description: activityDescription,
      category_id,
    } = pinnedActivity;

    const emailData =
      category_id?.toString() === "2" ? await getEmailData(id) : null;

    const description =
      category_id?.toString() === "2"
        ? emailData?.email_body
        : activityDescription;

    const attachments =
      category_id?.toString() === "2" ? emailData?.attachments : [];

    const descriptionLength = description?.length || 0;
    const numberOfLineBreaks = (description?.match(/\n/g) || []).length;
    const textArray = description?.split(/^/gm) || [];
    const textToShow = textArray?.slice(0, 3).join("");

    const trimmedDescription =
      descriptionLength > 140 || numberOfLineBreaks > 3
        ? textToShow.substring(0, 137) + "..."
        : description;

    const trimmedDescriptionLink = trimmedDescription
      ? trimmedDescription.replaceAll("<a", "<a target='_blank'")
      : "";
    const descriptionLink = description
      ? description.replaceAll("<a", "<a target='_blank'")
      : "";

    setProcessedPinnedActivity({
      ...pinnedActivity,
      description,
      trimmedDescription,
      trimmedDescriptionLink,
      descriptionLink,
      attachments,
    });
  };

  // Other activities
  const processLogs = async () => {
    const resolvedLogs = await Promise.all(
      props.logs.map(async (data) => {
        const { date, activities } = data;

        const resolvedActivities = await Promise.all(
          activities.map(async (activity) => {
            const {
              id,
              description: activityDescription,
              category_id,
            } = activity;

            const emailData =
              category_id?.toString() === "2" ? await getEmailData(id) : null;

            const description =
              category_id?.toString() === "2"
                ? emailData?.email_body
                : activityDescription;

            const attachments =
              category_id?.toString() === "2" ? emailData?.attachments : [];

            const descriptionLength = description?.length || 0;
            const numberOfLineBreaks = (description?.match(/\n/g) || []).length;
            const textArray = description?.split(/^/gm) || [];
            const textToShow = textArray?.slice(0, 3).join("");

            const trimmedDescription =
              descriptionLength > 140 || numberOfLineBreaks > 3
                ? textToShow.substring(0, 137) + "..."
                : description;

            const trimmedDescriptionLink = trimmedDescription
              ? trimmedDescription.replaceAll("<a", "<a target='_blank'")
              : "";
            const descriptionLink = description
              ? description.replaceAll("<a", "<a target='_blank'")
              : "";

            return {
              ...activity,
              description,
              trimmedDescription,
              trimmedDescriptionLink,
              descriptionLink,
              attachments,
            };
          })
        );

        return {
          date,
          activities: resolvedActivities,
        };
      })
    );

    setProcessedLogs(resolvedLogs);
  };

  useEffect(() => {
    fetchMailProvider();
  }, []);

  useEffect(() => {
    loadActivities(1, "initial");
  }, [loadActivities]);

  useEffect(() => {
    processLogs();
  }, [props.logs]);

  useEffect(() => {
    processPinnedActivity();
  }, [pinnedActivity]);

  return (
    <div className="activityLog">
      <div className="activityLog__header">
        <div>
          <h3 className="activity-title">Activity Timeline</h3>
        </div>
        <div className=" activityLog__menu">
          <button
            type="button"
            onClick={() => setExpand(!expand)}
            className="activityLog__button"
          >
            {expand ? "Collapse All" : "Expand All"}
          </button>
        </div>
      </div>
      <div style={{ height: "700px", overflow: "auto" }}>
        <InfiniteScroll
          pageStart={1}
          loadMore={(page) => props.loadActivities(page, "nextPage")}
          hasMore={props.hasMoreItems}
          loader={<DashboardLoader key={0} />}
          useWindow={false}
          initialLoad={false}
        >
          {processedLogs.length === 0 &&
          !processedPinnedActivity &&
          activityLoader === false ? (
            <div>
              <h2
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  margin: "20px 30px",
                  textAlign: "center",
                }}
              >
                Log your first activity or set a task to follow-up with this
                client.
              </h2>
            </div>
          ) : (
            <div className="tracks">
              {processedPinnedActivity && (
                <div>
                  <div className="activityLog__date">
                    {moment(processedPinnedActivity.date).format("D MMM YYYY")}
                  </div>
                  <Activity
                    {...processedPinnedActivity}
                    icon={
                      pinnedActivity.category_name === "Notes" &&
                      pinnedActivity.name === "Web form submitted"
                        ? "Webform"
                        : pinnedActivity.category_name === "Opened Email"
                        ? "EmailGreen"
                        : pinnedActivity.category_name + "Green"
                    }
                    time={moment(pinnedActivity.created_at).format("LT")}
                    name={
                      pinnedActivity.type === "activity_logged"
                        ? `${pinnedActivity.category_name} `
                        : "Task Completed"
                    }
                    type={pinnedActivity.type}
                    title={pinnedActivity.name}
                    description={
                      expand
                        ? processedPinnedActivity.descriptionLink
                        : processedPinnedActivity.trimmedDescriptionLink
                    }
                    descriptionFull={processedPinnedActivity.description}
                    created_by={pinnedActivity.created_by}
                    opportunityName={props.opportunityName}
                    whiteSpace={"pre-wrap"}
                    id={pinnedActivity.id}
                    account_id={pinnedActivity.account_id}
                    opportunity_id={pinnedActivity.opportunity_id}
                    result_id={pinnedActivity.result_id}
                    result_name={pinnedActivity.result_name}
                    category_id={pinnedActivity.category_id}
                    opportunity_name={pinnedActivity.opportunity_name}
                    account_name={pinnedActivity.account_name}
                    contact_id={pinnedActivity.contact_id}
                    contact_name={pinnedActivity.contact_name}
                    loadActivities={loadActivities}
                    metadata={processedPinnedActivity.email_data}
                    parentId={pinnedActivity.id}
                    integration={props.integration}
                    is_pinned={pinnedActivity.is_pinned}
                    pinnedId={pinnedActivity ? pinnedActivity.id : null}
                    metadataType={pinnedActivity.metadata_type}
                    toEmail={props.toEmail}
                    iconExtension={
                      pinnedActivity.metadata_type === "calendly"
                        ? "png"
                        : "svg"
                    }
                    mailProvider={mailProvider}
                    attachments={processedPinnedActivity.attachments}
                  />
                </div>
              )}
              {processedLogs.map(({ date, activities }) => (
                <div key={date + uuidv4()}>
                  {activities.length > 0 && (
                    <div className="activityLog__date">
                      {moment(date).format("D MMM YYYY")}
                    </div>
                  )}
                  {activities.map((activity) => {
                    const {
                      id,
                      description,
                      name,
                      type,
                      created_at,
                      category_name,
                      created_by,
                      account_id,
                      opportunity_id,
                      contact_name,
                      contact_id,
                      carrier_name,
                      carrier_id,
                      result_id,
                      category_id,
                      result_name,
                      opportunity_name,
                      account_name,
                      email_data: metadata,
                      parent_id,
                      is_pinned,
                      metadata_type,
                      attachments,
                    } = activity;
                    return (
                      <div key={id}>
                        <Activity
                          {...activity}
                          icon={
                            metadata_type === "calendly"
                              ? "calendlyIcon"
                              : category_name === "Notes" &&
                                name === "Web form submitted"
                              ? "Webform"
                              : category_name === "Opened Email"
                              ? "EmailGreen"
                              : category_name + "Green"
                          }
                          time={moment(created_at).format("LT")}
                          name={
                            type === "activity_logged"
                              ? `${category_name} `
                              : "Task Completed"
                          }
                          type={type}
                          title={name}
                          description={
                            expand
                              ? activity.descriptionLink
                              : activity.trimmedDescriptionLink
                          }
                          descriptionFull={description}
                          created_by={created_by}
                          opportunityName={props.opportunityName}
                          whiteSpace={"pre-wrap"}
                          id={id}
                          account_id={account_id}
                          opportunity_id={opportunity_id}
                          result_id={result_id}
                          result_name={result_name}
                          category_id={category_id}
                          opportunity_name={opportunity_name}
                          account_name={account_name}
                          contact_id={contact_id}
                          contact_name={contact_name}
                          carrier_id={carrier_id}
                          carrier_name={carrier_name}
                          loadActivities={loadActivities}
                          metadata={metadata}
                          parentId={metadata?.id}
                          integration={props.integration}
                          is_pinned={is_pinned}
                          pinnedId={pinnedActivity ? pinnedActivity.id : null}
                          metadataType={metadata_type}
                          customerName={props.customerName}
                          toEmail={props.toEmail}
                          iconExtension={
                            metadata_type === "calendly" ? "png" : "svg"
                          }
                          mailProvider={mailProvider}
                          attachments={attachments}
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default React.memo(ActivityLog);
