import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
// import PauseSequenceModal from "./PauseSequenceModal";
import DropdownAnalyticsFilter from "../Dropdown/DropdownAnalyticsFilter";
import DropdownCustomRangeFilter from "../Dropdown/DropdownCustomRangeFilter";
import LineChartAnalytics from "../EmailAnalytics/LineGraphAnalytics";
import "../EmailAnalytics/EmailAnalytics.scss";
import { truncateDecimals } from "../../utils/Helper/reusableFunctions";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
//images
import clickEmailIcon from "../../images/clickEmailIcon.png";
import openEmailIcon from "../../images/openEmailIcon.png";
import replyEmailIcon from "../../images/replyEmailIcon.png";
import spamEmailIcon from "../../images/spamEmailIcon.png";
import totalSentIcon from "../../images/totalSentIcon.png";
import undeliveredEmailIcon from "../../images/undeliveredEmailIcon.png";
import unsubscribeEmailIcon from "../../images/unsubscribe.png";
// Redux stuff
import { connect } from "react-redux";
import {
  companyOnboardingSet,
  setTab,
  setActiveTabIndex,
  authUserSet,
} from "../../redux";

const Sequencing = (props) => {
  const [stats, setStats] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [sequenceId, setSequenceId] = useState(null);
  // const [action, setAction] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(["active"]);
  const [selectedDateFilter, setSelectedDateFilter] = useState("last_4_weeks");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [analyticsMode, setAnalyticsMode] = useState("table");
  const [chartSeries, setChartSeries] = useState([]);
  const [user_ids, setUser_ids] = useState(props.authUser.id);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.authUser.name);
  const [metrics, setMetrics] = useState({
    bounce: 0,
    click: 0,
    reply: 0,
    open: 0,
    sent: 0,
    spam: 0,
  });
  // constants
  const grids = [
    {
      name: "total sent",
      img: totalSentIcon,
      matrix: metrics.sent,
      matrixPercentage: null,
      type: "sent",
    },
    {
      name: "opens",
      img: openEmailIcon,
      matrix: metrics.open,
      matrixPercentage:
        metrics.open === 0 ? 0 : (metrics.open / metrics.sent) * 100,
      type: "open",
    },
    {
      name: "clicks",
      img: clickEmailIcon,
      matrix: metrics.click,
      matrixPercentage:
        metrics.click === 0 ? 0 : (metrics.click / metrics.sent) * 100,
      type: "clicks",
    },
    {
      name: "replies",
      img: replyEmailIcon,
      matrix: metrics.reply,
      matrixPercentage:
        metrics.reply === 0 ? 0 : (metrics.reply / metrics.sent) * 100,
      type: "reply",
    },
    {
      name: "bounces",
      img: undeliveredEmailIcon,
      matrix: metrics.bounce,
      matrixPercentage:
        metrics.bounce === 0 ? 0 : (metrics.bounce / metrics.sent) * 100,
      type: "bounce",
    },
    {
      name: "spam",
      img: spamEmailIcon,
      matrix: metrics.spam,
      matrixPercentage:
        metrics.spam === 0 ? 0 : (metrics.spam / metrics.sent) * 100,
      type: "spam",
    },
    {
      name: "unsubscribes",
      img: unsubscribeEmailIcon,
      matrix: metrics.unsubscribe,
      matrixPercentage:
        metrics.unsubscribe === 0
          ? 0
          : (metrics.unsubscribe / metrics.sent) * 100,
      type: "unsubscribe",
    },
  ];
  const dateFilters = [
    { label: "Last 7 days", value: "last_7_days" },
    { label: "Month to date", value: "this_month" },
    { label: "Last 4 weeks", value: "last_4_weeks" },
    { label: "Last 3 months", value: "last_3_months" },
    { label: "Last 6 months", value: "last_6_months" },
    { label: "Last 12 months", value: "last_12_months" },
    { label: "All time", value: "all_time" },
  ];
  const filters = ["active", "paused", "completed"];
  // functions
  // const filterHandler = (e) => {
  //   const { value } = e.target;
  //   if (e.target.checked) {
  //     const newFilters = [...selectedFilters, value];
  //     setSelectedFilters(newFilters);
  //   } else {
  //     const removeFilter = selectedFilters.filter((filter) => filter !== value);
  //     setSelectedFilters(removeFilter);
  //   }
  // };
  const dateChangeHandler = (date, name) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    name === "toDate" ? setToDate(offsetDate) : setFromDate(offsetDate);
  };
  const dateFilterChangeHandler = (value) => {
    setSelectedDateFilter(value);
    setToDate("");
    setFromDate("");
  };
  const applyCustomDateFilter = () => {
    setSelectedDateFilter("custom_range");
    getStats(null, user_ids);
  };

  const getStats = (sortKey, user_ids = props.authUser.id) => {
    let url = `/sequences/scheduled/stats`;
    if (selectedDateFilter === "custom_range") {
      url += `?start_date_from=${moment(fromDate).format(
        "YYYY-MM-DD"
      )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
    } else {
      url += `?time=${selectedDateFilter}`;
    }
    // if (selectedFilters) {
    //   const filter = selectedFilters.join(",");
    //   url += `&sequence_type=${filter}`;
    // }
    if (sortKey) {
      const dir = sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (user_ids) {
      url += `&user_ids=${user_ids}`;
    }
    setLoading(true);
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setStats(res.data.sequences);
        setChartSeries(res.data.chart_stats);
        setMetrics(res.data.stats);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getStats(null, user_ids);
  }, [selectedDateFilter, selectedFilters]);
  const tableHeaders = [
    { value: "name", label: "Sequence Name", sort: true },
    { value: "total_enrolled", label: "ENROLLMENTS", sort: false },
    { value: "click", label: "CLICKED", sort: true },
    { value: "open", label: "OPENED", sort: true },
    { value: "bounce", label: "BOUNCED", sort: true },
    { value: "spam", label: "SPAM", sort: true },
    { value: "delivery", label: "DELIVERY", sort: true },
    { value: "unsubscribe", label: "Unsubscribe", sort: true },
    // { value: "", label: "ACTION", sort: false },
  ];
  const handleUserId = (user_ids, userName) => {
    setUser_ids(user_ids);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    getStats(null, user_ids);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };
  return (
    <div className="reports">
      {/* <PauseSequenceModal
        showOpportunityModal={showModal}
        setShowOpportunityModal={() => setShowModal(true)}
        handleCloseModal={() => setShowModal(false)}
        sequenceId={sequenceId}
        action={action}
        getStats={getStats}
      /> */}
      <ReactTooltip multiline={true} id="beta-tooltip" place="right" />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>
          Email Sequences
          <Link
            to="/sequencing-create"
            className="button-md"
            style={{
              float: "right",
              fontSize: "13.33px",
              textDecoration: "none",
              justifyContent: "center",
              textAlign: "center",
              paddingTop: "8px",
              height: "26px",
              width: "110px",
            }}
          >
            Create Sequence
          </Link>
          <div style={{ display: "inline-block", marginLeft: "50px" }}>
            <DropdownDashboard
              handleUserId={handleUserId}
              user_ids={user_ids}
              userName={props.authUser.name}
              userId={props.authUser.id}
              displayMenu={displayMenu}
              showDropdownMenu={showDropdownMenu}
              displayUserName={displayUserName}
              userRole={props.authUser.role}
            />
          </div>
        </h2>
        <p> </p>
      </div>
      <div className="email-analytics-filters">
        <h3>Filters: </h3>
        <div style={{ display: "flex" }}>
          {/* {filters.map((filter) => (
            <label
              className="coloredTasks-menu"
              style={
                selectedFilters.includes(filter)
                  ? {
                      border: "2px solid #4A9876",
                      textTransform: "capitalize",
                      fontSize: "14px",
                    }
                  : { textTransform: "capitalize", fontSize: "14px" }
              }
            >
              <input
                value={filter}
                onChange={filterHandler}
                style={{ display: "none" }}
                type="checkbox"
                checked={selectedFilters.includes(filter)}
                disabled={
                  selectedFilters.length === 1 &&
                  selectedFilters.includes(filter)
                }
              />
              {filter}
            </label>
          ))} */}
          <div className="email-analytics-range">
            <DropdownAnalyticsFilter
              selectedDateFilter={selectedDateFilter}
              dateFilters={dateFilters}
              dateFilterChangeHandler={dateFilterChangeHandler}
              toDate={toDate}
              fromDate={fromDate}
            />
          </div>
          <div style={{ margin: "0 20px 0 0" }}>
            <DropdownCustomRangeFilter
              toDate={toDate}
              fromDate={fromDate}
              dateChangeHandler={dateChangeHandler}
              submitHandler={applyCustomDateFilter}
            />
          </div>
        </div>
      </div>
      <div className="email-analytics-grid">
        {grids.map((grid) => {
          let newTabData = {
            type: "sequenceCampaignMetrices",
            id: grid.type,
            name: grid.name,
            campaignType: grid.type,
            time: selectedDateFilter,
            start_time: fromDate,
            end_time: toDate,
          };
          return (
            <div
              className="email-analytics-metrics"
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({ ...newTabData, blank: true });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab(newTabData);
                  props.history.push("/active-tabs");
                }
              }}
            >
              <img src={grid.img} />
              <div>
                <div className="email-analytics-amount">
                  {grid.matrixPercentage === null
                    ? grid.matrix
                    : `${truncateDecimals(grid.matrixPercentage, 1)}%`}
                </div>
                <div className="email-analytics-infoname">{grid.name}</div>
                <div className="email-analytics-info">
                  {grid.matrixPercentage !== null &&
                    `${grid.matrix} ${grid.name}`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="reports-tab-container">
        <button
          className="reports-tab"
          type="button"
          onClick={() => setAnalyticsMode("table")}
          data-tip="Tabular view"
        >
          <i className="fa fa-table" aria-hidden="true"></i>
        </button>
        <button
          className="reports-tab"
          type="button"
          onClick={() => setAnalyticsMode("line")}
          data-tip="Line chart"
        >
          <i className="fa fa-line-chart" aria-hidden="true"></i>
        </button>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : loading === false && stats.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No sequences found
        </div>
      ) : analyticsMode === "table" ? (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw", tableLayout: "fixed" }}
        >
          <colgroup>
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "13%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            {/* <col span="1" style={{ width: "14%" }} /> */}
          </colgroup>
          <thead>
            <tr>
              {tableHeaders.map((headers) => (
                <th key={headers.label}>
                  <Row>
                    <Col lg={9} xl={9}>
                      {headers.label}
                    </Col>
                    <Col lg={3} xl={3}>
                      {headers.sort && (
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            color: "#657885",
                          }}
                          onClick={() => {
                            setSortDirection(!sortDirection);
                            getStats(headers.value, user_ids);
                          }}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                          <i className="fa fa-long-arrow-down"></i>
                        </button>
                      )}
                    </Col>
                  </Row>
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {stats?.map((stat) => (
              <tr>
                <td>
                  <Link
                    to={`/sequencing/${stat.id}`}
                    style={{ color: "#4A9876", textDecoration: "none" }}
                  >
                    {stat.name}
                  </Link>
                </td>
                <td>{stat.total_enrolled}</td>
                <td>{stat.click}</td>
                <td>{stat.open}</td>
                <td>{stat.bounce}</td>
                <td>{stat.spam}</td>
                <td>{stat.delivery}</td>
                <td>{stat.unsubscribe}</td>
                {/* <td>
                  <button
                    type="button"
                    onClick={() => {
                      setSequenceId(stat.id);
                      setShowModal(true);
                      stat.published ? setAction("stop") : setAction("resume");
                    }}
                    style={{
                      background: "#4A9876",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                      outline: "none",
                      padding: "5px 6px",
                      borderRadius: "3px",
                      width: "70%",
                    }}
                  >
                    {stat.published ? "Stop" : "Resume"}
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <LineChartAnalytics series={chartSeries} />
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};
export default connect(MSP, MDP)(Sequencing);
