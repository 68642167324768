import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLoader from "../../Loaders/DashboardLoader";
import { withRouter } from "react-router-dom";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../../redux";
import { Col, Row } from "react-grid-system";
import Pagination from "react-js-pagination";
import moment from "moment";

const AccountStatusChange = (props) => {
  const [filter, setFilter] = useState("last_30_days");
  const [deals, setDeals] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sortingKey, setSortingKey] = useState(null);

  const allFilters = [
    { value: "all_time", label: "All time" },
    { value: "last_7_days", label: "Last 7 days" },
    { value: "last_14_days", label: "Last 14 days" },
    { value: "last_30_days", label: "Last 30 days" },
    { value: "last_60_days", label: "Last 60 days" },
    { value: "last_90_days", label: "Last 90 days" },
    { value: "last_365_days", label: "Last 365 days" },
  ];
  const allHeaders = [
    {
      label: "Owner",
      value: "account_owner",
      sort: true,
      sortKey: "account_owner",
      type: null,
    },
    {
      label: "Account Name",
      value: "account_name",
      sort: true,
      sortKey: "account_name",
      type: null,
    },
    {
      label: "Prev Status",
      value: "prev_status",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "New Status",
      value: "new_status",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Status Change Date",
      value: "status_changed_at",
      sort: true,
      sortKey: "status_changed_at",
      type: "date",
    },
    {
      label: "Current Status",
      value: "current_status",
      sort: false,
      sortKey: null,
      type: null,
    },
  ];

  const getDeals = (page = 1, sortKey, sortDir) => {
    setLoader(true);
    let url = `/accounts/status-change-history?user_ids=${props.userId}&duration=${filter}&_limit=30&_page=${page}`;
    if (sortKey) {
      const dir = sortDir === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setDeals(res.data.status_change_history);
        setTotal(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getDeals();
  }, [filter, props.userId]);
  return (
    <div className="card">
      <div className="funnel-heading">
        <h4 className="funnel-heading-sub-1">
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                props.setTab({
                  type: "accountStatusChangeReport",
                  id: `accountStatusChangeReport`,
                  name: `Account Status Change`,
                  blank: true,
                  userId: props?.userId,
                  userName: props?.userName,
                });
              } else {
                props.setActiveTabIndex(props.totalTabs + 1);
                props.setTab({
                  type: "accountStatusChangeReport",
                  id: `accountStatusChangeReport`,
                  name: `Account Status Change`,
                  userId: props?.userId,
                  userName: props?.userName,
                });
                props.history.push("/active-tabs");
              }
            }}
          >
            Account Status Changes{" "}
          </span>
          <span style={{ color: "#4A9876" }}>{total}</span>
        </h4>
        <h6 className="funnel-heading-sub-c">
          <select
            className="funnel-select"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {allFilters.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </h6>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <>
          <div
            style={{
              overflow: "auto",
              maxHeight: props.maxHeight,
              ...(props.maxWidth && {
                maxWidth: props.maxWidth - 15,
              }),
            }}
          >
            <table className="accountList__table" style={{ marginTop: "0" }}>
              <thead>
                {allHeaders.map((header) => (
                  <th key={header.value + header.label}>
                    <Row>
                      <Col lg={9} xl={9}>
                        {header.label}
                      </Col>
                      <Col lg={3} xl={3}>
                        {header.sort && (
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() => {
                              getDeals(
                                activePage,
                                header.sortKey,
                                !sortDirection
                              );
                              setSortDirection(!sortDirection);
                              setSortingKey(header.sortKey);
                            }}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        )}
                      </Col>
                    </Row>
                  </th>
                ))}
              </thead>
              <tbody>
                {deals?.map((account) => {
                  let newTabData = {
                    type: "account",
                    id: account.account_id,
                    name: account.account_name,
                  };
                  return (
                    <tr key={account.account_id + account.account_name}>
                      {allHeaders.map((header) => (
                        <td key={header.value + header.label}>
                          {header.value === "account_name" ? (
                            <span
                              style={{ cursor: "pointer", color: "#4A9876" }}
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  props.setTab({ ...newTabData, blank: true });
                                } else {
                                  props.setActiveTabIndex(props.totalTabs + 1);
                                  props.setTab(newTabData);
                                  props.history.push("/active-tabs");
                                }
                              }}
                            >
                              {account.account_name}
                            </span>
                          ) : header.type === "date" ? (
                            moment(account?.[header.value]).format("l")
                          ) : (
                            account?.[header.value]
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              setActivePage(page);
              getDeals(page, sortingKey, sortDirection);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        </>
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};
export default withRouter(connect(MSP, MDP)(AccountStatusChange));
