import "../../sass/Reports.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

const WebFormList = () => {
  const [webform, setWebForm] = useState([]);
  useEffect(() => {
    axios({
      method: "GET",
      url: `/company/contact-form`,
    }).then((res) => {
      setWebForm(res.data.web_forms);
    });
  }, []);

  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>Webforms</h2>
          <p> </p>
        </div>
        <Link
          to={`/webform/contact/create`}
          className="button-md"
          style={{
            float: "right",
            marginRight: "20px",
            marginTop: "10px",
            textDecoration: "none",
            textAlign: "center",
            paddingTop: "7px",
            height: "25px",
            width: "140px",
            fontWeight: "500",
          }}
        >
          Create Webform
          <Plus
            size={14}
            color="#62CA9D"
            strokeWidth={3}
            style={{ transform: "translateY(2px)", marginLeft: "5px" }}
          />
        </Link>
      </div>
      <table
        className="accountList__table"
        style={{ marginTop: "20px", width: "93vw" }}
      >
        <thead>
          <tr>
            <th>heading</th>
            <th>theme</th>
            <th>is active</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {webform?.map((form) => (
            <tr key={form.id}>
              <td>{form.heading}</td>
              <td>{form.theme}</td>
              <td>{form.is_active ? "Yes" : "No"}</td>
              <td>
                <Link
                  style={{
                    border: "1px solid #ffffff",
                    borderRadius: "9px",
                    padding: "5px 10px",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "18px",
                    color: "#73738C",
                    background: "#F4F6F5",
                    height: "35px",
                    cursor: "pointer",
                    marginRight: "12px",
                  }}
                  to={`/webform/contact/edit/${form.id}`}
                >
                  Edit
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WebFormList;
