export const contactTerminologies = {
  contact_owner: [
    "contact owner",
    "owner",
    "customer owner",
    "person owner",
    "contacts owner",
    "customers owner",
    "user owner",
    "owners",
  ],
  name: [
    "contact name",
    "name",
    "customer name",
    "person name",
    "user name",
    "full name",
    "client name",
  ],
  account_name: [
    "account name",
    "company name",
    "company",
    "organization name",
    "organization",
    "org",
    "org name",
  ],
  carrier_name: ["carrier name", "carrier"],
  source: ["source", "contact source", "customer source", "person source"],
  state: ["state", "contact state", "customer state", "person state"],
  zip: [
    "zip",
    "zip code",
    "postal code",
    "contact zip",
    "customer zip",
    "person zip",
    "contact zip code",
    "customer zip code",
    "person zip code",
    "contact postal code",
    "customer postal code",
    "person postal code",
  ],
  city: ["city", "contact city", "customer city", "person city"],
  address: [
    "address",
    "contact address",
    "customer address",
    "person address",
    "full address",
  ],
  linkedin: [
    "linkedin",
    "linkedin url",
    "contact linkedin",
    "customer linkedin",
    "contact linkedin url",
    "customer linkedin url",
    "person linkedin url",
    "linkedin contact profile url",
    "linkedin person profile url",
  ],
  country: ["country", "contact country", "customer country", "person country"],
  email: [
    "email",
    "email address",
    "contact email",
    "customer email",
    "contact email address",
    "customer email address",
    "person email address",
  ],
  formatted_phone: [
    "phone",
    "phone number",
    "contact phone",
    "customer phone",
    "telephone number",
    "contact phone number",
    "customer phone number",
    "person phone number",
    "direct phone number",
  ],
  phone_ext: [
    "phone extension",
    "person phone extension",
    "customer phone extension",
    "contact phone extension",
    "telephone extension",
  ],
  formatted_mobile: [
    "mobile",
    "mobile number",
    "cell",
    "cell number",
    "customer mobile",
    "customer mobile number",
    "customer cell",
    "customer cell number",
    "contact mobile",
    "contact mobile number",
    "contact cell",
    "contact cell number",
    "mobile phone",
  ],
  description: [
    "description",
    "short description",
    "notes",
    "contact description",
    "customer description",
    "person description",
  ],
  status: [
    "status",
    "contact status",
    "customer status",
    "lead status",
    "person status",
  ],
  preferred_communication: ["preferred communication"],
  opportunity_name: ["deal name", "deal", "opportunity", "opportunity name"],
  role: [
    "job title",
    "job",
    "title",
    "role",
    "contact role",
    "customer role",
    "contact job title",
    "customer job title",
    "person job title",
  ],
  unique_identifier: ["unique identifier", "zoominfo contact id"],
};
