import { accountTerminologies } from "./accountTerminologies";
import { activityTerminologies } from "./activityTerminologies";
import { carrierTeminologies } from "./carrierTeminologies";
import { contactTerminologies } from "./contactTerminologies";
import { dealTeminologies } from "./dealTeminologies";
import { laneTerminologies } from "./laneTerminologies";

export const commonTerminologies = {
  account: accountTerminologies,
  carrier: carrierTeminologies,
  contact: contactTerminologies,
  deal: dealTeminologies,
  activity: activityTerminologies,
  lane: laneTerminologies,
};
