import axios from "axios";
import React, { useEffect, useState } from "react";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import DashboardLoader from "../../Loaders/DashboardLoader";

const PipelineMetrics = ({ userId }) => {
  const [filter, setFilter] = useState("all_time");
  const [metrics, setMetrics] = useState({});
  const [loader, setLoader] = useState(false);

  const allFilters = [
    { value: "all_time", label: "All time" },
    { value: "last_3_months", label: "Last 3 months" },
    { value: "last_6_months", label: "Last 6 months" },
    { value: "last_12_months", label: "Last 12 months" },
  ];
  const allMetrics = [
    { label: "Avg deals created per month", value: "average_deals_created" },
    { label: "Avg deals won per month", value: "average_deals_won" },
    { label: "Sales cycle length", value: "average_sales_cycle_length" },
    { label: "Close rate", value: "close_rate_percentage" },
  ];

  const getMetrics = () => {
    setLoader(true);
    axios({
      method: "GET",
      url: `/charts/pipeline-metrics?user_ids=${userId}&duration=${filter}`,
    })
      .then((res) => {
        setMetrics(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getMetrics();
  }, [filter, userId]);

  return (
    <div className="card">
      <div className="funnel-heading">
        <h4 className="funnel-heading-sub-1">Pipeline Metrics</h4>
        <h6 className="funnel-heading-sub-c">
          <select
            className="funnel-select"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {allFilters.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </h6>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            gridGap: "15px 10px",
          }}
        >
          {allMetrics.map((metric) => (
            <div
              style={{
                border: "1px solid #e3e3e3",
                padding: "0px 5px",
                borderRadius: "8px",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
                height: "142px",
              }}
              key={metric.value + metric.label}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  position: "absolute",
                  top: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                }}
              >
                {metric.label}
              </h2>
              <p
                style={
                  metric.value === "close_rate_percentage"
                    ? {
                        fontSize: "38px",
                        fontWeight: "700",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -35%)",
                        color: "#252A2D",
                      }
                    : {
                        fontSize: "38px",
                        fontWeight: "700",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%)",
                        color: "#252A2D",
                        width: "100%",
                      }
                }
              >
                {metric.value === "close_rate_percentage" ? (
                  <Gauge
                    width={280}
                    height={100}
                    value={metrics?.[metric.value] ?? 0}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={60}
                    sx={(theme) => ({
                      [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 24,
                        transform: "translate(0px, -15px)",
                        color: "#252A2D",
                      },
                      [`& .${gaugeClasses.valueArc}`]: {
                        fill: "#4A9876",
                      },
                    })}
                    text={({ value }) => `${value}%`}
                  />
                ) : (
                  `${metrics?.[metric.value] ?? 0} ${
                    metric.value === "average_sales_cycle_length" ? "days" : ""
                  }`
                )}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PipelineMetrics;
