import React, { Component } from "react";
import "../sass/Tasks.scss";
import Task from "./Task";
import axios from "axios";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "react-dates/initialize";
import TaskChevron from "../images/TaskChevron.svg";
import ReactTooltip from "react-tooltip";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../redux";

class Tasks extends Component {
  state = {
    scheduled: [],
    open: [],
    overdue: [],
    schedule_length: "",
    open_length: "",
    overdue_length: "",
    isGrouped: false,
  };
  resetLoader = () => {
    axios({
      method: "GET",
      url: `/dashboard/tasks?user_ids=${this.props.userId}`,
    }).then((response) => {
      this.setState({
        scheduled: response.data.scheduled.tasks,
        open: response.data.open.tasks,
        overdue: response.data.overdue.tasks,
        schedule_length: response.data.scheduled.total,
        open_length: response.data.open.total,
        overdue_length: response.data.overdue.total,
        isGrouped: response.data.is_grouped,
      });
    });
  };
  componentDidMount() {
    axios({
      method: "GET",
      url: `/dashboard/tasks?user_ids=${this.props.userId}`,
    })
      .then((response) => {
        this.setState({
          scheduled: response.data.scheduled.tasks,
          open: response.data.open.tasks,
          overdue: response.data.overdue.tasks,
          schedule_length: response.data.scheduled.total,
          open_length: response.data.open.total,
          overdue_length: response.data.overdue.total,
          isGrouped: response.data.is_grouped,
        });
      })
      .catch((error) => console.log(error));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      axios({
        method: "GET",
        url: `/dashboard/tasks?user_ids=${this.props.userId}`,
      })
        .then((response) => {
          this.setState({
            scheduled: response.data.scheduled.tasks,
            open: response.data.open.tasks,
            overdue: response.data.overdue.tasks,
            scheduledGrouped: response.data.scheduled.users,
            openGrouped: response.data.open.users,
            overdueGrouped: response.data.overdue.users,
            schedule_length: response.data.scheduled.total,
            open_length: response.data.open.total,
            overdue_length: response.data.overdue.total,
            isGrouped: response.data.is_grouped,
          });
        })
        .catch((error) => console.log(error));
    }
  }
  toggleHover = () => {
    this.setState((prevState) => ({ isHovered: !prevState.isHovered }));
  };

  render() {
    const {
      scheduled,
      overdue,
      open,
      schedule_length,
      open_length,
      overdue_length,
    } = this.state;
    return (
      <div className="card" style={{ textAlign: "left", padding: "7px 20px" }}>
        <ReactTooltip multiline={true} id="Today-Tasks-h2" />
        <ReactTooltip multiline={true} id="Scheduled-Tasks-h2" />
        <ReactTooltip multiline={true} id="Scheduled-Tasks-i" />
        <ReactTooltip multiline={true} id="Open-Tasks-h2" />
        <ReactTooltip multiline={true} id="Open-Tasks-i" />
        <ReactTooltip multiline={true} id="Overdue-Tasks-h2" />
        <ReactTooltip multiline={true} id="Overdue-Tasks-i" />
        <h3 className="h2">
          <span
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                this.props.setTab({
                  type: "task",
                  id: `${this.props.userId}allTasks`,
                  name: "All Tasks",
                  blank: true,
                  taskType: "",
                  length: schedule_length,
                  userId: this.props.userId,
                  userName: this.props.userName,
                });
              } else {
                this.props.setActiveTabIndex(this.props.totalTabs + 1);
                this.props.setTab({
                  type: "task",
                  id: `${this.props.userId}allTasks`,
                  name: "All Tasks",
                  taskType: "",
                  length: schedule_length,
                  userId: this.props.userId,
                  userName: this.props.userName,
                });
                this.props.history.push("/active-tabs");
              }
            }}
            style={{
              color: "inherit",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            data-tip="View all tasks."
            data-for="Today-Tasks-h2"
          >
            Today's Tasks
            <img
              src={TaskChevron}
              alt=""
              style={{ transform: "translateY(5px)", marginLeft: "5px" }}
            />
          </span>
        </h3>
        <ReactTooltip multiline={true} />
        {this.state.isGrouped === false ? (
          <div>
            <div className="scheduled" style={{ position: "relative" }}>
              <h4 className="h3" style={{ marginTop: "22px" }}>
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}scheduled`,
                        name: "Scheduled Tasks",
                        blank: true,
                        taskType: "scheduled",
                        length: schedule_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}scheduled`,
                        name: "Scheduled Tasks",
                        taskType: "scheduled",
                        length: schedule_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="Go to Scheduled tasks report"
                  data-for="Scheduled-Tasks-h2"
                >
                  Scheduled
                </span>
                <span
                  style={{
                    width: "19px",
                    height: "17.25px",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    background: "#61C99D",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    padding: "1.5px 6px",
                    letterSpacing: "-0.005em",
                    fontStyle: "normal",
                    color: "#FFFFFF",
                    marginLeft: "5px",
                  }}
                >
                  {schedule_length}
                </span>
                <span
                  data-tip="Scheduled tasks have a date AND time associated with them.<br />
              They are most recommended as setting both with your customer increases rate of response."
                  style={{
                    position: "absolute",
                    top: "9%",
                    right: "2%",
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#4A9876",
                    padding: "0px 5.3px",
                    height: "14px",
                    borderRadius: "50%",
                    border: "1px solid #4A9876",
                  }}
                  data-for="Scheduled-Tasks-i"
                  data-multiline={true}
                >
                  i
                </span>
              </h4>
              <div>
                {scheduled.length !== 0 ? (
                  scheduled?.map((task, index) => {
                    const {
                      account_id,
                      account_name,
                      category,
                      start_time,
                      name,
                      description,
                      end_time,
                      id,
                      opportunity_id,
                      opportunity_name,
                      contact_id,
                      contact_name,
                      date,
                      carrier_id,
                      carrier_name,
                    } = task;

                    let newTabData = {};
                    if (opportunity_id !== null) {
                      newTabData = {
                        type: "opportunity",
                        id: opportunity_id,
                        name: opportunity_name,
                        account_id: account_id,
                      };
                    } else if (contact_id !== null) {
                      newTabData = {
                        type: "contact",
                        id: contact_id,
                        name: contact_name,
                      };
                    } else if (carrier_id !== null) {
                      newTabData = {
                        type: "carrier",
                        id: carrier_id,
                        name: carrier_name,
                      };
                    } else {
                      newTabData = {
                        type: "account",
                        id: account_id,
                        name: account_name,
                      };
                    }

                    return (
                      <div
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            this.props.setTab({ ...newTabData, blank: true });
                          } else {
                            this.props.setActiveTabIndex(
                              this.props.totalTabs + 1
                            );
                            this.props.setTab(newTabData);
                            this.props.history.push("/active-tabs");
                          }
                        }}
                      >
                        <Task
                          key={id}
                          name={name}
                          time={
                            start_time &&
                            moment(start_time, "HH:mm:ss").format("hh:mm") +
                              " - " +
                              moment(end_time, "HH:mm:ss").format("hh:mm A")
                          }
                          detail={description}
                          icon={`${category}Green`}
                          taskId={id}
                          accountName={account_name}
                          opportunityName={opportunity_name}
                          contactName={contact_name}
                          date={date}
                          carrierName={carrier_name}
                        />
                      </div>
                    );
                  })
                ) : (
                  <p>No tasks scheduled at the moment</p>
                )}
              </div>
            </div>
            <div className="open" style={{ position: "relative" }}>
              <h4 className="h3">
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}open`,
                        name: "Open Tasks",
                        blank: true,
                        taskType: "open",
                        length: open_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}open`,
                        name: "Open Tasks",
                        taskType: "open",
                        length: open_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="Go to Open tasks report"
                  data-for="Open-Tasks-h2"
                >
                  Open
                </span>
                <span
                  style={{
                    width: "19px",
                    height: "17.25px",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    border: "1.3px solid #B2BECD",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    padding: "1.5px 6px",
                    letterSpacing: "-0.005em",
                    fontStyle: "normal",
                    color: "#5C5C70",
                    marginLeft: "5px",
                  }}
                >
                  {open_length}
                </span>
                <span
                  data-tip="Tasks that have a scheduled date, but no time scheduled.<br />
              Priority is set based on the oldest task created date and time."
                  style={{
                    position: "absolute",
                    top: "9%",
                    right: "2%",
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#4A9876",
                    padding: "0px 5.3px",
                    height: "14px",
                    borderRadius: "50%",
                    border: "1px solid #4A9876",
                  }}
                  data-for="Open-Tasks-i"
                  data-multiline={true}
                >
                  i
                </span>
              </h4>
              {open?.length !== 0 ? (
                open?.map((task) => {
                  const {
                    account_id,
                    account_name,
                    category,
                    name,
                    description,
                    id,
                    opportunity_id,
                    opportunity_name,
                    contact_id,
                    contact_name,
                    date,
                    carrier_id,
                    carrier_name,
                  } = task;

                  let newTabData = {};
                  if (opportunity_id !== null) {
                    newTabData = {
                      type: "opportunity",
                      id: opportunity_id,
                      name: opportunity_name,
                      account_id: account_id,
                    };
                  } else if (contact_id !== null) {
                    newTabData = {
                      type: "contact",
                      id: contact_id,
                      name: contact_name,
                    };
                  } else if (carrier_id !== null) {
                    newTabData = {
                      type: "carrier",
                      id: carrier_id,
                      name: carrier_name,
                    };
                  } else {
                    newTabData = {
                      type: "account",
                      id: account_id,
                      name: account_name,
                    };
                  }

                  return (
                    <div
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          this.props.setTab({ ...newTabData, blank: true });
                        } else {
                          this.props.setActiveTabIndex(
                            this.props.totalTabs + 1
                          );
                          this.props.setTab(newTabData);
                          this.props.history.push("/active-tabs");
                        }
                      }}
                    >
                      <Task
                        key={id}
                        name={name}
                        detail={description}
                        icon={`${category}Green`}
                        taskId={id}
                        accountName={account_name}
                        opportunityName={opportunity_name}
                        contactName={contact_name}
                        date={date}
                        carrierName={carrier_name}
                      />
                    </div>
                  );
                })
              ) : (
                <p>No tasks open at the moment</p>
              )}
            </div>
            <div className="overdue" style={{ position: "relative" }}>
              <h4 className="h3">
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}overdue`,
                        name: "Overdue Tasks",
                        blank: true,
                        taskType: "overdue",
                        length: overdue_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}overdue`,
                        name: "Overdue Tasks",
                        taskType: "overdue",
                        length: overdue_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="Go to Overdue tasks report"
                  data-for="Overdue-Tasks-h2"
                >
                  Overdue
                </span>
                <span
                  style={{
                    width: "19px",
                    height: "17.25px",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    background: "#F36D6D",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    padding: "1.5px 6px",
                    letterSpacing: "-0.005em",
                    fontStyle: "normal",
                    color: "#FFFFFF",
                    marginLeft: "5px",
                  }}
                >
                  {overdue_length}
                </span>
                <span
                  data-tip="Overdue tasks are open tasks that have not been marked completed,<br/>
                   that have a past date set for completion.<br/>
                   Update these ASAP to provide the best client experience."
                  style={{
                    position: "absolute",
                    top: "9%",
                    right: "2%",
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#4A9876",
                    padding: "0px 5.3px",
                    height: "14px",
                    borderRadius: "50%",
                    border: "1px solid #4A9876",
                  }}
                  data-for="Overdue-Tasks-i"
                  data-multiline={true}
                >
                  i
                </span>
              </h4>
              {overdue.length !== 0 ? (
                overdue?.map((task) => {
                  const {
                    account_id,
                    account_name,
                    category,
                    description,
                    id,
                    name,
                    opportunity_id,
                    opportunity_name,
                    contact_id,
                    contact_name,
                    date,
                    carrier_id,
                    carrier_name,
                  } = task;

                  let newTabData = {};
                  if (opportunity_id !== null) {
                    newTabData = {
                      type: "opportunity",
                      id: opportunity_id,
                      name: opportunity_name,
                      account_id: account_id,
                    };
                  } else if (contact_id !== null) {
                    newTabData = {
                      type: "contact",
                      id: contact_id,
                      name: contact_name,
                    };
                  } else if (carrier_id !== null) {
                    newTabData = {
                      type: "carrier",
                      id: carrier_id,
                      name: carrier_name,
                    };
                  } else {
                    newTabData = {
                      type: "account",
                      id: account_id,
                      name: account_name,
                    };
                  }

                  return (
                    <div
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          this.props.setTab({ ...newTabData, blank: true });
                        } else {
                          this.props.setActiveTabIndex(
                            this.props.totalTabs + 1
                          );
                          this.props.setTab(newTabData);
                          this.props.history.push("/active-tabs");
                        }
                      }}
                    >
                      <Task
                        key={id}
                        name={name}
                        detail={description}
                        icon={`${category}Green`}
                        taskId={id}
                        accountName={account_name}
                        opportunityName={opportunity_name}
                        contactName={contact_name}
                        date={date}
                        carrierName={carrier_name}
                      />
                    </div>
                  );
                })
              ) : (
                <p>No tasks overdue at the moment</p>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="scheduled" style={{ position: "relative" }}>
              <h4 className="h3" style={{ marginTop: "22px" }}>
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}scheduled`,
                        name: "Scheduled Tasks",
                        blank: true,
                        taskType: "scheduled",
                        length: schedule_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}scheduled`,
                        name: "Scheduled Tasks",
                        taskType: "scheduled",
                        length: schedule_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="Go to Scheduled tasks report"
                  data-for="Scheduled-Tasks-h2"
                >
                  Scheduled
                </span>
                <span
                  style={{
                    width: "19px",
                    height: "17.25px",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    background: "#61C99D",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    padding: "1.5px 6px",
                    letterSpacing: "-0.005em",
                    fontStyle: "normal",
                    color: "#FFFFFF",
                    marginLeft: "5px",
                  }}
                >
                  {this.state.scheduledGrouped
                    ?.map((task) => task.total_tasks)
                    .reduce((a, b) => a + b, 0)}
                </span>
                <span
                  data-tip="Scheduled tasks have a date AND time associated with them.<br />
              They are most recommended as setting both with your customer increases rate of response."
                  style={{
                    position: "absolute",
                    top: "9%",
                    right: "2%",
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#4A9876",
                    padding: "0px 5.3px",
                    height: "14px",
                    borderRadius: "50%",
                    border: "1px solid #4A9876",
                  }}
                  data-for="Scheduled-Tasks-i"
                  data-multiline={true}
                >
                  i
                </span>
              </h4>
              <div
                style={{
                  boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                  borderRadius: "8px",
                  padding: "10px",
                  margin: "0 3px",
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th data-tip="Member">Member</th>
                      <th className="funnel-amounts" data-tip="Accounts">
                        Acc.
                      </th>
                      <th className="funnel-amounts" data-tip="Deals">
                        Deals
                      </th>
                      <th className="funnel-amounts" data-tip="Contacts">
                        Con.
                      </th>
                      <th className="funnel-amounts" data-tip="Total">
                        Tot.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.scheduledGrouped?.map((task) => {
                      const {
                        name,
                        total_tasks,
                        total_account_tasks,
                        total_opportunity_tasks,
                        total_contact_tasks,
                      } = task;
                      return (
                        <tr>
                          <td className="funnel-reasons">{name}</td>
                          <td className="funnel-amounts">
                            {total_account_tasks}
                          </td>
                          <td className="funnel-amounts">
                            {total_opportunity_tasks}
                          </td>
                          <td className="funnel-amounts">
                            {total_contact_tasks}
                          </td>
                          <td className="funnel-amounts">{total_tasks}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="open" style={{ position: "relative" }}>
              <h4 className="h3">
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}open`,
                        name: "Open Tasks",
                        blank: true,
                        taskType: "open",
                        length: open_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}open`,
                        name: "Open Tasks",
                        taskType: "open",
                        length: open_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="Go to Open tasks report"
                  data-for="Open-Tasks-h2"
                >
                  Open
                </span>
                <span
                  style={{
                    width: "19px",
                    height: "17.25px",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    border: "1.3px solid #B2BECD",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    padding: "1.5px 6px",
                    letterSpacing: "-0.005em",
                    fontStyle: "normal",
                    color: "#5C5C70",
                    marginLeft: "5px",
                  }}
                >
                  {this.state.openGrouped
                    ?.map((task) => task.total_tasks)
                    .reduce((a, b) => a + b, 0)}
                </span>
                <span
                  data-tip="Tasks that have a scheduled date, but no time scheduled.<br />
              Priority is set based on the oldest task created date and time."
                  style={{
                    position: "absolute",
                    top: "9%",
                    right: "2%",
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#4A9876",
                    padding: "0px 5.3px",
                    height: "14px",
                    borderRadius: "50%",
                    border: "1px solid #4A9876",
                  }}
                  data-for="Open-Tasks-i"
                  data-multiline={true}
                >
                  i
                </span>
              </h4>
              <div
                style={{
                  boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                  borderRadius: "8px",
                  padding: "10px",
                  margin: "0 3px",
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th data-tip="Member">Member</th>
                      <th className="funnel-amounts" data-tip="Accounts">
                        Acc.
                      </th>
                      <th className="funnel-amounts" data-tip="Deals">
                        Deals
                      </th>
                      <th className="funnel-amounts" data-tip="Contacts">
                        Con.
                      </th>
                      <th className="funnel-amounts" data-tip="Total">
                        Tot.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.openGrouped?.map((task) => {
                      const {
                        name,
                        total_tasks,
                        total_account_tasks,
                        total_opportunity_tasks,
                        total_contact_tasks,
                      } = task;
                      return (
                        <tr>
                          <td className="funnel-reasons">{name}</td>
                          <td className="funnel-amounts">
                            {total_account_tasks}
                          </td>
                          <td className="funnel-amounts">
                            {total_opportunity_tasks}
                          </td>
                          <td className="funnel-amounts">
                            {total_contact_tasks}
                          </td>
                          <td className="funnel-amounts">{total_tasks}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="overdue" style={{ position: "relative" }}>
              <h4 className="h3">
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}overdue`,
                        name: "Overdue Tasks",
                        blank: true,
                        taskType: "overdue",
                        length: overdue_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "task",
                        id: `${this.props.userId}overdue`,
                        name: "Overdue Tasks",
                        taskType: "overdue",
                        length: overdue_length,
                        userId: this.props.userId,
                        userName: this.props.userName,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="Go to Overdue tasks report"
                  data-for="Overdue-Tasks-h2"
                >
                  Overdue
                </span>
                <span
                  style={{
                    width: "19px",
                    height: "17.25px",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    background: "#F36D6D",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    padding: "1.5px 6px",
                    letterSpacing: "-0.005em",
                    fontStyle: "normal",
                    color: "#FFFFFF",
                    marginLeft: "5px",
                  }}
                >
                  {this.state.overdueGrouped
                    ?.map((task) => task.total_tasks)
                    .reduce((a, b) => a + b, 0)}
                </span>
                <span
                  data-tip="Overdue tasks are open tasks that have not been marked completed,<br/>
                   that have a past date set for completion.<br/>
                   Update these ASAP to provide the best client experience."
                  style={{
                    position: "absolute",
                    top: "9%",
                    right: "2%",
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#4A9876",
                    padding: "0px 5.3px",
                    height: "14px",
                    borderRadius: "50%",
                    border: "1px solid #4A9876",
                  }}
                  data-for="Overdue-Tasks-i"
                  data-multiline={true}
                >
                  i
                </span>
              </h4>
              <div
                style={{
                  boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                  borderRadius: "8px",
                  padding: "10px",
                  margin: "0 3px",
                }}
              >
                <ReactTooltip />
                <table>
                  <thead>
                    <tr>
                      <th data-tip="Member">Member</th>
                      <th className="funnel-amounts" data-tip="Accounts">
                        Acc.
                      </th>
                      <th className="funnel-amounts" data-tip="Deals">
                        Deals
                      </th>
                      <th className="funnel-amounts" data-tip="Contacts">
                        Con.
                      </th>
                      <th className="funnel-amounts" data-tip="Total">
                        Tot.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.overdueGrouped?.map((task) => {
                      const {
                        name,
                        total_tasks,
                        total_account_tasks,
                        total_opportunity_tasks,
                        total_contact_tasks,
                      } = task;
                      return (
                        <tr>
                          <td className="funnel-reasons">{name}</td>
                          <td className="funnel-amounts">
                            {total_account_tasks}
                          </td>
                          <td className="funnel-amounts">
                            {total_opportunity_tasks}
                          </td>
                          <td className="funnel-amounts">
                            {total_contact_tasks}
                          </td>
                          <td className="funnel-amounts">{total_tasks}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(Tasks));
