import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import axios from "axios";
import AccountIcon from "../../images/AccountIcon.svg";
import CarrierGreenIcon from "../../images/CarrierGreenIcon.svg";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import { countriesList } from "../../constants/countriesList";
import Autocomplete from "react-autocomplete";
import { colourStyles } from "../../constants/selectStyles";
import { usStatesList } from "../../constants/usStatesList";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  fetchAllAllFormContactFields,
  authUserSet,
} from "../../redux";

import { MenuItem, Modal } from "@mui/material";
import InputField from "../../components/Reusables/InputField/InputField";
import MuiSelect from "../../components/Reusables/Select/MuiSelect";
import MuiButton from "../../components/Reusables/Button/MuiButton";

import { muiModalStyle } from "../../constants/muiModalStyle";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

ReactModal.defaultStyles.overlay.zIndex = 10000001;

class CreateContactModal extends Component {
  state = {
    fieldsData: [],
    customFields: {},
    customFieldsForAPI: {},
    customFieldsOfAccount: {},
    errorMessage: false,
    isLoading: false,
    windowWidth: window.innerWidth,
    phoneValidationError: false,
    emailValidationError: false,
    searchItems: [],
    searchValue: null,
    menuOpen: "bottom",
    account_id: null,
    carrier_id: null,
    customFieldRevenueTypeCheck: [],
    defaultFields: {
      contactOwner: null,
      name: null,
      source: null,
      email: null,
      phone: null,
      phone_ext: null,
      role: null,
      mobile: null,
      date_of_birth: null,
      preferred_communication: [],
      zip: null,
      city: null,
      address: null,
      state: null,
      linkedin: null,
      country: "United States",
      searchItems: [],
      searchValue: null,
      account_id: null,
      carrier_id: null,
    },
    allFields: [],
    isHovered: false,
  };
  searchValueChangeHandler = (e) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        account_id: null,
        carrier_id: null,
      },
      searchValue: e.target.value,
      account_id: null,
      carrier_id: null,
    }));
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/accounts/search?query=${value}`,
    }).then((res) => {
      let isNewAccount =
        res.data.accounts.length === 0 && e.target.value.length > 1;
      this.setState({
        searchItems: isNewAccount
          ? [{ id: "create-new-account", name: e.target.value }]
          : res.data.accounts,
      });
    });
  };
  createNewAccountFromContact = (item) => {
    axios({
      method: "POST",
      url: `/accounts/create`,
      data: {
        owners: [
          {
            is_primary: true,
            owner_id: this.props.authUser.id,
          },
        ],
        name: item.name,
        source: null,
        state: null,
        zip: null,
        city: null,
        address: null,
        country: null,
        email: null,
        formatted_phone: null,
        phone_ext: null,
        website: null,
        linkedin: null,
        description: null,
        preferred_communication: [],
        custom_fields: this.state.customFieldsOfAccount,
      },
    })
      .then((res) => {
        this.setState((prevState) => ({
          defaultFields: {
            ...prevState.defaultFields,
            account_id: res.data.account.account_id,
          },
          account_id: res.data.account.account_id,
          searchValue: item.name,
        }));
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          NotificationManager.error("Account already created.");
        } else {
          NotificationManager.error("Error creating account.");
        }
      });
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      name: null,
      source: null,
      email: null,
      phone: null,
      phone_ext: null,
      role: null,
      mobile: null,
      date_of_birth: null,
      preferred_communication: [],
      frontendPreferredCommunicationArray: [],
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      isLoading: false,
      phoneValidationError: false,
      emailValidationError: false,
      zip: null,
      city: null,
      address: null,
      linkedin: null,
      state: null,
      country: "United States",
      searchItems: [],
      searchValue: null,
      account_id: null,
      carrier_id: null,
      defaultFields: {
        name: null,
        source: null,
        email: null,
        phone: null,
        phone_ext: null,
        role: null,
        mobile: null,
        date_of_birth: null,
        preferred_communication: [],
        zip: null,
        city: null,
        address: null,
        state: null,
        linkedin: null,
        country: "United States",
        searchItems: [],
        searchValue: null,
        account_id: null,
        carrier_id: null,
      },
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.fetchContactsData();
      this.fetchAccountData();
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          contactOwner: this.props.authUser.id,
          country: this.props.companyOnboarding?.country
            ? this.props.companyOnboarding?.country
            : "United States",
        },
        allFields: this.props.allContactFields,
      }));
    }
  }

  fetchContactsData = () => {
    this.setState((prevState) => ({
      fieldsData: this.props.allContactCustomFields,
    }));
    const customField = this.props.allContactCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allContactCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  fetchAccountData = () => {
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    this.setState({
      customFieldsOfAccount: obj,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("mousemove", this.mouseCoordinates, {
      passive: true,
    });
    this.fetchContactsData();
    this.fetchAccountData();
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        contactOwner: this.props.authUser.id,
        country: this.props.companyOnboarding?.country
          ? this.props.companyOnboarding?.country
          : "United States",
      },
      allFields: this.props.allContactFields,
    }));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getFields = () => {
    this.state.fields.fields.map((item, i) => {
      return console.log(item.label);
    });
  };
  changeHandler = (name, e) => {
    const { value } = e.target;
    if (name === "mobile" || name === "phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      const formattedValue = formatter.input(value); // Format the input value
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: formattedValue ? formattedValue : null,
        },
      }));
    } else {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: e.target.value === "" ? null : e.target.value,
        },
      }));
    }
  };
  defaultFieldsMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [fieldName]: selectedOptions,
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  changeHandlerSelect = (name, value) => {
    this.setState((prevState) => ({
      defaultFields: { ...prevState.defaultFields, [name]: value.value },
    }));
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    } else {
      return;
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.defaultFields.phone !== null &&
      this.state.defaultFields.phone !== "" &&
      !phoneValidator.test(this.state.defaultFields.phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.phone !== null &&
      this.state.defaultFields.phone !== "" &&
      phoneValidator.test(this.state.defaultFields.phone)
    ) {
      this.setState({
        phoneValidationError: false,
      });
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      !emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        emailValidationError: false,
      });
    }
    if (
      this.state.defaultFields.name === null ||
      this.state.defaultFields.name === ""
    ) {
      this.setState({ errorMessage: true });
      return;
    }
    const requiredFields = this.state.allFields
      ?.filter((field) => field.required)
      ?.filter((field) =>
        (field.name === "account_id" && this.props.accountId) ||
        (field.name === "account_id" && this.props.carrierId) ||
        (field.name === "account_id" && this.state.carrier_id)
          ? field.name !== "account_id"
          : field
      )
      ?.map((field) => field.name);
    console.log("hassan check kr is ko", requiredFields);
    const isNotError = requiredFields?.every((field) =>
      Array.isArray(this.state.defaultFields[field])
        ? this.state.defaultFields[field].length !== 0
        : this.state.defaultFields[field] !== null &&
          this.state.defaultFields[field] !== ""
    );
    const isNotErrorCustom = requiredFields?.every((field) =>
      Array.isArray(this.state.customFields[field])
        ? this.state.customFields[field].length !== 0
        : this.state.customFields[field] !== null &&
          this.state.customFields[field] !== ""
    );
    console.log("isNotError", isNotError);
    if (!isNotError || !isNotErrorCustom) {
      this.setState({ errorMessage: true });
      return false;
    }
    let defaultFields = this.state.defaultFields;
    for (let i in defaultFields) {
      if (defaultFields[i] === "") {
        defaultFields[i] = null;
      }
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/contacts/create`,
      data: {
        contact_owner_id: defaultFields.contactOwner,
        account_id:
          this.props.accountId === undefined
            ? defaultFields.account_id
            : this.props.accountId,
        carrier_id:
          this.props.carrierId === undefined
            ? defaultFields.carrier_id
            : this.props.carrierId,
        opportunity_id:
          this.props.opportunityId === undefined
            ? null
            : this.props.opportunityId,
        name: defaultFields.name,
        role: defaultFields.role,
        formatted_phone: defaultFields.phone,
        phone_ext: defaultFields.phone_ext,
        formatted_mobile: defaultFields.mobile,
        date_of_birth: defaultFields.date_of_birth,
        email: defaultFields.email,
        source: defaultFields.source,
        preferred_communication: defaultFields.preferred_communication?.map(
          (pref) => pref.value
        ),
        zip: defaultFields.zip,
        city: defaultFields.city,
        address: defaultFields.address,
        state: defaultFields.state,
        linkedin:
          defaultFields.linkedin === null
            ? null
            : defaultFields.linkedin.startsWith("https://") ||
              defaultFields.linkedin.startsWith("http://")
            ? defaultFields.linkedin
            : "https://" + defaultFields.linkedin,
        country: defaultFields.country,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "contact",
          id: response.data.contact.contact_id,
          name: response.data.contact.name,
        };

        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
        this.handleCloseModal();
      })
      .then((response) => {
        NotificationManager.success("Contact created successfully!");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating contact.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => this.fetchContactsData());
  };
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };

  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired,
    fieldParent
  ) => {
    let inputType;

    const err =
      (fieldName === "account_id" && this.props.accountId) ||
      (fieldName === "account_id" && this.props.carrierId)
        ? null
        : this.state.errorMessage === true &&
          fieldRequired &&
          (this.state.defaultFields[fieldName] === null ||
            this.state.defaultFields[fieldName] === "" ||
            this.state.defaultFields[fieldName]?.length === 0 ||
            this.state.customFields[fieldName] === null ||
            this.state.customFields[fieldName] === "" ||
            this.state.customFields[fieldName]?.length === 0);

    const emailErr =
      fieldName === "email" &&
      this.state.emailValidationError === true &&
      this.state.defaultFields.email !== "" &&
      this.state.defaultFields.email !== null;

    const phoneErr =
      fieldName === "phone" &&
      this.state.phoneValidationError === true &&
      this.state.defaultFields.phone !== "" &&
      this.state.defaultFields.phone !== null;

    if (fieldName === "account_id") {
      inputType = (
        <div className="modal-searchable-contact-div">
          {this.props.carrierName || this.props.accountName ? (
            <div style={{ marginBottom: "15px" }}>
              <InputField
                label={fieldLabel}
                err={err}
                helperText={`${fieldLabel} is required`}
                value={
                  this.props.carrierName
                    ? this.props.carrierName
                    : this.props.accountName
                }
                readOnly
              />
            </div>
          ) : (
            <Autocomplete
              inputProps={{
                style: {
                  border: err ? "1px solid #d32f2f" : "1px solid #c5c5d3",
                  borderRadius: "4px",
                  padding: "12px",
                  width: "93%",
                  marginBottom: "15px",
                  display: "block",
                },
                className: err && "error-placeholder",
                placeholder: err ? "Account is required" : "Search Account",
              }}
              items={this.state?.searchItems}
              menuStyle={{
                borderRadius: "3px",
                // left: "70px",
                display: "block",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                background: "#252a2d",
                padding: "3px 0",
                position: "fixed",
                overflow: "auto",
                maxHeight: "250px",
                zIndex: "2147483647",
              }}
              getItemValue={(item) => item.name}
              renderItem={(item, highlighted) => {
                const { type, id } = item;
                let logo = (
                  <img
                    src={type === "carrier" ? CarrierGreenIcon : AccountIcon}
                    alt=""
                    width="13"
                    key={id + type}
                    style={{ transform: "translateY(9px)" }}
                  />
                );
                return (
                  <div
                    key={`${item.id}${item.type}`}
                    style={{
                      backgroundColor: highlighted ? "#3B4043" : "#252a2d",
                      color: "#CFCFD8",
                      zIndex: "2147483647",
                      fontSize: "16px",
                      cursor: "pointer",
                      borderBottom: "1px solid black",
                      padding: "5px 0",
                    }}
                  >
                    <div style={{ paddingLeft: "15px" }}>
                      {logo}
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginLeft: "15px",
                        }}
                      >
                        {item.id === "create-new-account" && "+ Create "}
                        {item.name.length > 23
                          ? item.name.substring(0, 22)
                          : item.name}
                      </span>
                    </div>
                    <div
                      style={{
                        marginLeft: "45px",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#A5A1AC",
                      }}
                    >
                      {item.id === "create-new-account"
                        ? "Create new Account"
                        : item.type === "carrier"
                        ? "Carrier"
                        : "Account"}
                    </div>
                  </div>
                );
              }}
              value={this.state.searchValue}
              onChange={this.searchValueChangeHandler}
              onSelect={(value, item) => {
                item.id === "create-new-account"
                  ? this.createNewAccountFromContact(item)
                  : this.setState((prevState) => ({
                      defaultFields: {
                        ...prevState.defaultFields,
                        ...(item.type === "carrier"
                          ? { carrier_id: item.id }
                          : { account_id: item.id }),
                      },
                      ...(item.type === "carrier"
                        ? { carrier_id: item.id }
                        : { account_id: item.id }),
                      searchValue: item.name,
                    }));
              }}
            />
          )}
        </div>
      );
    } else if (fieldName === "date_of_birth") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "15px" }}
        >
          <DatePicker
            name="date_of_birth"
            className="datePicker"
            selected={Date.parse(this.state.defaultFields.date_of_birth)}
            value={Date.parse(this.state.defaultFields.date_of_birth)}
            onChange={(date) => this.dobChangeHandler(date)}
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            placeholderText={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/mm/yyyy"
                : "mm/dd/yyyy"
            }
            autoComplete="off"
            required={fieldRequired}
          />

          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    }
    //  if (fieldName === "preferred_communication") {
    //   let multiSelectDropdownValue = this.state.defaultFields[fieldName];
    //   const preferredChoices = [
    //     { value: "call", label: "Call" },
    //     { value: "email", label: "Email" },
    //     { value: "text", label: "Text" },
    //   ];
    //   inputType = (
    //     <Select
    //       value={multiSelectDropdownValue}
    //       closeMenuOnSelect={false}
    //       menuPlacement={this.state.menuOpen}
    //       isMulti
    //       name={fieldName}
    //       styles={colourStyles}
    //       options={preferredChoices.map((choice) => {
    //         const { value, label } = choice;
    //         return { value, label };
    //       })}
    //       placeholder="-Select-"
    //       className="basic-multi-select"
    //       classNamePrefix="select"
    //       required={fieldRequired}
    //       onChange={(selectedOption) =>
    //         this.defaultFieldsMultiDropdownChangeHandler(
    //           fieldName,
    //           selectedOption
    //         )
    //       }
    //     />
    //   );
    // }
    else if (fieldType === "text") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            error={
              fieldName === "email"
                ? emailErr
                : fieldName === "phone"
                ? phoneErr
                : err
            }
            helperText={
              fieldName === "email"
                ? "Please enter a valid email address"
                : fieldName === "phone"
                ? "Please enter a valid phone number"
                : `${fieldLabel} is required`
            }
            value={
              fieldParent === "default_fields"
                ? this.state.defaultFields[fieldName]
                : this.state.customFields[fieldName]
            }
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            error={err}
            helperText={`${fieldLabel} is required`}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            error={err}
            helperText={`${fieldLabel} is required`}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) => this.customFieldRevenueChangeHandler(e)}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            name={fieldName}
            label={fieldLabel}
            error={err}
            helperText={`${fieldLabel} is required`}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
            multiline={true}
          />
        </div>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="number"
            name={fieldName}
            label={fieldLabel}
            error={err}
            helperText={`${fieldLabel} is required`}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "dropdown") {
      inputType =
        fieldName === "state" ? (
          this.state.defaultFields.country !== "United States" ? (
            <div style={{ marginBottom: "15px" }}>
              <InputField
                type="text"
                name="state"
                error={err}
                helperText={`${fieldLabel} is required`}
                label={fieldLabel}
                value={this.state.defaultFields.state}
                onChange={(e) => this.changeHandler("state", e)}
                required={fieldRequired}
              />
            </div>
          ) : (
            <div
              className="controlsForms"
              style={{ width: "100%", marginBottom: "15px" }}
            >
              <Select
                className="basic-single"
                classNamePrefix="select"
                menuPlacement={this.state.menuOpen}
                styles={colourStyles}
                value={{
                  value: this.state.defaultFields.state,
                  label: this.state.defaultFields.state,
                }}
                placeholder="-Select-"
                onChange={(selected) =>
                  this.changeHandlerSelect("state", selected)
                }
                isClearable={false}
                isSearchable={true}
                options={usStatesList.map((state) => ({
                  value: state,
                  label: state,
                }))}
              />
              <label className="activeLabel">
                <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
                {fieldRequired && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
            </div>
          )
        ) : fieldName === "contact_owner_id" ? (
          <div style={{ marginBottom: "0px", marginTop: "15px" }}>
            <MuiSelect
              label={fieldLabel}
              value={this.state.defaultFields.contactOwner}
              onChange={(e) => this.changeHandler("contactOwner", e)}
              name="contactOwner"
              required={fieldRequired}
              menuItem={this.props.allUsers?.map((user) => {
                const { id, name } = user;
                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
            />
          </div>
        ) : // <select
        //   value={this.state.defaultFields.contactOwner}
        //   onChange={(e) => this.changeHandler("contactOwner", e)}
        //   name="contactOwner"
        //   style={{ width: "99%" }}
        // >
        //   {this.props.allUsers?.map((user) => {
        //     const { id, name } = user;
        //     return (
        //       <option key={id} value={id}>
        //         {name}
        //       </option>
        //     );
        //   })}
        // </select>
        fieldName === "country" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "15px" }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              menuPlacement={this.state.menuOpen}
              styles={colourStyles}
              value={{
                value: this.state.defaultFields.country,
                label: this.state.defaultFields.country,
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                this.changeHandlerSelect("country", selected)
              }
              isClearable={false}
              isSearchable={true}
              options={countriesList.map((country) => ({
                value: country,
                label: country,
              }))}
            />
            <label className="activeLabel">{fieldLabel}</label>
          </div>
        ) : fieldName === "source" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "15px" }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              menuPlacement={this.state.menuOpen}
              styles={colourStyles}
              value={{
                value: this.state.defaultFields.source,
                label: this.state.defaultFields.source,
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                this.changeHandlerSelect("source", selected)
              }
              isClearable={false}
              isSearchable={true}
              options={this.props.allAccountSources?.map((source) => ({
                value: source.name,
                label: source.name,
              }))}
            />
            <label className="activeLabel">{fieldLabel}</label>
          </div>
        ) : (
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "15px" }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              menuPlacement={this.state.menuOpen}
              styles={colourStyles}
              required={fieldRequired}
              value={{
                value: this.state.customFields[fieldName],
                label: this.state.customFields[fieldName],
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                fieldParent === "default_fields"
                  ? this.changeHandlerSelect(fieldName, selected.value)
                  : this.customFieldSelectChangeHandler(
                      fieldName,
                      selected.value
                    )
              }
              isClearable={false}
              isSearchable={true}
              name={fieldName}
              options={fieldChoices.map((choice) => {
                const { value } = choice;
                return { value, label: value };
              })}
            />
            <label className="activeLabel">{fieldLabel}</label>
          </div>
        );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px", marginBottom: "15px" }}>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left", marginBottom: "15px" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={(e) =>
                    fieldParent === "default_fields"
                      ? this.changeHandler(fieldName, e)
                      : this.customFieldChangeHandler(e)
                  }
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "15px" }}
        >
          <DatePicker
            name={fieldName}
            className="datePicker"
            selected={Date.parse(this.state.customFields[fieldName])}
            value={Date.parse(this.state.customFields[fieldName])}
            onChange={(date) =>
              this.customFieldDateChangeHandler(fieldName, date)
            }
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            placeholderText={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/mm/yyyy"
                : "mm/dd/yyyy"
            }
            autoComplete="off"
            required={fieldRequired}
            popperPlacement="top"
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "15px" }}
        >
          <Select
            value={multiSelectDropdownValue}
            closeMenuOnSelect={false}
            menuPlacement={this.state.menuOpen}
            isMulti
            name={fieldName}
            styles={colourStyles}
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value: value, label: value };
            })}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            required={fieldRequired}
            onChange={(selectedOption) =>
              this.customFieldMultiDropdownChangeHandler(
                fieldName,
                selectedOption
              )
            }
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    }
    return (
      <>
        {fieldType === "heading" ? (
          <h3 className="modal__heading-sub">{fieldLabel}</h3>
        ) : (
          <div>
            {/* <label>
              {fieldLabel === "Account"
                ? this.props.carrierId
                  ? "Carrier"
                  : "Account"
                : fieldLabel}{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label> */}
            {inputType}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {(fieldName === "account_id" && this.props.accountId) ||
              (fieldName === "account_id" && this.props.carrierId)
                ? null
                : this.state.errorMessage === true &&
                  fieldRequired &&
                  (this.state.defaultFields[fieldName] === null ||
                    this.state.defaultFields[fieldName] === "" ||
                    this.state.defaultFields[fieldName]?.length === 0 ||
                    this.state.customFields[fieldName] === null ||
                    this.state.customFields[fieldName] === "" ||
                    this.state.customFields[fieldName]?.length === 0)
                ? `${fieldLabel} is required`
                : null}
            </div> */}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "email" &&
              this.state.emailValidationError === true &&
              this.state.defaultFields.email !== "" &&
              this.state.defaultFields.email !== null
                ? `Please enter a valid email address`
                : null}
            </div> */}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "phone" &&
              this.state.phoneValidationError === true &&
              this.state.defaultFields.phone !== "" &&
              this.state.defaultFields.phone !== null
                ? `Please enter a valid phone number`
                : null}
            </div> */}
          </div>
        )}
      </>
    );
  };

  dobChangeHandler = (date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState((prevState) => ({
      defaultFields: { ...prevState.defaultFields, date_of_birth: offsetDate },
    }));
  };
  render() {
    return (
      <div>
        <Modal
          open={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onClose={this.handleCloseModal}
          ariaHideApp={false}
          style={muiModalStyle}
        >
          <div className="muiModal">
            <div className="modal__content">
              <div className="modal__header">
                <div className="modal__head">
                  <h2 className="modal__heading">Create Contact</h2>
                </div>
                <button
                  onClick={this.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    height: "12px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <ClearIcon fontSize="small" />
                </button>
              </div>
              <form
                className="modal__form"
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {this.state.allFields?.map((field) => {
                  const {
                    id,
                    label,
                    type,
                    choices,
                    name,
                    required,
                    parent_id,
                  } = field;
                  return (
                    <div key={id}>
                      {this.showInput(
                        name,
                        label,
                        type,
                        choices,
                        required,
                        parent_id
                      )}
                    </div>
                  );
                })}
              </form>
              <div className="modal__buttons">
                <button
                  onClick={this.handleCloseModal}
                  type="reset"
                  style={{ bottom: "10px", left: "5px" }}
                  className="modal__cancel-button"
                >
                  Cancel
                </button>
                <MuiButton
                  type="button"
                  className="saveBtn"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "auto",
                    height: "35px",
                    color: "white",
                    backgroundColor: "#252a2d",
                  }}
                  disabled={this.state.isLoading}
                  onClick={this.submitHandler}
                  loading={this.state.isLoading}
                  endIcon={
                    <AddIcon
                      style={{
                        color: this.state.isHovered ? "black" : "#62CA9D",
                      }}
                    />
                  }
                  onMouseEnter={() => {
                    this.setState({ isHovered: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ isHovered: false });
                  }}
                >
                  {this.state.isLoading ? "Creating..." : "Create"}{" "}
                </MuiButton>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    allContactFields: state.allContactFields.fields,
    authUser: state.login.authUser,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountCustomFields: state.allAccountCustomFields.fields,
    allContactCustomFields: state.allContactCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    fetchAllAllFormContactFields: (allContactFields) =>
      dispatch(fetchAllAllFormContactFields(allContactFields)),
  };
};

export default connect(MSP, MDP)(withRouter(CreateContactModal));
