import React from "react";
import Button from "@mui/material/Button";

const MuiButton = ({
  loading = false,
  className = "",
  onClick,
  type,
  children,
  startIcon,
  endIcon,
  style,
  width = "100%",
  variant = "contained",
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Button
      sx={{
        width,
        textTransform: "none",
        fontWeight: 700,
        backgroundColor: "#252a2d",
        color: "white",
      }}
      variant={variant}
      style={style}
      className={className}
      disabled={loading}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Button>
  );
};

export default MuiButton;
