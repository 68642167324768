import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateUserModal from "./CreateUserModal";
import axios from "axios";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";
import { debounce } from "lodash";
import TaskTourUser from "../Onboarding/TaskTourUser";
import { Col, Row } from "react-grid-system";
import { v4 as uuidv4 } from "uuid";
import { NotificationManager } from "react-notifications";
// Redux stuff
import { connect } from "react-redux";
import { setActiveTabIndex, authUserSet } from "../../redux";
import ShotClockSettingsModal from "./ShotClockSettingsModal";
import SharedUserSetingModal from "./SharedUserSetingModal";

function Users(props) {
  const [userModal, setUserModal] = useState(false);
  const [userEditModal, setUserEditModal] = useState(false);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [teamRole, setTeamRole] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [userId, setUserId] = useState(null);
  const [country_code, setCountry_code] = useState(null);
  const [totalTeams, setTotalTeams] = useState([]);
  const [tourComplete, setTourComplete] = useState(false);
  const [sortDirection, setSortDirection] = useState(false);
  const [isApplying, setIsApplying] = useState(true);
  const [resendEmail, setResendEmail] = useState(null);
  const [resendLoader, setResendLoader] = useState(false);
  const [showShotClockModal, setShowShotClockModal] = useState(false);
  const [showMultipleAccountModal, setShowMultipleAccountModal] =
    useState(false);

  const handleOpenUserModal = () => {
    setUserModal(true);
    setTourComplete(true);
  };
  const handleCloseUserModal = () => {
    setUserModal(false);
  };
  const handleOpenUserEditModal = (
    name,
    email,
    teamId,
    teamRole,
    roleId,
    mobile,
    userId,
    teams,
    country_code
  ) => {
    setUserEditModal(true);
    setName(name);
    setEmail(email);
    setTeamId(teamId);
    setTeamRole(teamRole);
    setRoleId(roleId);
    setMobile(mobile);
    setUserId(userId);
    setTotalTeams(teams);
    setCountry_code(country_code);
  };
  const handleCloseUserEditModal = () => {
    setUserEditModal(false);
  };
  const handleOpenUserDeleteModal = (name, userId) => {
    setUserDeleteModal(true);
    setUserId(userId);
    setName(name);
  };
  const handleCloseUserDeleteModal = () => {
    setUserDeleteModal(false);
  };
  const fetchUsers = debounce((sortKey = "name") => {
    let url = `/admin/users`;
    if (sortKey) {
      const dir = sortDirection === true ? "desc" : "asc";
      url += `?sort_key=${sortKey}&sort_dir=${dir}`;
    }
    setIsApplying(true);
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        setUsers(
          response.data.users?.slice()?.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // Ignore case for comparison
            const nameB = b.name.toUpperCase(); // Ignore case for comparison

            if (nameA < nameB) {
              return -1; // Negative value: a should come before b
            }
            if (nameA > nameB) {
              return 1; // Positive value: b should come before a
            }
            return 0; // Return 0 for elements considered equal
          })
        );
        setIsApplying(false);
      })
      .catch((error) => setIsApplying(false));
  }, 500);
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const resendSignupEmail = (email) => {
    setResendEmail(email);
    setResendLoader(true);
    axios({
      url: `/admin/resend-signup-invitation`,
      method: "POST",
      data: {
        email,
      },
    })
      .then((res) => {
        NotificationManager.success("Invitation email sent");
        setResendLoader(false);
      })
      .catch((err) => {
        NotificationManager.error("Error sending invitation email");
        setResendLoader(false);
      });
  };
  return (
    <div style={{ margin: "0px 30px" }}>
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>Users</h2>
          <p>{users.length} users in your company</p>
        </div>
        {props.authUser.permissions.includes("crm:add:user") && (
          <div
            style={{ marginTop: "4vh", float: "right", marginRight: "30px" }}
          >
            <Link
              to="users/import"
              className="button-md"
              style={{
                border: "1px solid #4A9876",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                width: "169px",
                height: "40px",
                background: "#FFFFFF",
                color: "#4A9876",
                textDecoration: "none",
                padding: "7px 14px",
                fontWeight: "500",
                fontSize: "14px",
                marginRight: "10px",
              }}
            >
              Import from CSV
            </Link>
            <button
              type="button"
              onClick={() => handleOpenUserModal()}
              // className="button-md tour-add-user"
              className="button-md"
            >
              Add user
            </button>
            <CreateUserModal
              showOpportunityModal={userModal}
              setShowOpportunityModal={handleOpenUserModal}
              handleCloseModal={handleCloseUserModal}
              fetchUsers={fetchUsers}
            />
            <TaskTourUser tourComplete={tourComplete} />
          </div>
        )}
      </div>
      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Link
          to="/users"
          style={{
            textDecoration: "none",
            color: "#4A9876",
            padding: "4px 12px",
            borderBottom: "1px solid #4A9876",
            fontWeight: "500",
          }}
        >
          Users
        </Link>
        <Link
          to="/teams"
          style={{
            textDecoration: "none",
            color: "black",
            padding: "4px 12px",
          }}
        >
          Teams
        </Link>
        <Link
          to="/roles"
          style={{
            textDecoration: "none",
            color: "black",
            padding: "4px 12px",
          }}
        >
          Roles
        </Link>
        {props.authUser.role === "SUPERADMIN" && (
          <div
            onClick={() => setShowShotClockModal(true)}
            style={{
              textDecoration: "none",
              color: "black",
              padding: "4px 12px",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Shot Clock
          </div>
        )}
        {props.companyOnboarding?.is_multiple_account_owner_enabled && (
          <div
            onClick={() => setShowMultipleAccountModal(true)}
            style={{
              textDecoration: "none",
              color: "black",
              padding: "4px 12px",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Shared Account Owners
          </div>
        )}
      </div>
      <div>
        {isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : (
          <table className="accountList__table" style={{ width: "93vw" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "11px" }}>
                  <Row>
                    <Col lg={9} xl={9}>
                      Name
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() => {
                          setSortDirection(!sortDirection);
                          fetchUsers("name");
                        }}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th style={{ fontSize: "11px" }}>
                  <Row>
                    <Col lg={9} xl={9}>
                      Email
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() => {
                          setSortDirection(!sortDirection);
                          fetchUsers("email");
                        }}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th style={{ fontSize: "11px" }}>
                  <Row>
                    <Col lg={9} xl={9}>
                      Phone
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() => {
                          setSortDirection(!sortDirection);
                          fetchUsers("formatted_mobile");
                        }}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th style={{ fontSize: "11px" }}>Role</th>
                <th style={{ fontSize: "11px" }}>Teams</th>
                {props.authUser.permissions.includes("crm:add:user") && (
                  <th style={{ fontSize: "11px" }}>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => {
                const {
                  name,
                  email,
                  id,
                  teams,
                  role,
                  formatted_mobile,
                  country_code,
                  email_verified_at,
                } = user;
                return (
                  <tr key={id + uuidv4() + "userTable"}>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>
                      {country_code && `+${country_code}`}
                      {formatted_mobile}
                    </td>
                    <td>{role?.name}</td>
                    <td>{teams.map((team) => team.name).join(", ")}</td>
                    {props.authUser.permissions.includes("crm:add:user") && (
                      <td>
                        <button
                          type="button"
                          style={{
                            borderRadius: "9px",
                            padding: "5px 10px",
                            fontSize: "15px",
                            lineHeight: "18px",
                            height: "35px",
                            width: "56px",
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                          className="button-md"
                          onClick={() =>
                            handleOpenUserEditModal(
                              name,
                              email,
                              teams?.map((team) => team.id),
                              teams?.map((team) => team.role),
                              role === null ? null : role?.id,
                              formatted_mobile === undefined
                                ? null
                                : formatted_mobile,
                              id,
                              teams,
                              country_code
                            )
                          }
                        >
                          Edit
                        </button>
                        {email_verified_at === null && (
                          <button
                            disabled={resendLoader && resendEmail === email}
                            onClick={() => resendSignupEmail(email)}
                            style={{
                              border: "1px solid #C5C5D3",
                              borderRadius: "9px",
                              padding: "5px",
                              fontWeight: "500",
                              fontSize: "15px",
                              lineHeight: "18px",
                              color: "#F36363",
                              background: "white",
                              height: "35px",
                              width: "108px",
                              cursor: "pointer",
                            }}
                          >
                            {resendLoader && resendEmail === email
                              ? "Sending"
                              : "Resend Email"}
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <EditUserModal
          showOpportunityModal={userEditModal}
          setShowOpportunityModal={handleOpenUserEditModal}
          handleCloseModal={handleCloseUserEditModal}
          name={name}
          email={email}
          teamId={teamId}
          teamRole={teamRole}
          roleId={roleId}
          formatted_mobile={mobile}
          userId={userId}
          totalTeams={totalTeams}
          key={name + uuidv4()}
          fetchUsers={fetchUsers}
          countryPhoneCode={country_code}
          authUserId={props.authUser.id}
          authUserSet={props.authUserSet}
          authUser={props.authUser}
          handleOpenUserDeleteModal={() =>
            handleOpenUserDeleteModal(name, userId)
          }
        />
        <DeleteUserModal
          showOpportunityModal={userDeleteModal}
          setShowOpportunityModal={handleOpenUserDeleteModal}
          handleCloseModal={handleCloseUserDeleteModal}
          userId={userId}
          name={name}
          key={name + uuidv4()}
          users={users}
          fetchUsers={fetchUsers}
          handleCloseUserEditModal={handleCloseUserEditModal}
        />
        <ShotClockSettingsModal
          showOpportunityModal={showShotClockModal}
          setShowOpportunityModal={() => setShowShotClockModal(true)}
          handleCloseModal={() => setShowShotClockModal(false)}
        />
        <SharedUserSetingModal
          showOpportunityModal={showMultipleAccountModal}
          setShowOpportunityModal={() => setShowMultipleAccountModal(true)}
          handleCloseModal={() => setShowMultipleAccountModal(false)}
        />
      </div>
    </div>
  );
}
const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(Users);
