import React, { Component } from "react";
import { Col, Row } from "react-grid-system";
import ReactModal from "react-modal";
import DeleteIcon from "../images/DeleteIconRed.svg";
import { Plus } from "lucide-react";

class GroupModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Add Group</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.props.addGroupHandler}>
                <label style={{ fontWeight: "500", fontSize: "14px" }}>
                  Group Name
                </label>
                <input
                  name="label"
                  type="text"
                  value={this.props?.label}
                  onChange={this.props.changeHandler}
                  style={
                    this.props.errorMessage === true &&
                    (this.props?.label === null || this.props?.label === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.props.errorMessage === true &&
                  (this.props?.label === null || this.props?.label === "")
                    ? "Group Name is required"
                    : null}
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="modal__confirm-button">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

class EditGroupModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Edit Group</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.props.editGroupHandler}>
                <label style={{ fontWeight: "500", fontSize: "14px" }}>
                  Group Name
                </label>
                <input
                  name="editFieldLabel"
                  type="text"
                  value={this.props?.currentGroup?.label}
                  onChange={this.props.groupLabelChangeHandler}
                  style={
                    this.props.errorMessage === true &&
                    (this.props?.currentGroup?.label === null ||
                      this.props?.currentGroup?.label === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.props.errorMessage === true &&
                  (this.props?.currentGroup?.label === null ||
                    this.props?.currentGroup?.label === "")
                    ? "Group Name is required"
                    : null}
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.props.isLoading}
                  >
                    {this.props.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

class FieldModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const fieldTypes = this.props?.fieldTypes;
    const emptyChoiceHandler = this.props?.fieldChoices.map(
      (choice) => choice.value === ""
    );
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "80vh", top: "10%", width: "45vw", left: "20%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Add Field</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.props.addFieldHandler}>
                <Row>
                  <Col>
                    <label style={{ fontWeight: "500", fontSize: "14px" }}>
                      Group
                    </label>
                    <select
                      name="addFieldModalGroupId"
                      onChange={this.props.changeHandler}
                      value={this.props.addFieldModalGroupId}
                      style={{ width: "100%" }}
                    >
                      {this.props?.accountFields.map((field) => {
                        const { label, id } = field;
                        return (
                          <option value={id} key={id}>
                            {label}
                          </option>
                        );
                      })}
                    </select>
                    <label style={{ fontWeight: "500", fontSize: "14px" }}>
                      Field Name
                    </label>
                    <input
                      type="text"
                      name="fieldLabel"
                      value={this.props?.fieldLabel}
                      onChange={this.props.changeHandler}
                      style={
                        this.props.errorMessage === true &&
                        (this.props?.fieldLabel === null ||
                          this.props?.fieldLabel === "")
                          ? {
                              border: "2px solid #F36363",
                            }
                          : {}
                      }
                    />
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {this.props.errorMessage === true &&
                      (this.props?.fieldLabel === null ||
                        this.props?.fieldLabel === "")
                        ? "Field Name is required"
                        : null}
                    </div>
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                    >
                      Required?{" "}
                      <input
                        name="fieldRequired"
                        value={true}
                        checked={this.props.fieldRequired}
                        type="checkbox"
                        onChange={this.props.fieldRequiredCheckBoxChangeHandler}
                        style={{ display: "inline-block", width: "12px" }}
                      />
                    </label>
                    <label style={{ fontWeight: "500", fontSize: "14px" }}>
                      Field Type
                    </label>
                    <select
                      name="fieldType"
                      onChange={this.props.changeHandler}
                      style={
                        this.props.errorMessage === true &&
                        (this.props?.fieldType === null ||
                          this.props?.fieldType === "")
                          ? {
                              border: "2px solid #F36363",
                              width: "100%",
                            }
                          : { width: "100%" }
                      }
                    >
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      {fieldTypes.map((type) => {
                        const { label, name, id } = type;

                        return (
                          <option
                            key={id}
                            value={name}
                            // name="hasChoices"
                            // onChange={this.props.changeHandler}
                          >
                            {label}
                          </option>
                        );
                      })}
                    </select>
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {this.props.errorMessage === true &&
                      (this.props?.fieldType === null ||
                        this.props?.fieldType === "")
                        ? "Field Type is required"
                        : null}
                    </div>
                    {this.props?.hasChoices === true ? (
                      <div>
                        <label style={{ fontWeight: "500", fontSize: "14px" }}>
                          Enter Choices
                        </label>
                        <button
                          type="button"
                          onClick={() => {
                            const keyIndex =
                              this.props?.fieldChoices?.length - 1;
                            this.props.addChoice();
                            document
                              .getElementById(`dropdownAddFields-${keyIndex}`)
                              .scrollIntoView({ behavior: "smooth" });
                          }}
                          className="button-sm"
                        >
                          Add Choice
                          <Plus
                            size={14}
                            strokeWidth={3}
                            color="#62CA9D"
                            style={{
                              transform: "translateY(2px)",
                              marginLeft: "5px",
                            }}
                          />
                        </button>
                        <div style={{ height: "30vh", overflowY: "auto" }}>
                          {this.props?.fieldChoices.map((choice, index) => (
                            <div
                              style={{ display: "flex" }}
                              id={`dropdownAddFields-${index}`}
                            >
                              <input
                                key={choice.tempId}
                                name="fieldChoices"
                                value={choice.value}
                                onChange={(e) =>
                                  this.props.changeNewChoiceHandler(
                                    choice.tempId,
                                    e
                                  )
                                }
                                style={
                                  this.props.errorMessage === true &&
                                  this.props?.fieldChoices &&
                                  this.props?.fieldChoices.length < 2
                                    ? {
                                        border: "2px solid #F36363",
                                      }
                                    : this.props.errorMessage === true &&
                                      this.props?.hasChoices === true &&
                                      this.props?.fieldChoices &&
                                      emptyChoiceHandler.includes(true) &&
                                      (choice.value === "" ||
                                        choice.value === null)
                                    ? {
                                        border: "2px solid #F36363",
                                      }
                                    : {}
                                }
                              />
                              <button
                                type="button"
                                onClick={(e) =>
                                  this.props.deleteChoiceNewChangeHandler(
                                    choice.tempId,
                                    e
                                  )
                                }
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  outline: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="Delete"
                                  width="16px"
                                />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {this.props.errorMessage === true &&
                      this.props?.hasChoices === true &&
                      this.props?.fieldChoices &&
                      this.props?.fieldChoices.length < 2
                        ? "Please enter minimum of 2 choices"
                        : null}
                    </div>
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {this.props.errorMessage === true &&
                      this.props?.hasChoices === true &&
                      this.props?.fieldChoices &&
                      emptyChoiceHandler.includes(true)
                        ? "Choices cannot be empty"
                        : null}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ marginLeft: "60px" }}>
                      <label
                        style={{
                          fontWeight: "500",
                          marginBottom: "10px",
                          fontSize: "14px",
                        }}
                      >
                        Preview
                      </label>
                      {this.props.showPreview()}
                    </div>
                  </Col>
                </Row>

                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.props.isLoading}
                  >
                    {this.props.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

class EditFieldModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const fieldTypes = this.props?.fieldTypes;
    const currentGroup = this.props?.accountFields.find(
      (res) => res.id === this.props?.currentField.parent_id
    );
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "80vh", top: "10%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Edit Field</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.props.editFieldHandler}>
                <label style={{ fontWeight: "500", fontSize: "14px" }}>
                  Group
                </label>
                <input value={currentGroup?.label} readOnly />
                <label style={{ fontWeight: "500", fontSize: "14px" }}>
                  Field Name
                </label>
                <input
                  type="text"
                  name="label"
                  value={this.props?.currentField?.label}
                  onChange={this.props.editFieldChangeHandler}
                />
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  Required?{" "}
                  <input
                    name="required"
                    value={true}
                    checked={this.props?.currentField?.required}
                    type="checkbox"
                    onChange={this.props.editRequiredFieldChangeHandler}
                    style={{ display: "inline-block", width: "12px" }}
                  />
                </label>
                <label style={{ fontWeight: "500", fontSize: "14px" }}>
                  Field Type
                </label>
                <select
                  name="type"
                  onChange={this.props.editFieldChangeHandler}
                  value={this.props?.currentField.type}
                  style={{ width: "100%" }}
                >
                  <option value="" hidden>
                    -Select-
                  </option>
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {fieldTypes.map((type) => {
                    const { label, name, id } = type;

                    return (
                      <option key={id} value={name}>
                        {label}
                      </option>
                    );
                  })}
                </select>
                {this.props?.currentField.type === "dropdown" ||
                this.props?.currentField.type === "radio" ||
                this.props?.currentField.type === "multi_select_dropdown" ? (
                  <div>
                    <label style={{ fontWeight: "500", fontSize: "14px" }}>
                      Enter Choices
                    </label>
                    <button
                      type="button"
                      onClick={() => {
                        const keyIndex =
                          this.props?.editFieldChoices?.length - 1;
                        this.props.addEditFieldChoice();
                        document
                          .getElementById(`dropdownEditFields-${keyIndex}`)
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                      className="button-sm"
                    >
                      Add Choice
                      <Plus
                        size={14}
                        strokeWidth={3}
                        color="#62CA9D"
                        style={{
                          transform: "translateY(2px)",
                          marginLeft: "5px",
                        }}
                      />
                    </button>
                    <div style={{ height: "30vh", overflowY: "auto" }}>
                      {this.props?.currentField.choices.map((choice) => {
                        return (
                          <div>
                            {choice.destroy === true ? null : (
                              <div style={{ display: "flex" }}>
                                <input
                                  name="choices"
                                  key={choice.id}
                                  value={choice.value}
                                  onChange={(e) =>
                                    this.props.editChoiceChangeHandler(
                                      choice.id,
                                      e
                                    )
                                  }
                                />
                                <button
                                  type="button"
                                  onClick={(e) =>
                                    this.props.deleteChoiceChangeHandler(
                                      choice.id,
                                      e
                                    )
                                  }
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={DeleteIcon}
                                    alt="Delete"
                                    width="16px"
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {this.props?.editFieldChoices.map((choice, index) => {
                        return (
                          <div
                            style={{ display: "flex" }}
                            id={`dropdownEditFields-${index}`}
                          >
                            <input
                              key={choice.tempId}
                              name="editFieldChoices"
                              value={choice.value}
                              onChange={(e) =>
                                this.props.changeEditFieldNewChoiceHandler(
                                  choice.tempId,
                                  e
                                )
                              }
                            />
                            <button
                              type="button"
                              onClick={(e) =>
                                this.props.deleteEditChoiceNewChangeHandler(
                                  choice.tempId,
                                  e
                                )
                              }
                              style={{
                                background: "transparent",
                                border: "none",
                                outline: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img src={DeleteIcon} alt="Delete" width="16px" />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.props.isLoading}
                  >
                    {this.props.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
class DeleteFieldModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "27vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Field</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.props.deleteFieldHandler}>
                <p>
                  Are you sure you want to delete this field?
                  <br />
                  All the data corresponding to this field will be lost
                </p>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="modal__confirm-button">
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

class DeleteGroupModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "24vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Group</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.props.deleteGroupHandler}>
                <p>Are you sure you want to delete this group?</p>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="modal__confirm-button">
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export {
  GroupModal,
  FieldModal,
  EditFieldModal,
  DeleteFieldModal,
  DeleteGroupModal,
  EditGroupModal,
};
