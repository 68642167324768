import React, { Component } from "react";
import axios from "axios";
import "../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import moment from "moment";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../redux";
import { debounce } from "lodash";
import { Col, Row } from "react-grid-system";
import DropdownDashboard from "./Dropdown/DropdownDashboard";

class ActivityList extends Component {
  state = {
    accounts: [],
    query: "",
    activePage: 1,
    total: 0,
    showContactModal: false,
    sortDirection: true,
    userId: this.props.userId ? this.props.userId : "",
    period: this.props.period,
    isApplying: false,
    userName: this.props.authUser.name,
    user_ids: this.props.userId,
    displayMenu: false,
    displayUserName: this.props.userName,
    openedEmail: false,
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  handleUserId = (user_ids, userName) => {
    this.setState(
      {
        user_ids,
        displayMenu: !this.state.displayMenu,
        displayUserName: userName,
      },
      () => this.fetchAccounts()
    );
  };
  handleOpenedEmail = () => {
    // this will trigger open email
    this.setState(
      {
        openedEmail: !this.state.openedEmail,
      },
      () => this.fetchAccounts()
    );
  };

  fetchAccounts = debounce((query, page = 1, sortKey = "created_at") => {
    let url = `/activities?_limit=30&_page=${page}`;
    if (this.state.user_ids) {
      url += `&user_ids=${this.state.user_ids}`;
    }
    if (query) {
      url += `&name=${query}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (this.state.openedEmail) {
      url += `&category=opened_email`;
    }
    if (this.state.period) {
      let activityPeriod = "";
      const { period } = this.state;
      if (period === "daily") {
        activityPeriod = "today";
      } else if (period === "weekly") {
        activityPeriod = "this_week";
      } else if (period === "monthly") {
        activityPeriod = "this_month";
      } else if (period === "quarterly") {
        activityPeriod = "this_quarter";
      } else if (period === "yearly") {
        activityPeriod = "this_year";
      } else {
        activityPeriod = period;
      }
      url += `&created=${activityPeriod}`;
    }
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          accounts: response.data.activities,
          total: response.data.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);

  componentDidMount() {
    this.fetchAccounts();
  }
  periodChangeHandler = (e) => {
    const { value } = e.target;
    this.setState(
      {
        period: value,
      },
      () => this.fetchAccounts()
    );
  };

  render() {
    const account = this.state.accounts;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Activities ({this.state.displayUserName}){" "}
          <span style={{ color: "#4A9876" }}>{this.state.total}</span>
          <div style={{ float: "right" }}>
            <DropdownDashboard
              handleUserId={this.handleUserId}
              user_ids={this.state.user_ids}
              userName={this.state.userName}
              userId={this.props.authUser.id}
              displayMenu={this.state.displayMenu}
              showDropdownMenu={this.showDropdownMenu}
              displayUserName={this.state.displayUserName}
              userRole={this.props.authUser.role}
            />
          </div>
        </h1>
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Accounts"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchAccounts(value, 1);
              this.setState({ query: value, activePage: 1 });
            }}
          />
        </div>
        <div style={{ margin: "10px 0" }}>
          <span style={{ fontWeight: "500" }}>
            Filter by Created Date : &nbsp;&nbsp;
          </span>
          <select
            value={this.state.period}
            onChange={this.periodChangeHandler}
            className="c-icon-1"
            style={{
              background: "#EEF6F2",
              color: "#FFB202",
              width: "130px",
              outline: "none",
            }}
          >
            <option value="daily">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_7_days">Last 7 days</option>
            <option value="last_30_days">Last 30 days</option>
            <option value="last_quarter">Last Quarter</option>
            <option value="weekly">This Week</option>
            <option value="monthly">This Month</option>
            <option value="quarterly">This Quarter</option>
            <option value="yearly">This Year</option>
          </select>
          <div style={{ display: "inline-block", marginLeft: "15px" }}>
            <span style={{ fontWeight: "500" }}>
              Filter by Opened Email : &nbsp;&nbsp;
              <input
                type="checkbox"
                value={this.state.openedEmail}
                checked={this.state.openedEmail}
                onChange={this.handleOpenedEmail}
              />
            </span>
          </div>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : account.length === 0 ? (
          <div
            style={{
              margin: "15px auto",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            No Activities logged by this user in this time period.
          </div>
        ) : (
          <table className="accountList__table">
            <thead>
              <tr>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Title
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchAccounts(
                              null,
                              this.state.activePage,
                              "name"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>Record</th>
                <th>Category</th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Result
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchAccounts(
                              null,
                              this.state.activePage,
                              "result_name"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Created Date
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchAccounts(
                              null,
                              this.state.activePage,
                              "created_at"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
            <tbody>
              {account?.map((account) => {
                const {
                  name,
                  category_name,
                  account_name,
                  contact_name,
                  opportunity_name,
                  account_id,
                  contact_id,
                  opportunity_id,
                  result_name,
                  created_at,
                } = account;
                let newTabData = {};
                let assignedTo = "";
                if (account_name !== null) {
                  assignedTo = account_name;
                  newTabData = {
                    type: "account",
                    id: account_id,
                    name: account_name,
                  };
                } else if (contact_name !== null) {
                  assignedTo = contact_name;
                  newTabData = {
                    type: "contact",
                    id: contact_id,
                    name: contact_name,
                  };
                } else {
                  assignedTo = opportunity_name;
                  newTabData = {
                    type: "opportunity",
                    id: opportunity_id,
                    name: opportunity_name,
                    account_id: account_id,
                  };
                }

                return (
                  <tr key={account.id}>
                    <td>{name}</td>
                    <td
                      style={{ cursor: "pointer", color: "#4A9876" }}
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          this.props.setTab({ ...newTabData, blank: true });
                        } else {
                          this.props.setActiveTabIndex(
                            this.props.totalTabs + 1
                          );
                          this.props.setTab(newTabData);
                          this.props.history.push("/active-tabs");
                        }
                      }}
                    >
                      {assignedTo}
                    </td>
                    <td>{category_name}</td>
                    <td>{result_name}</td>
                    <td>
                      {created_at && moment(created_at).format(dateFormat)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(ActivityList);
