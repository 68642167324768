import React, { Component } from "react";
import ReactModal from "react-modal";
// import Enterprise from "./Cards/Enterprise";
// import PremiumAnnually from "./Cards/PremiumAnnually";
// import PremiumMonthly from "./Cards/PremiumMonthly";
import ProAnnually from "./Cards/ProAnnually";
import ProMonthly from "./Cards/ProMonthly";
import PlanChangeConfirmModal from "./PlanChangeConfirmModal";

class PlanChangeModal extends Component {
  state = {
    showModal: true,
    plan: "monthly",
    package: null,
    planId: null,
    proButtonDisable: false,
    premiumButtonDisable: false,
    enterpriseButtonDisable: false,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  planChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      this.setState({ plan: "annually" });
    } else {
      this.setState({ plan: "monthly" });
    }
  };
  updatePlan = (planId) => {
    this.setState(
      {
        planId,
      },
      this.props.handleOpenConfirmModal()
    );
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    // const { plan } = this.state;
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "80vh", top: "0%", left: "0%", width: "100vw" }}
            >
              <div className="modal__header">
                <button
                  type="button"
                  className="modal__clear"
                  onClick={this.props.handleCloseModal}
                  style={{
                    position: "absolute",
                    top: "2vh",
                    left: "2vw",
                    width: "135px",
                  }}
                >
                  Go Back
                </button>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid #E3E3E3",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <h2 style={{ marginBottom: "0px" }}>Pricing & Billing</h2>
                  <p>Select a plan for your team</p>
                </div>
              </div>
              {/* <div style={{ margin: "20px 0px" }}>
                <span
                  className="general-heading"
                  style={{ transform: "translateY(-10px)" }}
                >
                  Monthly &nbsp;
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    value={plan}
                    id="checkboxView"
                    onChange={this.planChange}
                    checked={this.state.plan === "annually"}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="general-heading">
                  {" "}
                  &nbsp;Annually (20% Off)
                </span>
              </div> */}
              <div className="grid-container" style={{ marginTop: "20px" }}>
                <ProMonthly
                  plan={"monthly"}
                  plans={this.props.plans}
                  subscriptionDetails={this.props.subscriptionDetails}
                  updatePlan={this.updatePlan}
                  trialDateLimitExceeded={this.props.trialDateLimitExceeded}
                  carrierDateLimitExceeded={this.props.carrierDateLimitExceeded}
                  dec15LimitExceeded={this.props.dec15LimitExceeded}
                />
                <ProAnnually
                  plan={"annually"}
                  plans={this.props.plans}
                  subscriptionDetails={this.props.subscriptionDetails}
                  updatePlan={this.updatePlan}
                  trialDateLimitExceeded={this.props.trialDateLimitExceeded}
                  carrierDateLimitExceeded={this.props.carrierDateLimitExceeded}
                  dec15LimitExceeded={this.props.dec15LimitExceeded}
                />
                {/* {plan === "monthly" ? (
                  <PremiumMonthly
                    plan={this.state.plan}
                    plans={this.props.plans}
                    subscriptionDetails={this.props.subscriptionDetails}
                    updatePlan={this.updatePlan}
                  />
                ) : (
                  <PremiumAnnually
                    plan={this.state.plan}
                    plans={this.props.plans}
                    subscriptionDetails={this.props.subscriptionDetails}
                    updatePlan={this.updatePlan}
                  />
                )}
                <Enterprise
                  plan={this.state.plan}
                  plans={this.props.plans}
                  subscriptionDetails={this.props.subscriptionDetails}
                  updatePlan={this.updatePlan}
                /> */}
              </div>
              {/* <FreePlan
                plan={this.state.plan}
                plans={this.props.plans}
                subscriptionDetails={this.props.subscriptionDetails}
                updatePlan={this.updatePlan}
              /> */}
            </div>
            <PlanChangeConfirmModal
              showOpportunityModal={this.props.showConfirmModal}
              setShowOpportunityModal={this.props.handleOpenConfirmModal}
              handleCloseModal={this.props.handleCloseConfirmModal}
              changePlan={this.props.changePlan}
              planId={this.state.planId}
            />
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default PlanChangeModal;
