import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import axios from "axios";
import { BASE_URL } from "../../config";
import LogActivityIcon from "../../images/LogActivity.svg";
import LogActivityIconGray from "../../images/ActivityLogGray.svg";
import CompleteTaskIcon from "../../images/CompleteTask.svg";
import CompleteTaskIconGreen from "../../images/CompleteTaskGreen.svg";

import "react-dates/lib/css/_datepicker.css";

import "../../sass/Activity.scss";
import "../../sass/OverrideDates.scss";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TaskCreateModal from "./../TaskCreateModal";
import { Hidden, Visible } from "react-grid-system";
import TaskTourAccountPage from "./../Onboarding/TaskTourAccountPage";
import ReactTooltip from "react-tooltip";
import { Plus } from "lucide-react";

const DummyActivityForm = (props) => {
  const [categories, setCategories] = useState([]);
  const [results, setResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [users, setUsers] = useState([]);
  const [nextButton, setNextButton] = useState(false);
  const [finishButton, setFinishButton] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASE_URL}/tasks/categories`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.task_categories;
        setCategories(data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios({
      method: "GET",
      url: `${BASE_URL}/activities/results`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => setResults(res.data.results))
      .catch((err) => {
        console.log(err);
      });
  }, [props.customerId]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `/admin/users`,
    })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const fillCompleteTaskForm = (
    taskId,
    categoryId,
    name,
    opportunityId,
    contactId
  ) => {
    console.log("ee liyo", taskId, categoryId, name);
    setFormFields({
      ...formFields,
      type: "task_completed",
      category_id: categoryId,
      name,
      task_id: taskId,
      opportunity_id: opportunityId,
      contact_id: contactId,
    });
  };

  const handleOpenModal = () => {
    props.setShowTaskModal(true);
  };

  const handleCloseModal = () => {
    props.setShowTaskModal(false);
  };

  const [formFields, setFormFields] = useState({
    name: "",
    description: "",
    account_id: props.customerId,
    opportunity_id: "",
    contact_id: "",
    category_id: "",
    result_id: "",
    type: "activity_logged",
    task_id: "",
  });

  const {
    name,
    description,
    opportunity_id,
    contact_id,
    category_id,
    result_id,
    account_id,
    task_id,
  } = formFields;

  const submitHandler = (e) => {
    e.preventDefault();
    setFinishButton(true);
    props.setDescription(description);
    props.setLoggedData(true);
    NotificationManager.success("Activity Logged Successfully!");
  };
  return (
    <div className="activity">
      <div className="activity__next" style={{ paddingBottom: "25px" }}>
        <div style={{ display: "flex" }}>
          <h3 className="activity-title">Follow-up Task</h3>
          <button
            onClick={handleOpenModal}
            className="c-opportunityAdd"
            style={{
              marginLeft: "35%",
              marginTop: "7px",
              width: "100px",
              height: "23px",
              border: "none",
              outline: "none",
              padding: "1px 13px",
              background: "#61C99D",
              borderRadius: "15px",
            }}
          >
            Add Task
            <Plus
              size={14}
              color="#62CA9D"
              strokeWidth={3}
              style={{ transform: "translateY(2px)", marginLeft: "5px" }}
            />
          </button>
          <TaskCreateModal
            accountId={props.customerId}
            showTaskModal={props.showTaskModal}
            setShowTaskModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            customerName={props.customerName}
            users={users}
            keyValue="ActivityForm"
          />
        </div>

        <div className="tour-next-task">
          {!props.loggedData && (
            <div
              className="schedule__items tour-task-dashboard"
              data-tip="Call Salesdash"
              onClick={() => {
                fillCompleteTaskForm({
                  taskId: 1,
                  categoryId: 1,
                  name: "Call Salesdash",
                  opportunityId: 1,
                  contactId: 1,
                });
                setNextButton(true);
              }}
            >
              <ReactTooltip multiline={true} />
              <span className="com-1">
                <img
                  src={require(`../../images/CallGreen.svg`)}
                  alt=""
                  width={18}
                />
              </span>
              <div className="schedule__heading">
                <span className="name">Call Salesdash</span>
                <span className="time">11:00 - 11:30 AM</span>
              </div>
              <div className="schedule__details">
                <span>
                  Salesdash{" "}
                  <span className="schedule__details-sub"> Acct.</span>{" "}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <TaskTourAccountPage
        NextButton={nextButton}
        finishButton={finishButton}
        restart={props.restart}
        setRestart={props.setRestart}
      />
      <div
        className="activity__add tour-complete-task"
        style={{ marginTop: "25px" }}
      >
        <h3 className="activity-title">Add Activity</h3>

        {/* FORM */}

        <form>
          <NotificationContainer />
          <h5 className="c-title">Action</h5>
          <button
            className={`${
              formFields.type === "activity_logged" ? "active" : ""
            } c-button`}
            onClick={() =>
              setFormFields({
                ...formFields,
                type: "activity_logged",
                task_id: null,
                name: "",
                category_id: "",
              })
            }
            type="button"
            style={{ paddingBottom: "5px" }}
          >
            {formFields.type === "activity_logged" ? (
              <img
                src={LogActivityIcon}
                alt=""
                style={{ transform: "translateY(4px)", marginRight: "10px" }}
              />
            ) : (
              <img
                src={LogActivityIconGray}
                alt=""
                style={{ transform: "translateY(4px)", marginRight: "10px" }}
              />
            )}
            New Activity
          </button>
          <button
            className={`${
              formFields.type === "task_completed" ? "active" : ""
            } c-button`}
            onClick={() =>
              setFormFields({ ...formFields, type: "task_completed" })
            }
            type="button"
            style={{ paddingBottom: "5px" }}
          >
            {formFields.type === "task_completed" ? (
              <img
                src={CompleteTaskIconGreen}
                alt=""
                style={{ transform: "translateY(4px)", marginRight: "10px" }}
              />
            ) : (
              <img
                src={CompleteTaskIcon}
                alt=""
                style={{ transform: "translateY(4px)", marginRight: "10px" }}
              />
            )}
            Complete Task
          </button>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <h5 className="c-title">Deal (optional)</h5>
              <select
                className="c-dropdown"
                value={opportunity_id}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    opportunity_id: parseInt(e.target.value),
                  })
                }
              >
                <option value="" hidden>
                  --Select--
                </option>
                <option value="" selected disabled>
                  --Select--
                </option>
              </select>
            </div>
            <div>
              <h5 className="c-title">Contact (optional)</h5>
              <select
                className="c-dropdown"
                value={contact_id}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    contact_id: parseInt(e.target.value),
                  })
                }
              >
                <option value="" hidden>
                  --Select--
                </option>
                <option value="" selected disabled>
                  --Select--
                </option>
              </select>
            </div>
          </div>
          {formFields.type === "task_completed" && (
            <>
              <h5 className="c-title">Choose Task *</h5>
              <select
                className="c-dropdown"
                value={task_id}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    task_id: parseInt(e.target.value),
                  })
                }
                required={formFields.type === "task_completed"}
              >
                <option value="" hidden>
                  --Select--
                </option>
                <option value="" selected disabled>
                  --Select--
                </option>
                <option key={`Call Salesdash`} value={task_id}>
                  Call Salesdash
                </option>
              </select>
            </>
          )}
          {formFields.type === "activity_logged" && (
            <>
              <h5 className="c-title">
                Type{" "}
                <span style={{ color: "#0058DB", display: "inline-block" }}>
                  &nbsp;<span style={{ color: "#4A9876" }}>*</span>
                </span>
              </h5>
              <div className="activityForm_buttons">
                <div className="modal-radioButton c-radioButton">
                  {categories.map((category) => {
                    const { id, name } = category;
                    return (
                      <span key={`${name}_${id}`}>
                        <label
                          htmlFor={`cat_a${account_id}_c${id}`}
                          style={
                            category_id === id
                              ? {
                                  border: "2px solid #A0DFC4",
                                  outline: "none",
                                  color: "#555555",
                                  padding: "4px 12px",
                                  borderRadius: "9px",
                                  marginRight: "8px",
                                  background: "#F4F6F5",
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "4px 12px",
                                  border: "none",
                                  borderRadius: "9px",
                                  background: "#F4F6F5",
                                  marginRight: "8px",
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                }
                          }
                        >
                          {name}
                        </label>
                        <input
                          type="radio"
                          name="category"
                          id={`cat_a${account_id}_c${id}`}
                          className="radio-button"
                          checked={category_id === id}
                          value={id}
                          onChange={(e) => {
                            console.log("hahaha", e.target.value);
                            setFormFields({
                              ...formFields,
                              category_id: parseInt(e.target.value),
                            });
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {errorMessage === true &&
                (category_id === null || category_id === "")
                  ? "Please select a type"
                  : null}
              </div>
              <h5 className="c-title">
                Title{" "}
                <span style={{ color: "#0058DB", display: "inline-block" }}>
                  &nbsp;<span style={{ color: "#4A9876" }}>*</span>
                </span>
              </h5>
              <input
                id="AccountActivityForm"
                type="text"
                className="activity-input"
                name="name"
                autoComplete="off"
                value={name}
                onChange={(e) =>
                  setFormFields({ ...formFields, name: e.target.value })
                }
                style={
                  errorMessage === true && (name === null || name === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {}
                }
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {errorMessage === true && (name === null || name === "")
                  ? "Title is required"
                  : null}
              </div>
            </>
          )}
          <h5 className="c-title">Result (optional)</h5>
          <select
            className="c-dropdown"
            value={result_id}
            onChange={(e) =>
              setFormFields({
                ...formFields,
                result_id: parseInt(e.target.value),
              })
            }
          >
            <option value="" hidden>
              --Select--
            </option>
            <option value="" selected disabled>
              --Select--
            </option>
            {results?.map((opportunity) => {
              const { id, name } = opportunity;

              return (
                <option key={`${name}_${id}`} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
          <h5 className="c-title">Description (optional)</h5>
          <textarea
            className="c-input-area"
            name="description"
            value={description}
            onChange={(e) =>
              setFormFields({ ...formFields, description: e.target.value })
            }
          ></textarea>
          <Hidden md sm xs>
            <button className="logActivityButton" onClick={submitHandler}>
              Log Activity
            </button>
          </Hidden>
          <Visible md sm xs>
            <div>
              <button
                className="logActivityButton"
                onClick={submitHandler}
                style={{ float: "left" }}
              >
                Log Activity
              </button>
            </div>
          </Visible>
        </form>
      </div>
    </div>
  );
};

export default DummyActivityForm;
