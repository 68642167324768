import React, { Component } from "react";
import axios from "axios";
import "../../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { debounce } from "lodash";
import { Col, Row } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
import { colourStyles } from "../../constants/selectStyles";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import { AsYouType } from "libphonenumber-js";
import RangeSlider from "../MaterialComponents/RangeSlider/RangeSlider";
import CreateCarrierModal from "./CreateCarrierModal";
import CreateCarrierCustomModal from "./CreateCarrierCustomModal";
import CarrierGreenNavIcon from "../../images/CarrierGreenNavIcon.svg";
import LaneNavIcon from "../../images/LaneNavIcon.svg";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
  setReportsFilters,
} from "../../redux";
import { Plus } from "lucide-react";

class CarriersList extends Component {
  state = {
    accounts: [],
    query: "",
    activePage: 1,
    total: 0,
    showCarrierModal: false,
    showNewCarrierModal: false,
    sortDirection: true,
    isApplying: true,
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    userName: this.props.authUser.name,
    user_ids: this.props.userId || this.props.authUser.id,
    displayMenu: false,
    displayUserName: this.props.userName || this.props.authUser.name,
    homebase: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    origin: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    destination: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    price: null,
    equipmentTypes: [],
    speciaRequirements: [],
    modes: [],
    daysOfWeek: [],
    deadHeadHomebase: 25,
    deadHeadOrigin: 25,
    deadHeadDestination: 25,
    searchType: "carrier",
  };
  days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  handleOpenCarrierModal = () => {
    this.setState({
      showCarrierModal: true,
    });
  };
  handleCloseCarrierModal = () => {
    this.setState({
      showCarrierModal: false,
    });
  };
  handleOpenNewCarrierModal = () => {
    this.setState({
      showNewCarrierModal: true,
    });
  };
  handleCloseNewCarrierModal = () => {
    this.setState({
      showNewCarrierModal: false,
    });
  };

  fetchAccounts = debounce((query, page = 1, sortKey) => {
    const {
      homebase,
      origin,
      destination,
      equipmentTypes,
      speciaRequirements,
      modes,
      price,
      daysOfWeek,
      deadHeadOrigin,
      deadHeadDestination,
      deadHeadHomebase,
      searchType,
      user_ids,
      displayUserName,
    } = this.state;
    let url = `/carriers?_limit=30&_page=${page}&search_by=${searchType}`;
    if (query) {
      url += `&name=${encodeURIComponent(query)}`;
    }
    if (user_ids) {
      url += `&user_ids=${user_ids}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (searchType === "carrier") {
      if (homebase.city) {
        url += `&homebase_city=${homebase.city}`;
      } else if (homebase.zip) {
        url += `&homebase_zip=${homebase.zip}`;
      } else if (homebase.state) {
        url += `&homebase_state=${homebase.state}`;
      }
      if (homebase.address && deadHeadHomebase !== 0) {
        url += `&homebase_lat=${homebase.lat}`;
        url += `&homebase_long=${homebase.long}`;
        url += `&homebase_radius=${deadHeadHomebase}`;
      }
    } else if (searchType === "lane") {
      if (origin.city) {
        url += `&origin_city=${origin.city}`;
      } else if (origin.zip) {
        url += `&origin_zip=${origin.zip}`;
      } else if (origin.state) {
        url += `&origin_state=${origin.state}`;
      }
      if (origin.address && deadHeadOrigin !== 0) {
        url += `&origin_lat=${origin.lat}`;
        url += `&origin_long=${origin.long}`;
        url += `&origin_radius=${deadHeadOrigin}`;
      }
      if (destination.address && deadHeadDestination !== 0) {
        url += `&destination_lat=${destination.lat}`;
        url += `&destination_long=${destination.long}`;
        url += `&destination_radius=${deadHeadDestination}`;
      }
      if (destination.city) {
        url += `&destination_city=${destination.city}`;
      } else if (destination.zip) {
        url += `&destination_zip=${destination.zip}`;
      } else if (destination.state) {
        url += `&destination_state=${destination.state}`;
      }
    }
    if (equipmentTypes && equipmentTypes.length > 0) {
      url += `&equipment_type_ids=${equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&equipment_type_ids=`;
    }
    if (speciaRequirements && speciaRequirements.length > 0) {
      url += `&requirement_type_ids=${speciaRequirements
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&requirement_type_ids=`;
    }
    if (modes && modes.length > 0) {
      url += `&mode_ids=${modes?.map((type) => type.value).join(",")}`;
    } else {
      url += `&mode_ids=`;
    }
    if (daysOfWeek.length > 0 && searchType === "lane") {
      url += `&days_of_week=${daysOfWeek.join(",")}`;
    } else {
      url += `&days_of_week=`;
    }
    if (price) {
      url += `&price=${price}`;
    }
    this.props.setReportsFilters({
      id: "CarriersList",
      filters: {
        query,
        activePage: page,
        sortKey,
        sortDirection: this.state.sortDirection,
        origin,
        destination,
        equipmentTypes,
        speciaRequirements,
        modes,
        price,
        daysOfWeek,
        user_ids,
        displayUserName,
        homebase,
        searchType,
        deadHeadOrigin,
        deadHeadDestination,
        deadHeadHomebase,
      },
    });
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          accounts: response.data.carriers?.map((carrier) => ({
            ...carrier,
            equipment_types: carrier.equipment_types?.map((type) => ({
              value: type.id,
              label: type.name,
            })),
            modes: carrier.modes?.map((type) => ({
              value: type.id,
              label: type.name,
            })),
            requirement_types: carrier.requirement_types?.map((type) => ({
              value: type.id,
              label: type.name,
            })),
          })),
          total: response.data.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);
  logCall = (description, selectedAccount) => {
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: `${description?.direction} Call`,
        description: `${description?.direction} Call made at ${Date(
          description?.startTime
        )} to <span style="color: #4A9876;">${
          description?.to?.phoneNumber
        }</span>`,
        category_id: 1,
        account_id: selectedAccount.id,
        type: "activity_logged",
      },
    }).then((res) => {
      NotificationManager.success("Call logged.");
    });
  };
  fetchAllReportsFilters = () => {
    const selectedReport = this.props.reportsFilters?.find(
      (report) => report.id === "CarriersList"
    );
    if (this.props.reportsFilters && selectedReport) {
      const {
        query,
        activePage,
        sortKey,
        sortDirection,
        origin,
        destination,
        equipmentTypes,
        speciaRequirements,
        modes,
        price,
        daysOfWeek,
        user_ids,
        displayUserName,
        homebase,
        searchType,
        deadHeadOrigin,
        deadHeadDestination,
        deadHeadHomebase,
      } = selectedReport.filters;
      this.setState(
        {
          query,
          activePage,
          sortKey,
          sortDirection,
          origin,
          destination,
          equipmentTypes,
          speciaRequirements,
          modes,
          price,
          daysOfWeek,
          user_ids,
          displayUserName,
          homebase,
          searchType,
          deadHeadOrigin,
          deadHeadDestination,
          deadHeadHomebase,
        },
        () =>
          this.fetchAccounts(
            this.state.query,
            this.state.activePage,
            this.state.sortKey
          )
      );
    } else {
      this.fetchAccounts();
    }
  };
  handleClickToDial = (e) => {
    const data = e.data;
    if (
      data &&
      data.type === "rc-active-call-notify" &&
      data.call &&
      data.call.terminationType === "final"
    ) {
      const selectedAccount = this.state.accounts?.find((table) =>
        data.call.to.phoneNumber.includes(table.formatted_phone)
      );
      if (selectedAccount) {
        this.logCall(data.call, selectedAccount);
      }
    }
  };
  componentDidMount() {
    window.addEventListener("message", this.handleClickToDial);
    this.fetchAllReportsFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.homebase.address !== prevState.homebase.address ||
      this.state.origin.address !== prevState.origin.address ||
      this.state.destination.address !== prevState.destination.address ||
      this.state.price !== prevState.price ||
      this.state.equipmentTypes?.length !== prevState.equipmentTypes?.length ||
      this.state.speciaRequirements?.length !==
        prevState.speciaRequirements?.length ||
      this.state.modes?.length !== prevState.modes?.length ||
      this.state.daysOfWeek?.length !== prevState.daysOfWeek?.length ||
      (this.state.origin.address &&
        this.state.deadHeadOrigin !== prevState.deadHeadOrigin) ||
      (this.state.homebase.address &&
        this.state.deadHeadHomebase !== prevState.deadHeadHomebase) ||
      (this.state.destination.address &&
        this.state.deadHeadDestination !== prevState.deadHeadDestination) ||
      this.state.searchType !== prevState.searchType
    ) {
      this.fetchAccounts();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleClickToDial);
  }
  handleHomebasePlaceSelect = (place) => {
    this.setState({
      homebase: place,
    });
  };
  handleOriginPlaceSelect = (place) => {
    this.setState({
      origin: place,
    });
  };
  handleDestinationPlaceSelect = (place) => {
    this.setState({
      destination: place,
    });
  };
  handleDeadHeadHomebase = (e, deadHeadHomebase) => {
    this.setState({
      deadHeadHomebase,
    });
  };
  handleDeadHeadOrigin = (e, deadHeadOrigin) => {
    this.setState({
      deadHeadOrigin,
    });
  };
  handleDeadHeadDestination = (e, deadHeadDestination) => {
    this.setState({
      deadHeadDestination,
    });
  };
  daysOfWeekChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      const newDays = [
        ...this.state.daysOfWeek.filter((day) => day !== ""),
        value,
      ];
      this.setState({
        daysOfWeek: newDays,
      });
    } else {
      const newDays = this.state.daysOfWeek.filter((pc) => pc !== value);
      this.setState({
        daysOfWeek: newDays,
      });
    }
  };
  changeFilterHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  tableHeaders = [
    {
      value: "account_name",
      label: "Name",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "mc_number",
      label: "MC#",
      sort: true,
      editable: true,
      type: "number",
    },
    {
      value: "status",
      label: "Status",
      sort: true,
      editable: true,
      type: "dropdown",
    },
    {
      value: "email",
      label: "email",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "formatted_phone",
      label: "phone",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "address",
      label: "Home Base",
      sort: false,
      editable: false,
      type: "google",
    },
    {
      value: "equipment_types",
      label: "Equipment Types",
      sort: false,
      editable: true,
      type: "multi_select",
    },
    {
      value: "modes",
      label: "Modes",
      sort: false,
      editable: true,
      type: "multi_select",
    },
    {
      value: "requirement_types",
      label: "Special Requirements",
      sort: false,
      editable: true,
      type: "multi_select",
    },
  ];
  renderTableData = (carrierData, tableData, name, value, dateFormat) => {
    let newTabData = {
      type: "carrier",
      id: carrierData.id,
      name: carrierData.name,
    };
    let renderItem = <td>{value}</td>;
    if (!tableData.editable) {
      renderItem = <td>{value}</td>;
    } else if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === carrierData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({ ...newTabData, blank: true });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(newTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === carrierData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: carrierData.id,
                    isTrue: true,
                    name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: carrierData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === carrierData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(carrierData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: carrierData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (name === "formatted_phone") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === carrierData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { color: "#4A9876" }
              }
            >
              <a
                target="_blank"
                style={{ color: "#4A9876", cursor: "pointer" }}
                data-stringify-link={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                delay="150"
                data-sk="tooltip_parent"
                href={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                rel="noopener noreferrer"
                tabindex="-1"
                data-remove-tab-index="true"
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === carrierData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: carrierData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: carrierData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === carrierData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(carrierData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: carrierData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (tableData.type === "text") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(carrierData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "number") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            type="number"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(carrierData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "dropdown") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(carrierData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            <option value="" selected disabled>
              -Select-
            </option>
            {this.props.allCarrierStatuses?.map((list) => (
              <option key={list.id} value={list.name}>
                {list.name}
              </option>
            ))}
          </select>
        </td>
      );
    } else if (tableData.type === "multi_select") {
      const options =
        tableData.value === "modes"
          ? this.props.allModes
          : tableData.value === "requirement_types"
          ? this.props.allSpecialRequirements
          : this.props.allEquipmentTypes;
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value?.map((type) => type.label)?.join(", ")}
          </span>
          <Select
            autoFocus={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
            }
            className="inlineReportEdit-input"
            closeMenuOnSelect={false}
            value={carrierData?.[tableData?.value]}
            isMulti
            name={name}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            menuPlacement="auto"
            styles={colourStyles}
            options={options?.map((type) => {
              return { value: type.id, label: type.name };
            })}
            classNamePrefix="select"
            onChange={(selectedOption) =>
              this.fieldMultiSelectChangeHandler(
                carrierData,
                name,
                selectedOption
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (account, name, value) => {
    let carrierData = account;
    let formattedValue = value;
    if (name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }
    carrierData = {
      ...account,
      [name]: formattedValue === "" ? null : formattedValue,
    };
    this.setState({
      accounts: this.state.accounts?.map((acc) => {
        if (acc.id === account.id) {
          acc = {
            ...account,
            [name]: formattedValue === "" ? null : formattedValue,
          };
        }
        return acc;
      }),
    });
    this.submitHandler(carrierData);
  };
  fieldMultiSelectChangeHandler = (account, fieldName, selectedOptions) => {
    let carrierData = account;
    carrierData = {
      ...account,
      [fieldName]: selectedOptions,
    };
    this.setState((prevState) => ({
      accounts: this.state.accounts?.map((acc) => {
        if (acc.id === account.id) {
          acc = {
            ...account,
            [fieldName]: selectedOptions,
          };
        }
        return acc;
      }),
    }));
    this.submitHandler(carrierData);
  };
  handleUserId = (user_ids, userName) => {
    this.setState(
      {
        user_ids,
        displayMenu: !this.state.displayMenu,
        displayUserName: userName,
      },
      () => this.fetchAccounts()
    );
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  submitHandler = debounce((carrierData) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (
      carrierData.formatted_phone !== null &&
      carrierData.formatted_phone !== "" &&
      !phoneValidator.test(carrierData.formatted_phone)
    ) {
      return false;
    }
    if (
      carrierData.email !== null &&
      carrierData.email !== "" &&
      !emailValidator.test(carrierData.email)
    ) {
      return false;
    }
    if (!carrierData.name) {
      return false;
    }
    console.log(carrierData);
    const selectedStatus = this.props.allCarrierStatuses?.find(
      (s) => s.name === carrierData.status
    );
    axios({
      method: "PUT",
      url: `/carriers/${carrierData.id}`,
      data: {
        name: carrierData.name,
        mc_number: carrierData.mc_number,
        status_id: selectedStatus.id,
        owner_ids: carrierData.owners?.map((owner) => owner.id),
        email: carrierData.email,
        phone_ext: carrierData.phone_ext,
        formatted_phone: carrierData.formatted_phone,
        equipment_type_ids: carrierData.equipment_types?.map(
          (type) => type.value
        ),
        requirement_type_ids: carrierData.requirement_types?.map(
          (type) => type.value
        ),
        mode_ids: carrierData.modes?.map((type) => type.value),
        dot_number: carrierData.dot_number,
        fleet_size: carrierData.fleet_size,
        description: carrierData.description,
        city: carrierData.city,
        state: carrierData.state,
        zip: carrierData.zip,
        country: carrierData.country,
        address: carrierData.address,
        lat: carrierData.lat,
        long: carrierData.long,
        custom_fields: carrierData.custom_fields,
      },
    })
      .then((response) => {
        // NotificationManager.success("Account edited successfully.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing carrier.");
        }
      });
  }, 500);

  render() {
    const accounts = this.state.accounts;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Carriers <span style={{ color: "#4A9876" }}>{this.state.total}</span>
          <div style={{ float: "right" }}>
            <DropdownDashboard
              handleUserId={this.handleUserId}
              user_ids={this.state.user_ids}
              userName={this.state.userName}
              userId={this.props.authUser.id}
              displayMenu={this.state.displayMenu}
              showDropdownMenu={this.showDropdownMenu}
              displayUserName={this.state.displayUserName}
              userRole={this.props.authUser.role}
            />
          </div>
        </h1>
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Carriers"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchAccounts(value, 1);
              this.setState({ query: value, activePage: 1 });
            }}
          />
          <button
            className="button-md"
            type="button"
            onClick={
              this.props.isCarrierFieldsModified
                ? this.handleOpenNewCarrierModal
                : this.handleOpenCarrierModal
            }
            style={{ float: "right" }}
          >
            Add Carrier
            <Plus
              size={14}
              color="#62CA9D"
              strokeWidth={3}
              style={{ transform: "translateY(2px)", marginLeft: "5px" }}
            />
          </button>
        </div>
        <div className="modal-radioButton c-radioButton">
          {[
            {
              label: "Search by Carriers",
              value: "carrier",
              image: CarrierGreenNavIcon,
            },
            { label: "Search by Lanes", value: "lane", image: LaneNavIcon },
          ].map(({ label, value, image }) => (
            <span key={`${label}_${value}`}>
              <label
                htmlFor={`laneType_${label}_${value}`}
                style={
                  this.state.searchType === value
                    ? {
                        border: "2px solid #4a9876",
                        outline: "none",
                        color: "#555555",
                        padding: "4px 12px",
                        borderRadius: "9px",
                        marginRight: "8px",
                        background: "#F4F6F5",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }
                    : {
                        color: "#555555",
                        padding: "4px 12px",
                        border: "none",
                        borderRadius: "9px",
                        background: "#F4F6F5",
                        marginRight: "8px",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }
                }
              >
                <img src={image} height="16px" /> {label}
              </label>
              <input
                type="radio"
                name="searchType"
                id={`laneType_${label}_${value}`}
                className="radio-button"
                checked={this.state.searchType === value}
                value={value}
                onChange={() => {
                  this.setState({
                    searchType: value,
                  });
                }}
              />
            </span>
          ))}
        </div>
        <div
          className="lanes-panel-form"
          style={{ display: "grid", gridTemplateColumns: "310px 310px 310px" }}
        >
          {this.state.searchType === "carrier" ? (
            <div>
              <label>Home Base</label>
              <GoogleAutocompleteComponent
                onPlaceSelect={this.handleHomebasePlaceSelect}
              />
              <label style={{ marginTop: "10px" }}>
                Expand Home Base Search ({this.state.deadHeadHomebase} Miles)
              </label>
              <RangeSlider
                width={285}
                value={this.state.deadHeadHomebase}
                handleChange={this.handleDeadHeadHomebase}
              />
            </div>
          ) : (
            <>
              <div>
                <label>Origin</label>
                <GoogleAutocompleteComponent
                  onPlaceSelect={this.handleOriginPlaceSelect}
                />
                <label style={{ marginTop: "10px" }}>
                  Expand Origin Search ({this.state.deadHeadOrigin} Miles)
                </label>
                <RangeSlider
                  width={285}
                  value={this.state.deadHeadOrigin}
                  handleChange={this.handleDeadHeadOrigin}
                />
              </div>
              <div>
                <label>Destination</label>
                <GoogleAutocompleteComponent
                  onPlaceSelect={this.handleDestinationPlaceSelect}
                />
                <label style={{ marginTop: "10px" }}>
                  Expand Destination Search ({this.state.deadHeadDestination}{" "}
                  Miles)
                </label>
                <RangeSlider
                  width={285}
                  value={this.state.deadHeadDestination}
                  handleChange={this.handleDeadHeadDestination}
                />
              </div>
            </>
          )}
        </div>
        <div className="lanes-panel-form-1">
          <div className="lanes-panel-form-1-div">
            <label>Equipment Types</label>
            <Select
              value={this.state.equipmentTypes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allEquipmentTypes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  equipmentTypes: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Modes</label>
            <Select
              value={this.state.modes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allModes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  modes: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Special Requirements</label>
            <Select
              value={this.state.speciaRequirements}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allSpecialRequirements?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  speciaRequirements: selectedOptions,
                })
              }
            />
          </div>
        </div>
        {this.state.searchType === "lane" && (
          <div>
            <label
              style={{ color: "#586874", fontWeight: "500", fontSize: "14px" }}
            >
              Days of Week{" "}
              <span style={{ fontSize: "12px" }}>
                (When their trucks are available for this lane)
              </span>
            </label>
            <div
              className="modal-radioButton c-radioButton"
              style={{ marginTop: "5px" }}
            >
              {this.days.map((day) => (
                <span key={day}>
                  <label
                    htmlFor={`week_${day}`}
                    style={
                      this.state.daysOfWeek.includes(day)
                        ? {
                            border: "2px solid #4a9876",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "8px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                            textTransform: "uppercase",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "8px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                            textTransform: "uppercase",
                          }
                    }
                  >
                    {day}
                    <input
                      type="checkbox"
                      name="category"
                      id={`week_${day}`}
                      className="radio-button"
                      checked={this.state.daysOfWeek.includes(day)}
                      value={day}
                      onChange={(e) => this.daysOfWeekChangeHandler(e)}
                    />
                  </label>
                </span>
              ))}
            </div>
          </div>
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : this.state.isApplying === false && this.state.total === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontWeight: "500",
            }}
          >
            No carriers found
          </div>
        ) : (
          <table className="accountList__table">
            <thead>
              <tr>
                {this.tableHeaders.map((headers) => (
                  <th>
                    <Row>
                      <Col lg={9} xl={9}>
                        {headers.label}
                      </Col>
                      <Col lg={3} xl={3}>
                        {headers.sort && (
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() =>
                              this.setState(
                                {
                                  sortDirection: !this.state.sortDirection,
                                },
                                this.fetchAccounts(
                                  null,
                                  this.state.activePage,
                                  headers.value
                                )
                              )
                            }
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        )}
                      </Col>
                    </Row>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {accounts?.map((account) => {
                return (
                  <tr key={account.id}>
                    {this.tableHeaders?.map((header) =>
                      this.renderTableData(
                        account,
                        header,
                        header.value === "account_name" ? "name" : header.value,
                        account[
                          header.value === "account_name"
                            ? "name"
                            : header.value
                        ],
                        dateFormat
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {this.state.showCarrierModal && (
          <CreateCarrierModal
            showOpportunityModal={this.state.showCarrierModal}
            setShowOpportunityModal={this.handleOpenCarrierModal}
            handleCloseModal={this.handleCloseCarrierModal}
          />
        )}
        {this.state.showNewCarrierModal && (
          <CreateCarrierCustomModal
            showOpportunityModal={this.state.showNewCarrierModal}
            setShowOpportunityModal={this.handleOpenNewCarrierModal}
            handleCloseModal={this.handleCloseNewCarrierModal}
          />
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    isCarrierFieldsModified: state.allCarrierFields.isModified,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allCarrierStatuses: state.allCarrierStatuses.carrierStatuses,
    reportsFilters: state.reportsFilters.reports,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
    setReportsFilters: (reportsFilters) =>
      dispatch(setReportsFilters(reportsFilters)),
  };
};

export default connect(MSP, MDP)(CarriersList);
