import React, { useState } from "react";
import "./App.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
// import { Redirect } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../config";
import "../../sass/PaymentCards.scss";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error("**Stripe publishable key environment variable not set**");
  console.error(
    "**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**"
  );
  console.error("**Replace .env.example with .env and **");
}

const CheckoutForm = ({
  productSelected,
  customer,
  priceId,
  stripeCustomerId,
  subscriptionDetails,
  latestInvoicePaymentIntentStatusState,
  setLatestInvoicePaymentIntentStatusState,
  latestInvoiceId,
  setLatestInvoiceId,
  planName,
  packageName,
  priceToDisplayInCard,
  totalPriceToDisplayInCard,
  subPriceToDisplayInCard,
  discountAmount,
  userToDisplayInCard,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const [accountInformation, setAccountInformation] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("United States");
  const [state, setState] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const termsChangeHandler = (e) => {
    const { checked } = e.target;
    if (checked === true) {
      setAgreeTerms(true);
    } else {
      setAgreeTerms(false);
    }
  };

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    console.log(
      "handlePaymentThatRequiresCustomerAction",
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry
    );
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      setLatestInvoicePaymentIntentStatusState(
        subscription.latest_invoice.payment_intent.status
      );
      setLatestInvoiceId(subscription.latest_invoice.id);
      throw new Error("Your card was declined.");
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({
    paymentMethodId,
    invoiceId,
    priceId,
    stripeCustomerId,
    paymentMethod,
  }) {
    return (
      axios({
        method: "POST",
        url: `${BASE_URL}/payments/retry-invoice`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
        data: {
          stripe_customer_payment_method_id: stripeCustomerId,
          stripe_invoice_id: invoiceId,
          card_last_4_digits: paymentMethod.card.last4.toString(),
          card_exp_month: paymentMethod.card.exp_month.toString(),
          card_exp_year: paymentMethod.card.exp_year.toString(),
          card_brand: paymentMethod.card.brand.toString(),
          card_country: paymentMethod.card.country.toString(),
          address,
          city,
          country,
          zip_code: paymentMethod.billing_details.address.postal_code,
          state,
          first_name: firstName,
          last_name: lastName,
        },
      })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result.invoice,
            paymentMethodId: paymentMethod.id,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.log(error);
          // An error has happened. Display the failure to the user here.
          setSubscribing(false);
          setErrorToDisplay(error && error.error && error.error.decline_code);
        })
    );
  }

  function onSubscriptionComplete(result) {
    console.log(result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
    }

    setAccountInformation(result);

    window.location.reload();
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function createSubscription({
    paymentMethodIdNew,
    priceId,
    stripeCustomerId,
    paymentMethod,
  }) {
    // const priceId = priceId;
    console.log(
      "stripe pay hassan sub",
      priceId,
      stripeCustomerId,
      paymentMethodIdNew
    );

    return (
      axios({
        method: "POST",
        url: `${BASE_URL}/payments/create-subscription`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
        data: {
          stripe_customer_id: stripeCustomerId,
          primary_plan_id: priceId,
          // payment_method_id: paymentMethodIdNew
        },
      })
        // fetch(`${BASE_URL}/payments/create-subscription`, {
        //   method: 'post',
        //   headers: {
        //     'Content-type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     stripe_customer_id: stripeCustomerId,
        //     price_id: priceId,
        //     payment_method_id: paymentMethodId
        //   }),
        // })
        //   .then((response) => {
        //     return response.json();
        //   })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          console.log("stripe pay result", result);
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.data.subscription,
            paymentMethodId: paymentMethod.id,
            priceId: priceId,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(error.message || error.error.decline_code);
        })
    );
  }
  const countriesList = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Barbuda",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Trty.",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Caicos Islands",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Futuna Islands",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard",
    "Herzegovina",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Jan Mayen Islands",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea",
    "Korea (Democratic)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "McDonald Islands",
    "Mexico",
    "Micronesia",
    "Miquelon",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "Nevis",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Principe",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre",
    "Saint Vincent",
    "Samoa",
    "San Marino",
    "Sao Tome",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia",
    "South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "The Grenadines",
    "Timor-Leste",
    "Tobago",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "US Minor Outlying Islands",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (British)",
    "Virgin Islands (US)",
    "Wallis",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const handleSubmit = async (event, priceId, stripeCustomerId) => {
    // Block native form submission.
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (
      firstName === null ||
      firstName === "" ||
      lastName === null ||
      lastName === "" ||
      address === null ||
      address === "" ||
      city === null ||
      city === "" ||
      state === null ||
      state === "" ||
      country === null ||
      country === ""
    ) {
      setErrorMessage(true);
      return false;
    } else {
      setErrorMessage(false);
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus =
      latestInvoicePaymentIntentStatusState;

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[createPaymentMethod error]", error);
      setSubscribing(false);
      setErrorToDisplay(error && error.message);
      return;
    }
    console.log("[PaymentMethod]", paymentMethod);
    console.log("stripe pay hassan", priceId, stripeCustomerId);
    axios({
      method: "POST",
      url: `${BASE_URL}/payments/method`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        first_name: firstName,
        last_name: lastName,
        address,
        city,
        state,
        zip_code: paymentMethod.billing_details.address.postal_code,
        country,
        stripe_customer_payment_method_id: paymentMethod.id,
        card_last_4_digits: paymentMethod.card.last4.toString(),
        card_exp_month: paymentMethod.card.exp_month.toString(),
        card_exp_year: paymentMethod.card.exp_year.toString(),
        card_brand: paymentMethod.card.brand.toString(),
        card_country: paymentMethod.card.country.toString(),
      },
    }).then((res) => {
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatusState === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = latestInvoiceId;
        retryInvoiceWithNewPaymentMethod({
          paymentMethodId: res.data.payment_method.id,
          invoiceId: invoiceId,
          priceId,
          stripeCustomerId: paymentMethodId,
          paymentMethod,
        });
        return;
      }
      createSubscription({
        paymentMethodIdNew: res.data.payment_method.id,
        priceId,
        stripeCustomerId:
          stripeCustomerId === null ? res.data.stripe_cus_id : stripeCustomerId,
        paymentMethod,
      });
    });
    // const paymentMethodId = paymentMethod.id;
    // if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
    //   // Update the payment method and retry invoice payment
    //   const invoiceId = localStorage.getItem('latestInvoiceId');
    //   retryInvoiceWithNewPaymentMethod({
    //     paymentMethodId: paymentMethodId,
    //     invoiceId: invoiceId,
    //     priceId,
    //     stripeCustomerId
    //   });
    //   return;
    // }

    // Create the subscription
  };

  // if (accountInformation) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/account',
  //         state: { accountInformation: accountInformation },
  //       }}
  //     />
  //   );
  // } else {
  return (
    <div id="payment-form" className="flex justify-center">
      <div className="w-full inline-block border p-4 rounded-md">
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #E3E3E3",
            marginBottom: "10px",
          }}
        >
          <div>
            <h2 style={{ marginBottom: "0px" }}>Checkout</h2>
            <p>Enter payment details to confirm payment</p>
          </div>
        </div>

        <div className="w-full">
          <div className="row">
            <form
              id="payment-form"
              onSubmit={(e) => handleSubmit(e, priceId, stripeCustomerId)}
            >
              <div className="col-2-of-3">
                <div
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                    width: "40vw",
                    padding: "32px 0px 32px 32px",
                    marginLeft: "10vw",
                  }}
                >
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <h2
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#0C0D0D",
                        marginBottom: "24px",
                      }}
                    >
                      Payment Details
                    </h2>
                    <div
                      style={{ display: "inline-block", marginRight: "10px" }}
                    >
                      <label
                        style={{
                          marginTop: "5px",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#0C0D0D",
                        }}
                      >
                        First Name
                      </label>
                      <input
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={
                          errorMessage === true &&
                          (firstName === null || firstName === "")
                            ? {
                                padding: "7px",
                                border: "2px solid #F36363",
                                borderRadius: "4px",
                              }
                            : {
                                padding: "7px",
                                border: "1px solid #6F7272",
                                borderRadius: "4px",
                              }
                        }
                      />
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {errorMessage === true &&
                        (firstName === null || firstName === "") ? (
                          "First Name is required"
                        ) : (
                          <div style={{ marginBottom: "12px" }}>&nbsp;</div>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <label
                        style={{
                          marginTop: "5px",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#0C0D0D",
                        }}
                      >
                        Last Name
                      </label>
                      <input
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        style={
                          errorMessage === true &&
                          (lastName === null || lastName === "")
                            ? {
                                padding: "7px",
                                border: "2px solid #F36363",
                                borderRadius: "4px",
                              }
                            : {
                                padding: "7px",
                                border: "1px solid #6F7272",
                                borderRadius: "4px",
                              }
                        }
                      />
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {errorMessage === true &&
                        (lastName === null || lastName === "") ? (
                          "Last Name is required"
                        ) : (
                          <div style={{ marginBottom: "12px" }}>&nbsp;</div>
                        )}
                      </div>
                    </div>
                    <label
                      style={{
                        marginTop: "5px",
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "150%",
                        color: "#0C0D0D",
                      }}
                    >
                      Card Number
                    </label>
                    <div
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                      id="card-element"
                      style={{
                        width: "400px",
                        padding: "7px",
                        border: "1px solid #6F7272",
                        borderRadius: "4px",
                      }}
                    >
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#32325d",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
                              "::placeholder": {
                                color: "#a0aec0",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                      />
                    </div>
                    <div
                      className="text-gray-700 text-base mt-2"
                      role="alert"
                      style={{ fontSize: "12px", color: "red" }}
                    >
                      {errorToDisplay ? errorToDisplay : null}
                    </div>
                    <div>
                      <label
                        style={{
                          marginTop: "5px",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#0C0D0D",
                        }}
                      >
                        Billing Address
                      </label>
                      <input
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={
                          errorMessage === true &&
                          (address === null || address === "")
                            ? {
                                padding: "7px",
                                border: "2px solid #F36363",
                                borderRadius: "4px",
                                width: "355px",
                              }
                            : {
                                padding: "7px",
                                border: "1px solid #6F7272",
                                borderRadius: "4px",
                                width: "355px",
                              }
                        }
                      />
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {errorMessage === true &&
                        (address === null || address === "")
                          ? "Address is required"
                          : null}
                      </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <label
                        style={{
                          marginTop: "5px",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#0C0D0D",
                        }}
                      >
                        City
                      </label>
                      <input
                        name="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        style={
                          errorMessage === true &&
                          (city === null || city === "")
                            ? {
                                padding: "7px",
                                border: "2px solid #F36363",
                                borderRadius: "4px",
                                marginRight: "10px",
                              }
                            : {
                                padding: "7px",
                                border: "1px solid #6F7272",
                                borderRadius: "4px",
                                marginRight: "10px",
                              }
                        }
                      />
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {errorMessage === true &&
                        (city === null || city === "") ? (
                          "City is required"
                        ) : (
                          <div style={{ marginBottom: "12px" }}>&nbsp;</div>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <label
                        style={{
                          marginTop: "5px",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#0C0D0D",
                        }}
                      >
                        State
                      </label>
                      {country !== "United States" ? (
                        <input
                          type="text"
                          name="state"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          style={
                            errorMessage === true &&
                            (state === null || state === "")
                              ? {
                                  padding: "7px",
                                  border: "2px solid #F36363",
                                  borderRadius: "4px",
                                  marginRight: "10px",
                                }
                              : {
                                  padding: "7px",
                                  border: "1px solid #6F7272",
                                  borderRadius: "4px",
                                  marginRight: "10px",
                                }
                          }
                        />
                      ) : (
                        <select
                          name="state"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          style={
                            errorMessage === true &&
                            (state === null || state === "")
                              ? {
                                  padding: "7px",
                                  border: "2px solid #F36363",
                                  borderRadius: "4px",
                                  marginRight: "5px",
                                  width: "181px",
                                }
                              : {
                                  padding: "7px",
                                  border: "1px solid #6F7272",
                                  borderRadius: "4px",
                                  marginRight: "5px",
                                  width: "181px",
                                }
                          }
                        >
                          <option value="" selected disabled>
                            -Select-
                          </option>
                          <option>AL</option>
                          <option>AK</option>
                          <option>AZ</option>
                          <option>AR</option>
                          <option>CA</option>
                          <option>CO</option>
                          <option>CT</option>
                          <option>DE</option>
                          <option>FL</option>
                          <option>GA</option>
                          <option>HI</option>
                          <option>ID</option>
                          <option>IL</option>
                          <option>IN</option>
                          <option>IA</option>
                          <option>KS</option>
                          <option>KY</option>
                          <option>LA</option>
                          <option>ME</option>
                          <option>MD</option>
                          <option>MA</option>
                          <option>MI</option>
                          <option>MN</option>
                          <option>MS</option>
                          <option>MO</option>
                          <option>MT</option>
                          <option>NE</option>
                          <option>NV</option>
                          <option>NH</option>
                          <option>NJ</option>
                          <option>NM</option>
                          <option>NY</option>
                          <option>NC</option>
                          <option>ND</option>
                          <option>OH</option>
                          <option>OK</option>
                          <option>OR</option>
                          <option>PA</option>
                          <option>RI</option>
                          <option>SC</option>
                          <option>SD</option>
                          <option>TN</option>
                          <option>TX</option>
                          <option>UT</option>
                          <option>VT</option>
                          <option>VA</option>
                          <option>WA</option>
                          <option>WV</option>
                          <option>WI</option>
                          <option>WY</option>
                        </select>
                      )}
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {errorMessage === true &&
                        (state === null || state === "") ? (
                          "State is required"
                        ) : (
                          <div style={{ marginBottom: "12px" }}>&nbsp;</div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        style={{
                          marginTop: "5px",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "150%",
                          color: "#0C0D0D",
                        }}
                      >
                        Country
                      </label>
                      <select
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        style={
                          errorMessage === true &&
                          (country === null || country === "")
                            ? {
                                padding: "7px",
                                border: "2px solid #F36363",
                                borderRadius: "4px",
                                marginRight: "5px",
                                width: "181px",
                              }
                            : {
                                padding: "7px",
                                border: "1px solid #6F7272",
                                borderRadius: "4px",
                                marginRight: "5px",
                                width: "181px",
                              }
                        }
                      >
                        {countriesList.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1-of-3">
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                    borderRadius: "8px",
                    width: "282px",
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      marginBottom: "14px",
                    }}
                  >
                    <span style={{ textTransform: "uppercase" }}>
                      {packageName}
                    </span>{" "}
                    PLAN Breakdown
                  </div>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#383A39",
                    }}
                  >
                    <span style={{ textTransform: "capitalize" }}>
                      {planName}
                    </span>{" "}
                    Billing{" "}
                    <span style={{ marginLeft: "50px" }}>
                      ${priceToDisplayInCard}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#383A39",
                    }}
                  >
                    No. of users{" "}
                    <span style={{ marginLeft: "80px" }}>
                      {userToDisplayInCard}
                    </span>
                  </div>
                  {discountAmount && (
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "150%",
                        color: "#383A39",
                      }}
                    >
                      Discount{" "}
                      <span style={{ marginLeft: "99px" }}>
                        ${discountAmount}
                      </span>
                    </div>
                  )}
                  <div
                    style={{ border: "1px solid #E3E3E3", margin: "16px 0" }}
                  ></div>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#383A39",
                      marginBottom: "15px",
                    }}
                  >
                    Total Billing{" "}
                    <span style={{ marginLeft: "60px" }}>
                      ${totalPriceToDisplayInCard}
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    style={{ width: "12px", display: "inline-block" }}
                    onChange={termsChangeHandler}
                    checked={agreeTerms}
                  />
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#383A39",
                    }}
                  >
                    By checking this box, I have read and I agree to the terms
                    of the{" "}
                    <a
                      href="https://salesdashcrm.com/agreement/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#4A9876" }}
                    >
                      service agreement.
                    </a>
                  </span>
                  <button
                    className="button-md"
                    style={
                      !agreeTerms
                        ? { background: "#E5E5E5", marginTop: "25px" }
                        : { marginTop: "25px" }
                    }
                    type="submit"
                    disabled={!agreeTerms}
                  >
                    <div>
                      {subscribing && errorMessage === false
                        ? "Purchasing Plan ..."
                        : "Purchase Plan"}
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
// };

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default PaymentForm;
