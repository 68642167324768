import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import DropdownSequence from "../Dropdown/DropdownSequence";
import { Plus } from "lucide-react";

class AllSequenceModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    allSequences: [],
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  getAllSequences = () => {
    axios({
      method: "GET",
      url: `/sequences`,
    }).then((res) =>
      this.setState({
        allSequences: res.data.sequences?.filter(
          (seq) => seq.published === true
        ),
      })
    );
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getAllSequences();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.getAllSequences();
    }
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "66vh", top: "12%", width: "30vw", left: "32%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Choose Email Sequence</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <p style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: "13px" }}>
                  Choose from existing templates
                </span>
                <span style={{ margin: "0 5px", fontWeight: "500" }}>OR</span>
                <Link
                  to="/sequencing-create"
                  className="button-sm-secondary"
                  style={{
                    padding: "4px 8px",
                    height: "20px",
                    textDecoration: "none",
                    transform: "translate(0px, -6px)",
                  }}
                >
                  New Sequence
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </Link>
              </p>
              <div
                style={{
                  height: "auto",
                  maxHeight: "45vh",
                  overflowY: "auto",
                  margin: "5px",
                  padding: "5px",
                }}
              >
                {this.state.allSequences?.map((temp) => (
                  <div
                    key={temp.id}
                    style={{
                      // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "5px",
                      padding: "7px 10px",
                      marginBottom: "6px",
                      position: "relative",
                      border: "1px solid #e5e5e5",
                    }}
                    // onClick={() => this.props.setSequenceValue(temp.id)}
                  >
                    <span
                      onClick={() => this.props.setSequenceValue(temp.id)}
                      style={{
                        cursor: "pointer",
                        color: "#4A9876",
                        textDecoration: "underline",
                      }}
                    >
                      {temp.name}
                    </span>{" "}
                    <span
                      style={{
                        position: "absolute",
                        left: "93%",
                        top: "-50%",
                      }}
                    >
                      <DropdownSequence
                        handleOpenEditSequenceModal={
                          this.props.handleOpenEditSequenceModal
                        }
                        setSequenceValue={this.props.setSequenceValue}
                        tempId={temp.id}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default AllSequenceModal;
