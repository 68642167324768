import React from "react";
import Collapsible from "react-collapsible";
import ArrowIconDown from "../../images/ArrowDown.svg";
import ArrowIconUp from "../../images/ArrowUp.svg";
import Squarespacelogo from "../../images/Squarespacelogo.jpg";
import { WEB_FORM_PATH } from "../../config";

class FormInstructions extends React.Component {
  state = {
    copySuccess: false,
  };
  copyCodeToClipboard = (inputField) => {
    const el = inputField;
    el.select();
    document.execCommand("copy");
    this.setState({ copySuccess: true });
  };
  render() {
    return (
      <>
        <div>
          <input
            style={{
              width: "300px",
              display: "inline-block",
              padding: "4px 8px",
            }}
            ref={(inputfield) => (this.inputField = inputfield)}
            value={`${WEB_FORM_PATH}/contact/${this.props.companyId}/${this.props.userId}/${this.props.webformId}`}
          />
          <button
            type="button"
            style={{
              padding: "5px 10px",
              background: "white",
              border: "1px solid black",
              marginLeft: "5px",
              cursor: "pointer",
              borderRadius: "3px",
            }}
            onClick={() => this.copyCodeToClipboard(this.inputField)}
          >
            <i className="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
            {this.state.copySuccess ? "Copied" : "Copy to Clipboard"}
          </button>
        </div>
        <h3>Embed the form on your website</h3>
        <p>Choose how to embed the form on your website</p>
        <div>
          <Collapsible
            className="collapseHeader"
            classParentString="collapseHeader"
            trigger={
              <div>
                <i className="fa fa-code" aria-hidden="true"></i> General
                instructions
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "30%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconDown} alt="" height="10px" />
                </button>
              </div>
            }
            triggerWhenOpen={
              <div>
                <i class="fa fa-code" aria-hidden="true"></i> General
                instructions
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "4%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconUp} alt="" height="10px" />
                </button>
              </div>
            }
            triggerStyle={{
              fontSize: "15px",
              lineHeight: "18px",
              cursor: "pointer",
            }}
          >
            <ol style={{ fontWeight: "500", fontSize: "14px" }}>
              <li>Copy the code snippet below</li>
              <li>
                Paste the code right where you want the form to appear in your
                template or CMS editor
              </li>
            </ol>
            <p>
              When entering the snippet to your CMS, make sure you are in “code
              editing” mode. For specific instructions for popular website
              systems, see below.
            </p>
            <div>
              <input
                style={{
                  width: "300px",
                  display: "inline-block",
                  padding: "4px 8px",
                }}
                spellcheck="false"
                ref={(inputfield) => (this.generalInputField = inputfield)}
                value={`<ins id="salesdashWebForms" data-form_type="contact_form" data-company_id="${this.props.companyId}" data-user_id="${this.props.userId}" data-web_form_id="${this.props.webformId}"></ins><script src="${WEB_FORM_PATH}/form.js" type="text/javascript"></script>`}
              />
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  background: "white",
                  border: "1px solid black",
                  marginLeft: "5px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
                onClick={() => this.copyCodeToClipboard(this.generalInputField)}
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
                {this.state.copySuccess ? "Copied" : "Copy to Clipboard"}
              </button>
            </div>
          </Collapsible>
          <Collapsible
            className="collapseHeader"
            classParentString="collapseHeader"
            trigger={
              <div>
                <i className="fa fa-wordpress" aria-hidden="true"></i> WordPress
                - Classic editor
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "30%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconDown} alt="" height="10px" />
                </button>
              </div>
            }
            triggerWhenOpen={
              <div>
                <i className="fa fa-wordpress" aria-hidden="true"></i> WordPress
                - Classic editor
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "4%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconUp} alt="" height="10px" />
                </button>
              </div>
            }
            triggerStyle={{
              fontSize: "15px",
              lineHeight: "18px",
              cursor: "pointer",
            }}
          >
            <ol style={{ fontWeight: "500", fontSize: "14px" }}>
              <li>Copy the code snippet below</li>
              <li>
                Log in to your WordPress site and go to edit the page or post to
                which you want to add the form
              </li>
              <li>
                Select the Text tab from the right top corner of the content
                editor to be able to enter code inside the content
              </li>
              <li>
                Locate the exact place where you want to include the form and
                place the cursor there
              </li>
              <li>Paste the code snippet</li>
              <li>
                Make sure you see the form correctly when you switch back to the
                Visual tab
              </li>
              <li>Save or Publish the page/post</li>
            </ol>
            <div>
              <input
                style={{
                  width: "300px",
                  display: "inline-block",
                  padding: "4px 8px",
                }}
                spellcheck="false"
                ref={(inputfield) => (this.classicInputField = inputfield)}
                value={`<ins id="salesdashWebForms" data-form_type="contact_form" data-company_id="${this.props.companyId}" data-user_id="${this.props.userId}" data-web_form_id="${this.props.webformId}"></ins><script src="${WEB_FORM_PATH}/form.js" type="text/javascript"></script>`}
              />
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  background: "white",
                  border: "1px solid black",
                  marginLeft: "5px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
                onClick={() => this.copyCodeToClipboard(this.classicInputField)}
              >
                <i class="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
                {this.state.copySuccess ? "Copied" : "Copy to Clipboard"}
              </button>
            </div>
          </Collapsible>
          <Collapsible
            className="collapseHeader"
            classParentString="collapseHeader"
            trigger={
              <div>
                <i className="fa fa-wordpress" aria-hidden="true"></i> WordPress
                - Block editor
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "30%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconDown} alt="" height="10px" />
                </button>
              </div>
            }
            triggerWhenOpen={
              <div>
                <i className="fa fa-wordpress" aria-hidden="true"></i> WordPress
                - Block editor
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "4%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconUp} alt="" height="10px" />
                </button>
              </div>
            }
            triggerStyle={{
              fontSize: "15px",
              lineHeight: "18px",
              cursor: "pointer",
            }}
          >
            <ol style={{ fontWeight: "500", fontSize: "14px" }}>
              <li>Copy the code snippet below</li>
              <li>
                Log in to your WordPress site and go to edit the page or post
                where you want to add the form
              </li>
              <li>
                From the Add Block menu (“+” icon on the top left corner), add a
                Custom HTML block
              </li>
              <li>Paste the code snippet in the newly added block</li>
              <li>Save or Publish the page/post</li>
            </ol>
            <div>
              <input
                style={{
                  width: "300px",
                  display: "inline-block",
                  padding: "4px 8px",
                }}
                spellcheck="false"
                ref={(inputfield) => (this.blockInputField = inputfield)}
                value={`<ins id="salesdashWebForms" data-form_type="contact_form" data-company_id="${this.props.companyId}" data-user_id="${this.props.userId}" data-web_form_id="${this.props.webformId}"></ins><script src="${WEB_FORM_PATH}/form.js" type="text/javascript"></script>`}
              />
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  background: "white",
                  border: "1px solid black",
                  marginLeft: "5px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
                onClick={() => this.copyCodeToClipboard(this.blockInputField)}
              >
                <i class="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
                {this.state.copySuccess ? "Copied" : "Copy to Clipboard"}
              </button>
            </div>
          </Collapsible>
          <Collapsible
            className="collapseHeader"
            classParentString="collapseHeader"
            trigger={
              <div>
                <img src={Squarespacelogo} alt="" style={{ width: "16px" }} />{" "}
                Squarespace
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "30%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconDown} alt="" height="10px" />
                </button>
              </div>
            }
            triggerWhenOpen={
              <div>
                <img src={Squarespacelogo} alt="" style={{ width: "16px" }} />{" "}
                Squarespace
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "4%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIconUp} alt="" height="10px" />
                </button>
              </div>
            }
            triggerStyle={{
              fontSize: "15px",
              lineHeight: "18px",
              cursor: "pointer",
            }}
          >
            <ol style={{ fontWeight: "500", fontSize: "14px" }}>
              <li>Copy the code snippet below</li>
              <li>
                Log in to your Squarespace site and go to edit the page or post
                where you want to add the form
              </li>
              <li>
                Click an insert point and choose Code* from the Block menu
              </li>
              <li>Paste the code snippet</li>
              <li>
                Make sure that the “Display source” option is unchecked and
                “HTML” is selected from the dropdown menu
              </li>
              <li>When you're all done, select %sApply%s</li>
              <li>Save or Publish the page/post</li>
            </ol>
            <p>
              Note that Code Blocks might not be available on all plans in
              Squarespace. Official help page:
              <a
                href="https://support.squarespace.com/hc/en-us/articles/206543167"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#4A9876", cursor: "pointer" }}
              >
                https://support.squarespace.com/hc/en-us/articles/206543167
              </a>
            </p>
            <div>
              <input
                style={{
                  width: "300px",
                  display: "inline-block",
                  padding: "4px 8px",
                }}
                spellcheck="false"
                ref={(inputfield) => (this.squareSpaceInputField = inputfield)}
                value={`<ins id="salesdashWebForms" data-form_type="contact_form" data-company_id="${this.props.companyId}" data-user_id="${this.props.userId}"></ins><script src="${WEB_FORM_PATH}/form.js" type="text/javascript"></script>`}
              />
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  background: "white",
                  border: "1px solid black",
                  marginLeft: "5px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
                onClick={() =>
                  this.copyCodeToClipboard(this.squareSpaceInputField)
                }
              >
                <i class="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
                {this.state.copySuccess ? "Copied" : "Copy to Clipboard"}
              </button>
            </div>
          </Collapsible>
        </div>
      </>
    );
  }
}

export default FormInstructions;
