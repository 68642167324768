export const activityTerminologies = {
  name: [
    "activity title",
    "activity name",
    "name",
    "title",
    "activity",
    "note title",
    "notes title",
  ],
  description: [
    "activity description",
    "description",
    "short description",
    "desc.",
    "desc",
  ],
  created_at: [
    "activity date and time",
    "created date",
    "created at",
    "created",
    "created_date",
    "created_at",
  ],
  category: ["activity type", "type", "category", "activity category"],
  account_id: [
    "account_id",
    "company_id",
    "organization_id",
    "account id",
    "company id",
    "organization id",
  ],
  account_name: [
    "account_name",
    "company_name",
    "organization_name",
    "account name",
    "company name",
    "organization name",
  ],
  account_email: [
    "account_email",
    "company_email",
    "organization_email",
    "account email",
    "company email",
    "organization email",
  ],
  contact_id: [
    "contact_id",
    "person_id",
    "customer_id",
    "contact id",
    "person id",
    "customer id",
  ],
  contact_name: [
    "contact_name",
    "person_name",
    "customer_name",
    "contact name",
    "person name",
    "customer name",
  ],
};
