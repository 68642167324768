import React, { useEffect, useState } from "react";
import DashboardLoader from "../../Loaders/DashboardLoader";
import axios from "axios";
import "./UserLeaderboard.scss";

const UserLeaderboard = (props) => {
  const [filter, setFilter] = useState(
    props.type === "activities" ? "today" : "last_30_days"
  );
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);

  const allFilters = [
    { value: "all_time", label: "All time" },
    { value: "today", label: "Today" },
    { value: "last_7_days", label: "Last 7 days" },
    { value: "last_14_days", label: "Last 14 days" },
    { value: "last_30_days", label: "Last 30 days" },
    { value: "last_60_days", label: "Last 60 days" },
    { value: "last_90_days", label: "Last 90 days" },
    { value: "last_365_days", label: "Last 365 days" },
  ];

  const getMetrics = () => {
    setLoader(true);
    axios({
      method: "GET",
      url: `/admin/leader-board?duration=${filter}&board_type=${props.type}`,
    })
      .then((res) => {
        setUsers(res.data.leader_board);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getMetrics();
  }, [filter, props.type]);

  return (
    <div className="card">
      <div className="funnel-heading">
        <h4 className="funnel-heading-sub-1">
          {props.type === "activities"
            ? "Activities"
            : props.type === "account_status_changes"
            ? "Acc. Status Changes"
            : "Stage Changes"}{" "}
          Leaderboard
        </h4>
        <h6 className="funnel-heading-sub-c">
          <select
            className="funnel-select"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {allFilters.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </h6>
      </div>
      {/* <div style={{ textAlign: "left", marginTop: "-5px" }}>
        <select
          className="funnel-select"
          name="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          style={{ fontWeight: "500" }}
        >
          {allTypes.map((fil) => (
            <option key={fil.value} value={fil.value}>
              {fil.label}
            </option>
          ))}
        </select>
      </div> */}
      {loader ? (
        <DashboardLoader />
      ) : (
        <div
          style={{
            overflow: "auto",
            maxHeight: props.maxHeight,
            ...(props.maxWidth && {
              maxWidth: props.maxWidth,
            }),
          }}
        >
          <table className="leaderboard-table">
            <tr>
              <th>Rank</th>
              <th>User</th>
              <th>Total</th>
            </tr>
            {users?.map((user, index) => (
              <tr key={user.user_id}>
                <td>
                  {index === 0
                    ? "1st"
                    : index === 1
                    ? "2nd"
                    : index === 2
                    ? "3rd"
                    : index + 1 + "th"}
                </td>
                <td
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="customer__profile"
                    style={
                      user.profile_img
                        ? {
                            backgroundImage: `url(${user.profile_img})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "30px",
                            color: "transparent",
                            backgroundSize: "cover",
                            backgroundColor: "transparent",
                          }
                        : {
                            border: "1px solid black",
                          }
                    }
                  >
                    <div className="customer__profile-text">
                      {user.user_name
                        ?.split(" ")
                        .map((a) => a.charAt(0))
                        .join("")}
                    </div>
                  </div>
                  <div style={{ marginLeft: "10px" }}>{user.user_name}</div>
                </td>
                <td style={{ fontSize: "18px", fontWeight: "500" }}>
                  <div style={{ marginRight: "6px" }}>{user.count}</div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default UserLeaderboard;
