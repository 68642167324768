import React from "react";
import "../../sass/DropdownHeader.scss";
// import EmailTemplateIcon from "../../images/EmailTemplateIcon.svg";
import ReactTooltip from "react-tooltip";
import ShowMore from "../../images/ShowMoreGray.svg";
import { Plus } from "lucide-react";

class DropdownTemplateOptions extends React.Component {
  state = {
    displayMenu: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  render() {
    const { id, name, subject, body, personalize_vars, is_private } =
      this.props.templateData;
    return (
      <div
        className="dropdownTask"
        ref={this.wrapperRef}
        style={{ background: "transparent", width: "20px" }}
      >
        <div
          className="button"
          style={{ fontSize: "14px", transform: "translate(-17px,-24px)" }}
          onClick={this.showDropdownMenu}
        >
          <img src={ShowMore} alt="V" width={14} />
        </div>
        {this.state.displayMenu ? (
          <ul
            style={{
              width: "auto",
              top: this.props.distanceBetweenDivs > 118 ? "-59px" : "-20px",
              right: "55px",
              borderRadius: "4px",
              zIndex: "1002",
              background: "#4A9876",
            }}
          >
            <li
              className="dropdownAddLi"
              style={{ width: "34.22px", background: "#f7f7f7" }}
              onClick={() => {
                this.props.handleOpenTemplateEditModal();
                this.props.getSingleTemplateData(
                  id,
                  name,
                  subject,
                  body,
                  personalize_vars,
                  is_private
                );
                this.setState({ displayMenu: false });
              }}
            >
              Edit
            </li>
            <li
              className="dropdownAddLi"
              style={{ width: "34.22px", background: "#f7f7f7" }}
              onClick={() => {
                this.props.handleOpenTemplateDeleteModal();
                this.props.getSingleTemplateData(
                  id,
                  name,
                  subject,
                  body,
                  personalize_vars,
                  is_private
                );
                this.setState({ displayMenu: false });
              }}
            >
              Delete
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

class DropdownTemplate extends React.Component {
  state = {
    displayMenu: false,
    showOpportunityModal: false,
    distanceBetweenDivs: 0,
  };
  listRef = React.createRef();
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };
  checkHeight = (e) => {
    const listTop =
      window.pageYOffset + this.listRef?.current?.getBoundingClientRect().top;
    const itemTop = window.pageYOffset + e.target?.getBoundingClientRect().top;
    this.setState({
      distanceBetweenDivs: itemTop - listTop,
    });
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownTemplate"
        style={{
          background: "transparent",
          width: "45px",
          padding: "4px 8px",
        }}
      >
        <ReactTooltip />
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={{ fontSize: "14px", fontWeight: "500" }}
          data-tip="Add Template"
        >
          {/* <img src={EmailTemplateIcon} alt="Template" width="16px" /> */}
          Template
        </div>

        {this.state.displayMenu ? (
          <ul style={{ width: "271px" }}>
            <li style={{ cursor: "default", width: "255px" }}>
              <div style={{ position: "relative" }}>
                <input
                  value={this.state.searchValue}
                  onChange={(e) => this.props.getAllTemplates(e.target.value)}
                  placeholder="Search templates"
                  style={{ width: "94%", fontSize: "14px", padding: "4px" }}
                />
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ position: "absolute", right: "20px", top: "7px" }}
                ></i>
              </div>
            </li>
            <div
              style={
                this.props.allTemplates?.length > 2
                  ? {
                      height: "auto",
                      maxHeight: "440px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }
                  : {}
              }
              ref={this.listRef}
            >
              {this.props.allTemplates?.map((template) => (
                <li
                  key={template.id}
                  onMouseEnter={(e) => this.checkHeight(e)}
                  style={{ width: "250px", fontSize: "14px" }}
                >
                  <div
                    style={{
                      maxWidth: "240px",
                      width: "auto",
                      textAlign: "left",
                    }}
                    onClick={(e) => {
                      this.props.readTemplateValues(template.id);
                      this.showDropdownMenu(e);
                    }}
                  >
                    {template.name}
                  </div>
                  <span
                    style={{
                      position: "absolute",
                      right: "-10%",
                      top: "20%",
                    }}
                    className="dropdownTemplate-options"
                  >
                    <DropdownTemplateOptions
                      accountId={this.props.accountId}
                      opportunityId={this.props.opportunityId}
                      contactId={this.props.contactId}
                      getAllTemplates={this.props.getAllTemplates}
                      templateData={template}
                      handleOpenTemplateEditModal={
                        this.props.handleOpenTemplateEditModal
                      }
                      handleOpenTemplateDeleteModal={
                        this.props.handleOpenTemplateDeleteModal
                      }
                      getSingleTemplateData={this.props.getSingleTemplateData}
                      distanceBetweenDivs={this.state.distanceBetweenDivs}
                    />
                  </span>
                </li>
              ))}
            </div>
            <li
              onClick={this.props.handleOpenTemplateModal}
              style={
                this.props.allTemplates?.length !== 0
                  ? {
                      borderTop: "1px solid #e5e5e5",
                      color: "#A7ABAA",
                      width: "250px",
                      fontSize: "14px",
                    }
                  : { color: "#A7ABAA", width: "250px", fontSize: "14px" }
              }
            >
              <Plus
                size={14}
                strokeWidth={3}
                color="#62CA9D"
                style={{
                  transform: "translateY(2px)",
                  marginRight: "5px",
                }}
              />
              Create new template
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default DropdownTemplate;
