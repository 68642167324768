import React, { Component } from "react";
import "../../sass/CustomerIntro.scss";
import { withRouter } from "react-router-dom";
import Opportunity from "./../Opportunity";
import Edit from "../../images/EditIcon.svg";
import ContactsIcon from "../../images/ContactsIcon.svg";
import AccountsIcon from "../../images/AccountsIcon.svg";
import CarrierGreenIcon from "../../images/CarrierGreenIcon.svg";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SendEmailIcon from "../../images/AccountEmailIconBlack.svg";
import ArrowIcon from "../../images/ArrowDown.svg";
import ArrowIconUp from "../../images/ArrowUp.svg";
import OppModal from "./../OppModal";
import SendEmailModal from "./../SendEmail/SendEmailModal";
import Collapsible from "react-collapsible";
import AccountIcon from "../../images/AccountIcon.svg";
import axios from "axios";
import DealsNavIcon from "../../images/AccountDealIconNew.svg";
import TaskCreateModal from "./../TaskCreateModal";
import AccountTaskIcon from "../../images/AccountTaskIconNew.svg";
import SaveButtonAccount from "../../images/SaveButtonAccount.svg";
import EditIcon from "../../images/EditIcon.svg";
import CrossIcon from "../../images/CrossIcon.svg";
import "../../sass/SpinningCircle.scss";
import AccountActivityIcon from "../../images/ActivityAccountIcon.svg";
import DocumentIcon from "../../images/DocumentIcon.svg";
import DeleteIcon from "../../images/DeleteIcon.svg";
import DocumentQuickAccessIcon from "../../images/DocumentQuickAccessIconNew.svg";
import { countriesList } from "../../constants/countriesList";
import { AsYouType } from "libphonenumber-js";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
  setStoredRecordCollapsible,
  removeStoredRecordCollapsible,
  setStoredRecordSelectable,
  removeStoredRecordSelectable,
  fetchAllAccountSources,
} from "../../redux";
import { compose } from "recompose";
import ReactTooltip from "react-tooltip";
import Autocomplete from "react-autocomplete";
import IntegrationRedirectModal from "../Integration/IntegrationRedirectModal";
import SourceChangeModal from "../Accounts/SourceChangeModal";
import DeleteAttachmentModal from "../SendEmail/DeleteAttachmentModal";
import DisqualifyContactModal from "../DisqualifyContactModal";
import DeleteContactModal from "./DeleteContactModal";
import DeleteIconRed from "../../images/DeleteIconRed.svg";
import CreateDealModal from "../CreateModal/CreateDealModal";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";
import { CurrencyList } from "../../constants/currencylist";
import { Plus } from "lucide-react";

class ContactsIntro extends Component {
  state = {
    userData: {
      custom_fields: {},
    },
    customFieldsForAPI: {},
    opportunities: [],
    allFields: [],
    showOpportunityModal: false,
    showNewOpportunityModal: false,
    editing: false,
    name: null,
    email: null,
    formatted_phone: null,
    phone_ext: null,
    role: null,
    linkedin: null,
    source: null,
    preferred_communication: [],
    customFields: {},
    status: null,
    showDisqualifyModal: false,
    showDeleteModal: false,
    disqualify_reason: null,
    showEmailModal: false,
    showContactModal: false,
    contact_owner: null,
    accountName: null,
    carrierName: null,
    windowWidth: window.innerWidth,
    errorMessage: false,
    contact_owner_id: null,
    searchItems: [],
    account_id: null,
    searchValue: null,
    oldAccountId: null,
    tasks: [],
    users: [],
    showRedirectModal: false,
    integration: false,
    showInputField: null,
    showButtonModal: false,
    isLoading: false,
    showSourceModal: false,
    showInfo: true,
    showInfoOf: "",
    documents: [],
    showDeleteAttachmentModal: false,
    documentId: null,
    showAddDocumentButton: true,
    formatted_mobile: null,
    date_of_birth: null,
    phoneValidationError: false,
    emailValidationError: false,
    postMarkId: null,
    postMarkVerified: false,
    showSaveEmailModal: false,
    showAllSequenceModal: false,
    customFieldRevenueTypeCheck: [],
    zip: null,
    city: null,
    address: null,
    state: null,
    country: "United States",
    funnels: [],
    showAddDealButton: true,
  };
  fileInput = React.createRef();

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: true });
  };

  handleCloseNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: false });
  };
  handleOpenSourceModal = () => {
    this.setState({
      showSourceModal: true,
    });
  };
  handleCloseSourceModal = () => {
    this.setState({
      showSourceModal: false,
    });
  };
  handleOpenDisqualifyModal = () => {
    this.setState({
      showDisqualifyModal: true,
    });
  };

  handleCloseDisqualifyModal = () => {
    this.setState({
      showDisqualifyModal: false,
    });
  };
  handleOpenSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: true,
    });
  };

  handleCloseSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: false,
    });
  };
  handleOpenAllSequenceModal = () => {
    if (this.state.email === "" || this.state.email === null) {
      this.handleOpenSaveEmailModal();
    } else {
      this.setState({
        showSaveEmailModal: false,
        showAllSequenceModal: true,
      });
    }
  };
  handleCloseAllSequenceModal = () => {
    this.setState({
      showAllSequenceModal: false,
    });
  };
  handleOpenDeleteModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  handleOpenTaskModal = () => {
    this.props.setShowTaskModal(true);
  };

  handleCloseTaskModal = () => {
    this.props.setShowTaskModal(false);
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
      showInputField: null,
    });
  };
  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
      showInputField: null,
    });
  };
  handleOpenDeleteAttachmentModal = (id) => {
    this.setState({
      showDeleteAttachmentModal: true,
      documentId: id,
    });
  };

  handleCloseDeleteAttachmentModal = () => {
    this.getDocuments();
    this.setState({
      showDeleteAttachmentModal: false,
    });
  };
  deleteAttachmentHandler = (id) => {
    axios({
      method: "DELETE",
      url: `/attachments/${id}`,
    })
      .then((res) => {
        this.handleCloseDeleteAttachmentModal();
        NotificationManager.success("Document deleted successfully.");
      })
      .catch((err) => NotificationManager.error("Failed to delete document."));
  };

  getTasks = () => {
    axios({
      method: "GET",
      url: `/contacts/${this.props.contactId}/tasks`,
    })
      .then((res) => {
        this.setState({ tasks: res.data.tasks });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getUsers = () => {
    axios({
      method: "GET",
      url: `/admin/users?contact_id=${this.props.contactId}`,
    }).then((response) => {
      this.setState({
        users: response.data.users?.slice()?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Ignore case for comparison
          const nameB = b.name.toUpperCase(); // Ignore case for comparison

          if (nameA < nameB) {
            return -1; // Negative value: a should come before b
          }
          if (nameA > nameB) {
            return 1; // Positive value: b should come before a
          }
          return 0; // Return 0 for elements considered equal
        }),
      });
    });
  };

  handleOpenEmailModal = () => {
    this.setState({
      showEmailModal: true,
      showInputField: null,
    });
  };

  handleCloseEmailModal = () => {
    this.setState({
      showEmailModal: false,
      showInputField: null,
    });
  };
  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };

  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  fetchContactsData = () => {
    axios({
      method: "GET",
      url: `/contacts/${this.props.contactId}`,
    }).then((res) => {
      // let { date_of_birth } = res.data.contact;
      // date_of_birth = new Date(date_of_birth);
      // const offsetDate =
      //   date_of_birth.getTimezoneOffset() < 0
      //     ? new Date(
      //         date_of_birth.getTime() -
      //           date_of_birth.getTimezoneOffset() * 60000
      //       )
      //     : new Date(
      //         date_of_birth.getTime() +
      //           date_of_birth.getTimezoneOffset() * 60000
      //       );
      this.setState({
        userData: res.data.contact,
        accountName: res.data.contact.account_name,
        carrierName: res.data.contact.carrier_name,
        name: res.data.contact.name,
        email: res.data.contact.email,
        formatted_phone: res.data.contact.formatted_phone,
        phone_ext: res.data.contact.phone_ext,
        formatted_mobile: res.data.contact.formatted_mobile,
        date_of_birth: res.data.contact.date_of_birth,
        source: res.data.contact.source,
        role: res.data.contact.role,
        linkedin:
          res.data.contact.linkedin === null
            ? null
            : res.data.contact.linkedin.startsWith("https://") ||
              res.data.contact.linkedin.startsWith("http://")
            ? res.data.contact.linkedin
            : "https://" + res.data.contact.linkedin,
        status: res.data.contact.status,
        zip: res.data.contact.zip,
        city: res.data.contact.city,
        address: res.data.contact.address,
        state: res.data.contact.state,
        country: res.data.contact.country,
        disqualify_reason: res.data.contact.disqualify_reason,
        preferred_communication: (
          res.data.contact.preferred_communication || []
        ).filter((com) => com !== ""),
        contact_owner: res.data.contact.contact_owner,
        contact_owner_id: res.data.contact.contact_owner_id,
        searchValue: res.data.contact.account_name,
        account_id: res.data.contact.account_id,
        oldAccountId: res.data.contact.account_id,
        showButtonModal: false,
        showInputField: null,
        phoneValidationError: false,
        emailValidationError: false,
      });
      this.customFieldInitializer(res.data.contact.custom_fields);
    });
  };
  getAllFunnels = () => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      this.setState({
        funnels: res.data.funnels,
      });
    });
  };

  fetchShowInfoOf = () => {
    this.setState({
      showInfoOf: this.props.storedRecordSelectable.contact,
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.fetchShowInfoOf();
    this.getUsers();
    this.getTasks();
    this.getDocuments();
    this.getAllFunnels();
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      this.props.setIntegration(res.data.user.mail_provider);
      this.setState({
        integration: res.data.user.mail_provider,
        postMarkId: res.data.user.postmark_signature_id,
        postMarkVerified: res.data.user.postmark_signature_verified,
      });
    });
    axios({
      method: "GET",
      url: `/contacts/${this.props.contactId}`,
    }).then((res) => {
      this.props.setContactEmail(res.data.contact.email);
      this.props.setAccountData(res.data.contact);
      // let { date_of_birth } = res.data.contact;
      // date_of_birth = new Date(date_of_birth);
      // const offsetDate =
      //   date_of_birth.getTimezoneOffset() < 0
      //     ? new Date(
      //         date_of_birth.getTime() -
      //           date_of_birth.getTimezoneOffset() * 60000
      //       )
      //     : new Date(
      //         date_of_birth.getTime() +
      //           date_of_birth.getTimezoneOffset() * 60000
      //       );
      this.setState({
        userData: res.data.contact,
        accountName: res.data.contact.account_name,
        carrierName: res.data.contact.carrier_name,
        name: res.data.contact.name,
        email: res.data.contact.email,
        formatted_phone: res.data.contact.formatted_phone,
        phone_ext: res.data.contact.phone_ext,
        formatted_mobile: res.data.contact.formatted_mobile,
        date_of_birth: res.data.contact.date_of_birth,
        source: res.data.contact.source,
        role: res.data.contact.role,
        linkedin:
          res.data.contact.linkedin === null
            ? null
            : res.data.contact.linkedin.startsWith("https://") ||
              res.data.contact.linkedin.startsWith("http://")
            ? res.data.contact.linkedin
            : "https://" + res.data.contact.linkedin,
        zip: res.data.contact.zip,
        city: res.data.contact.city,
        address: res.data.contact.address,
        state: res.data.contact.state,
        country: res.data.contact.country,
        status: res.data.contact.status,
        disqualify_reason: res.data.contact.disqualify_reason,
        preferred_communication: (
          res.data.contact.preferred_communication || []
        ).filter((com) => com !== ""),
        contact_owner: res.data.contact.contact_owner,
        contact_owner_id: res.data.contact.contact_owner_id,
        searchValue: res.data.contact.account_name,
        account_id: res.data.contact.account_id,
        oldAccountId: res.data.contact.account_id,
      });
      this.customFieldInitializer(res.data.contact.custom_fields);
    });
    this.props.fetchAllOpportunitiesContacts(this.props.contactId);
  }
  customFieldInitializer = (initialValue) => {
    this.setState({
      allFields: this.props.allContactCustomFields,
    });
    const customField = this.props.allContactCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const finalObject = { ...obj, ...initialValue };
    const customFieldTypeCheck = this.props.allContactCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.props.setAccountCustomFields(finalObject);
    this.setState((prevState) => ({
      customFields: finalObject,
      customFieldsForAPI: finalObject,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  editStart = () => {
    this.setState(
      {
        editing: !this.state.editing,
        phoneValidationError: false,
        emailValidationError: false,
        errorMessage: false,
      },
      () => {
        if (!this.state.editing) {
          this.fetchContactsData();
        }
      }
    );
  };
  editCancel = () => {
    this.fetchContactsData();
    this.setState({
      editing: false,
      phoneValidationError: false,
      emailValidationError: false,
      errorMessage: false,
    });
  };
  preferredCommunicationChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.name === "preferred_communication") {
      if (e.target.checked === true) {
        this.setState((prevState) => ({
          preferred_communication: [
            ...this.state.preferred_communication.filter((com) => com !== ""),
            value,
          ],
        }));
      } else {
        this.setState({
          preferred_communication: this.state.preferred_communication.filter(
            (pc) => pc !== value
          ),
        });
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value !== "" ? e.target.value : null,
    });
  };
  handlePhoneChangeHandler = (e) => {
    const { name, value } = e.target;
    const formatter = new AsYouType("US"); // Initialize AsYouType instance
    const formattedValue = formatter.input(value); // Format the input value
    console.log(formattedValue);
    this.setState({
      [name]: formattedValue !== "" ? formattedValue : null,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      !phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const data = this.state;
    if (!data.name) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/contacts/${this.props.contactId}`,
      data: {
        name: this.state.name,
        role: this.state.role,
        linkedin: this.state.linkedin
          ? this.state.linkedin.startsWith("https://") ||
            this.state.linkedin.startsWith("http://")
            ? this.state.linkedin
            : "https://" + this.state.linkedin
          : null,
        source: this.state.source,
        email: this.state.email,
        formatted_phone: this.state.formatted_phone,
        phone_ext: this.state.phone_ext,
        formatted_mobile: this.state.formatted_mobile,
        zip: this.state.zip,
        city: this.state.city,
        address: this.state.address,
        state: this.state.state,
        country: this.state.country,
        date_of_birth: this.state.date_of_birth
          ? moment(this.state.date_of_birth).format("YYYY-MM-DD")
          : null,
        preferred_communication: this.state.preferred_communication,
        contact_owner_id: this.state.contact_owner_id,
        custom_fields: customField,
        account_id:
          this.state.account_id === null
            ? this.state.oldAccountId
            : this.state.account_id,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "contact",
          id: this.props.contactId,
          name: this.state.name,
        };
        this.props.setTab({ ...newTabData, edited: true });
        NotificationManager.success("Contact edited successfully.");
      })
      .then((response) => this.fetchContactsData())
      .then((response) =>
        this.setState({
          editing: false,
          errorMessage: false,
          showButtonModal: false,
          showInputField: null,
          isLoading: false,
          phoneValidationError: false,
          emailValidationError: false,
        })
      )
      .catch((error) => {
        if (error?.response?.status === 500) {
          NotificationManager.error("Error editing contact.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
        this.setState({ isLoading: false });
      });
  };
  saveEmailSubmitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      !phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const data = this.state;
    if (!data.name) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/contacts/${this.props.contactId}`,
      data: {
        name: this.state.name,
        role: this.state.role,
        linkedin: this.state.linkedin
          ? this.state.linkedin.startsWith("https://") ||
            this.state.linkedin.startsWith("http://")
            ? this.state.linkedin
            : "https://" + this.state.linkedin
          : null,
        source: this.state.source,
        email: this.state.email,
        formatted_phone: this.state.formatted_phone,
        phone_ext: this.state.phone_ext,
        formatted_mobile: this.state.formatted_mobile,
        date_of_birth: this.state.date_of_birth,
        zip: this.state.zip,
        city: this.state.city,
        address: this.state.address,
        state: this.state.state,
        country: this.state.country,
        preferred_communication: this.state.preferred_communication,
        contact_owner_id: this.state.contact_owner_id,
        custom_fields: customField,
        account_id:
          this.state.account_id === null
            ? this.state.oldAccountId
            : this.state.account_id,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "contact",
          id: this.props.contactId,
          name: this.state.name,
        };
        this.props.setTab({ ...newTabData, edited: true });
        NotificationManager.success("Contact edited successfully.");
      })
      .then((response) => this.fetchContactsData())
      .then((response) =>
        this.setState({
          editing: false,
          errorMessage: false,
          showButtonModal: false,
          showInputField: null,
          isLoading: false,
          phoneValidationError: false,
          emailValidationError: false,
          showSaveEmailModal: false,
          showAllSequenceModal: true,
        })
      )
      .catch((error) => {
        if (error?.response?.status === 500) {
          NotificationManager.error("Error editing contact.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
        this.setState({ isLoading: false });
      });
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  showDealPortion = () => {
    this.setState({
      showInfo: true,
      showInfoOf: "deals",
      showAddDealButton: true,
    });
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    console.log("QWE date", type, date);
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  getDocuments = () => {
    axios({
      method: "GET",
      url: `/attachments?contact_id=${this.props.contactId}`,
    }).then((res) => this.setState({ documents: res.data.attachments }));
  };
  openFileSelect = () => this.fileInput.current.click();
  handleInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    this.submitAttachment(event.target.files[0]);
  };
  submitAttachment = (selectedFile) => {
    const { contactId } = this.props;
    const formData = new FormData();
    formData.append("attachment", selectedFile);
    formData.append("contact_id", contactId);
    axios({
      method: "POST",
      url: `/attachments`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((res) => {
      this.getDocuments();
      NotificationManager.success("Document added successfully.");
    });
  };
  dobChangeHandler = (date) => {
    this.setState({
      date_of_birth: date,
    });
  };
  showInput = (fieldName, fieldLabel, fieldType, fieldChoices, singleEdit) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldRevenueChangeHandler}
        />
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          style={{ fontFamily: "inherit" }}
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        ></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="number"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <select
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        >
          <option selected disabled>
            -Select-
          </option>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return <option key={id}>{value}</option>;
          })}
        </select>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div>
          <input
            type="checkbox"
            onFocus={() =>
              this.setState(singleEdit && { showInputField: fieldName })
            }
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  type="radio"
                  onFocus={() =>
                    this.setState(singleEdit && { showInputField: fieldName })
                  }
                  style={{ display: "inline-block", textAlign: "left" }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                />
                <label
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          selected={Date.parse(this.state.customFields[fieldName])}
          value={Date.parse(this.state.customFields[fieldName])}
          onChange={(date) =>
            this.customFieldDateChangeHandler(fieldName, date)
          }
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      // let multiSelectDropdownValue =
      //   this.state.customFields[fieldName] !== null
      //     ? this.state.customFields[fieldName]?.split(";")
      //     : null;
      // let multiSelectDropdownValueFrontEnd =
      //   multiSelectDropdownValue !== []
      //     ? multiSelectDropdownValue.map((m) => {
      //         return { label: m, value: m };
      //       })
      //     : [];
      const colourStyles = {
        control: (styles) => ({
          ...styles,
          backgroundColor: "white",
          width: "280px",
          outline: "#C0EAD8",
          minHeight: "30px",
          padding: "5px auto",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? "#C0EAD8"
              : null,
            cursor: isDisabled ? "not-allowed" : "default",

            ":active": {
              ...styles[":active"],
              backgroundColor:
                !isDisabled && (isSelected ? data.color : "#C0EAD8"),
            },
          };
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "#C0EAD8",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "black",
          fontSize: "13px",
        }),
      };
      inputType = (
        <Select
          autoFocus={singleEdit}
          className={
            singleEdit
              ? "customerIntro-inputField basic-multi-select"
              : "basic-multi-select"
          }
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          closeMenuOnSelect={false}
          value={this.state.customFields[fieldName]}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value: value, label: value };
          })}
          classNamePrefix="select"
          onChange={(selectedOption) =>
            this.customFieldMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    }
    return inputType;
  };
  searchValueChangeHandler = (e) => {
    this.setState({
      searchValue: e.target.value,
      account_id: null,
    });
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/accounts/search?query=${value}`,
    }).then((res) => {
      this.setState({ searchItems: res.data.accounts });
    });
  };
  statusChangeHandler = (e) => {
    const { value } = e.target;
    if (value === "disqualified") {
      return this.handleOpenDisqualifyModal();
    }
    this.setState({
      status: value,
    });
    axios({
      method: "PATCH",
      url: `/contacts/${this.props.contactId}/status`,
      data: {
        status: value,
      },
    })
      .then((res) => {
        NotificationManager.success("Successfully changed status");
        this.fetchContactsData();
      })
      .catch((err) => {
        NotificationManager.error("Error changing status");
      });
  };
  checkDeletedUserHandle = (userIds, selectedUser) => {
    if (!userIds.includes(this.state.contact_owner_id) && !selectedUser) {
      return (
        <option value={this.state.contact_owner_id} selected disabled>
          {this.state.account_owner}
        </option>
      );
    }
  };

  render() {
    const { opportunities } = this.props;
    const { showInputField } = this.state;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const userIds = this.props.allUsers?.map((user) => user.id);
    const selectedUser = this.props.allUsers?.find(
      (user) => parseInt(user.id) === parseInt(this.state.contact_owner_id)
    );
    return (
      <div className="customerInfo">
        <div className="customerInfo__Intro">
          <div
            className="customerInfo-icon"
            style={{ background: "#EBFAFF", transform: "translateY(-17px)" }}
          >
            <img
              src={ContactsIcon}
              alt=""
              width="17.5px"
              style={{ transform: "translateY(11px)", background: "#EBFAFF" }}
            />
          </div>
          <NotificationContainer />
          <div className="customerInfo-info">
            <h2 className="c-type">
              Contact{" "}
              <i
                className="fa fa-circle"
                style={{ fontSize: "4px", transform: "translateY(-3px)" }}
              ></i>{" "}
              <select
                className={
                  this.state.status?.toLowerCase() === "client"
                    ? "c-icon-won"
                    : this.state?.status === "disqualified"
                    ? "c-icon-lost"
                    : "c-icon-3"
                }
                name="status"
                value={this.state?.status}
                onChange={(e) => {
                  this.statusChangeHandler(e);
                }}
              >
                <option value="disqualified">Disqualified</option>
                {this.props.allContactStatuses
                  ?.filter((stat) => stat.name !== "disqualified")
                  .map((stat) => (
                    <option
                      value={stat.name}
                      style={{ textTransform: "capitalize" }}
                    >
                      {stat.name}
                    </option>
                  ))}
              </select>
              <span style={{ color: "#F36363" }}>
                {" "}
                {this.state?.status === "disqualified"
                  ? "(" + this.state?.disqualify_reason + ")"
                  : null}
              </span>
            </h2>
            <h1 className="c-name">
              {this.state.editing ? (
                <input
                  type="text"
                  name="name"
                  value={this.state?.name}
                  onChange={this.changeHandler}
                  className="c-name-input"
                  autoComplete="off"
                  style={{ width: "120%" }}
                />
              ) : this.state.userData.name &&
                this.state.userData.name.length > 30 ? (
                this.state.userData.name.substring(0, 30) + "..."
              ) : (
                this.state.userData.name
              )}
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.name === null || this.state.name === "")
                  ? "Name is required"
                  : null}
              </div>
              <button
                onClick={this.editStart}
                className="account-editButton-pencil"
              >
                <img src={Edit} alt="Edit" height="13" />
              </button>
            </h1>
          </div>
          {/* Contact owner*/}
          <div
            className="c-hoverDesc-div"
            onClick={() =>
              this.state.contact_owner &&
              this.setState({ showInputField: "contact_owner" })
            }
          >
            <h5 className="c-title-act">Contact Owner</h5>
            {showInputField === "contact_owner" ? (
              <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                <select
                  autoFocus
                  className="customerIntro-inputField"
                  onFocus={() =>
                    this.setState({ showInputField: "contact_owner" })
                  }
                  name="contact_owner_id"
                  value={this.state.contact_owner_id}
                  onChange={this.changeHandler}
                >
                  {this.checkDeletedUserHandle(userIds, selectedUser)}
                  {this.props.allUsers.map((user) => {
                    const { id, name } = user;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={() =>
                      this.setState(
                        { showInputField: null },
                        this.fetchContactsData
                      )
                    }
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.contact_owner === null
                  ? "Unassigned Leads"
                  : this.state.contact_owner}
                <button
                  className="c-hoverDesc-button"
                  onClick={() =>
                    this.state.contact_owner &&
                    this.setState({ showInputField: "contact_owner" })
                  }
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          {/*role*/}
          <div
            className="c-hoverDesc-div"
            onClick={() => this.setState({ showInputField: "role" })}
          >
            <h5 className="c-title-act">Job Title</h5>
            {showInputField === "role" ? (
              <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                <input
                  autoFocus
                  className="customerIntro-inputField"
                  onFocus={() => this.setState({ showInputField: "role" })}
                  type="text"
                  name="role"
                  value={this.state.role}
                  onChange={this.changeHandler}
                />
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={() =>
                      this.setState(
                        { showInputField: null },
                        this.fetchContactsData
                      )
                    }
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.role}{" "}
                <button
                  className="c-hoverDesc-button"
                  onClick={() => this.setState({ showInputField: "role" })}
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          {/*linkedin*/}
          <div
            className="c-hoverDesc-div"
            onClick={() => this.setState({ showInputField: "linkedin" })}
          >
            <h5 className="c-title-act">Linkedin</h5>
            {showInputField === "linkedin" ? (
              <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                <input
                  autoFocus
                  className="customerIntro-inputField"
                  onFocus={() => this.setState({ showInputField: "linkedin" })}
                  type="text"
                  name="linkedin"
                  value={this.state.linkedin}
                  onChange={this.changeHandler}
                />
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={() =>
                      this.setState(
                        { showInputField: null },
                        this.fetchContactsData
                      )
                    }
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.linkedin === null ? null : (
                  <a
                    href={this.state.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-desc"
                    style={{ cursor: "pointer", color: "#4A9876" }}
                  >
                    {this.state.linkedin && this.state.linkedin.length > 40
                      ? this.state.linkedin.substring(0, 39) + "..."
                      : this.state.linkedin}
                  </a>
                )}{" "}
                <button
                  className="c-hoverDesc-button"
                  onClick={() => this.setState({ showInputField: "linkedin" })}
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          {this.props.showTaskModal && (
            <TaskCreateModal
              accountId={this.props.customerId}
              showTaskModal={this.props.showTaskModal}
              setShowTaskModal={this.handleOpenTaskModal}
              handleCloseModal={this.handleCloseTaskModal}
              customerName={this.props.customerName}
              opportunities={opportunities}
              getTasks={this.getTasks}
              users={this.state.users}
              existingUser={this.props.authUser.id}
              keyValue="ContactsIntro"
            />
          )}
          <ReactTooltip />
          <div
            className="accountsActionButtonDiv"
            style={{ justifyContent: "space-between" }}
          >
            <label
              htmlFor="ContactActivityForm"
              className="account-Logactivity-button"
              data-tip="Add Activity"
            >
              <img src={AccountActivityIcon} alt="" style={{ width: "16px" }} />
            </label>
            <button
              type="button"
              onClick={this.handleOpenTaskModal}
              data-tip="Add Task"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={AccountTaskIcon} alt="" style={{ width: "16px" }} />
              <div>Task</div>
            </button>
            <button
              type="button"
              onClick={
                this.state?.status === "disqualified"
                  ? () => {
                      NotificationManager.error(
                        "The contact must be marked as qualified to add deals"
                      );
                    }
                  : this.props.isDealFieldsModified
                  ? this.handleOpenNewOpportunityModal
                  : this.handleOpenModal
              }
              data-tip="Add Deal"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={DealsNavIcon} alt="" style={{ width: "16px" }} />
              <div>Deal</div>
            </button>
            <button
              type="button"
              for="file-upload"
              onClick={this.openFileSelect}
              data-tip="Add Document"
              className="accountsActionButtonDiv-buttons"
            >
              <img
                src={DocumentQuickAccessIcon}
                alt=""
                style={{ width: "16px", height: "17px" }}
              />
              <div>Document</div>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={
                this.state.integration !== null ||
                (this.state.postMarkId !== null && this.state.postMarkVerified)
                  ? this.handleOpenEmailModal
                  : this.handleOpenRedirectModal
              }
              data-tip="Send Email"
              className="button-md button-outline-green button-outline-black-hover"
              style={{
                height: "36px",
                padding: "0 16px",
                fontWeight: "500",
                background: "#ffffff",
                color: "#000",
                border: "1px solid #000",
                width: "100%",
                borderRadius: "50px",
              }}
            >
              <img
                src={SendEmailIcon}
                alt=""
                style={{ width: "16px", transform: "translateY(2px)" }}
              />
              &nbsp;Send Email
            </button>
          </div>
          {this.state.editing && (
            <button
              type="button"
              className="accountDeleteButton"
              onClick={this.handleOpenDeleteModal}
            >
              <img src={DeleteIconRed} alt="Delete" width="18px" />
            </button>
          )}
          <div className="customer-shortcut">
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "accounts"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "accounts" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "contact",
                        id: "accounts",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "contact",
                        id: "accounts",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "accounts" &&
                      this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "accounts",
                    showAddDealButton: true,
                  };
                })
              }
            >
              {this.state.carrierName
                ? `Carrier (${this.state.carrierName ? "1" : "0"})`
                : `Account (${this.state.accountName ? "1" : "0"})`}
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "deals"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "deals" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "contact",
                        id: "deals",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "contact",
                        id: "deals",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "deals" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "deals",
                    showAddDealButton: true,
                  };
                })
              }
            >
              Deals ({opportunities.length})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "docs"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "docs" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "contact",
                        id: "docs",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "contact",
                        id: "docs",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "docs" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "docs",
                  };
                })
              }
            >
              Documents ({this.state.documents.length})
            </span>
          </div>
        </div>
        {/*Accounts */}
        {this.state.showInfo && this.state.showInfoOf === "accounts" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              triggerDisabled={!this.state.accountName ? true : false}
              trigger={
                <div>
                  {this.state.carrierName
                    ? `Carrier (${this.state.carrierName ? "1" : "0"})`
                    : `Account (${this.state.accountName ? "1" : "0"})`}
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div>
                  {this.state.carrierName
                    ? `Carrier (${this.state.carrierName ? "1" : "0"})`
                    : `Account (${this.state.accountName ? "1" : "0"})`}
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <div
                onClick={() => {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab({
                    type: this.state.carrierName ? "carrier" : "account",
                    id: this.state.carrierName
                      ? this.state.userData.carrier_id
                      : this.state.userData.account_id,
                    name: this.state.carrierName
                      ? this.state.carrierName
                      : this.state.accountName,
                  });
                }}
              >
                {(this.state.accountName || this.state.carrierName) && (
                  <div className="documentStorage">
                    <div
                      className="schedule__items-1"
                      data-tip={
                        this.state.carrierName || this.state.accountName
                      }
                    >
                      <ReactTooltip />
                      <span
                        className="com-2"
                        style={{
                          background: this.state.carrierName
                            ? "#B3E0DC"
                            : "#FFF1D6",
                        }}
                      >
                        <img
                          src={
                            this.state.carrierName
                              ? CarrierGreenIcon
                              : AccountsIcon
                          }
                          alt=""
                          style={{
                            transform: "translate(0.5px,0px)",
                            background: this.state.carrierName
                              ? "#B3E0DC"
                              : "#FFF1D6",
                            width: "18px",
                          }}
                        />
                      </span>
                      <div className="schedule__heading">
                        <span className="name-opp">
                          {this.state.carrierName
                            ? this.state.carrierName.length > 25
                              ? this.state.carrierName.substring(0, 25) + "..."
                              : this.state.carrierName
                            : this.state.accountName.length > 25
                            ? this.state.accountName.substring(0, 25) + "..."
                            : this.state.accountName}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Collapsible>
          </div>
        )}
        {/* Deals */}
        {this.state.showInfo && this.state.showInfoOf === "deals" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount-deal"
              classParentString="collapseHeaderAccount-deal"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Deals ({opportunities.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Deals ({opportunities.length})
                  </h4>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
              onClosing={() => this.setState({ showAddDealButton: false })}
              onOpening={() => this.setState({ showAddDealButton: true })}
            >
              {this.state.showAddDealButton && (
                <button
                  onClick={
                    this.state?.status === "disqualified"
                      ? () => {
                          NotificationManager.error(
                            "The account must be marked as qualified to add deals"
                          );
                        }
                      : this.props.isDealFieldsModified
                      ? this.handleOpenNewOpportunityModal
                      : this.handleOpenModal
                  }
                  className="button-sm"
                  style={{ position: "absolute", top: "9px", right: "8px" }}
                >
                  Add Deal
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              )}
              {opportunities.length > 2 ? (
                <div style={{ height: "200px", overflowY: "auto" }}>
                  {opportunities?.map((opportunity) => {
                    const close_date =
                      opportunity.close_date &&
                      moment(opportunity.close_date, "YYYY-MM-DD").format(
                        "MMMM DD, YYYY"
                      );
                    return (
                      <Opportunity
                        contactId={this.props.contactId}
                        name={opportunity.name}
                        detail={close_date}
                        type={opportunity.stage_name}
                        key={opportunity.id}
                        funnels={this.state.funnels}
                        authUser={this.props.authUser}
                        fetchAllOpportunities={() =>
                          this.props.fetchAllOpportunitiesContacts(
                            this.props.contactId
                          )
                        }
                        getConfetti={this.props.getConfetti}
                        users={this.state.users}
                        opportunityData={{ ...opportunity }}
                        {...opportunity}
                      />
                    );
                  })}
                </div>
              ) : (
                <div>
                  {opportunities?.map((opportunity) => {
                    const close_date =
                      opportunity.close_date &&
                      moment(opportunity.close_date, "YYYY-MM-DD").format(
                        "MMMM DD, YYYY"
                      );
                    return (
                      <Opportunity
                        contactId={this.props.contactId}
                        name={opportunity.name}
                        detail={close_date}
                        type={opportunity.stage_name}
                        key={opportunity.id}
                        funnels={this.state.funnels}
                        authUser={this.props.authUser}
                        fetchAllOpportunities={() =>
                          this.props.fetchAllOpportunitiesContacts(
                            this.props.contactId
                          )
                        }
                        getConfetti={this.props.getConfetti}
                        users={this.state.users}
                        opportunityData={{ ...opportunity }}
                        {...opportunity}
                      />
                    );
                  })}
                </div>
              )}
            </Collapsible>
          </div>
        )}
        {/*Documnets */}
        {this.state.showInfo && this.state.showInfoOf === "docs" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              onOpen={() => this.setState({ showAddDocumentButton: true })}
              onClose={() => this.setState({ showAddDocumentButton: false })}
              triggerDisabled={this.state.documents.length === 0 ? true : false}
              trigger={
                <div>
                  Documents ({this.state.documents.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Documents ({this.state.documents.length})
                  </h4>
                  {this.state.showAddDocumentButton && (
                    <button
                      type="button"
                      onClick={this.openFileSelect}
                      for="file-upload"
                      className="button-sm"
                      style={{
                        transform: "translateY(-6px)",
                      }}
                    >
                      Add Documents
                      <Plus
                        size={14}
                        color="#62CA9D"
                        strokeWidth={3}
                        style={{
                          transform: "translateY(2px)",
                          marginLeft: "5px",
                        }}
                      />
                    </button>
                  )}
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <div
                style={
                  this.state.documents.length > 2
                    ? { height: "200px", overflowY: "auto" }
                    : {}
                }
              >
                {this.state.documents?.map((doc) => (
                  <div className="documentStorage" key={doc.id}>
                    <a
                      href={doc.s3_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={doc.s3_url}
                    >
                      <div className="schedule__items-1" data-tip={doc.name}>
                        <ReactTooltip />
                        <span
                          className="com-2"
                          style={{ background: "#D2DCFF" }}
                        >
                          <img
                            src={DocumentIcon}
                            alt=""
                            style={{
                              transform: "translate(0.5px,0px)",
                              background: "#D2DCFF",
                              width: "18px",
                            }}
                          />
                        </span>
                        <div className="schedule__heading">
                          <span className="name-opp">
                            {doc.name.length > 25
                              ? doc.name.substring(0, 25) + "..."
                              : doc.name}
                          </span>
                        </div>
                        <div className="schedule__desc">
                          {doc.created_at &&
                            moment(doc.created_at).format("MMMM DD, YYYY")}{" "}
                          .{" "}
                          {doc.size > 1024
                            ? (doc.size / 1024).toFixed(2) + "MB"
                            : doc.size + "KB"}
                        </div>
                      </div>
                    </a>
                    <button
                      className="schedule__delete"
                      type="button"
                      onClick={() =>
                        this.handleOpenDeleteAttachmentModal(doc.id)
                      }
                    >
                      <img src={DeleteIcon} alt="x" width="16px" />
                    </button>
                  </div>
                ))}
              </div>
            </Collapsible>
          </div>
        )}
        <input
          style={{ display: "none" }}
          id="file-upload"
          type="file"
          ref={this.fileInput}
          onChange={this.handleInputChange}
        />
        {this.state.showDisqualifyModal && (
          <DisqualifyContactModal
            showOpportunityModal={this.state.showDisqualifyModal}
            setShowOpportunityModal={this.handleOpenDisqualifyModal}
            handleCloseModal={this.handleCloseDisqualifyModal}
            customerName={this.props.customerName}
            contactId={this.props.contactId}
            fetchAccountData={this.fetchContactsData}
            status={this.props.status}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteContactModal
            showOpportunityModal={this.state.showDeleteModal}
            setShowOpportunityModal={this.handleOpenDeleteModal}
            handleCloseModal={this.handleCloseDeleteModal}
            customerName={this.props.customerName}
            contactId={this.props.contactId}
            tabIndex={this.props.tabIndex}
            deleteTab={this.props.deleteTab}
          />
        )}
        <SendEmailModal
          showOpportunityModal={this.state.showEmailModal}
          setShowOpportunityModal={this.handleOpenEmailModal}
          handleCloseModal={this.handleCloseEmailModal}
          email={this.state.email}
          contactId={this.props.contactId}
          loadActivities={this.props.loadActivities}
          accountId={this.state.account_id}
          replyToEmail={this.state.email ? [this.state.email] : []}
          fromEmail={this.props.authUser.email}
          idSequence={this.props.contactId}
          type={"contact_id"}
          companyId={this.props.authUser.company_id}
          integration={this.state.integration}
          recordChangeHandler={this.changeHandler}
          recordSubmitHandler={this.saveEmailSubmitHandler}
          recorderLoading={this.state.isLoading}
          emailValidationError={this.state.emailValidationError}
          showSaveEmailModal={this.state.showSaveEmailModal}
          showAllSequenceModal={this.state.showAllSequenceModal}
          handleOpenAllSequenceModal={this.handleOpenAllSequenceModal}
          handleCloseAllSequenceModal={this.handleCloseAllSequenceModal}
          handleOpenSaveEmailModal={this.handleOpenSaveEmailModal}
          handleCloseSaveEmailModal={this.handleCloseSaveEmailModal}
          allUsers={this.props.allUsers}
          recordName={this.state.name}
        />
        {this.state.showRedirectModal && (
          <IntegrationRedirectModal
            showOpportunityModal={this.state.showRedirectModal}
            setShowOpportunityModal={this.handleOpenRedirectModal}
            handleCloseModal={this.handleCloseRedirectModal}
          />
        )}
        {this.state.editing ? (
          <form onSubmit={this.submitHandler}>
            <div
              className="customerInfo__Info"
              style={{ height: "53vh", overflowY: "auto" }}
            >
              <div className="collapseHeaderAccount-edit">
                <h4 className="c-title-main">Contact Info</h4>
                {this.state.contact_owner && (
                  <h5 className="c-title-act">Contact Owner</h5>
                )}
                {this.state.contact_owner && (
                  <select
                    name="contact_owner_id"
                    value={this.state.contact_owner_id}
                    onChange={this.changeHandler}
                  >
                    {this.checkDeletedUserHandle(userIds, selectedUser)}
                    {this.props.allUsers.map((user) => {
                      const { id, name } = user;
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                )}
                <h4 className="c-title-act">Account</h4>
                <Autocomplete
                  inputProps={{
                    style: {
                      border: "1px solid #c5c5d3",
                      borderRadius: "4px",
                      padding: "5px",
                      width: "100%",
                      margin: "5px 0",
                    },
                    placeholder: "Search Account",
                  }}
                  items={this.state?.searchItems}
                  menuStyle={{
                    borderRadius: "3px",
                    // left: "70px",
                    display: "block",
                    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                    background: "#252a2d",
                    padding: "3px 0",
                    position: "fixed",
                    overflow: "auto",
                    maxHeight: "250px",
                    zIndex: "2147483647",
                  }}
                  getItemValue={(item) => item.name}
                  renderItem={(item, highlighted) => {
                    const { type, id } = item;
                    let logo = (
                      <img
                        src={AccountIcon}
                        alt=""
                        width="13"
                        key={id + type}
                        style={{ transform: "translateY(9px)" }}
                      />
                    );
                    return (
                      <div
                        key={`${item.id}${item.type}`}
                        style={{
                          backgroundColor: highlighted ? "#3B4043" : "#252a2d",
                          color: "#CFCFD8",
                          zIndex: "2147483647",
                          fontSize: "16px",
                          cursor: "pointer",
                          borderBottom: "1px solid black",
                          padding: "5px 0",
                        }}
                      >
                        <div style={{ paddingLeft: "15px" }}>
                          {logo}
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              marginLeft: "15px",
                            }}
                          >
                            {item.name.length > 23
                              ? item.name.substring(0, 22)
                              : item.name}
                          </span>
                        </div>
                        <div
                          style={{
                            marginLeft: "45px",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "300",
                            color: "#A5A1AC",
                          }}
                        >
                          Account
                        </div>
                      </div>
                    );
                  }}
                  value={this.state.searchValue}
                  onChange={this.searchValueChangeHandler}
                  onSelect={(value, item) => {
                    this.setState({
                      account_id: item.id,
                      searchValue: item.name,
                    });
                  }}
                />
                <h5 className="c-title-act">Email</h5>
                <input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.changeHandler}
                />
                {this.state.emailValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid email
                  </div>
                )}
                <h5 className="c-title-act">Phone</h5>
                <input
                  type="tel"
                  name="formatted_phone"
                  value={this.state.formatted_phone}
                  onChange={this.changeHandler}
                />
                {this.state.phoneValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid phone number
                  </div>
                )}
                <h5 className="c-title-act">Phone Extension</h5>
                <input
                  type="text"
                  name="phone_ext"
                  value={this.state.phone_ext}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Mobile</h5>
                <input
                  type="tel"
                  name="formatted_mobile"
                  // pattern="[0-9\-]+"
                  value={this.state.formatted_mobile}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Job Title</h5>
                <input
                  type="text"
                  name="role"
                  value={this.state.role}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Linkedin</h5>
                <input
                  type="text"
                  name="linkedin"
                  value={this.state.linkedin}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">City</h5>
                <input
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">State</h5>
                {this.state.country !== "United States" ? (
                  <input
                    type="text"
                    name="state"
                    value={this.state.state}
                    onChange={this.changeHandler}
                  />
                ) : (
                  <select
                    name="state"
                    value={this.state.state}
                    onChange={this.changeHandler}
                  >
                    <option value="" selected disabled>
                      -Select-
                    </option>
                    <option>AL</option>
                    <option>AK</option>
                    <option>AZ</option>
                    <option>AR</option>
                    <option>CA</option>
                    <option>CO</option>
                    <option>CT</option>
                    <option>DE</option>
                    <option>FL</option>
                    <option>GA</option>
                    <option>HI</option>
                    <option>ID</option>
                    <option>IL</option>
                    <option>IN</option>
                    <option>IA</option>
                    <option>KS</option>
                    <option>KY</option>
                    <option>LA</option>
                    <option>ME</option>
                    <option>MD</option>
                    <option>MA</option>
                    <option>MI</option>
                    <option>MN</option>
                    <option>MS</option>
                    <option>MO</option>
                    <option>MT</option>
                    <option>NE</option>
                    <option>NV</option>
                    <option>NH</option>
                    <option>NJ</option>
                    <option>NM</option>
                    <option>NY</option>
                    <option>NC</option>
                    <option>ND</option>
                    <option>OH</option>
                    <option>OK</option>
                    <option>OR</option>
                    <option>PA</option>
                    <option>RI</option>
                    <option>SC</option>
                    <option>SD</option>
                    <option>TN</option>
                    <option>TX</option>
                    <option>UT</option>
                    <option>VT</option>
                    <option>VA</option>
                    <option>WA</option>
                    <option>WV</option>
                    <option>WI</option>
                    <option>WY</option>
                  </select>
                )}
                <h5 className="c-title-act">Zip/Postal</h5>
                <input
                  type="text"
                  name="zip"
                  value={this.state.zip}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Country</h5>
                <select
                  name="country"
                  value={this.state.country}
                  onChange={this.changeHandler}
                >
                  {countriesList.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                <h5 className="c-title-act">Preferred Communication</h5>
                <div className="customerInfo__AddInfo-icon">
                  <div className="modal-radioButton">
                    <label
                      htmlFor="call"
                      style={
                        this.state?.preferred_communication.includes("call")
                          ? {
                              border: "2px solid #4A9876",
                              outline: "none",
                              color: "#4A9876",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              marginRight: "13px",
                              background: "#ffffff",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              border: "none",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                      }
                    >
                      Call
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="call"
                      id="call"
                      className="radio-button"
                      onChange={this.preferredCommunicationChangeHandler}
                    />
                    <label
                      htmlFor="email"
                      style={
                        this.state?.preferred_communication.includes("email")
                          ? {
                              border: "2px solid #4A9876",
                              outline: "none",
                              color: "#4A9876",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              marginRight: "13px",
                              background: "#ffffff",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              border: "none",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                      }
                    >
                      Email
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="email"
                      id="email"
                      className="radio-button"
                      onChange={this.preferredCommunicationChangeHandler}
                    />
                    <label
                      htmlFor="text"
                      style={
                        this.state?.preferred_communication.includes("text")
                          ? {
                              border: "2px solid #4A9876",
                              outline: "none",
                              color: "#4A9876",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              marginRight: "13px",
                              background: "#ffffff",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              border: "none",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                      }
                    >
                      Text
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="text"
                      id="text"
                      className="radio-button"
                      onChange={this.preferredCommunicationChangeHandler}
                    />
                  </div>
                </div>
                <h5 className="c-title-act">Source</h5>
                <div className="customerInfo__AddInfo-icon">
                  <select
                    name="source"
                    value={this.state.source}
                    onChange={this.changeHandler}
                  >
                    <option hidden>-select-</option>
                    <option selected disabled>
                      -select-
                    </option>
                    {this.props.allAccountSources.map((source) => (
                      <option key={source.id} value={source.name}>
                        {source.name}
                      </option>
                    ))}
                  </select>
                </div>
                <h5 className="c-title-act">Birthday</h5>
                <DatePicker
                  name="date_of_birth"
                  selected={Date.parse(this.state.date_of_birth)}
                  value={Date.parse(this.state.date_of_birth)}
                  onChange={(date) => this.dobChangeHandler(date)}
                  dateFormat={
                    this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/MM/yyyy"
                      : "MM/dd/yyyy"
                  }
                  placeholderText={
                    this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/mm/yyyy"
                      : "mm/dd/yyyy"
                  }
                  autoComplete="off"
                />
              </div>
              {this?.state?.allFields?.map((field) => {
                const { label, id, fields, meta } = field;
                return (
                  <div key={id}>
                    {meta === null && (
                      <div className="collapseHeaderAccount-edit">
                        <h4 className="c-title-main-1">{label}</h4>
                        <div>
                          {fields.map((data) => {
                            const { id, label, type, choices, name } = data;
                            return (
                              <div key={id}>
                                <h5 className="c-title-act">{label}</h5>
                                <div key={id}>
                                  {this.showInput(name, label, type, choices)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="customerIntroStateChange">
              <p>Save changes to fields?</p>
              <button
                type="button"
                onClick={this.editCancel}
                className="modal__clear-1 customerIntroStateChange-cancel"
              >
                Cancel
              </button>
              <button
                disabled={this.state.isLoading}
                type="button"
                onClick={this.submitHandler}
                className="modal__addButton-1 customerIntroStateChange-save"
              >
                {this.state.isLoading ? "Saving" : "Save"}
              </button>
            </div>
          </form>
        ) : (
          <div className="customerInfo__Info">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              onOpening={() =>
                this.props.setStoredRecordCollapsible({
                  type: "contact",
                  id: "default",
                })
              }
              onClosing={() =>
                this.props.removeStoredRecordCollapsible({
                  type: "contact",
                  id: "default",
                })
              }
              open={this.props.storedRecordCollapsible.contact.includes(
                "default"
              )}
              trigger={
                <div>
                  Contact Info
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div>
                  Contact Info
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "4%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIconUp} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              {/*status*/}
              <h5 className="c-title-act">Contact Status</h5>
              <select
                className="c-icon-1"
                style={{
                  background: "#EEF6F2",
                  color: "#FFB202",
                  width: "130px",
                }}
                name="status"
                value={this.state?.status}
                onChange={(e) => {
                  this.statusChangeHandler(e);
                }}
              >
                <option value="disqualified">Disqualified</option>
                {this.props.allContactStatuses
                  ?.filter((stat) => stat.name !== "disqualified")
                  .map((stat) => (
                    <option
                      value={stat.name}
                      style={{ textTransform: "capitalize" }}
                    >
                      {stat.name}
                    </option>
                  ))}
              </select>
              <div>
                <h5 className="c-title-act">Account</h5>
                {this.state.accountName ? (
                  <p
                    className="c-desc"
                    onClick={() => {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "account",
                        id: this.state.userData.account_id,
                        name: this.state.accountName,
                      });
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#4A9876",
                      textDecoration: "underline",
                    }}
                  >
                    {this.state.accountName}
                  </p>
                ) : (
                  <p className="c-desc">-</p>
                )}
              </div>
              {/*email*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "email" })}
              >
                <h5 className="c-title-act">Email</h5>
                {showInputField === "email" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "email" })}
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={() =>
                          this.setState(
                            { showInputField: null },
                            this.fetchContactsData
                          )
                        }
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.email !== null ? (
                      <span
                        style={{
                          color: "#4A9876",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={
                          this.state.integration !== null ||
                          (this.state.postMarkId !== null &&
                            this.state.postMarkVerified)
                            ? this.handleOpenEmailModal
                            : this.handleOpenRedirectModal
                        }
                      >
                        {this.state.email}
                      </span>
                    ) : (
                      this.state.email
                    )}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "email" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {this.state.emailValidationError && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid email
                </div>
              )}
              {/*formatted_phone*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "formatted_phone" })
                }
              >
                <h5 className="c-title-act">Phone</h5>
                {showInputField === "formatted_phone" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "formatted_phone" })
                      }
                      type="tel"
                      name="formatted_phone"
                      value={this.state.formatted_phone}
                      onChange={this.handlePhoneChangeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={() =>
                          this.setState(
                            { showInputField: null },
                            this.fetchContactsData
                          )
                        }
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc  c-hoverDesc">
                    <a
                      target="_blank"
                      data-stringify-link={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      delay="150"
                      data-sk="tooltip_parent"
                      href={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      rel="noopener noreferrer"
                      tabindex="-1"
                      data-remove-tab-index="true"
                    >
                      {this.state.formatted_phone}
                    </a>{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "formatted_phone" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {this.state.phoneValidationError && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid phone number
                </div>
              )}
              {/*phone_ext*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "phone_ext" })}
              >
                <h5 className="c-title-act">Phone Extension</h5>
                {showInputField === "phone_ext" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "phone_ext" })
                      }
                      type="text"
                      name="phone_ext"
                      value={this.state.phone_ext}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={() =>
                          this.setState(
                            { showInputField: null },
                            this.fetchContactsData
                          )
                        }
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc  c-hoverDesc">
                    {this.state.phone_ext}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "phone_ext" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*formatted_mobile*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "formatted_mobile" })
                }
              >
                <h5 className="c-title-act">Mobile</h5>
                {showInputField === "formatted_mobile" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "formatted_mobile" })
                      }
                      type="tel"
                      name="formatted_mobile"
                      value={this.state.formatted_mobile}
                      onChange={this.handlePhoneChangeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={() =>
                          this.setState(
                            { showInputField: null },
                            this.fetchContactsData
                          )
                        }
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc  c-hoverDesc">
                    <a
                      target="_blank"
                      data-stringify-link={`tel:${this.state.formatted_mobile
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      delay="150"
                      data-sk="tooltip_parent"
                      href={`tel:${this.state.formatted_mobile
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      rel="noopener noreferrer"
                      tabindex="-1"
                      data-remove-tab-index="true"
                    >
                      {this.state.formatted_mobile}
                    </a>{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "formatted_mobile" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*address*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "address" })}
              >
                <h5 className="c-title-act">Address</h5>
                {showInputField === "address" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "address" })
                      }
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.address}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "address" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*city*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "city" })}
              >
                <h5 className="c-title-act">City</h5>
                {showInputField === "city" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "city" })}
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.city}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "city" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*state*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "state" })}
              >
                <h5 className="c-title-act">State</h5>
                {showInputField === "state" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    {this.state.country !== "United States" ? (
                      <input
                        autoFocus
                        className="customerIntro-inputField"
                        onFocus={() =>
                          this.setState({ showInputField: "state" })
                        }
                        type="text"
                        name="state"
                        value={this.state.state}
                        onChange={this.changeHandler}
                      />
                    ) : (
                      <select
                        className="customerIntro-inputField"
                        onFocus={() =>
                          this.setState({ showInputField: "state" })
                        }
                        name="state"
                        value={this.state.state}
                        onChange={this.changeHandler}
                      >
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option>AL</option>
                        <option>AK</option>
                        <option>AZ</option>
                        <option>AR</option>
                        <option>CA</option>
                        <option>CO</option>
                        <option>CT</option>
                        <option>DE</option>
                        <option>FL</option>
                        <option>GA</option>
                        <option>HI</option>
                        <option>ID</option>
                        <option>IL</option>
                        <option>IN</option>
                        <option>IA</option>
                        <option>KS</option>
                        <option>KY</option>
                        <option>LA</option>
                        <option>ME</option>
                        <option>MD</option>
                        <option>MA</option>
                        <option>MI</option>
                        <option>MN</option>
                        <option>MS</option>
                        <option>MO</option>
                        <option>MT</option>
                        <option>NE</option>
                        <option>NV</option>
                        <option>NH</option>
                        <option>NJ</option>
                        <option>NM</option>
                        <option>NY</option>
                        <option>NC</option>
                        <option>ND</option>
                        <option>OH</option>
                        <option>OK</option>
                        <option>OR</option>
                        <option>PA</option>
                        <option>RI</option>
                        <option>SC</option>
                        <option>SD</option>
                        <option>TN</option>
                        <option>TX</option>
                        <option>UT</option>
                        <option>VT</option>
                        <option>VA</option>
                        <option>WA</option>
                        <option>WV</option>
                        <option>WI</option>
                        <option>WY</option>
                      </select>
                    )}
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.state}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "state" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*zip*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "zip" })}
              >
                <h5 className="c-title-act">Zip/Postal</h5>
                {showInputField === "zip" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "zip" })}
                      type="text"
                      name="zip"
                      value={this.state.zip}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.zip}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "zip" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*country*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "country" })}
              >
                <h5 className="c-title-act">Country</h5>
                {showInputField === "country" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <select
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "country" })
                      }
                      name="country"
                      value={this.state.country}
                      onChange={this.changeHandler}
                    >
                      {countriesList.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.country}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "country" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*source*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "source" })}
              >
                <h5 className="c-title-act">
                  Source{" "}
                  <button
                    type="button"
                    onClick={this.handleOpenSourceModal}
                    className="account-sourceButton"
                    data-tip="Add a new source to track where a client came from."
                  >
                    +
                  </button>
                </h5>
                {showInputField === "source" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <div className="customerInfo__AddInfo-icon">
                      <select
                        className="customerIntro-inputField"
                        onFocus={() =>
                          this.setState({ showInputField: "source" })
                        }
                        name="source"
                        value={this.state.source}
                        onChange={this.changeHandler}
                      >
                        <option hidden>-select-</option>
                        <option selected disabled>
                          -select-
                        </option>
                        {this.props.allAccountSources.map((source) => (
                          <option key={source.id} value={source.name}>
                            {source.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={() =>
                          this.setState(
                            { showInputField: null },
                            this.fetchContactsData
                          )
                        }
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.source}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "source" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*preferred_communication*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "preferred_communication" })
                }
              >
                <h5 className="c-title-act">Preferred Communication</h5>
                {showInputField === "preferred_communication" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <div className="customerInfo__AddInfo-icon">
                      <div className="modal-radioButton">
                        <label
                          htmlFor="call"
                          style={
                            this.state?.preferred_communication.includes("call")
                              ? {
                                  border: "2px solid #4A9876",
                                  outline: "none",
                                  color: "#4A9876",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  marginRight: "13px",
                                  background: "#ffffff",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  border: "none",
                                  background: "#F4F6F5",
                                  marginRight: "13px",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                          }
                        >
                          Call
                        </label>
                        <input
                          type="checkbox"
                          name="preferred_communication"
                          value="call"
                          id="call"
                          className="radio-button"
                          onChange={this.preferredCommunicationChangeHandler}
                        />
                        <label
                          htmlFor="email"
                          style={
                            this.state?.preferred_communication.includes(
                              "email"
                            )
                              ? {
                                  border: "2px solid #4A9876",
                                  outline: "none",
                                  color: "#4A9876",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  marginRight: "13px",
                                  background: "#ffffff",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  border: "none",
                                  background: "#F4F6F5",
                                  marginRight: "13px",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                          }
                        >
                          Email
                        </label>
                        <input
                          type="checkbox"
                          name="preferred_communication"
                          value="email"
                          id="email"
                          className="radio-button"
                          onChange={this.preferredCommunicationChangeHandler}
                        />
                        <label
                          htmlFor="text"
                          style={
                            this.state?.preferred_communication.includes("text")
                              ? {
                                  border: "2px solid #4A9876",
                                  outline: "none",
                                  color: "#4A9876",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  marginRight: "13px",
                                  background: "#ffffff",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  border: "none",
                                  background: "#F4F6F5",
                                  marginRight: "13px",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                          }
                        >
                          Text
                        </label>
                        <input
                          type="checkbox"
                          name="preferred_communication"
                          value="text"
                          id="text"
                          className="radio-button"
                          onChange={this.preferredCommunicationChangeHandler}
                        />
                      </div>
                    </div>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={() =>
                          this.setState(
                            { showInputField: null },
                            this.fetchContactsData
                          )
                        }
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <div className="customerInfo__AddInfo-icon c-hoverDesc">
                    {this.state.preferred_communication.map((pc) => (
                      <span
                        className="c-icon-1"
                        style={{
                          border: "2px solid #4A9876",
                          outline: "none",
                          color: "#4A9876",
                          padding: "3px 12px 1px 12px",
                          borderRadius: "15px",
                          marginRight: "13px",
                          background: "#ffffff",
                          fontWeight: "normal",
                          fontSize: "13px",
                          lineHeight: "14px",
                          height: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        {pc}
                      </span>
                    ))}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "preferred_communication",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </div>
                )}
              </div>
              {/*date_of_birth*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "date_of_birth" })
                }
              >
                <h5 className="c-title-act">Birthday</h5>
                {showInputField === "date_of_birth" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <DatePicker
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "date_of_birth" })
                      }
                      name="date_of_birth"
                      selected={Date.parse(this.state.date_of_birth)}
                      value={Date.parse(this.state.date_of_birth)}
                      onChange={(date) => this.dobChangeHandler(date)}
                      dateFormat={
                        this.props.companyOnboarding?.date_format ===
                        "DD/MM/YYYY"
                          ? "dd/MM/yyyy"
                          : "MM/dd/yyyy"
                      }
                      placeholderText={
                        this.props.companyOnboarding?.date_format ===
                        "DD/MM/YYYY"
                          ? "dd/mm/yyyy"
                          : "mm/dd/yyyy"
                      }
                      autoComplete="off"
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchOpportunitiesData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.date_of_birth === null
                      ? ""
                      : moment(this.state.date_of_birth).format(dateFormat)}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "date_of_birth",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
            </Collapsible>
            {this.state?.allFields?.map((field) => {
              const { label, id, fields, meta } = field;
              return (
                <div key={id}>
                  {meta === null ? (
                    <div>
                      <Collapsible
                        className="collapseHeaderAccount"
                        classParentString="collapseHeaderAccount"
                        overflowWhenOpen="initial"
                        onOpening={() =>
                          this.props.setStoredRecordCollapsible({
                            type: "contact",
                            id,
                          })
                        }
                        onClosing={() =>
                          this.props.removeStoredRecordCollapsible({
                            type: "contact",
                            id,
                          })
                        }
                        open={this.props.storedRecordCollapsible.contact.includes(
                          id
                        )}
                        trigger={
                          <div>
                            {label}
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                padding: "3px 10px",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                outline: "none",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30%",
                                right: "2%",
                              }}
                            >
                              <img src={ArrowIcon} alt="" height="10px" />
                            </button>
                          </div>
                        }
                        triggerWhenOpen={
                          <div>
                            {label}
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                padding: "3px 10px",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                outline: "none",
                                cursor: "pointer",
                                position: "absolute",
                                top: "4%",
                                right: "2%",
                              }}
                            >
                              <img src={ArrowIconUp} alt="" height="10px" />
                            </button>
                          </div>
                        }
                        triggerClassName="c-title-main"
                        triggerOpenedClassName="c-title-main"
                      >
                        <div>
                          {fields?.map((data) => {
                            const { id, label, type, name, choices } = data;
                            return type === "date" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={() =>
                                          this.setState(
                                            { showInputField: null },
                                            this.fetchContactsData
                                          )
                                        }
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null ||
                                    this?.state?.userData?.custom_fields[
                                      name
                                    ] === undefined
                                      ? ""
                                      : moment(
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]
                                        ).format(dateFormat)}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "multi_select_dropdown" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={() =>
                                          this.setState(
                                            { showInputField: null },
                                            this.fetchContactsData
                                          )
                                        }
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ] &&
                                        this?.state?.userData?.custom_fields[
                                          name
                                        ]
                                          .replace(/;/g, ", ")
                                          .substr(1)
                                          .slice(0, -2)}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "hyperlink" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchContactsData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p
                                    className="c-desc c-hoverDesc"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      marginBottom: "15px",
                                      whiteSpace: "pre-wrap",
                                      height: "auto",
                                    }}
                                  >
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null ? (
                                      ""
                                    ) : (
                                      <a
                                        href={
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]?.startsWith("https://") ||
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]?.startsWith("http://")
                                            ? this?.state?.userData
                                                ?.custom_fields[name]
                                            : "https://" +
                                              this?.state?.userData
                                                ?.custom_fields[name]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="c-desc"
                                        style={{
                                          cursor: "pointer",
                                          color: "#4A9876",
                                        }}
                                      >
                                        {this?.state?.userData?.custom_fields[
                                          name
                                        ] &&
                                        this?.state?.userData?.custom_fields[
                                          name
                                        ].length > 40
                                          ? this?.state?.userData?.custom_fields[
                                              name
                                            ].substring(0, 39) + "..."
                                          : this?.state?.userData
                                              ?.custom_fields[name]}
                                      </a>
                                    )}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "revenue" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchOpportunitiesData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : currencySymbol.symbol +
                                        getCurrencyValue(
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]
                                        )?.toLocaleString("en-US")}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "paragraph" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchContactsData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p
                                    className="c-desc c-hoverDesc"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      marginBottom: "15px",
                                      whiteSpace: "pre-wrap",
                                      height: "auto",
                                    }}
                                  >
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ]}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={() =>
                                          this.setState(
                                            { showInputField: null },
                                            this.fetchContactsData
                                          )
                                        }
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ]}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Collapsible>
                    </div>
                  ) : null}
                </div>
              );
            })}
            {this.props.showOpportunityModal && (
              <OppModal
                contactId={this.props.contactId}
                showOpportunityModal={this.props.showOpportunityModal}
                setShowOpportunityModal={this.handleOpenModal}
                handleCloseModal={this.handleCloseModal}
                customerName={this.props.customerName}
                fetchAllOpportunitiesAgain={() =>
                  this.props.fetchAllOpportunitiesContacts(this.props.contactId)
                }
                email={this.state.email}
                formatted_mobile={this.state.formatted_mobile}
                fromRecord={true}
                showDealPortion={this.showDealPortion}
                accountIdFromContact={
                  this.state.account_id === null
                    ? this.state.oldAccountId
                    : this.state.account_id
                }
              />
            )}
            <CreateDealModal
              contactId={this.props.contactId}
              showOpportunityModal={this.state.showNewOpportunityModal}
              setShowOpportunityModal={this.handleOpenNewOpportunityModal}
              handleCloseModal={this.handleCloseNewOpportunityModal}
              customerName={this.props.customerName}
              fetchAllOpportunitiesAgain={() =>
                this.props.fetchAllOpportunitiesContacts(this.props.contactId)
              }
              email={this.state.email}
              formatted_mobile={this.state.formatted_mobile}
              fromRecord={true}
              showDealPortion={this.showDealPortion}
              accountIdFromContact={
                this.state.account_id === null
                  ? this.state.oldAccountId
                  : this.state.account_id
              }
            />
            {this.state.showDeleteAttachmentModal && (
              <DeleteAttachmentModal
                showOpportunityModal={this.state.showDeleteAttachmentModal}
                setShowOpportunityModal={this.handleOpenDeleteAttachmentModal}
                handleCloseModal={this.handleCloseDeleteAttachmentModal}
                deleteAttachmentHandler={this.deleteAttachmentHandler}
                documentId={this.state?.documentId}
              />
            )}
            {this.state.showSourceModal && (
              <SourceChangeModal
                showOpportunityModal={this.state.showSourceModal}
                setShowOpportunityModal={this.handleOpenSourceModal}
                handleCloseModal={this.handleCloseSourceModal}
                fetchAccountData={this.fetchContactsData}
                getSources={this.props.fetchAllAccountSources}
                allAccountSources={this.props.allAccountSources}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    isDealFieldsModified: state.allDealFields.isModified,
    storedRecordCollapsible: state.storedRecordCollapsible.fields,
    storedRecordSelectable: state.storedRecordSelectable.fields,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allContactStatuses: state.allContactStatuses.contactStatuses,
    allContactCustomFields: state.allContactCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    setStoredRecordCollapsible: (data) =>
      dispatch(setStoredRecordCollapsible(data)),
    setStoredRecordSelectable: (data) =>
      dispatch(setStoredRecordSelectable(data)),
    removeStoredRecordCollapsible: (data) =>
      dispatch(removeStoredRecordCollapsible(data)),
    removeStoredRecordSelectable: (data) =>
      dispatch(removeStoredRecordSelectable(data)),
    fetchAllAccountSources: (data) => dispatch(fetchAllAccountSources(data)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(ContactsIntro);
