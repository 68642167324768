import React, { Component } from "react";
import ReactModal from "react-modal";
import AccountIcon from "../../images/AccountIcon.svg";
import axios from "axios";
import { Plus } from "lucide-react";

class ContactsExistingModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    accountsContacts: [],
    transferAccount: [],
    allReportTransfer: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentDidUpdate(prevProps, prevState) {
    const contactsNotIncluded = this.props.contacts?.map(
      (contact) => contact.id
    );
    const tableShow = this.state.accountsContacts
      ?.map((contact) => {
        const { id } = contact;
        if (!contactsNotIncluded.includes(id)) {
          return id;
        }
      })
      .filter((c) => c != null);
    if (this.props.showOpportunityModal && tableShow.length === 0) {
      this.noTableShow();
    }
    if (this.props.accountId !== prevProps.accountId) {
      axios({
        method: "GET",
        url: `/accounts/${this.props.accountId}/contacts`,
      }).then((res) => {
        this.setState({ accountsContacts: res.data.contacts });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  submitHandler = () => {
    const contactsNotIncluded = this.props.contacts?.map(
      (contact) => contact.id
    );
    const contacts = this.state.accountsContacts
      .map((contact) => {
        const { id } = contact;
        if (!contactsNotIncluded.includes(id)) {
          return id;
        }
      })
      .filter((c) => c != null);
    axios({
      method: "POST",
      url: `/contacts/mappings`,
      data: {
        contact_ids: this.state.allReportTransfer
          ? contacts
          : this.state.transferAccount,
        opportunity_id: this.props.opportunityId,
      },
    }).then((res) => window.location.reload());
  };
  allReportTransfer = () => {
    this.setState({
      allReportTransfer: !this.state.allReportTransfer,
      transferAccount: [],
    });
  };
  transferAccountChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.name === "transferAccount") {
      if (e.target.checked === true) {
        this.setState((prevState) => ({
          transferAccount: [
            ...this.state.transferAccount.filter((com) => com !== ""),
            value,
          ],
        }));
      } else {
        this.setState({
          transferAccount: this.state.transferAccount.filter(
            (pc) => pc !== value
          ),
        });
      }
    }
  };
  noTableShow = () => {
    this.props.handleOpenContactCreateModal();
    this.props.handleCloseModal();
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const contactsNotIncluded = this.props.contacts?.map(
      (contact) => contact.id
    );
    const tableShow = this.state.accountsContacts
      ?.map((contact) => {
        const { id } = contact;
        if (!contactsNotIncluded.includes(id)) {
          return id;
        }
      })
      .filter((c) => c != null);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div className="modal__content" style={{ width: "30vw" }}>
              <div className="modal__header">
                <span>
                  <div className="customerInfo-icon">
                    <img
                      src={AccountIcon}
                      alt=""
                      width="17px"
                      style={{ transform: "translateY(9px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Add Contacts</h2>
                  <p className="modal__heading-desc">
                    Add existing contact(s) into a deal
                  </p>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    height: "12px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form
                style={{
                  maxHeight: "66vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {tableShow.length === 0 ? (
                  () => this.noTableShow()
                ) : (
                  <table className="accountList__tableReport">
                    <thead>
                      <tr>
                        <th style={{ width: "8px" }}>
                          <input
                            type="checkbox"
                            style={{ width: "12px", height: "12px" }}
                            onClick={this.allReportTransfer}
                          />
                        </th>
                        <th>Name</th>
                        <th>Job Title</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.accountsContacts?.map((contact) => {
                        const { id, name, role } = contact;
                        if (!contactsNotIncluded.includes(id))
                          return (
                            <tr key={id}>
                              <td>
                                {this.state.allReportTransfer ? (
                                  <input
                                    type="checkbox"
                                    style={{ width: "12px", height: "12px" }}
                                    name="transferAccount"
                                    value={parseInt(id)}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    style={{ width: "12px", height: "12px" }}
                                    name="transferAccount"
                                    value={parseInt(id)}
                                    onClick={this.transferAccountChangeHandler}
                                  />
                                )}
                              </td>
                              <td>{name}</td>
                              <td>{role}</td>
                            </tr>
                          );
                      })}
                    </tbody>
                  </table>
                )}
                <div className="modal__buttons">
                  <button
                    onClick={() => {
                      this.props.handleOpenContactCreateModal();
                      this.props.handleCloseModal();
                    }}
                    type="button"
                    className="contact-existing-button"
                  >
                    Add a new Contact
                    <Plus
                      size={14}
                      color="#62CA9D"
                      strokeWidth={3}
                      style={{
                        transform: "translateY(2px)",
                        marginLeft: "5px",
                      }}
                    />
                  </button>
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                    style={{ right: "30%", left: "auto" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={this.submitHandler}
                    className="modal__confirm-button"
                    disabled={
                      !this.state.allReportTransfer &&
                      this.state.transferAccount.length === 0
                    }
                    style={
                      !this.state.allReportTransfer &&
                      this.state.transferAccount.length === 0
                        ? {
                            background: "#E5E5E5",
                            color: "white",
                            cursor: "not-allowed",
                          }
                        : {}
                    }
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default ContactsExistingModal;
