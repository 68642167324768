import React from "react";
import "../../sass/DropdownHeader.scss";
import moment from "moment";

class DropdownAnalyticsFilter extends React.Component {
  state = {
    displayMenu: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  render() {
    const selectedDateFilter =
      this.props.selectedDateFilter === "custom_range"
        ? `${moment(this.props.fromDate).format("l")} to ${moment(
            this.props.toDate
          ).format("l")}`
        : this.props.dateFilters.find(
            (date) => date.value === this.props.selectedDateFilter
          ).label;
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownAccount"
        style={
          this.props.selectedDateFilter === "custom_range"
            ? {
                background: "transparent",
                width: "165px",
              }
            : {
                background: "transparent",
                width: "120px",
              }
        }
      >
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={
            this.props.selectedDateFilter === "custom_range"
              ? {
                  fontSize: "15px",
                  width: "165px",
                  fontWeight: "500",
                }
              : {
                  fontSize: "15px",
                  width: "120px",
                  fontWeight: "500",
                }
          }
        >
          {selectedDateFilter}{" "}
          <i
            className="fa fa-caret-down"
            aria-hidden="true"
            style={{ color: "#000" }}
          ></i>
        </div>

        {this.state.displayMenu ? (
          <ul style={{ width: "141px", top: "30px", left: "0" }}>
            {this.props.dateFilters.map((date) => (
              <li
                value={date.value}
                key={date.value}
                onClick={() => {
                  this.props.dateFilterChangeHandler(date.value);
                  this.setState({
                    displayMenu: false,
                  });
                }}
                style={
                  this.props.selectedDateFilter === date.value
                    ? {
                        width: "125px",
                        cursor: "pointer",
                        background: "#4A9876",
                        color: "white",
                        fontSize: "14px",
                      }
                    : { width: "125px", cursor: "pointer", fontSize: "14px" }
                }
              >
                {date.label}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
}

export default DropdownAnalyticsFilter;
