import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../sass/Reports.scss";
import moment from "moment";

const CampaignPage = (props) => {
  const [campaign, setCampaign] = useState([]);
  const getCampaign = () => {
    axios({
      method: "GET",
      url: `/email-campaigns/${props.campaignId}`,
    }).then((res) => setCampaign(res.data.activities));
  };
  useEffect(() => getCampaign(), []);
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>{props.campaignName}</h2>
          <p> </p>
        </div>
      </div>
      <table
        className="accountList__table"
        style={{ marginTop: "20px", width: "93vw" }}
      >
        <thead>
          <tr>
            <th>Record</th>
            <th>Email</th>
            <th>Subject</th>
            <th>Delivered At</th>
            <th>Opened</th>
            <th>First Opened At</th>
            <th>Clicked</th>
            <th>bounced</th>
            <th>replied</th>
          </tr>
        </thead>
        <tbody>
          {campaign?.map((cam) => {
            const {
              id,
              to_email,
              delivered_at,
              clicked,
              bounced,
              replied,
              account_name,
              contact_name,
              opportunity_name,
              subject,
              opened,
              first_opened_at,
            } = cam;
            return (
              <tr key={id}>
                <td>{account_name || contact_name || opportunity_name}</td>
                <td>{to_email}</td>
                <td>{subject}</td>
                <td>{moment(delivered_at).format("lll")}</td>
                <td>{opened ? "Yes" : "No"}</td>
                <td>{moment(first_opened_at).format("lll")}</td>
                <td>{clicked ? "Yes" : "No"}</td>
                <td>{bounced ? "Yes" : "No"}</td>
                <td>{replied ? "Yes" : "No"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CampaignPage;
