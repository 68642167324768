import React, { Component } from "react";
import ReactModal from "react-modal";
import "../sass/CustomerModal.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AccountIcon from "../images/AccountIcon.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { countriesList } from "../constants/countriesList";
import { colourStyles } from "../constants/selectStyles";
import { usStatesList } from "../constants/usStatesList";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../redux";
import {
  isValidCurrency,
  getCurrencyValue,
} from "../utils/Helper/reusableFunctions";
import { MenuItem, Modal } from "@mui/material";
import InputField from "./Reusables/InputField/InputField";
import MuiSelect from "./Reusables/Select/MuiSelect";
import MuiButton from "./Reusables/Button/MuiButton";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import { muiModalStyle } from "../constants/muiModalStyle";


class CustomerModal extends Component {
  state = {
    fieldsData: [],
    name: null,
    state: null,
    source: null,
    zip: null,
    city: null,
    address: null,
    email: null,
    formatted_phone: null,
    phone_ext: null,
    website: null,
    linkedin: null,
    description: null,
    accountOwner: null,
    sharedAccountOwners: [],
    country: "United States",
    preferred_communication: [],
    frontendPreferredCommunicationArray: [],
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    phoneValidationError: false,
    emailValidationError: false,
    customFieldRevenueTypeCheck: [],
    menuOpen: "bottom",
    requiredFields: {
      name: { required: true },
      phone: { required: false },
      phone_ext: { required: false },
      email: { required: false },
      address: { required: false },
      city: { required: false },
      state: { required: false },
      zip: { required: false },
      country: { required: false },
      preferred_communication: { required: false },
      source: { required: false },
      website: { required: false },
      linkedin: { required: false },
      description: { required: false },
    },
    isHovered: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      name: null,
      state: null,
      source: null,
      zip: null,
      city: null,
      address: null,
      email: null,
      formatted_phone: null,
      phone_ext: null,
      website: null,
      linkedin: null,
      description: null,
      preferred_communication: [],
      frontendPreferredCommunicationArray: [],
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      isLoading: false,
      phoneValidationError: false,
      emailValidationError: false,
    });
  };
  fetchAccountData = () => {
    this.setState({
      fieldsData: this.props.allAccountCustomFields,
    });
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  fetchRequiredFields = () => {
    axios({
      method: "GET",
      url: `/admin/settings`,
    }).then((res) =>
      this.setState({
        requiredFields: res.data.settings.account,
      })
    );
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("mousemove", this.mouseCoordinates, {
      passive: true,
    });
    this.fetchAccountData();
    this.fetchRequiredFields();
    this.setState({
      accountOwner: this.props.authUser.id,
      country: this.props.companyOnboarding?.country
        ? this.props.companyOnboarding?.country
        : "United States",
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.fetchAccountData();
      this.fetchRequiredFields();
      this.setState({
        accountOwner: this.props.authUser.id,
        country: this.props.companyOnboarding?.country
          ? this.props.companyOnboarding?.country
          : "United States",
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  changeHandler = (e) => {
    const { value } = e.target;
    if (e.target.name === "preferred_communication") {
      if (e.target.checked === true) {
        this.setState({
          preferred_communication: [
            ...this.state.preferred_communication,
            value,
          ],
        });
      } else {
        this.setState({
          preferred_communication: this.state.preferred_communication.filter(
            (pc) => pc !== value
          ),
        });
      }
    } else if (e.target.name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      const formattedValue = formatter.input(value); // Format the input value
      this.setState({
        [e.target.name]: formattedValue ? formattedValue : null,
      });
    } else if (e.target.name === "accountOwner") {
      this.setState({
        accountOwner: e.target.value === "" ? null : e.target.value,
        sharedAccountOwners: [],
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value === "" ? null : e.target.value,
      });
    }
  };
  changeHandlerSelect = (name, value) => {
    this.setState({
      [name]: value.value,
    });
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  accountOwnerMultiDropdownChangeHandler = (selectedOptions) => {
    this.setState((prevState) => ({
      sharedAccountOwners: selectedOptions,
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    // const phoneValidator = /[^a-zA-Z]/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    // if (
    //   this.state.formatted_phone !== null &&
    //   this.state.formatted_phone !== "" &&
    //   !phoneValidator.test(this.state.formatted_phone)
    // ) {
    //   this.setState({
    //     isLoading: false,
    //     phoneValidationError: true,
    //   });
    //   return false;
    // }
    // if (
    //   this.state.formatted_phone !== null &&
    //   this.state.formatted_phone !== "" &&
    //   phoneValidator.test(this.state.formatted_phone)
    // ) {
    //   this.setState({
    //     phoneValidationError: false,
    //   });
    // }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      emailValidator.test(this.state.email)
    ) {
      this.setState({
        emailValidationError: false,
      });
    }
    const data = this.state;
    if (data.name === null || data.name === "") {
      this.setState({ errorMessage: true });
      return;
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/accounts/create`,
      data: {
        owners:
          !this.state.accountOwner ||
          this.state.accountOwner === "Unassigned Leads"
            ? []
            : this.state.sharedAccountOwners
                ?.map((user) => ({
                  is_primary: false,
                  owner_id: user.value,
                }))
                .concat([
                  {
                    is_primary: true,
                    owner_id: this.state.accountOwner,
                  },
                ]),
        name: this.state.name,
        source: this.state.source,
        state: this.state.state,
        zip: this.state.zip,
        city: this.state.city,
        address: this.state.address,
        country:
          this.state.country === null ? "United States" : this.state.country,
        email: this.state.email,
        formatted_phone: this.state.formatted_phone,
        phone_ext: this.state.phone_ext,
        website:
          this.state.website === null
            ? null
            : this.state.website.startsWith("https://") ||
              this.state.website.startsWith("http://")
            ? this.state.website
            : "https://" + this.state.website,
        linkedin:
          this.state.linkedin === null
            ? null
            : this.state.linkedin.startsWith("https://") ||
              this.state.linkedin.startsWith("http://")
            ? this.state.linkedin
            : "https://" + this.state.linkedin,
        description:
          this.state.description === "" ? null : this.state.description,
        preferred_communication: this.state.preferred_communication,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "account",
          id: response.data.account.account_id,
          name: response.data.account.name,
          phone: response.data.account.formatted_phone,
        };

        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
        this.handleCloseModal();
      })
      .then((response) => {
        NotificationManager.success("Account created successfully!");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating account.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => {
        this.fetchAccountData();
      });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired
  ) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldRevenueChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
            multiline={true}
          />
        </div>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="number"
            label={fieldLabel}
            name={fieldName}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
        >
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            menuPlacement={this.state.menuOpen}
            required={fieldRequired}
            value={{
              value: this.state.customFields[fieldName],
              label: this.state.customFields[fieldName],
            }}
            placeholder="-Select-"
            onChange={(selected) =>
              this.customFieldSelectChangeHandler(fieldName, selected.value)
            }
            isClearable={false}
            isSearchable={true}
            name={fieldName}
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value, label: value };
            })}
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px", marginBottom: "15px" }}>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left", marginBottom: "15px" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "15px" }}
        >
          <DatePicker
            name={fieldName}
            className="datePicker"
            selected={Date.parse(this.state.customFields[fieldName])}
            value={Date.parse(this.state.customFields[fieldName])}
            onChange={(date) =>
              this.customFieldDateChangeHandler(fieldName, date)
            }
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            placeholderText={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/mm/yyyy"
                : "mm/dd/yyyy"
            }
            autoComplete="off"
            required={fieldRequired}
            popperPlacement="top"
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      let options = [];
      if (
        fieldName === "cf_equipment_type" ||
        fieldName === "cf_equipment_types"
      ) {
        options = this.props.allEquipmentTypes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_special_requirements") {
        options = this.props.allSpecialRequirements?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_modes") {
        options = this.props.allModes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else {
        options = fieldChoices.map((choice) => {
          const { value } = choice;
          return { value, label: value };
        });
      }
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "5px" }}
        >
          <Select
            value={multiSelectDropdownValue}
            closeMenuOnSelect={false}
            menuPlacement={this.state.menuOpen}
            isMulti
            name={fieldName}
            styles={colourStyles}
            options={options}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            required={fieldRequired}
            onChange={(selectedOption) =>
              this.customFieldMultiDropdownChangeHandler(
                fieldName,
                selectedOption
              )
            }
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    }
    return (
      <div>
        {inputType}
      </div>
    );
  };
  render() {
    const {
      name,
      source,
      state,
      zip,
      city,
      address,
      email,
      formatted_phone,
      phone_ext,
      website,
      linkedin,
      description,
      country,
    } = this.state;
    const { fieldsData } = this.state;


    // const modalStyle = {
    //   content: {
    //     background: "transparent",
    //     border: "none",
    //     width: "0px",
    //   },
    // };



    return (
      <Modal
        open={this.props.showOpportunityModal}
        contentLabel="onRequestClose Example"
        onClose={this.handleCloseModal}
        ariaHideApp={false}
        style={muiModalStyle}
      >
        <div className="muiModal">
          <div className="modal__content">
            <div className="modal__header">
              <span>
                <NotificationContainer />
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Create Account</h2>
              </div>
              <button
                onClick={this.handleCloseModal}
                className="modal__close"
                style={{
                  border: "none",
                  background: "transparent",
                  height: "12px",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                {" "}
                <ClearIcon fontSize="small" />
              </button>
            </div>
            <form
              className="modal__form"
              style={{
                overflowY: "scroll",
                maxHeight: "60vh",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div style={{ marginBottom: "0px", marginTop: "15px" }}>
                <MuiSelect
                  label={this.props.companyOnboarding
                  ?.is_multiple_account_owner_enabled ? "Primary Account Owner *" : "Account Owner *"}
                  value={this.state.accountOwner}
                  onChange={this.changeHandler}
                  name="accountOwner"
                  menuItem={[
                    ...(this.props.authUser.permissions.includes(
                      "crm:unassigned:leads:viewer"
                    ) || this.props.authUser.role === "SUPERADMIN"
                      ? [
                          <MenuItem value="Unassigned Leads">
                            Unassigned Leads
                          </MenuItem>,
                        ]
                      : []),
                    ...(this.props.allUsers?.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    )) || []),
                  ]}
                />
              </div>

              {/* <label>
                {this.props.companyOnboarding
                  ?.is_multiple_account_owner_enabled && "Primary "}
                Account Owner{" "}
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              </label> */}
              {/* <select
                value={this.state.accountOwner}
                onChange={this.changeHandler}
                name="accountOwner"
                style={{ width: "99%" }}
              >
                {(this.props.authUser.permissions.includes(
                  "crm:unassigned:leads:viewer"
                ) ||
                  this.props.authUser.role === "SUPERADMIN") && (
                  <option value="">Unassigned Leads</option>
                )}
                {this.props.allUsers?.map((user) => {
                  const { id, name } = user;
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select> */}

              {this.state.accountOwner &&
                this.props.companyOnboarding
                  ?.is_multiple_account_owner_enabled && (
                  <div
                    className="controlsForms"
                    style={{
                      width: "100%",
                      marginBottom: "15px",
                    }}
                  >
                    <Select
                      value={this.state.sharedAccountOwners}
                      closeMenuOnSelect={false}
                      menuPlacement={this.state.menuOpen}
                      isMulti
                      name={"accountOwner"}
                      styles={colourStyles}
                      options={this.props.allUsers
                        ?.filter(
                          (user) =>
                            user.id?.toString() !==
                            this.state.accountOwner?.toString()
                        )
                        ?.map((user) => ({
                          value: user.id,
                          label: user.name,
                        }))}
                      placeholder="-Select-"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required={true}
                      onChange={(selectedOption) =>
                        this.accountOwnerMultiDropdownChangeHandler(
                          selectedOption
                        )
                      }
                    />
                    <label className="activeLabel">Shared Account Owners</label>
                  </div>
                )}

              <div style={{ marginBottom: "15px" }}>
                <InputField
                  label="Account Name *"
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.changeHandler}
                  error={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                  }
                  helperText="Name is required"
                />
              </div>

              {/* <label>
                Account Name{" "}
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                value={name}
                // required
                onChange={this.changeHandler}
                style={
                  this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {}
                }
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.name === null || this.state.name === "")
                  ? "Name is required"
                  : null}
              </div> */}

              <div style={{ marginBottom: "15px" }}>
                <InputField
                  label="Phone number"
                  type="tel"
                  name="formatted_phone"
                  value={formatted_phone}
                  onChange={this.changeHandler}
                  error={this.state.phoneValidationError}
                  helperText="Please enter a valid phone number"
                  required={this.state.requiredFields?.["phone"]?.required}
                />
              </div>
              {/* <label>
                Phone number{" "}
                {this.state.requiredFields?.["phone"]?.required && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
              <input
                type="tel"
                name="formatted_phone"
                value={formatted_phone}
                onChange={this.changeHandler}
                required={this.state.requiredFields?.["phone"]?.required}
              />
              {this.state.phoneValidationError && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid phone number
                </div>
              )} */}

              <div style={{ marginBottom: "15px" }}>
                <InputField
                  label="Phone Extension"
                  type="text"
                  name="phone_ext"
                  value={phone_ext}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields?.["phone_ext"]?.required}
                />
              </div>
              {/* <label>
                Phone Extension{" "}
                {this.state.requiredFields?.["phone_ext"]?.required && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
              <input
                type="text"
                name="phone_ext"
                value={phone_ext}
                onChange={this.changeHandler}
                required={this.state.requiredFields?.["phone_ext"]?.required}
              /> */}

              <div style={{ marginBottom: "15px" }}>
                <InputField
                  label="Email"
                  pattern='/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.changeHandler}
                  error={this.state.emailValidationError}
                  helperText="Please enter a valid email"
                  required={this.state.requiredFields["email"].required}
                />
              </div>
              {/* 
              <label>
                Email{" "}
                {this.state.requiredFields["email"].required && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
              <input
                pattern='/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
                type="email"
                name="email"
                value={email}
                onChange={this.changeHandler}
                required={this.state.requiredFields["email"].required}
              />
              {this.state.emailValidationError && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid email
                </div>
              )} */}
              {/* <h3 className="modal__heading-sub">Work Address</h3> */}

              <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                <InputField
                  label="Address"
                  type="text"
                  name="address"
                  value={address}
                  onChange={this.changeHandler}
                  helperText="Please enter a valid email"
                  required={this.state.requiredFields["address"].required}
                />
              </div>

              {/* <label>
                Address{" "}
                {this.state.requiredFields["address"].required && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
              <input
                type="text"
                name="address"
                value={address}
                onChange={this.changeHandler}
                required={this.state.requiredFields["address"].required}
              /> */}
              <div className="modal__address">
                <div className="fieldSizing">
                  <InputField
                    label="City"
                    type="text"
                    name="city"
                    value={city}
                    onChange={this.changeHandler}
                    className="modal__address-city"
                    required={this.state.requiredFields["city"].required}
                  />
                  {/* <label>
                    City{" "}
                    {this.state.requiredFields["city"].required && (
                      <span
                        style={{
                          color: "#4A9876",
                          fontSize: "17px",
                          lineHeight: "16px",
                        }}
                      >
                        *
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={city}
                    onChange={this.changeHandler}
                    className="modal__address-city"
                    required={this.state.requiredFields["city"].required}
                  /> */}
                </div>
                <div>
                  {/* <label>
                    State{" "}
                    {this.state.requiredFields["state"].required && (
                      <span
                        style={{
                          color: "#4A9876",
                          fontSize: "17px",
                          lineHeight: "16px",
                        }}
                      >
                        *
                      </span>
                    )}
                  </label> */}
                  {/* <input
                      type="text"
                      name="state"
                      value={state}
                      onChange={this.changeHandler}
                      className="modal__address-state"
                      required={this.state.requiredFields["state"].required}
                    /> */}
                  {this.state.country !== "United States" ? (
                    <div
                      className="fieldSizing"
                      style={{ marginRight: "10px", marginBottom: "15px" }}
                    >
                      <InputField
                        label="State"
                        type="text"
                        name="state"
                        value={state}
                        onChange={this.changeHandler}
                        className="modal__address-state"
                        required={this.state.requiredFields["state"].required}
                      />
                    </div>
                  ) : (
                    <div style={{ marginRight: "12px" }}>
                      <MuiSelect
                        name="state"
                        value={state}
                        label="State"
                        onChange={this.changeHandler}
                        className="modal__address-state"
                        placeholder="State"
                        style={{
                          width: "80px",
                        }}
                        menuItem={usStatesList.map((list) => (
                          <MenuItem key={list} value={list}>
                            {list}
                          </MenuItem>
                        ))}
                        required={this.state.requiredFields["state"].required}
                      />
                    </div>
                  )}
                </div>
                <div className="fieldSizing">
                  <InputField
                    label="Zip/Postal"
                    type="text"
                    name="zip"
                    value={zip}
                    onChange={this.changeHandler}
                    className="modal__address-zip"
                    required={this.state.requiredFields["zip"].required}
                  />
                  {/* <label>
                    Zip/Postal{" "}
                    {this.state.requiredFields["zip"].required && (
                      <span
                        style={{
                          color: "#4A9876",
                          fontSize: "17px",
                          lineHeight: "16px",
                        }}
                      >
                        *
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="zip"
                    value={zip}
                    onChange={this.changeHandler}
                    className="modal__address-zip"
                    required={this.state.requiredFields["zip"].required}
                  /> */}
                </div>
              </div>

              <div style={{ marginBottom: "0px" }}>
                <MuiSelect
                  label="Country"
                  name="country"
                  value={country}
                  onChange={(selected) =>
                    this.changeHandlerSelect("country", selected.target)
                  }
                  menuItem={countriesList?.map((country, index) => (
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      key={country + index}
                      value={country}
                    >
                      {country}
                    </MenuItem>
                  ))}
                />
              </div>
              {/* <label>
                Country{" "}
                {this.state.requiredFields["country"].required && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                styles={colourStyles}
                menuPlacement={this.state.menuOpen}
                value={{
                  value: country,
                  label: country,
                }}
                placeholder="-Select-"
                onChange={(selected) =>
                  this.changeHandlerSelect("country", selected)
                }
                isClearable={false}
                isSearchable={true}
                options={countriesList.map((country) => ({
                  value: country,
                  label: country,
                }))}
              /> */}
              {/* <h3 className="modal__heading-sub">Additional Info</h3> */}

              {/* <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                <label>
                  Preferred Communication{" "}
                  {this.state.requiredFields["preferred_communication"]
                    .required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <div
                  className="modal-radioButton"
                  style={{ marginTop: "10px" }}
                >
                  <label
                    htmlFor="call"
                    style={
                      this.state?.preferred_communication.includes("call")
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            cursor: "pointer",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                            cursor: "pointer",
                          }
                    }
                  >
                    Call
                  </label>
                  <input
                    type="checkbox"
                    name="preferred_communication"
                    value="call"
                    id="call"
                    className="radio-button"
                    onChange={this.changeHandler}
                    required={
                      this.state.requiredFields["preferred_communication"]
                        .required
                    }
                  />
                  <label
                    htmlFor="email"
                    style={
                      this.state?.preferred_communication.includes("email")
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                            cursor: "pointer",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                            cursor: "pointer",
                          }
                    }
                  >
                    Email
                  </label>
                  <input
                    type="checkbox"
                    name="preferred_communication"
                    value="email"
                    id="email"
                    className="radio-button"
                    onChange={this.changeHandler}
                    required={
                      this.state.requiredFields["preferred_communication"]
                        .required
                    }
                  />
                  <label
                    htmlFor="text"
                    style={
                      this.state?.preferred_communication.includes("text")
                        ? {
                            border: "2px solid #A0DFC4",
                            outline: "none",
                            color: "#555555",
                            padding: "4px 12px",
                            borderRadius: "9px",
                            marginRight: "13px",
                            background: "#F4F6F5",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                            cursor: "pointer",
                          }
                        : {
                            color: "#555555",
                            padding: "4px 12px",
                            border: "none",
                            borderRadius: "9px",
                            background: "#F4F6F5",
                            marginRight: "13px",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "16px",
                            cursor: "pointer",
                          }
                    }
                  >
                    Text
                  </label>
                  <input
                    type="checkbox"
                    name="preferred_communication"
                    value="text"
                    id="text"
                    className="radio-button"
                    onChange={this.changeHandler}
                    required={
                      this.state.requiredFields["preferred_communication"]
                        .required
                    }
                  />
                </div>
              </div> */}
              <div>
                <div style={{ marginBottom: "0px" }}>
                  <MuiSelect
                    label="Source"
                    className="basic-single"
                    value={source}
                    onChange={(selected) =>
                      this.changeHandlerSelect("source", selected.target)
                    }
                    required={this.state.requiredFields["source"].required}
                    menuItem={this.props.allAccountSources?.map(
                      (source, index) => (
                        <MenuItem
                          sx={{ fontSize: "14px" }}
                          key={source.name + index}
                          value={source.name}
                        >
                          {source.name}
                        </MenuItem>
                      )
                    )}
                  />
                </div>
                {/* <label>
                  Source{" "}
                  {this.state.requiredFields["source"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  menuPlacement={this.state.menuOpen}
                  styles={colourStyles}
                  value={{
                    value: source,
                    label: source,
                  }}
                  placeholder="-Select-"
                  onChange={(selected) =>
                    this.changeHandlerSelect("source", selected)
                  }
                  isClearable={false}
                  isSearchable={true}
                  options={this.props.allAccountSources?.map((source) => ({
                    value: source.name,
                    label: source.name,
                  }))}
                /> */}
              </div>
              <div style={{ marginBottom: "15px" }}>
                <InputField
                  label="Website"
                  type="text"
                  name="website"
                  value={website}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["website"].required}
                />
                {/* <label>
                  Website{" "}
                  {this.state.requiredFields["website"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="website"
                  value={website}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["website"].required}
                /> */}
              </div>
              <div style={{ marginBottom: "15px" }}>
                <InputField
                  label="Linkedin"
                  type="text"
                  name="linkedin"
                  value={linkedin}
                  onChange={this.changeHandler}
                />
                {/* <label>Linkedin </label>
                <input
                  type="text"
                  name="linkedin"
                  value={linkedin}
                  onChange={this.changeHandler}
                /> */}
              </div>

              <InputField
                label="Description"
                name="description"
                value={description}
                onChange={this.changeHandler}
                multiline={true}
                required={this.state.requiredFields["description"].required}
              />
              {/* <label>
                Description{" "}
                {this.state.requiredFields["description"].required && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
              <textarea
                name="description"
                value={description}
                onChange={this.changeHandler}
                style={{ fontFamily: "inherit" }}
                required={this.state.requiredFields["description"].required}
              ></textarea> */}

              <div style={{ marginTop: "15px" }}>
                {fieldsData.map((group) => {
                  const { label, fields, meta, id } = group;
                  return (
                    <div key={id}>
                      {meta === null ? (
                        <div>
                          <h3 className="modal__heading-sub">{label}</h3>
                          {fields?.map((field) => {
                            const { id, label, type, choices, name, required } =
                              field;
                            return (
                              <div key={id}>
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  required
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </form>
            <div className="modal__buttons">
              <button
                onClick={this.handleCloseModal}
                type="reset"
                style={{ bottom: "10px", left: "5px" }}
                className="modal__cancel-button"
              >
                Cancel
              </button>
              <MuiButton
                type="button"
                className="saveBtn"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  width: "auto",
                  height: "35px",
                  color: "white",
                  backgroundColor: "#252a2d",
                }}
                disabled={this.state.isLoading}
                onClick={this.submitHandler}
                loading={this.state.isLoading}
                endIcon={
                  <AddIcon
                    style={{
                      color: this.state.isHovered ? "black" : "#62CA9D",
                    }}
                  />
                }
                onMouseEnter={() => {
                  this.setState({ isHovered: true });
                }}
                onMouseLeave={() => {
                  this.setState({ isHovered: false });
                }}
              >
                {this.state.isLoading ? "Creating..." : "Create"}{" "}
              </MuiButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountCustomFields: state.allAccountCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(withRouter(CustomerModal));
