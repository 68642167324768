import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import moment from "moment";
import { debounce } from "lodash";
import { Col, Row } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
import DisqualifyContactModal from "../DisqualifyContactModal";
import DisqualifyModal from "../DisqualifyModal";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  setReportsFilters,
} from "../../redux";

const LeadFunnelReport = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [showDisqualifyModal, setShowDisqualifyModal] = useState(false);
  const [showContactDisqualifyModal, setShowContactDisqualifyModal] =
    useState(false);
  const [leadId, setLeadId] = useState("");
  const [query, setQuery] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sortDirection, setSortDirection] = useState(true);
  const [sortKey, setSortKey] = useState(null);
  const [isApplying, setIsApplying] = useState(true);
  const [status, setStatus] = useState(props.status || "");
  const [statuses, setStatuses] = useState([]);
  const [inputFocused, setInputFocused] = useState({
    isTrue: false,
    id: "",
    name: "",
  });
  const [showNameInput, setShowNameInput] = useState({
    isTrue: false,
    id: "",
  });
  const [user_ids, setUser_ids] = useState(props.userId);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.userName);

  const getAllStatuses = () => {
    const newAllStatuses =
      props.type === "account"
        ? props.allAccountStatuses
        : props.allContactStatuses;
    setStatuses(newAllStatuses);
  };

  useEffect(() => {
    setStatus(props.status);
    getAllStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);
  const logCall = (description, selectedContact) => {
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: `${description?.direction} Call`,
        description: `${description?.direction} Call made at ${Date(
          description?.startTime
        )} to <span style="color: #4A9876;">${
          description?.to?.phoneNumber
        }</span>`,
        category_id: 1,
        ...(props.type === "contact" && { contact_id: selectedContact.id }),
        ...(props.type !== "contact" && { account_id: selectedContact.id }),
        type: "activity_logged",
      },
    }).then((res) => {
      NotificationManager.success("Call logged.");
    });
  };
  const handleClickToDial = (e) => {
    const data = e.data;
    if (
      data &&
      data.type === "rc-active-call-notify" &&
      data.call &&
      data.call.terminationType === "final"
    ) {
      const selectedContact = accounts?.find((table) =>
        data.call.to.phoneNumber.includes(table.formatted_phone)
      );
      if (selectedContact) {
        logCall(data.call, selectedContact);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("message", (e) => handleClickToDial(e));
    return window.removeEventListener("message", () => {});
  }, []);

  const fetchAccounts = debounce(
    (
      userIds = user_ids,
      query,
      page = 1,
      sortKey,
      sortDirection,
      statusParam = props.status
    ) => {
      console.log("statusParams", statusParam, props.status);
      let url = `/dashboard/${props.type}-leads?_limit=30&_page=${page}`;
      if (statusParam !== "All") {
        if (!statusParam && props.status === "All") {
        } else if (statusParam === "AllActiveLeads") {
          const mappedStatus =
            statuses.length === 0
              ? props.type === "account"
                ? props.allAccountStatuses
                : props.allContactStatuses
              : statuses;
          const activeLeads = mappedStatus?.filter(
            (stat) =>
              stat.name.toLowerCase() !== "long-term" &&
              stat.name.toLowerCase() !== "client" &&
              stat.name.toLowerCase() !== "disqualified"
          );
          const activeLeadsJoin = activeLeads
            ?.map((lead) => lead.name)
            .join(",");
          url += `&status=${activeLeadsJoin}`;
        } else if (!statusParam) {
          url += `&status=${props.status}`;
        } else {
          url += `&status=${statusParam}`;
        }
      }
      if (userIds) {
        url += `&user_ids=${userIds}`;
      }
      if (query) {
        url += `&name=${encodeURIComponent(query)}`;
      }
      if (sortKey) {
        const dir = sortDirection === true ? "desc" : "asc";
        url += `&sort_key=${
          sortKey === "created_date" ? "created_at" : sortKey
        }&sort_dir=${dir}`;
      }
      props.setReportsFilters({
        id: props.leadId,
        filters: {
          query,
          activePage: page,
          sortKey,
          sortDirection,
          statusParam,
        },
      });
      setIsApplying(true);
      axios({
        method: "GET",
        url,
      })
        .then((response) => {
          setAccounts(response.data.leads);
          setTotal(response.data.total);
          setIsApplying(false);
        })
        .catch((error) => setIsApplying(false));
    },
    500
  );
  const fetchAllReportsFilters = () => {
    const selectedReport = props.reportsFilters?.find(
      (report) => report.id === props.leadId
    );
    if (props.reportsFilters && selectedReport) {
      const {
        query: reportQuery,
        activePage: reportActivePage,
        sortKey: reportSortKey,
        sortDirection: reportSortDirection,
        statusParam: reportStatusParam,
      } = selectedReport.filters;
      setQuery(reportQuery);
      setActivePage(reportActivePage);
      setSortDirection(reportSortKey);
      setSortKey(reportSortDirection);
      setStatus(reportStatusParam);
      fetchAccounts(
        user_ids,
        reportQuery,
        reportActivePage,
        reportSortKey,
        reportSortDirection,
        reportStatusParam
      );
    } else {
      fetchAccounts();
    }
  };
  useEffect(() => {
    fetchAllReportsFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableHeaders = [
    { value: "name", label: "Name", editable: true, type: "text" },
    { value: "type", label: "type", editable: false, type: "text" },
    { value: "status", label: "status", editable: true, type: "dropdown" },
    { value: "email", label: "email", editable: true, type: "text" },
    {
      value: "formatted_phone",
      label: "phone",
      editable: true,
      type: "text",
    },
    {
      value: "created_at",
      label: "created date",
      editable: false,
      type: "date",
    },
    {
      value: "last_activity_date",
      label: "last activity date",
      editable: false,
      type: "date",
    },
  ];
  const renderTableData = (contactData, tableData, name, value, dateFormat) => {
    let newTabData = {
      type: props.type,
      id: contactData.id,
      name: contactData.name,
    };
    let renderItem = <td>{value}</td>;
    if (!tableData.editable) {
      if (tableData.type === "date") {
        renderItem = <td>{value && moment(value).format(dateFormat)}</td>;
      } else if (tableData.value === "type") {
        renderItem = <td>{props.type}</td>;
      } else {
        renderItem = <td>{value}</td>;
      }
    } else if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                inputFocused.id === contactData.id &&
                inputFocused.isTrue &&
                inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({ ...newTabData, blank: true });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab(newTabData);
                  props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                inputFocused.id === contactData.id &&
                inputFocused.isTrue &&
                inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() => {
                setInputFocused({
                  id: contactData.id,
                  isTrue: true,
                  name: name,
                });
                setShowNameInput({
                  isTrue: true,
                  id: contactData.id,
                });
              }}
            >
              Edit
            </button>
            <input
              value={value}
              style={
                showNameInput.id === contactData.id &&
                showNameInput.isTrue === true
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                fieldChangeHandler(contactData, name, e.target.value)
              }
              onFocus={() =>
                setInputFocused({
                  id: contactData.id,
                  isTrue: true,
                  name: name,
                })
              }
              onBlur={() => {
                setInputFocused({
                  id: "",
                  isTrue: false,
                  name: "",
                });
                setShowNameInput({
                  isTrue: false,
                  id: "",
                });
              }}
            />
          </div>
        </td>
      );
    } else if (name === "phone" || name === "formatted_phone") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                inputFocused.id === contactData.id &&
                inputFocused.isTrue &&
                inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                inputFocused.id === contactData.id &&
                inputFocused.isTrue &&
                inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() => {
                setInputFocused({
                  id: contactData.id,
                  isTrue: true,
                  name: name,
                });
                setShowNameInput({
                  isTrue: true,
                  id: contactData.id,
                });
              }}
            >
              Edit
            </button>
            <input
              value={value}
              style={
                showNameInput.id === contactData.id &&
                showNameInput.isTrue === true
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                fieldChangeHandler(contactData, name, e.target.value)
              }
              onFocus={() =>
                setInputFocused({
                  id: contactData.id,
                  isTrue: true,
                  name: name,
                })
              }
              onBlur={() => {
                setInputFocused({
                  id: "",
                  isTrue: false,
                  name: "",
                });
                setShowNameInput({
                  isTrue: false,
                  id: "",
                });
              }}
            />
          </div>
        </td>
      );
    } else if (tableData.type === "text") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              inputFocused.id === contactData.id &&
              inputFocused.isTrue &&
              inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              fieldChangeHandler(contactData, name, e.target.value)
            }
            onFocus={() =>
              setInputFocused({
                id: contactData.id,
                isTrue: true,
                name: name,
              })
            }
            onBlur={() =>
              setInputFocused({
                id: "",
                isTrue: false,
                name: "",
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "dropdown") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              inputFocused.id === contactData.id &&
              inputFocused.isTrue &&
              inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) => {
              setLeadId(contactData.id);
              statusChangeHandler(contactData, name, e.target.value);
            }}
            onFocus={() =>
              setInputFocused({
                id: contactData.id,
                isTrue: true,
                name: name,
              })
            }
            onBlur={() =>
              setInputFocused({
                id: "",
                isTrue: false,
                name: "",
              })
            }
          >
            <option hidden>-Select-</option>
            <option selected disabled value="">
              -Select-
            </option>
            {statuses?.map((cat) => (
              <option value={cat.name}>{cat.name}</option>
            ))}
          </select>
        </td>
      );
    }

    return renderItem;
  };
  const statusChangeHandler = (contact, name, value) => {
    const newAccount = accounts?.map((con) => {
      if (con.id === contact.id) {
        con = { ...contact, [name]: value === "" ? null : value };
      }
      return con;
    });
    setAccounts(newAccount);
    if (value === "disqualified") {
      return props.type === "contact"
        ? setShowContactDisqualifyModal(true)
        : setShowDisqualifyModal(true);
    }
    let method = props.type === "contact" ? "PATCH" : "PUT";
    axios({
      method,
      url: `/${props.type}s/${contact.id}/status`,
      data: {
        status: value,
      },
    })
      .then((res) => {
        NotificationManager.success("Successfully changed status");
      })
      .catch((err) => {
        NotificationManager.error("Error changing status");
      });
  };
  const fieldChangeHandler = (contact, name, value) => {
    let contactData = contact;
    let formattedValue = value;
    if (name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }
    contactData = {
      ...contact,
      [name]: formattedValue === "" ? null : formattedValue,
    };
    const newAccount = accounts?.map((con) => {
      if (con.id === contact.id) {
        con = {
          ...contact,
          [name]: formattedValue === "" ? null : formattedValue,
        };
      }
      return con;
    });
    setAccounts(newAccount);
    props.type === "contact"
      ? submitContactHandler(contactData)
      : submitAccountHandler(contactData);
  };
  const submitAccountHandler = (accountData) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (
      accountData.formatted_phone !== null &&
      accountData.formatted_phone !== "" &&
      !phoneValidator.test(accountData.formatted_phone)
    ) {
      return false;
    }
    if (
      accountData.email !== null &&
      accountData.email !== "" &&
      !emailValidator.test(accountData.email)
    ) {
      return false;
    }
    if (!accountData.name) {
      return false;
    }
    axios({
      method: "PUT",
      url: `/accounts/${accountData.id}`,
      data: {
        name: accountData.name,
        state: accountData.state,
        country: accountData.country,
        zip: accountData.zip,
        city: accountData.city,
        address: accountData.address,
        source: accountData.source,
        email: accountData.email,
        formatted_phone: accountData.formatted_phone,
        phone_ext: accountData.phone_ext,
        website: accountData.website
          ? accountData.website.startsWith("https://") ||
            accountData.website.startsWith("http://")
            ? accountData.website
            : "https://" + accountData.website
          : null,
        description: accountData.description,
        preferred_communication: [accountData.preferred_communication]?.filter(
          (pref) => pref !== ""
        ),
        custom_fields: accountData.custom_fields,
      },
    })
      .then((response) => {
        // NotificationManager.success("Account edited successfully.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing account.");
        }
      });
  };
  const handleUserId = (user_ids, userName) => {
    setUser_ids(user_ids);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    setQuery("");
    setActivePage(1);
    fetchAccounts(user_ids);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };
  const submitContactHandler = debounce((contactData) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (
      contactData.formatted_phone !== null &&
      contactData.formatted_phone !== "" &&
      !phoneValidator.test(contactData.formatted_phone)
    ) {
      return false;
    }
    if (
      contactData.email !== null &&
      contactData.email !== "" &&
      !emailValidator.test(contactData.email)
    ) {
      return false;
    }
    if (!contactData.name) {
      return false;
    }
    axios({
      method: "PUT",
      url: `/contacts/${contactData.id}`,
      data: {
        name: contactData.name,
        role: contactData.role,
        source: contactData.source,
        email: contactData.email,
        formatted_mobile: contactData.formatted_mobile,
        phone_ext: contactData.phone_ext,
        formatted_phone: contactData.formatted_phone,
        zip: contactData.zip,
        city: contactData.city,
        address: contactData.address,
        state: contactData.state,
        country: contactData.country,
        date_of_birth: contactData.date_of_birth,
        preferred_communication: [contactData.preferred_communication]?.filter(
          (pref) => pref !== ""
        ),
        contact_owner_id: contactData.contact_owner_id,
        custom_fields: contactData.custom_fields,
        account_id: contactData.account_id,
      },
    })
      .then((response) => {
        // NotificationManager.success("Contact edited successfully.");
      })
      .catch((error) => {
        NotificationManager.error("Error editing contact.");
      });
  }, 500);
  const dateFormat =
    props.companyOnboarding.date_format === "DD/MM/YYYY" ? "DD/MM/YYYY" : "l";

  return (
    <div className="accountList">
      <h1 className="accountList__heading">
        Leads <span style={{ color: "#4A9876" }}>{total}</span>
        <div style={{ float: "right" }}>
          <DropdownDashboard
            handleUserId={handleUserId}
            user_ids={user_ids}
            userName={props.authUser.name}
            userId={props.userId}
            displayMenu={displayMenu}
            showDropdownMenu={showDropdownMenu}
            displayUserName={displayUserName}
            userRole={props.authUser.role}
          />
        </div>
      </h1>
      <div className="accountList__function">
        <input
          type="search"
          placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Leads"
          className="reports-search"
          value={query}
          onChange={({ target: { value } }) => {
            fetchAccounts(user_ids, value, 1, sortKey, sortDirection, status);
            setQuery(value);
            setActivePage(1);
          }}
        />
      </div>
      <div style={{ margin: "10px 0" }}>
        <span style={{ fontWeight: "500" }}>Select Status : &nbsp;&nbsp;</span>
        <select
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
            setQuery("");
            fetchAccounts(
              user_ids,
              null,
              activePage,
              sortKey,
              sortDirection,
              e.target.value
            );
          }}
          className="c-icon-1"
          style={{
            background: "#EEF6F2",
            color: "#FFB202",
            width: "130px",
            outline: "none",
          }}
        >
          <option value="All">All Leads</option>
          <option value="AllActiveLeads">Active Leads</option>
          {statuses?.map((stat) => (
            <option key={stat.id}>{stat.name}</option>
          ))}
        </select>
      </div>
      <DisqualifyModal
        showOpportunityModal={showDisqualifyModal}
        setShowOpportunityModal={() => setShowDisqualifyModal(true)}
        handleCloseModal={() => setShowDisqualifyModal(false)}
        customerName={props.authUser.name}
        customerId={leadId}
        fetchAccountData={() => console.log("hi")}
      />
      <DisqualifyContactModal
        showOpportunityModal={showContactDisqualifyModal}
        setShowOpportunityModal={() => setShowContactDisqualifyModal(true)}
        handleCloseModal={() => setShowContactDisqualifyModal(false)}
        customerName={props.authUser.name}
        contactId={leadId}
        fetchAccountData={() => console.log("hi")}
      />
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          fetchAccounts(user_ids, query, page, sortKey, sortDirection, status);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
      {isApplying ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : isApplying === false && total === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No leads found
        </div>
      ) : (
        <table className="accountList__table">
          <thead>
            <tr>
              {tableHeaders.map((headers) => (
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      {headers.label}
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() => {
                          setSortDirection(!sortDirection);
                          setSortKey(headers.value);
                          fetchAccounts(
                            user_ids,
                            query,
                            activePage,
                            headers.value,
                            !sortDirection,
                            status
                          );
                        }}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {accounts?.map((account) => (
              <tr key={account.id}>
                {tableHeaders?.map((header) =>
                  renderTableData(
                    account,
                    header,
                    header.value,
                    account[header.value],
                    dateFormat
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          fetchAccounts(user_ids, query, page, sortKey, sortDirection, status);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    reportsFilters: state.reportsFilters.reports,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
    allContactStatuses: state.allContactStatuses.contactStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setReportsFilters: (reportsFilters) =>
      dispatch(setReportsFilters(reportsFilters)),
  };
};

export default connect(MSP, MDP)(LeadFunnelReport);
