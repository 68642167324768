import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import moment from "moment";
import { debounce } from "lodash";
import { Col, Row } from "react-grid-system";
import DropdownDashboard from "../Dropdown/DropdownDashboard";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";

const HottestFollowUpReport = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState("contact");
  // const [sortDirection, setSortDirection] = useState(true);
  const [isApplying, setIsApplying] = useState(true);
  const [user_ids, setUser_ids] = useState(props.userId);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.userName);

  useEffect(() => {
    setType(props.type);
  }, [props.type]);

  const fetchAccounts = debounce((userIds = user_ids, page = 1, sortKey) => {
    let url = `/contacts/hottest-follow-ups?_limit=30&_page=${page}`;
    if (type === "account") {
      url = `/accounts/hottest-follow-ups?_limit=30&_page=${page}`;
    }
    if (userIds) {
      url += `&user_ids=${userIds}`;
    }

    // if (sortKey) {
    //   const dir = sortDirection === true ? "desc" : "asc";
    //   url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    // }
    setIsApplying(true);
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        const data =
          type === "account" ? response.data.accounts : response.data.contacts;
        setAccounts(data);
        setTotal(response.data.total);
        setIsApplying(false);
      })
      .catch((error) => setIsApplying(false));
  }, 500);

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  const handleUserId = (user_ids, userName) => {
    setUser_ids(user_ids);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    setActivePage(1);
    fetchAccounts(user_ids);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };
  const tableHeaders = [
    { value: "name", label: "Name" },
    { value: "status", label: "status" },
    { value: "email", label: "email" },
    { value: "total_activities", label: "Total Activities" },
    { value: "last_activity_date", label: "last activity date" },
  ];
  console.log("followup", props);
  const dateFormat =
    props.companyOnboarding.date_format === "DD/MM/YYYY" ? "DD/MM/YYYY" : "l";
  return (
    <div className="accountList">
      <h1
        className="accountList__heading"
        style={{ textTransform: "capitalize" }}
      >
        {type}s <span style={{ color: "#4A9876" }}>{total}</span>
        <div style={{ float: "right" }}>
          <DropdownDashboard
            handleUserId={handleUserId}
            user_ids={user_ids}
            userName={props.authUser.name}
            userId={props.userId}
            displayMenu={displayMenu}
            showDropdownMenu={showDropdownMenu}
            displayUserName={displayUserName}
            userRole={props.authUser.role}
          />
        </div>
      </h1>
      <div style={{ margin: "10px 0", marginBottom: "20px" }}>
        <span
          onClick={() => setType("contact")}
          className={`hottestFollowUp-type ${
            type === "contact" && "hottestFollowUp-type-selected"
          }`}
        >
          Contact
        </span>
        <span
          onClick={() => setType("account")}
          className={`hottestFollowUp-type ${
            type === "account" && "hottestFollowUp-type-selected"
          }`}
        >
          Account
        </span>
      </div>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          fetchAccounts(user_ids, page);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
      {isApplying ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : isApplying === false && total === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No {type}s found
        </div>
      ) : (
        <table className="accountList__table">
          <thead>
            <tr>
              {tableHeaders.map((headers) => (
                <th>
                  <Row>
                    <Col lg={12} xl={12}>
                      {headers.label}
                    </Col>
                    {/* <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() => {
                          setSortDirection(!sortDirection);
                          fetchAccounts(activePage, headers.label);
                        }}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col> */}
                  </Row>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {accounts?.map((account) => {
              let newTabData = {
                type: type,
                id: account.id,
                name: account.name,
              };

              return (
                <tr key={account.id}>
                  <td
                    style={{ cursor: "pointer", color: "#4A9876" }}
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        props.setTab({ ...newTabData, blank: true });
                      } else {
                        props.setActiveTabIndex(props.totalTabs + 1);
                        props.setTab(newTabData);
                        props.history.push("/active-tabs");
                      }
                    }}
                  >
                    {account.name}
                  </td>
                  <td>{account.status}</td>
                  <td>{account.email}</td>
                  <td>{account.total_activities}</td>
                  <td>
                    {account.last_activity_date &&
                      moment(account.last_activity_date).format(dateFormat)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          fetchAccounts(user_ids, null, page);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(HottestFollowUpReport);
