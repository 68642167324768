import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import ReactModal from "react-modal";
import CreateSequenceTemplateModal from "./CreateSequenceTemplateModal";
import SequenceCreateModal from "./SequenceCreateModal";
import { NotificationManager } from "react-notifications";
import "../../sass/Sequence.scss";
import { withRouter } from "react-router-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import { Plus } from "lucide-react";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ height: "22px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(
  ({ step, removeStep, setDelay, stepIndex }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DragHandle />
      <div className="sequence-steps-1">
        <button
          type="button"
          onClick={() => removeStep(stepIndex)}
          className="sequence-button-remove"
        >
          Remove
        </button>
        <span style={{ fontWeight: "500" }}>Template Name: </span>
        {step.name}{" "}
        <div>
          Send in{" "}
          <input
            type="number"
            onChange={(e) => setDelay(stepIndex, parseInt(e.target.value))}
            value={step.delay}
            style={{ display: "inline-block" }}
            onWheel={(e) => e.target.blur()}
          />{" "}
          Days
        </div>
      </div>
    </div>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class EditSequenceModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  getAllSequences = () => {
    axios({
      method: "GET",
      url: `/sequences`,
    }).then((res) =>
      this.setState({
        allSequences: res.data.sequences,
      })
    );
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getAllSequences();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.getAllSequences();
    }
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "90vh", top: "2%", left: "12%", width: "70vw" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Edit Email Sequence</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <SequenceCreate
                selectedSequence={this.props.selectedSequence}
                handleCloseModal={this.props.handleCloseModal}
                fromDetailPage={this.props.fromDetailPage}
                getSequence={this.props.getSequence}
              />
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

function SequenceCreate({
  selectedSequence,
  handleCloseModal,
  getSequence,
  fromDetailPage,
}) {
  const [seqName, setSeqName] = useState("New Sequence");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [steps, setSteps] = useState([]);
  const [sendOnWeekdays, setSendOnWeekdays] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stopSequenceOnReply, setStopSequenceOnReply] = useState(false);

  useEffect(() => {
    const stepsArray = selectedSequence.steps
      ? selectedSequence.steps
          ?.sort((a, b) => a.order - b.order)
          .map((step) => {
            return {
              ...step,
              delay: isNaN(step.delay) ? 0 : step.delay / 86400,
            };
          })
      : [];
    setSeqName(selectedSequence.name);
    setSteps(stepsArray);
    setSendOnWeekdays(selectedSequence.send_on_week_days);
    setStopSequenceOnReply(selectedSequence.stop_on_reply);
    setIsPrivate(selectedSequence.is_private);
  }, [selectedSequence]);

  const submitHandler = () => {
    setLoading(true);
    axios({
      method: "PUT",
      url: `/sequences/${selectedSequence.id}`,
      data: {
        name: seqName,
        steps: steps?.map((step, index) => {
          return {
            template_id: step.template_id,
            order: index,
            delay: isNaN(step.delay) ? 0 : step.delay * 86400,
            ...(step.id && { id: step.id }),
          };
        }),
        send_on_week_days: sendOnWeekdays,
        stop_on_reply: stopSequenceOnReply,
        is_private: isPrivate,
      },
    })
      .then((res) => {
        setLoading(false);
        handleCloseModal();
        NotificationManager.success("Sequence edited successfully");
        fromDetailPage === true && getSequence();
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error editing sequence");
      });
  };
  const removeStep = (i) => {
    const newSteps = steps?.filter((step, index) => index !== i);
    setSteps(newSteps);
  };
  const setDelay = (i, value) => {
    const newStep = steps?.map((step, index) => {
      if (index === i) step.delay = value;
      return step;
    });
    setSteps(newStep);
  };
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ marginBottom: "0px" }}>
            <input
              value={seqName}
              onChange={(e) => setSeqName(e.target.value)}
            />
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "10%",
                transform: "translateX(-50%)",
              }}
            >
              <label style={{ display: "inline-block" }}>
                <span className="general-heading">Public Sequence &nbsp;</span>
                <label className="switch1">
                  <input
                    type="checkbox"
                    value={isPrivate}
                    checked={isPrivate}
                    onChange={() => setIsPrivate(!isPrivate)}
                    style={{ width: "20px", display: "inline-block" }}
                  />
                  <span
                    className="slider1 round1"
                    style={{ transform: "translateY(10px)" }}
                  ></span>
                </label>
                <span className="general-heading"> &nbsp;Private Sequence</span>
              </label>
            </div>
          </h2>
          <p> </p>
        </div>
      </div>
      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
        <span
          onClick={() => setShowSettings(false)}
          style={
            showSettings
              ? {
                  textDecoration: "none",
                  color: "black",
                  padding: "4px 12px",
                  cursor: "pointer",
                }
              : {
                  textDecoration: "none",
                  color: "#4A9876",
                  padding: "4px 12px",
                  borderBottom: "1px solid #4A9876",
                  fontWeight: "500",
                  cursor: "pointer",
                }
          }
        >
          Steps
        </span>
        <span
          onClick={() => setShowSettings(true)}
          style={
            showSettings
              ? {
                  textDecoration: "none",
                  color: "#4A9876",
                  padding: "4px 12px",
                  borderBottom: "1px solid #4A9876",
                  fontWeight: "500",
                  cursor: "pointer",
                }
              : {
                  textDecoration: "none",
                  color: "black",
                  padding: "4px 12px",
                  cursor: "pointer",
                }
          }
        >
          Settings
        </span>
      </div>
      {showSettings && (
        <>
          <div>
            <label style={{ fontWeight: "500", cursor: "pointer" }}>
              Execute steps on business days only
              <input
                type="checkbox"
                value={sendOnWeekdays}
                onChange={() => setSendOnWeekdays(!sendOnWeekdays)}
                checked={sendOnWeekdays}
                style={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "10px",
                  display: "inline-block",
                }}
              />
            </label>
            <p>If enabled, emails will be sent on business days only.</p>
          </div>
          <div>
            <label style={{ fontWeight: "500", cursor: "pointer" }}>
              Stop Sequence on user reply
              <input
                type="checkbox"
                value={stopSequenceOnReply}
                onChange={() => setStopSequenceOnReply(!stopSequenceOnReply)}
                checked={stopSequenceOnReply}
                style={{ width: "16px", height: "16px", marginLeft: "10px" }}
              />
            </label>
            <p>
              If enabled, sequence will be stopped if user replies to a
              sequence.
            </p>
          </div>
        </>
      )}
      {!showSettings && (
        <div className="sequence" style={{ height: "54vh", overflowY: "auto" }}>
          {steps.length === 0 && (
            <div className="sequence-steps">
              Get started by adding an email template to your new sequence.
            </div>
          )}
          <SortableContainer
            onSortEnd={({ oldIndex, newIndex }) => {
              setSteps(arrayMove(steps, oldIndex, newIndex));
            }}
            useDragHandle
          >
            {steps?.map((step, index) => (
              <SortableItem
                step={step}
                removeStep={removeStep}
                setDelay={setDelay}
                stepIndex={index}
                key={`step-${index}`}
                index={index}
              />
            ))}
          </SortableContainer>
          <button
            type="button"
            onClick={() => setShowCreateModal(true)}
            className="sequence-button"
          >
            <Plus
              size={14}
              color="#62CA9D"
              strokeWidth={3}
              style={{ transform: "translateY(2px)", marginLeft: "5px" }}
            />
          </button>
        </div>
      )}
      <SequenceCreateModal
        showOpportunityModal={showCreateModal}
        setShowOpportunityModal={() => setShowCreateModal(true)}
        handleCloseModal={() => setShowCreateModal(false)}
        createNewTemplate={() => {
          setShowTemplateModal(true);
          setShowCreateModal(false);
        }}
        setSteps={setSteps}
        steps={steps}
      />
      <CreateSequenceTemplateModal
        showOpportunityModal={showTemplateModal}
        setShowOpportunityModal={() => setShowTemplateModal(true)}
        handleCloseModal={() => setShowTemplateModal(false)}
        setSteps={setSteps}
        steps={steps}
      />
      <div className="modal__buttons">
        <button
          type="button"
          className="modal__cancel-button"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="modal__confirm-button"
          onClick={submitHandler}
          disabled={loading}
        >
          {loading ? "Saving" : "Save"}
        </button>
      </div>
    </div>
  );
}

export default withRouter(EditSequenceModal);
