export const laneTerminologies = {
  origin_city: [
    "origin city",
    "to city",
    "lane origin city",
    "route origin city",
    "lane to city",
    "route to city",
    "origin_city",
    "to_city",
  ],
  origin_state: [
    "origin state",
    "to state",
    "lane origin state",
    "route origin state",
    "lane to state",
    "route to state",
    "origin province",
    "to province",
    "lane origin province",
    "route origin province",
    "lane to province",
    "route to province",
    "origin_state",
    "to_state",
  ],
  origin_zip: [
    "origin zip",
    "to zip",
    "lane origin zip",
    "route origin zip",
    "lane to zip",
    "route to zip",
    "origin postal code",
    "to postal code",
    "lane origin postal code",
    "route origin postal code",
    "lane to postal code",
    "route to postal code",
    "origin_zip",
    "origin_postal_code",
    "origin_postalcode",
    "to_postal_code",
    "to_postalcode",
    "to_zip",
  ],
  origin_address: [
    "origin address",
    "to address",
    "lane origin address",
    "route origin address",
    "lane to address",
    "route to address",
    "origin_address",
    "to_address",
  ],
  destination_city: [
    "destination city",
    "from city",
    "lane destination city",
    "route destination city",
    "lane from city",
    "route from city",
    "destination_city",
    "from_city",
  ],
  destination_state: [
    "destination state",
    "from state",
    "lane destination state",
    "route destination state",
    "lane from state",
    "route from state",
    "destination province",
    "from province",
    "lane destination province",
    "route destination province",
    "lane from province",
    "route from province",
    "destination_state",
    "from_state",
  ],
  destination_zip: [
    "destination zip",
    "from zip",
    "lane destination zip",
    "route destination zip",
    "lane from zip",
    "route from zip",
    "destination postal code",
    "from postal code",
    "lane destination postal code",
    "route destination postal code",
    "lane from postal code",
    "route from postal code",
    "destination_zip",
    "destination_postal_code",
    "destination_postalcode",
    "from_postal_code",
    "from_postalcode",
    "from_zip",
  ],
  destination_address: [
    "destination address",
    "from address",
    "lane destination address",
    "route destination address",
    "lane from address",
    "route from address",
    "destination_address",
    "from_address",
  ],
  description: [
    "description",
    "notes",
    "short description",
    "lane description",
    "route description",
  ],
  status: ["status", "lane status", "route status"],
  volume: [
    "volume",
    "vol",
    "vol.",
    "lane volume",
    "lane vol",
    "frequency",
    "lane frequency",
    "freq",
    "freq.",
    "lane freq.",
    "route volume",
    "route vol",
    "route frequency",
    "route freq.",
  ],
  price: [
    "linehaul",
    "price",
    "lane price",
    "lane linehaul",
    "route linehaul",
    "route price",
  ],
  equiment_types: [
    "equiment types",
    "equipment",
    "equipments",
    "lane equipment",
    "lane equipments",
    "lane equiment types",
    "route equipment",
    "route equipments",
    "route equiment types",
    "equiment_types",
    "equiment_type",
  ],
  modes: ["modes", "lane modes", "mode", "route modes"],
  special_requirements: [
    "special requirements",
    "requirements",
    "sp. req.",
    "sp req.",
    "req.",
    "req",
    "lane special requirements",
    "route special requirements",
    "special_requirements",
    "special_requirement",
  ],
  lane_types: [
    "lane types",
    "types",
    "type",
    "route types",
    "route type",
    "lane_types",
  ],
  account_id: [
    "account_id",
    "company_id",
    "organization_id",
    "account id",
    "company id",
    "organization id",
  ],
  account_name: [
    "account_name",
    "company_name",
    "organization_name",
    "account name",
    "company name",
    "organization name",
  ],
  account_email: [
    "account_email",
    "company_email",
    "organization_email",
    "account email",
    "company email",
    "organization email",
  ],
  carrier_id: ["carrier_id", "carrier id"],
  carrier_name: ["carrier_name", "carrier name"],
  mc_number: [
    "mc_number",
    "mc number",
    "mc #",
    "mc#",
    "carrier mc#",
    "carrier mc #",
  ],
  dot_number: [
    "dot_number",
    "dot number",
    "dot #",
    "dot#",
    "carrier dot#",
    "carrier dot #",
  ],
};
