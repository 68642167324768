import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import "react-dates/initialize";
import axios from "axios";
import LogActivityIcon from "../images/LogActivity.svg";
import LogActivityIconGray from "../images/ActivityLogGray.svg";
import CompleteTaskIcon from "../images/CompleteTask.svg";
import CompleteTaskIconGreen from "../images/CompleteTaskGreen.svg";
import "react-dates/lib/css/_datepicker.css";
import "../sass/Activity.scss";
import "../sass/OverrideDates.scss";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TaskCreateModal from "./TaskCreateModal";
import Task from "./Task";
import { Hidden, Visible } from "react-grid-system";
import FollowupTask from "./Tasks/FollowupTask";
import SendEmailModal from "./SendEmail/SendEmailModal";
import ActivityJodit from "./Activity/ActivityJodit";
import AllTaskSequenceModal from "./Tasks/Sequence/AllTaskSequenceModal";
import SendTaskSequenceModal from "./Tasks/Sequence/SendTaskSequenceModal";

// Redux stuff
import { connect } from "react-redux";
import { authUserSet, companyOnboardingSet } from "../redux";
import { Check, Plus } from "lucide-react";

const ActivityForm = (props) => {
  const [categories, setCategories] = useState([]);
  const [results, setResults] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [description, setDescription] = useState("");
  const [showTaskSeqModal, setShowTaskSeqModal] = useState(false);
  const [showTaskSeqSendModal, setShowTaskSeqSendModal] = useState(false);
  const [selectedSeq, setSelectedSeq] = useState({});

  const handleOpenEmailModal = (
    taskId,
    categoryId,
    name,
    opportunityId,
    contactId
  ) => {
    setShowEmailModal(true);
    fillCompleteTaskForm(taskId, categoryId, name, opportunityId, contactId);
  };

  const getTasks = useCallback(() => {
    axios({
      method: "GET",
      url: `/accounts/${props.customerId}/tasks`,
    }).then((res) => {
      setTasks(res.data.tasks);
    });
  }, [props.customerId, props.callTasks]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `/tasks/categories`,
    }).then((res) => {
      const data = res.data.task_categories;
      setCategories(data);
    });
    getTasks();
    axios({
      method: "GET",
      url: `/activities/results`,
    }).then((res) => setResults(res.data.results));
  }, [props.customerId, getTasks]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `/admin/users?account_id=${props.customerId}`,
    }).then((response) => {
      setUsers(
        response.data.users?.slice()?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Ignore case for comparison
          const nameB = b.name.toUpperCase(); // Ignore case for comparison

          if (nameA < nameB) {
            return -1; // Negative value: a should come before b
          }
          if (nameA > nameB) {
            return 1; // Positive value: b should come before a
          }
          return 0; // Return 0 for elements considered equal
        })
      );
    });
  }, [props.customerId]);

  const fillCompleteTaskForm = (
    taskId,
    categoryId,
    name,
    opportunityId,
    contactId
  ) => {
    setFormFields({
      ...formFields,
      type: "task_completed",
      category_id: categoryId,
      name,
      task_id: taskId,
      opportunity_id: opportunityId,
      contact_id: contactId,
    });
  };

  const handleOpenModal = () => {
    props.setShowTaskModal(true);
  };

  const handleCloseModal = () => {
    props.setShowTaskModal(false);
  };
  const getMobileLink = (sliceNumber, number) => {
    return `<a target="_blank" data-stringify-link="tel:${sliceNumber}" delay="150" data-sk="tooltip_parent" href="tel:${sliceNumber}" rel="noopener noreferrer" tabindex="-1" data-remove-tab-index="true">${number}</a>`;
  };
  const getFormattedDescription = (value) => {
    let newValue = value;
    let phoneNumbers = [];
    const regexp = new RegExp(
      "\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+",
      "g"
    );
    phoneNumbers = [...newValue.matchAll(regexp)];
    for (const match of phoneNumbers) {
      const sliceNumber = match[0]
        ?.toString()
        ?.split("")
        ?.filter(
          (num) =>
            num !== "-" &&
            num !== " " &&
            num !== "." &&
            num !== "(" &&
            num !== ")"
        )
        ?.join("");
      if (match[0].length >= 10) {
        let link = getMobileLink(sliceNumber, match[0]);
        newValue = newValue.replaceAll(match[0], link);
      }
    }
    return newValue;
  };
  const updateContent = (value) => {
    setDescription(value);
  };

  const [formFields, setFormFields] = useState({
    name: "",
    account_id: props.customerId,
    opportunity_id: "",
    contact_id: "",
    category_id: 1,
    result_id: "",
    type: "activity_logged",
    task_id: "",
  });

  const {
    name,
    opportunity_id,
    contact_id,
    category_id,
    result_id,
    account_id,
    task_id,
    type,
  } = formFields;

  const submitHandler = (e) => {
    e.preventDefault();
    const newDescription = getFormattedDescription(description);
    if (
      name == null ||
      category_id === null ||
      name.trim() === "" ||
      category_id === ""
    ) {
      setErrorMessage(true);
      setLoading(false);
      return false;
    } else {
      setLoading(true);
      axios({
        method: "POST",
        url: `/activities/create`,
        data: {
          ...(name !== "" && {
            name,
          }),
          ...(description !== "" && {
            description: newDescription,
          }),
          ...(opportunity_id !== "" &&
            opportunity_id !== null && {
              opportunity_id,
            }),
          ...(contact_id !== "" &&
            contact_id !== null && {
              contact_id,
            }),
          ...(category_id !== "" && {
            category_id,
          }),
          ...(result_id !== "" && {
            result_id,
          }),
          account_id,
          type,
          ...(task_id !== "" && {
            task_id,
          }),
        },
      })
        .then((res) => {
          NotificationManager.success("Activity Logged Successfully!");
          props.loadActivities(1, "refetch");
          setErrorMessage(false);
          setLoading(false);
          getTasks();
        })
        .catch((err) => {
          setLoading(false);
        })
        .finally(() => {
          setFormFields({
            name: "",
            account_id: props.customerId,
            opportunity_id: "",
            contact_id: "",
            category_id: 1,
            result_id: "",
            type: "activity_logged",
          });
          setDescription("");
          setLoading(false);
        });
    }
  };
  const submitFollowUpEmail = () => {
    axios({
      method: "PATCH",
      url: `/tasks/${task_id}`,
    })
      .then((res) => {
        NotificationManager.success("Activity Logged Successfully!");
        props.loadActivities(1, "refetch");
        setErrorMessage(false);
        setLoading(false);
        getTasks();
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setFormFields({
          name: "",
          account_id: props.customerId,
          opportunity_id: "",
          contact_id: "",
          category_id: 1,
          result_id: "",
          type: "activity_logged",
        });
        setDescription("");
        setLoading(false);
      });
  };
  const makeDecision = (taskId, categoryId, name, opportunityId, contactId) => {
    categoryId === 2 && props.integration !== null
      ? handleOpenEmailModal(taskId, categoryId, name, opportunityId, contactId)
      : fillCompleteTaskForm(
          taskId,
          categoryId,
          name,
          opportunityId,
          contactId
        );
  };

  const { opportunities, contacts } = props;
  return (
    <div className="activity">
      <div className="activity__next">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 className="activity-title">Follow-up Task</h3>
          <button
            onClick={handleOpenModal}
            className="button-sm"
            style={{ marginLeft: "auto" }}
          >
            Add Task
            <Plus
              size={14}
              color="#62CA9D"
              strokeWidth={3}
              style={{ transform: "translateY(2px)", marginLeft: "5px" }}
            />
          </button>
          {props.showTaskModal && (
            <TaskCreateModal
              accountId={props.customerId}
              showTaskModal={props.showTaskModal}
              setShowTaskModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              customerName={props.customerName}
              opportunities={opportunities}
              contacts={contacts}
              getTasks={getTasks}
              users={users}
              existingUser={props.authUser.id}
              keyValue="ActivityForm"
            />
          )}
        </div>

        <div
          style={tasks.length > 2 ? { height: "200px", overflowY: "auto" } : {}}
        >
          {tasks?.map((task) => {
            const {
              id,
              name,
              description,
              start_time,
              end_time,
              date,
              category_id: categoryId,
              opportunity_id: opportunityId,
              contact_id: contactId,
              category_name,
              assigned_to,
              account_name,
              opportunity_name,
              contact_name,
            } = task;
            return (
              <div
                style={{
                  width: "100%",
                  border: "none",
                  background: "transparent",
                  outline: "none",
                  cursor: "pointer",
                  padding: "0",
                }}
                // onClick={() => {
                //   categoryId === 2 && props.integration !== null
                //     ? handleOpenEmailModal(
                //         taskId,
                //         categoryId,
                //         name,
                //         opportunityId,
                //         contactId
                //       )
                //     : fillCompleteTaskForm(
                //         taskId,
                //         categoryId,
                //         name,
                //         opportunityId,
                //         contactId
                //       );
                // }}
              >
                <Task
                  icon={`${category_name}Green`}
                  name={name}
                  key={`${name}_${id}`}
                  detail={description}
                  time={
                    start_time &&
                    moment(start_time, "HH:mm:ss").format("hh:mm") +
                      " - " +
                      moment(end_time, "HH:mm:ss").format("hh:mm A")
                  }
                  start_time={start_time}
                  end_time={end_time}
                  task_id={id}
                  taskIdState={formFields.task_id}
                  account_id={props.customerId}
                  date={date}
                  accountName={account_name}
                  opportunityName={opportunity_name}
                  contactName={contact_name}
                  showDropdown={true}
                  category_id={categoryId}
                  getTasks={getTasks}
                  assignedTo={assigned_to}
                  users={users}
                  opportunities={opportunities}
                  contacts={contacts}
                  opportunityId={opportunityId}
                  contactId={contactId}
                  makeDecision={makeDecision}
                  typeOfTask="account"
                />
              </div>
            );
          })}
        </div>
        <FollowupTask
          accountId={props.customerId}
          customerName={props.customerName}
          getTasks={getTasks}
          existingUser={props.authUser.id}
          keyValue="ActivityForm"
        />
        {showTaskSeqModal && (
          <AllTaskSequenceModal
            showOpportunityModal={showTaskSeqModal}
            setShowOpportunityModal={() => setShowTaskSeqModal(true)}
            handleCloseModal={() => setShowTaskSeqModal(false)}
            setShowTaskSeqSendModal={setShowTaskSeqSendModal}
            setSelectedSeq={setSelectedSeq}
          />
        )}
        {showTaskSeqSendModal && (
          <SendTaskSequenceModal
            showOpportunityModal={showTaskSeqSendModal}
            setShowOpportunityModal={() => setShowTaskSeqSendModal(true)}
            handleCloseModal={() => setShowTaskSeqSendModal(false)}
            selectedSeq={selectedSeq}
            accountId={props.customerId}
            users={users}
            existingUser={props.authUser.id}
            opportunities={opportunities}
            contacts={contacts}
            dateFormat={props.companyOnboarding?.date_format}
            getTasks={getTasks}
          />
        )}
        <button
          type="button"
          onClick={() => setShowTaskSeqModal(true)}
          className="button-sm-secondary button-outline-black-hover"
          style={{
            marginTop: "10px",
            border: "1px solid #000",
            background: "#fff",
          }}
        >
          + Follow-up Sequence
        </button>
        <button
          type="button"
          onClick={props.emailDecision}
          className="button-sm-secondary button-outline-black-hover"
          style={{
            marginTop: "10px",
            marginLeft: "16px",
            border: "1px solid #000",
            background: "#fff",
          }}
        >
          + Email Sequence
        </button>
      </div>
      <div className="activity__add" style={{ marginTop: "25px" }}>
        {/* FORM */}
        <SendEmailModal
          showOpportunityModal={showEmailModal}
          setShowOpportunityModal={() => setShowEmailModal(true)}
          handleCloseModal={() => setShowEmailModal(false)}
          email={props.accountEmail}
          accountId={props.customerId}
          loadActivities={props.loadActivities}
          contactEmailsArray={props.contactEmailsArray}
          replyToEmail={props.accountEmail ? [props.accountEmail] : []}
          taskComplete={true}
          taskSubmitHandler={submitFollowUpEmail}
          integration={props.integration}
          seqRef={props.seqRef}
          recordName={props.customerName}
        />
        <form>
          <NotificationContainer />
          <div className="activityFormContainerButton">
            <button
              className={`${
                formFields.type === "activity_logged"
                  ? "activityFormContainerButton-active"
                  : ""
              } activityFormContainerButton-button`}
              onClick={() =>
                setFormFields({
                  ...formFields,
                  type: "activity_logged",
                  task_id: null,
                  name: "",
                  category_id: 1,
                })
              }
              type="button"
            >
              <img
                src={
                  formFields.type === "activity_logged"
                    ? LogActivityIcon
                    : LogActivityIconGray
                }
                alt=""
                style={{ marginRight: "10px" }}
              />
              New Activity
            </button>
            <button
              className={`${
                formFields.type === "task_completed"
                  ? "activityFormContainerButton-active"
                  : ""
              } activityFormContainerButton-button`}
              onClick={() =>
                setFormFields({ ...formFields, type: "task_completed" })
              }
              type="button"
            >
              <img
                src={
                  formFields.type === "task_completed"
                    ? CompleteTaskIconGreen
                    : CompleteTaskIcon
                }
                alt=""
                style={{ marginRight: "10px" }}
              />
              Complete Task
            </button>
          </div>
          {formFields.type === "task_completed" && (
            <>
              <h5 className="c-title">Choose Task *</h5>
              <select
                className="c-dropdown"
                value={task_id}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    task_id: parseInt(e.target.value),
                    name: tasks?.find(
                      (task) => task.id === parseInt(e.target.value)
                    ).name,
                  })
                }
                required={formFields.type === "task_completed"}
              >
                <option value="" hidden>
                  --Select--
                </option>
                <option value="" selected disabled>
                  --Select--
                </option>
                {tasks?.map((task) => {
                  const { id, name } = task;

                  return (
                    <option key={`${name}_${id}`} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </>
          )}
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <h5 className="c-title">Deal (optional)</h5>
              <select
                className="c-dropdown"
                value={opportunity_id}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    opportunity_id: parseInt(e.target.value),
                  })
                }
              >
                <option value="" hidden>
                  --Select--
                </option>
                <option value="" selected disabled>
                  --Select--
                </option>
                {opportunities?.map((opportunity) => {
                  const { id, name } = opportunity;
                  return (
                    <option key={`${name}_${id}`} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <h5 className="c-title">Contact (optional)</h5>
              <select
                className="c-dropdown"
                value={contact_id}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    contact_id: parseInt(e.target.value),
                  })
                }
              >
                <option value="" hidden>
                  --Select--
                </option>
                <option value="" selected disabled>
                  --Select--
                </option>
                {contacts?.map((contact) => {
                  const { id, name } = contact;
                  return (
                    <option key={`${name}_${id}`} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {formFields.type === "activity_logged" && (
            <>
              <h5 className="c-title">
                Type{" "}
                <span style={{ color: "#0058DB", display: "inline-block" }}>
                  &nbsp;<span style={{ color: "#4A9876" }}>*</span>
                </span>
              </h5>
              <div className="activityForm_buttons">
                <div className="modal-radioButton c-radioButton">
                  {categories.map((category) => {
                    const { id, name } = category;
                    return (
                      <span key={`${name}_${id}`}>
                        <label
                          htmlFor={`cat_a${account_id}_c${id}`}
                          style={
                            category_id === id
                              ? {
                                  border: "2px solid #4a9876",
                                  outline: "none",
                                  color: "#555555",
                                  padding: "4px 12px",
                                  borderRadius: "9px",
                                  marginRight: "8px",
                                  background: "#F4F6F5",
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "4px 12px",
                                  border: "none",
                                  borderRadius: "9px",
                                  background: "#F4F6F5",
                                  marginRight: "8px",
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                }
                          }
                        >
                          {name}
                        </label>
                        <input
                          type="radio"
                          name="category"
                          id={`cat_a${account_id}_c${id}`}
                          className="radio-button"
                          checked={category_id === id}
                          value={id}
                          onChange={(e) => {
                            setFormFields({
                              ...formFields,
                              category_id: parseInt(e.target.value),
                            });
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {errorMessage === true &&
                (category_id === null || category_id === "")
                  ? "Please select a type"
                  : null}
              </div>
              <h5 className="c-title">
                Title{" "}
                <span style={{ color: "#0058DB", display: "inline-block" }}>
                  &nbsp;<span style={{ color: "#4A9876" }}>*</span>
                </span>
              </h5>
              <input
                id="AccountActivityForm"
                type="text"
                className="activity-input"
                name="name"
                autoComplete="off"
                value={name}
                tabIndex="1"
                onChange={(e) =>
                  setFormFields({ ...formFields, name: e.target.value })
                }
                style={
                  errorMessage === true && (name === null || name.trim() === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {}
                }
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {errorMessage === true && (name === null || name.trim() === "")
                  ? "Title is required"
                  : null}
              </div>
            </>
          )}
          <h5 className="c-title">Description (optional)</h5>
          <div
            style={{ position: "relative" }}
            className="c-input-area"
            role="textbox"
            tabIndex="2"
          >
            <ActivityJodit
              content={description}
              updateContent={updateContent}
            />
          </div>
          <h5 className="c-title">Result (optional)</h5>
          <select
            tabIndex="3"
            className="c-dropdown"
            value={result_id}
            onChange={(e) =>
              setFormFields({
                ...formFields,
                result_id: parseInt(e.target.value),
              })
            }
          >
            <option value="" hidden>
              --Select--
            </option>
            <option value="" selected disabled>
              --Select--
            </option>
            {results?.map((opportunity) => {
              const { id, name } = opportunity;

              return (
                <option key={`${name}_${id}`} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
          <Hidden md sm xs>
            <div style={{ textAlign: "right" }}>
              <button
                tabIndex="4"
                className="button-md"
                onClick={submitHandler}
                disabled={loading}
              >
                <span
                  style={{
                    transform: "translateY(-2px)",
                    display: "inline-block",
                  }}
                >
                  {loading ? "Logging" : "Log Activity"}
                </span>
                <Check
                  size={16}
                  color="#62CA9D"
                  style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                />
              </button>
            </div>
          </Hidden>
          <Visible md sm xs>
            <div>
              <button
                className="button-md"
                onClick={submitHandler}
                disabled={loading}
              >
                {loading ? "Logging" : "Log Activity"}
              </button>
            </div>
          </Visible>
        </form>
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ActivityForm);
