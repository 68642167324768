import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLoader from "../Loaders/DashboardLoader";
import Pagination from "react-js-pagination";
import moment from "moment";
import DropdownDashboard from "../Dropdown/DropdownDashboard";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";

const ActivitiesMetricsReport = (props) => {
  const [filter, setFilter] = useState("last_7_days");
  const [metrics, setMetrics] = useState(
    props.location.state.metrics || "all_activities"
  );
  const [user_ids, setUser_ids] = useState(props.authUser.id);
  const [deals, setDeals] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sortingKey, setSortingKey] = useState(null);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.authUser.name);

  const allFilters = [
    { value: "all_time", label: "All time" },
    { value: "last_7_days", label: "Last 7 days" },
    { value: "last_14_days", label: "Last 14 days" },
    { value: "last_30_days", label: "Last 30 days" },
    { value: "last_60_days", label: "Last 60 days" },
    { value: "last_90_days", label: "Last 90 days" },
    { value: "last_365_days", label: "Last 365 days" },
  ];
  const allMetrics = [
    { value: "all_activities", label: "All Activities" },
    { value: "email_activities", label: "All Emails" },
    { value: "call_activities", label: "All Calls" },
    { value: "average_new_account", label: "Avg new accounts" },
    { value: "average_accounts_moved", label: "Avg accounts moved out" },
    { value: "total_lanes_created", label: "Total Lanes Added" },
  ];
  const activitiesHeaders = [
    {
      label: "Owner",
      value: "created_by",
      sort: true,
      sortKey: "created_by",
      type: null,
    },
    {
      label: "Record",
      value: "record",
      sort: true,
      sortKey: "record",
      type: null,
    },
    {
      label: "Created Date",
      value: "created_at",
      sort: true,
      sortKey: "created_at",
      type: "date",
    },
    {
      label: "Type/Category",
      value: "category_name",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Title",
      value: "name",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Description",
      value: "description",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Result",
      value: "result_name",
      sort: false,
      sortKey: null,
      type: null,
    },
  ];
  const newAccountHeaders = [
    {
      label: "Account Owner",
      value: "owners",
      sort: true,
      sortKey: "owners",
      type: null,
    },
    {
      label: "Account Name",
      value: "name",
      sort: true,
      sortKey: "name",
      type: null,
    },
    {
      label: "Owner Change Date",
      value: "owner_changed_at",
      sort: true,
      sortKey: "owner_changed_at",
      type: "date",
    },
    {
      label: "Activity Created At",
      value: "activity_created_at",
      sort: true,
      sortKey: "activity_created_at",
      type: "date",
    },
  ];
  const accountMovedHeaders = [
    {
      label: "Owner",
      value: "owners",
      sort: false,
      sortKey: "owners",
      type: null,
    },
    {
      label: "Account Name",
      value: "name",
      sort: true,
      sortKey: "name",
      type: null,
    },
    {
      label: "Account Transition Date",
      value: "owner_changed_at",
      sort: true,
      sortKey: "owner_changed_at",
      type: "date",
    },
  ];
  const lanesHeaders = [
    {
      label: "Owner",
      value: "lanesOwner",
      sort: true,
      sortKey: "owner",
      type: null,
    },
    {
      label: "Record",
      value: "record",
      sort: true,
      sortKey: "record",
      type: null,
    },
    {
      label: "Origin",
      value: "origin_address",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Destination",
      value: "destination_address",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Eqpt Type",
      value: "equipment_types",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Mode",
      value: "mode",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Price",
      value: "price",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Description",
      value: "description",
      sort: false,
      sortKey: null,
      type: null,
    },
  ];
  const allHeaders =
    metrics === "average_new_account"
      ? newAccountHeaders
      : metrics === "average_accounts_moved"
      ? accountMovedHeaders
      : metrics === "total_lanes_created"
      ? lanesHeaders
      : activitiesHeaders;

  const fetchReports = (
    page = 1,
    user_ids = props.authUser.id,
    sortKey,
    sortDir
  ) => {
    setLoader(true);
    let url = `/charts/kpi-stats-records?user_ids=${user_ids}&duration=${filter}&_limit=30&_page=${page}&record_type=${metrics}`;
    if (sortKey) {
      const dir = sortDir === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setDeals(res.data?.[metrics]);
        setTotal(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleUserId = (userIds, userName) => {
    setUser_ids(userIds);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    fetchReports(activePage, userIds, sortingKey, sortDirection);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };

  useEffect(() => {
    fetchReports();
  }, [filter, metrics]);
  return (
    <div className="accountList">
      <h1 className="accountList__heading">
        Activity Metrics Reports{" "}
        <span style={{ color: "#4A9876" }}>{total}</span>
        <div style={{ float: "right" }}>
          <DropdownDashboard
            handleUserId={handleUserId}
            user_ids={user_ids}
            userName={props.authUser.name}
            userId={props.authUser.id}
            displayMenu={displayMenu}
            showDropdownMenu={showDropdownMenu}
            displayUserName={displayUserName}
            userRole={props.authUser.role}
          />
        </div>
      </h1>
      <div
        className="accountList__function"
        style={{ display: "flex", marginTop: "15px" }}
      >
        <div>
          <label
            style={{ fontSize: "14px", fontWeight: "500", marginBottom: "5px" }}
          >
            Time
          </label>
          <select
            className="funnel-select"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {allFilters.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginLeft: "15px" }}>
          <label
            style={{ fontSize: "14px", fontWeight: "500", marginBottom: "5px" }}
          >
            Report
          </label>
          <select
            className="funnel-select"
            name="metrics"
            value={metrics}
            onChange={(e) => setMetrics(e.target.value)}
          >
            {allMetrics.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <>
          <div
            style={{
              overflow: "auto",
              maxHeight: props.maxHeight,
              ...(props.maxWidth && {
                maxWidth: props.maxWidth - 15,
              }),
            }}
          >
            <table className="accountList__table" style={{ marginTop: "0" }}>
              <thead>
                {allHeaders.map((header) => (
                  <th>{header.label}</th>
                ))}
              </thead>
              <tbody>
                {deals?.map((deal) => {
                  const activityType = deal?.opportunity_id
                    ? "opportunity"
                    : deal?.contact_id
                    ? "contact"
                    : deal?.carrier_id
                    ? "carrier"
                    : "account";
                  let newTabData = {
                    type: activityType,
                    id: deal?.[`${activityType}_id`],
                    name: deal?.[`${activityType}_name`],
                  };
                  return (
                    <tr key={deal.id}>
                      {allHeaders.map((header) => (
                        <td>
                          {header.value === "record" ? (
                            <span
                              style={{ cursor: "pointer", color: "#4A9876" }}
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  props.setTab({ ...newTabData, blank: true });
                                } else {
                                  props.setActiveTabIndex(props.totalTabs + 1);
                                  props.setTab(newTabData);
                                  props.history.push("/active-tabs");
                                }
                              }}
                            >
                              {deal?.[`${activityType}_name`]}
                            </span>
                          ) : header.type === "date" ? (
                            deal?.[header.value] ? (
                              moment(deal?.[header.value]).format("l")
                            ) : (
                              "-"
                            )
                          ) : header.value === "equipment_types" ||
                            header.value === "modes" ? (
                            deal?.[header.value]
                              ?.map((val) => val.name)
                              ?.join(", ")
                          ) : header.value === "lanesOwner" ? (
                            deal?.account_owners?.length > 0 ? (
                              deal?.account_owners
                                ?.map((owner) => owner.name)
                                ?.join(", ")
                            ) : (
                              deal?.carrier_owners
                                ?.map((owner) => owner.name)
                                ?.join(", ")
                            )
                          ) : header.value === "owners" ? (
                            deal?.owners?.map((owner) => owner.name)?.join(", ")
                          ) : header.value === "name" &&
                            (metrics === "average_accounts_moved" ||
                              metrics === "average_new_account") ? (
                            <span
                              style={{ cursor: "pointer", color: "#4A9876" }}
                              onClick={(e) => {
                                let myNewTab = {
                                  type: "account",
                                  id: deal?.id,
                                  name: deal?.name,
                                };
                                if (e.metaKey || e.ctrlKey) {
                                  props.setTab({ ...myNewTab, blank: true });
                                } else {
                                  props.setActiveTabIndex(props.totalTabs + 1);
                                  props.setTab(myNewTab);
                                  props.history.push("/active-tabs");
                                }
                              }}
                            >
                              {deal?.name}
                            </span>
                          ) : (
                            deal?.[header.value]
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              setActivePage(page);
              fetchReports(page, user_ids, sortingKey, sortDirection);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        </>
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};
export default connect(MSP, MDP)(ActivitiesMetricsReport);
