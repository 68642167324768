import axios from "axios";
import React, { useState } from "react";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import GoogleMapComponent from "../GoogleComponents/GoogleMapComponent";
import Select from "react-select";
import "../../sass/LanesPanel.scss";
import LaneIcon from "../../images/lane.svg";

//redux
import { connect } from "react-redux";
import {
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
} from "../../redux";
import { Check } from "lucide-react";

const AddLanePanel = ({
  carrierId,
  accountId,
  setAddLane,
  allEquipmentTypes,
  allSpecialRequirements,
  allModes,
  allLaneTypes,
  getCarrierLanes,
}) => {
  //states
  const [origin, setOrigin] = useState({
    address: null,
    lat: null,
    long: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  });
  const [destination, setDestination] = useState({
    address: null,
    lat: null,
    long: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  });
  const [price, setPrice] = useState(null);
  const [rpm, setRpm] = useState(null);
  const [volume, setVolume] = useState(null);
  const [mileage, setMileage] = useState(1);
  const [equipmentTypes, setEquipementTypes] = useState([]);
  const [speciaRequirements, setSpeciaRequirements] = useState([]);
  const [laneTypes, setLaneTypes] = useState([]);
  const [modes, setModes] = useState([]);
  const [description, setDescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  // change handlers
  const handleOriginPlaceSelect = (place) => {
    setOrigin(place);
  };
  const handleDestinationPlaceSelect = (place) => {
    setDestination(place);
  };

  const daysOfWeekChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      const newDays = [...daysOfWeek.filter((day) => day !== ""), value];
      setDaysOfWeek(newDays);
    } else {
      const newDays = daysOfWeek.filter((pc) => pc !== value);
      setDaysOfWeek(newDays);
    }
  };

  // search by lane
  const searchByLane = () => {
    setSearchLoader(true);
    const searchParam = carrierId ? "accounts" : "carriers";
    let url = `/${searchParam}/lanes`;
    if (origin.city) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}origin_city=${origin.city}`;
    } else if (origin.zip) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}origin_zip=${origin.zip}`;
    } else if (origin.state) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}origin_state=${origin.state}`;
    }
    if (destination.city) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}destination_city=${destination.city}`;
    } else if (destination.zip) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}destination_zip=${destination.zip}`;
    } else if (destination.state) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}destination_state=${destination.state}`;
    }
    if (equipmentTypes.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}equipment_type_ids=${equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (speciaRequirements.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}requirement_type_ids=${speciaRequirements
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (modes.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}modes_ids=${modes
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (daysOfWeek.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}days_of_week=${daysOfWeek.join(",")}`;
    }
    if (price) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}price=${price}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setAccounts(res.data.lanes?.results);
        setSearchLoader(false);
        document
          .getElementById(`search-shipper-table-${carrierId}-${accountId}`)
          .scrollIntoView({ behavior: "smooth" });
      })
      .catch((err) => setSearchLoader(false));
  };
  // submitHandlers
  const createLane = () => {
    if (!origin.address || !destination.address) {
      setLoading(false);
      return false;
    }
    setLoading(true);
    axios({
      method: "POST",
      url: `/lanes/create`,
      data: {
        ...(carrierId && { carrier_id: carrierId }),
        ...(accountId && { account_id: accountId }),
        price: Number(price) || 0,
        description,
        origin_city: origin.city,
        origin_state: origin.state,
        origin_zip: origin.zip,
        origin_address: origin.address,
        origin_lat: origin.lat,
        origin_long: origin.long,
        destination_city: destination.city,
        destination_state: destination.state,
        destination_zip: destination.zip,
        destination_address: destination.address,
        destination_lat: destination.lat,
        destination_long: destination.long,
        equipment_type_ids: equipmentTypes?.map((type) => type.value),
        requirement_type_ids: speciaRequirements?.map((type) => type.value),
        mode_ids: modes?.map((type) => type.value),
        type_ids: laneTypes?.map((type) => type.value),
        volume: Number(volume),
        rate_per_mile: Number(rpm) || 0,
        days_of_week: daysOfWeek.length > 0 ? daysOfWeek.join(",") : null,
      },
    })
      .then((res) => {
        setLoading(false);
        setAddLane(false);
        getCarrierLanes();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      outline: "#C0EAD8",
      margin: "5px auto",
      // height: "35px",
      boxShadow: "none",
    }),
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        // height: "37px",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#3aab7b"
          : isFocused
          ? "#C0EAD8"
          : null,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#C0EAD8",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "black",
    }),
  };
  const setPricing = (type, value) => {
    if (type === "rpm") {
      const newPrice = value * mileage;
      const roundedPrice = Math.round(newPrice);
      setRpm(value);
      setPrice(roundedPrice);
    } else if (type === "price") {
      const newRpm = value / mileage;
      setRpm(newRpm.toFixed(2));
      setPrice(value);
    }
  };
  return (
    <div className="lanes-panel">
      <h3 className="activity-title">
        <span
          style={{
            display: "inline-block",
            marginRight: "5px",
          }}
        >
          <div
            className="customerInfo-icon-modal"
            style={{
              transform: "translateY(0px)",
              background: "rgb(255, 227, 179)",
              width: "30px",
              height: "30px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={LaneIcon} alt="+ Lane" style={{ width: "17px" }} />
          </div>
        </span>
        Add Lane
      </h3>
      <div className="lanes-panel-form">
        <div>
          <label>Origin</label>
          <GoogleAutocompleteComponent
            onPlaceSelect={handleOriginPlaceSelect}
          />
        </div>
        <div>
          <label>Destination</label>
          <GoogleAutocompleteComponent
            onPlaceSelect={handleDestinationPlaceSelect}
          />
        </div>
        <div>
          <label>Linehaul</label>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "14px",
                left: "8px",
                fontWeight: "500",
              }}
            >
              $
            </div>
            <input
              style={{ paddingLeft: "20px", width: "calc(95% - 20px)" }}
              type="number"
              value={price}
              onChange={(e) => setPricing("price", e.target.value)}
            />
          </div>
        </div>
        <div>
          <label>Rate Per Mile</label>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "14px",
                left: "8px",
                fontWeight: "500",
              }}
            >
              $
            </div>
            <input
              style={{ paddingLeft: "20px", width: "calc(95% - 20px)" }}
              type="number"
              value={rpm}
              onChange={(e) => setPricing("rpm", e.target.value)}
            />
          </div>
        </div>
        <div>
          <label>Volume (Weekly)</label>
          <input
            type="number"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
        </div>
      </div>
      <div className="lanes-panel-form-1">
        <div className="lanes-panel-form-1-div">
          <label>Equipment Types</label>
          <Select
            value={equipmentTypes}
            closeMenuOnSelect={false}
            isMulti
            styles={colourStyles}
            options={allEquipmentTypes?.map((type) => {
              return { value: type.id, label: type.name };
            })}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              DropdownIndicator: () => null,
            }}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOptions) => setEquipementTypes(selectedOptions)}
          />
        </div>
        <div className="lanes-panel-form-1-div">
          <label>Modes</label>
          <Select
            value={modes}
            closeMenuOnSelect={false}
            isMulti
            styles={colourStyles}
            options={allModes?.map((type) => {
              return { value: type.id, label: type.name };
            })}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              DropdownIndicator: () => null,
            }}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOptions) => setModes(selectedOptions)}
          />
        </div>
        <div className="lanes-panel-form-1-div">
          <label>Special Requirements</label>
          <Select
            value={speciaRequirements}
            closeMenuOnSelect={false}
            isMulti
            styles={colourStyles}
            options={allSpecialRequirements?.map((type) => {
              return { value: type.id, label: type.name };
            })}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              DropdownIndicator: () => null,
            }}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOptions) =>
              setSpeciaRequirements(selectedOptions)
            }
          />
        </div>
        <div className="lanes-panel-form-1-div">
          <label>Lane Type</label>
          <Select
            value={laneTypes}
            closeMenuOnSelect={false}
            isMulti
            styles={colourStyles}
            options={allLaneTypes?.map((type) => {
              return { value: type.id, label: type.name };
            })}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              DropdownIndicator: () => null,
            }}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOptions) => setLaneTypes(selectedOptions)}
          />
        </div>
      </div>
      <div>
        <label>
          {carrierId ? (
            <>
              Days of Week{" "}
              <span style={{ fontSize: "12px" }}>
                (When their trucks are available for this lane)
              </span>
            </>
          ) : (
            <>
              Days of Week{" "}
              <span style={{ fontSize: "12px" }}>
                (What days the freight ships out for this lane)
              </span>
            </>
          )}
        </label>
        <div
          className="modal-radioButton c-radioButton"
          style={{ marginTop: "5px" }}
        >
          {days.map((day) => (
            <span key={day}>
              <label
                htmlFor={`week_${day}`}
                style={
                  daysOfWeek.includes(day)
                    ? {
                        border: "2px solid #4a9876",
                        outline: "none",
                        color: "#555555",
                        padding: "4px 12px",
                        borderRadius: "9px",
                        marginRight: "8px",
                        background: "#F4F6F5",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        textTransform: "uppercase",
                      }
                    : {
                        color: "#555555",
                        padding: "4px 12px",
                        border: "none",
                        borderRadius: "9px",
                        background: "#F4F6F5",
                        marginRight: "8px",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        textTransform: "uppercase",
                      }
                }
              >
                {day}
                <input
                  type="checkbox"
                  name="category"
                  id={`week_${day}`}
                  className="radio-button"
                  checked={daysOfWeek.includes(day)}
                  value={day}
                  onChange={(e) => daysOfWeekChangeHandler(e)}
                />
              </label>
            </span>
          ))}
        </div>
      </div>
      <div
        className="lanes-panel-form-1-div"
        style={{ width: "100%", maxWidth: "100%", marginRight: "0" }}
      >
        <label>Notes</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ width: "98.5%" }}
        ></textarea>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <button
          type="button"
          className="button-md-black-outline"
          style={{ marginRight: "10px", border: "1px solid #000" }}
          onClick={searchByLane}
          disabled={searchLoader}
        >
          {searchLoader ? "Searching" : "Search"}{" "}
          {carrierId ? "Shippers" : "Carriers"}
        </button>
        <button
          className="button-md"
          type="button"
          disabled={loading}
          onClick={createLane}
        >
          {loading ? "Saving" : "Save"} Lane
          <Check
            size={16}
            color="#62CA9D"
            style={{ transform: "translateY(2px)", marginLeft: "5px" }}
          />
        </button>
      </div>
      <div>
        {origin?.address && destination?.address && (
          <GoogleMapComponent
            origin={origin}
            destination={destination}
            setMileage={setMileage}
          />
        )}
      </div>
      <div
        id={`search-shipper-table-${carrierId}-${accountId}`}
        style={{ overflow: "auto" }}
      >
        {accounts.length > 0 && (
          <table className="accountList__table">
            <thead>
              <th>{carrierId ? "Shipper" : "Carrier"}</th>
              <th>Owner</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Equipment Types</th>
              <th>Spec. Req.</th>
              <th>Price</th>
              <th>Description</th>
            </thead>
            <tbody>
              {accounts?.map((account) => {
                const owner = account.owners
                  ?.map((owner) => owner.name)
                  ?.join(", ");
                const name = carrierId
                  ? account.account_name
                  : account.carrier_name;
                return (
                  <tr key={account.id}>
                    <td>{name}</td>
                    <td>{owner}</td>
                    <td>{account.origin_address}</td>
                    <td>{account.destination_address}</td>
                    <td>
                      {account.equipment_types
                        ?.map((type) => type.name)
                        ?.join(", ")}
                    </td>
                    <td>
                      {account.requirement_types
                        ?.map((type) => type.name)
                        ?.join(", ")}
                    </td>
                    <td>{account.price}</td>
                    <td>{account.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allLaneTypes: state.allLaneTypes.laneTypes,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
  };
};

export default connect(MSP, MDP)(AddLanePanel);
