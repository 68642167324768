import React, { Component } from "react";
import InputField from "./Reusables/InputField/InputField";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import MuiButton from "./Reusables/Button/MuiButton";
import MuiSelect from "./Reusables/Select/MuiSelect";
import AddIcon from "@mui/icons-material/Add";

class OnboardingInfoModal extends Component {
  state = {};

  render() {
    const industryData = [
      { value: "Freight Brokerage", label: "Freight Brokerage" },
      { value: "others", label: "Other" },
    ];
    const currencyData = [
      { value: "USD", label: "USD" },
      { value: "CAD", label: "CAD" },
      { value: "MXN", label: "MXN" },
    ];
    const countryData = [
      { value: "United States", label: "United States" },
      { value: "Canada", label: "Canada" },
      { value: "Mexico", label: "Mexico" },
    ];

    const employeSizeData = [
      { value: "4", label: "1-4" },
      { value: "9", label: "5-9" },
      { value: "19", label: "10-19" },
      { value: "49", label: "20-49" },
      { value: "99", label: "50-99" },
      { value: "249", label: "100-249" },
      { value: "499", label: "250-499" },
      { value: "500", label: "500+" },
    ];

    const dateData = [
      { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
      { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
    ];

    const style = {
      content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: "4px",
        outline: "none",
      },
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
        zIndex: 20000002,
      },
    };
    return (
      <div>
        <Modal
          open={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onClose={this.props.handleCloseModal}
          style={style}
          ariaHideApp={false}
        >
          <div className="muiModal">
            <div
              className="modal__content"
              style={{
                height: "82vh",
                top: "10%",
                paddingTop: "24px",
              }}
            >
              <div className="modal__header">
                <div className="modal__head">
                  <h2 className="modal__heading">Get Started With Salesdash</h2>
                </div>
              </div>
              <form
                className="modal__form"
                style={{
                  padding: "15px",
                  overflowY: "scroll",
                  maxHeight: "60vh",
                  WebkitOverflowScrolling: "touch",
                }}
                onSubmit={this.props.submitHandler}
              >
                <div
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  <InputField
                    name="name"
                    type="text"
                    className="muiFields"
                    value={this.props.name}
                    onChange={this.props.changeHandler}
                    label="Company Name"
                    error={
                      this.props.errorMessage === true &&
                      (this.props.name === null || this.props.name === "")
                    }
                    helperText="Company Name is required"
                  />
                </div>

                <div
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  <InputField
                    name="mobile"
                    className="muiFields"
                    value={this.props.mobile}
                    onChange={this.props.changeHandler}
                    label="Phone"
                    error={
                      this.props.errorMessage === true &&
                      (this.props.mobile === null || this.props.mobile === "")
                    }
                    helperText="Phone number is required"
                  />
                </div>
                <MuiSelect
                  error={
                    this.props.errorMessage === true &&
                    (this.props.industry === null ||
                      this.props.industry === "" ||
                      (this.props.industry === "others" &&
                        this.props.industryOther === ""))
                  }
                  helperText="Industry is required"
                  label="Industry"
                  name="industry"
                  value={this.props.industry}
                  onChange={this.props.changeHandler}
                  menuItem={industryData?.map((industry, index) => (
                    <MenuItem
                      key={index + industry.value}
                      value={industry.value}
                    >
                      {industry.label}
                    </MenuItem>
                  ))}
                />

                {this.props.industry === "others" && (
                  <div style={{ marginBottom: "15px" }}>
                    <InputField
                      name="industryOther"
                      value={this.props.industryOther}
                      onChange={this.props.changeHandler}
                      label="What industry is your business in?"
                    />
                  </div>
                )}

                <MuiSelect
                  label="Employee Size"
                  name="employeeSize"
                  error={
                    this.props.errorMessage === true &&
                    (this.props.employeeSize === null ||
                      this.props.employeeSize === "" ||
                      this.props.employeeSize === undefined)
                  }
                  helperText="Please select number of employees in your company"
                  value={this.props.employeeSize}
                  onChange={this.props.changeHandler}
                  menuItem={employeSizeData?.map((employe, index) => (
                    <MenuItem key={index + employe.value} value={employe.value}>
                      {employe.label}
                    </MenuItem>
                  ))}
                />

                <MuiSelect
                  error={
                    this.props.errorMessage === true &&
                    (this.props.currency === null ||
                      this.props.currency === "" ||
                      this.props.currency === undefined)
                  }
                  hyperText="Please select default currency for your company"
                  label="Currency"
                  name="currency"
                  value={this.props.currency}
                  onChange={this.props.changeHandler}
                  menuItem={currencyData?.map((currency, index) => (
                    <MenuItem
                      key={currency.value + index}
                      value={currency.value}
                      sx={{ fontSize: "14px" }}
                    >
                      {currency.label}
                    </MenuItem>
                  ))}
                />

                <MuiSelect
                  label="Country"
                  name="country"
                  value={this.props.country}
                  onChange={this.props.changeHandler}
                  menuItem={countryData?.map((country, index) => (
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      key={country.value + index}
                      value={country.value}
                    >
                      {country.label}
                    </MenuItem>
                  ))}
                />

                <MuiSelect
                  label="Date Format"
                  name="dateFormat"
                  value={this.props.dateFormat}
                  onChange={this.props.changeHandler}
                  menuItem={dateData?.map((date, index) => (
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      key={date.value + index}
                      value={date.value}
                    >
                      {date.label}
                    </MenuItem>
                  ))}
                />

                <div className="muiButtons">
                  <MuiButton
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "21%",
                      padding: "2px 17px",
                      width: "auto",
                      height: "35px",
                      backgroundColor: "#252a2d",
                      color: "white",
                      textTransform: "none",
                      fontWeight: 700,
                    }}
                    type="submit"
                    className="saveBtn"
                    loading={this.props.loader}
                    endIcon={<AddIcon style={{ color: "#61c99d" }} />}
                  >
                    {" "}
                    {this.props.loader ? "Creating" : "Create"}{" "}
                  </MuiButton>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OnboardingInfoModal;
