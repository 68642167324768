import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DropdownAnalyticsFilter from "../Dropdown/DropdownAnalyticsFilter";
import DropdownCustomRangeFilter from "../Dropdown/DropdownCustomRangeFilter";
import DropdownDashboard from "../Dropdown/DropdownDashboard";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";

const CampaignMetricesReport = (props) => {
  const [campaignReport, setCampaignReport] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(props.time);
  const [toDate, setToDate] = useState(props.end_time ? props.end_time : "");
  const [fromDate, setFromDate] = useState(
    props.start_time ? props.start_time : ""
  );
  const [user_ids, setUser_ids] = useState(props.authUser.id);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.authUser.name);

  const tableHeaders = [
    { key: "owner", title: "Owner", sort: true },
    { key: "name", title: "Record Name", sort: true },
    { key: "status", title: "Status/Stage", sort: true },
    { key: "formatted_phone", title: "Phone", sort: true },
    { key: "formatted_mobile", title: "Mobile", sort: true },
    { key: "linkedin", title: "LinkedIn", sort: true },
    { key: "opened_at", title: "Opened At", sort: true },
    { key: "date", title: "Last Activity Date", sort: true },
  ];
  const dateFilters = [
    { label: "Last 7 days", value: "last_7_days" },
    { label: "Month to date", value: "this_month" },
    { label: "Last 4 weeks", value: "last_4_weeks" },
    { label: "Last 3 months", value: "last_3_months" },
    { label: "Last 6 months", value: "last_6_months" },
    { label: "Last 12 months", value: "last_12_months" },
    { label: "All time", value: "all_time" },
  ];
  const fetchReports = (user_ids = props.authUser.id) => {
    setLoading(true);
    let url = `/email-campaigns/stats-records?type=${props.type}`;
    if (selectedDateFilter === "custom_range") {
      url += `&start_date_from=${moment(fromDate).format(
        "YYYY-MM-DD"
      )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
    } else {
      url += `&time=${selectedDateFilter}`;
    }
    if (user_ids) {
      url += `&user_ids=${user_ids}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      setLoading(false);
      setTotal(res.data.total);
      setCampaignReport(res.data.campaign_records);
    });
  };
  const handleUserId = (user_ids, userName) => {
    setUser_ids(user_ids);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    fetchReports(user_ids);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };
  useEffect(() => {
    fetchReports();
  }, []);
  useEffect(() => {
    fetchReports(user_ids);
  }, [selectedDateFilter]);

  const dateFilterChangeHandler = (value) => {
    setSelectedDateFilter(value);
    setToDate("");
    setFromDate("");
  };
  const applyCustomDateFilter = () => {
    setSelectedDateFilter("custom_range");
    fetchReports(user_ids);
  };
  const dateChangeHandler = (date, name) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    name === "toDate" ? setToDate(offsetDate) : setFromDate(offsetDate);
  };
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>
            Bulk Email (
            <span style={{ textTransform: "capitalize" }}>{props.name}</span>){" "}
            <span style={{ color: "#4A9876" }}>{total}</span>
          </h2>
          <p> </p>
        </div>
        <div style={{ float: "right" }}>
          <DropdownDashboard
            handleUserId={handleUserId}
            user_ids={user_ids}
            userName={props.authUser.name}
            userId={props.authUser.id}
            displayMenu={displayMenu}
            showDropdownMenu={showDropdownMenu}
            displayUserName={displayUserName}
            userRole={props.authUser.role}
          />
        </div>
      </div>
      <div className="email-analytics-filters">
        <h3>Filters: </h3>
        <div style={{ display: "flex" }}>
          <div className="email-analytics-range" style={{ marginLeft: "0" }}>
            <DropdownAnalyticsFilter
              selectedDateFilter={selectedDateFilter}
              dateFilters={dateFilters}
              dateFilterChangeHandler={dateFilterChangeHandler}
              toDate={toDate}
              fromDate={fromDate}
            />
          </div>
          <div style={{ margin: "0 20px 0 0" }}>
            <DropdownCustomRangeFilter
              toDate={toDate}
              fromDate={fromDate}
              dateChangeHandler={dateChangeHandler}
              submitHandler={applyCustomDateFilter}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : !loading & (campaignReport.length === 0) ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No campaign found
        </div>
      ) : (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <thead>
            <tr>
              {tableHeaders
                .filter((header) =>
                  props.type !== "open" ? header.key !== "opened_at" : header
                )
                .filter((header) =>
                  props.type !== "open" &&
                  props.type !== "clicks" &&
                  props.type !== "reply"
                    ? header.key !== "linkedin" &&
                      header.key !== "formatted_phone" &&
                      header.key !== "formatted_mobile"
                    : header
                )
                .map((header) => (
                  <th>{header.title}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {campaignReport?.map((report) => {
              const type = report.contact_id
                ? "contact"
                : report.account_id
                ? "account"
                : "opportunity";
              const name = report[`${type}_name`];
              const linkedin = report[`${type}_linkedin`];
              const mobile = report[`${type}_formatted_mobile`];
              const phone = report[`${type}_formatted_phone`];
              const status =
                type === "opportunity"
                  ? report.stage_name
                  : report[`${type}_status`];
              const id = report[`${type}_id`];
              const owner = report[`${type}_owner`];
              const lastActivityDate = report[`${type}_last_activity_date`];
              let newTabData = {
                type,
                id: id,
                name: name,
              };
              return (
                <tr key={report.id}>
                  <td>{owner}</td>
                  <td
                    style={{ cursor: "pointer", color: "#4A9876" }}
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        props.setTab({ ...newTabData, blank: true });
                      } else {
                        props.setActiveTabIndex(props.totalTabs + 1);
                        props.setTab(newTabData);
                        props.history.push("/active-tabs");
                      }
                    }}
                  >
                    {name}
                  </td>
                  <td>{status}</td>
                  {(props.type === "open" ||
                    props.type === "clicks" ||
                    props.type === "reply") && (
                    <td>
                      <a
                        target="_blank"
                        data-stringify-link={`tel:${phone
                          ?.toString()
                          ?.split("")
                          ?.filter(
                            (num) =>
                              num !== "-" &&
                              num !== " " &&
                              num !== "." &&
                              num !== "(" &&
                              num !== ")"
                          )
                          ?.join("")}`}
                        delay="150"
                        data-sk="tooltip_parent"
                        href={`tel:${phone
                          ?.toString()
                          ?.split("")
                          ?.filter(
                            (num) =>
                              num !== "-" &&
                              num !== " " &&
                              num !== "." &&
                              num !== "(" &&
                              num !== ")"
                          )
                          ?.join("")}`}
                        rel="noopener noreferrer"
                        tabindex="-1"
                        data-remove-tab-index="true"
                      >
                        {phone}
                      </a>
                    </td>
                  )}
                  {(props.type === "open" ||
                    props.type === "clicks" ||
                    props.type === "reply") && (
                    <td>
                      <a
                        target="_blank"
                        data-stringify-link={`tel:${mobile
                          ?.toString()
                          ?.split("")
                          ?.filter(
                            (num) =>
                              num !== "-" &&
                              num !== " " &&
                              num !== "." &&
                              num !== "(" &&
                              num !== ")"
                          )
                          ?.join("")}`}
                        delay="150"
                        data-sk="tooltip_parent"
                        href={`tel:${mobile
                          ?.toString()
                          ?.split("")
                          ?.filter(
                            (num) =>
                              num !== "-" &&
                              num !== " " &&
                              num !== "." &&
                              num !== "(" &&
                              num !== ")"
                          )
                          ?.join("")}`}
                        rel="noopener noreferrer"
                        tabindex="-1"
                        data-remove-tab-index="true"
                      >
                        {mobile}
                      </a>
                    </td>
                  )}
                  {(props.type === "open" ||
                    props.type === "clicks" ||
                    props.type === "reply") && (
                    <td>
                      <a
                        href={linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-desc"
                        style={{ cursor: "pointer", color: "#4A9876" }}
                      >
                        {linkedin && linkedin.length > 40
                          ? linkedin.substring(0, 39) + "..."
                          : linkedin}
                      </a>
                    </td>
                  )}
                  {props.type === "open" && (
                    <td>
                      {props.type === "open" &&
                        moment(report.opened_at).format("LLL")}
                    </td>
                  )}
                  <td>
                    {lastActivityDate && moment(lastActivityDate).format("LLL")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(CampaignMetricesReport);
