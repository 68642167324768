import axios from "axios";
import React, { useEffect, useState } from "react";
import CreateTaskSequenceModal from "./CreateTaskSequenceModal";
import EditTaskSequenceModal from "./EditTaskSequenceModal";
import { Plus } from "lucide-react";

function TaskSequenceList() {
  const [sequences, setSequences] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSeq, setSelectedSeq] = useState({
    seqName: "",
    steps: [],
    seqId: null,
  });

  const getSeq = () => {
    axios({
      method: "GET",
      url: `/task-sequences`,
    }).then((res) => setSequences(res.data.task_sequences));
  };
  useEffect(() => {
    getSeq();
  }, []);
  return (
    <div className="reports">
      <CreateTaskSequenceModal
        showOpportunityModal={showModal}
        setShowOpportunityModal={() => setShowModal(true)}
        handleCloseModal={() => setShowModal(false)}
        getSeq={getSeq}
      />
      <EditTaskSequenceModal
        showOpportunityModal={showEditModal}
        setShowOpportunityModal={() => setShowEditModal(true)}
        handleCloseModal={() => setShowEditModal(false)}
        getSeq={getSeq}
        seqName={selectedSeq.seqName}
        steps={selectedSeq.steps}
        seqId={selectedSeq.seqId}
      />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>Task Sequences</h2>
          <p> </p>
        </div>
        <button
          className="button-md"
          onClick={() => setShowModal(true)}
          style={{
            float: "right",
            marginRight: "20px",
            marginTop: "10px",
            textDecoration: "none",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          Task Sequence
          <Plus
            size={14}
            color="#62CA9D"
            strokeWidth={3}
            style={{ transform: "translateY(2px)", marginLeft: "5px" }}
          />
        </button>
      </div>
      <table
        className="accountList__table"
        style={{ marginTop: "20px", width: "93vw" }}
      >
        <thead>
          <tr>
            <th>Sequence Name</th>
            <th>Number of tasks</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sequences?.map((seq) => (
            <tr key={seq.id}>
              <td>{seq.name}</td>
              <td>{seq.tasks.length}</td>
              <td>
                <button
                  type="button"
                  style={{
                    border: "1px solid #ffffff",
                    borderRadius: "9px",
                    padding: "5px 10px",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "18px",
                    color: "#73738C",
                    background: "#F4F6F5",
                    height: "35px",
                    width: "75px",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() => {
                    setSelectedSeq({
                      seqName: seq.name,
                      seqId: seq.id,
                      steps: seq.tasks?.map((task) => ({
                        name: task.name,
                        category_id: task.category_id.toString(),
                        start_time: task.start_time,
                        end_time: task.end_time,
                        delay: task.interval,
                      })),
                    });
                    setShowEditModal(true);
                  }}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TaskSequenceList;
