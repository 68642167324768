import React, { Component } from "react";
import DeleteIconRed from "../../images/DeleteIconRed.svg";
import { EllipsisVertical } from "lucide-react";
class ReportExitDropdown extends Component {
  state = {
    displayExitMenu: false,
  };
  exitRef = React.createRef();
  handleExitClickOutside = (event) => {
    if (
      this.state.displayExitMenu &&
      this.exitRef &&
      !this.exitRef.current?.contains(event.target)
    ) {
      this.setState({ displayExitMenu: false });
    }
  };
  showExitDropdownMenu = (e) => {
    e.preventDefault();
    this.setState({ displayExitMenu: !this.state.displayExitMenu });
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleExitClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleExitClickOutside);
  }
  render() {
    return (
      <div
        className="dropdownTask"
        style={{
          background: "transparent",
          width: "40px",
          marginLeft: "10px",
        }}
      >
        <div
          className="button"
          style={{
            fontSize: "14px",
            color: "black",
            width: "40px",
            right: "0",
            top: "-16px",
          }}
          onClick={this.showExitDropdownMenu}
        >
          <div
            style={{
              border: "1px solid #000",
              height: "18px",
              width: "20px",
              padding: "9px",
              borderRadius: "4px",
            }}
          >
            <EllipsisVertical size={18} />
          </div>
        </div>

        {this.state.displayExitMenu ? (
          <ul
            style={{
              width: "170px",
              background: "white",
              top: "40px",
              right: "0px",
            }}
            ref={this.exitRef}
          >
            <li
              style={{
                width: "150px",
                fontSize: "14px",
                color: "#D32F2F",
                cursor: "pointer",
              }}
              onClick={this.props.handleDeleteOpenModal}
            >
              <img src={DeleteIconRed} alt="Delete" width="14px" /> Delete
            </li>
            <li
              style={{
                width: "150px",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={this.props.handleExitOpenModal}
            >
              <i className="fa fa-close"></i>
              &nbsp;Exit without Saving
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default ReportExitDropdown;
