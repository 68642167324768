import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const marks = [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];

const SDSlider = styled(Slider)({
  color: "#4a9876",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  // "& .MuiSlider-thumb": {
  //   height: 24,
  //   width: 24,
  //   backgroundColor: "#fff",
  //   border: "2px solid currentColor",
  //   "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
  //     boxShadow: "inherit",
  //   },
  //   "&::before": {
  //     display: "none",
  //   },
  // },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#4a9876",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const RangeSlider = ({ width = 300, value = 25, handleChange }) => {
  return (
    <Box sx={{ width }}>
      <SDSlider
        getAriaLabel={() => "Minimum distance shift"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        disableSwap
        step={25}
        min={0}
        max={500}
        marks={marks.map((mark) => ({ value: mark, label: mark.toString() }))}
        color="success"
      />
    </Box>
  );
};

export default RangeSlider;
