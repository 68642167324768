import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import axios from "axios";
import AccountIcon from "../../images/AccountIcon.svg";
import CarrierGreenIcon from "../../images/CarrierGreenIcon.svg";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import { countriesList } from "../../constants/countriesList";
import Autocomplete from "react-autocomplete";
import { colourStyles } from "../../constants/selectStyles";
import { usStatesList } from "../../constants/usStatesList";
import {
  isValidCurrency,
  getCurrencyValue,
} from "../../utils/Helper/reusableFunctions";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import { MenuItem, Modal } from "@mui/material";
import MuiSelect from "../Reusables/Select/MuiSelect";
import InputField from "../Reusables/InputField/InputField";
import MuiButton from "../Reusables/Button/MuiButton";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import { muiModalStyle } from "../../constants/muiModalStyle";


ReactModal.defaultStyles.overlay.zIndex = 10000001;

class CreateContact extends Component {
  state = {
    fieldsData: [],
    name: null,
    source: null,
    role: null,
    email: null,
    formatted_phone: null,
    phone_ext: null,
    zip: null,
    city: null,
    address: null,
    linkedin: null,
    state: null,
    country: "United States",
    preferred_communication: [],
    frontendPreferredCommunicationArray: [],
    customFields: {},
    customFieldsForAPI: {},
    customFieldsOfAccount: {},
    errorMessage: false,
    isLoading: false,
    windowWidth: window.innerWidth,
    formatted_mobile: null,
    date_of_birth: null,
    contactOwner: null,
    phoneValidationError: false,
    emailValidationError: false,
    searchItems: [],
    searchValue: null,
    account_id: null,
    carrier_id: null,
    customFieldRevenueTypeCheck: [],
    menuOpen: "bottom",
    requiredFields: {
      name: { required: true },
      phone: { required: false },
      phone_ext: { required: false },
      mobile: { required: false },
      email: { required: false },
      role: { required: false },
      address: { required: false },
      linkedin: { required: false },
      city: { required: false },
      state: { required: false },
      zip: { required: false },
      country: { required: false },
      preferred_communication: { required: false },
      source: { required: false },
      date_of_birth: { required: false },
    },
    isOpen: false,
    isHovered: false,
  };

  handleClick = () => {
    this.setState({ isOpen: true }); // Open the calendar on input click
  };

  fetchRequiredFields = () => {
    axios({
      method: "GET",
      url: `/admin/settings`,
    }).then((res) =>
      this.setState({
        requiredFields: res.data.settings.contact,
      })
    );
  };
  searchValueChangeHandler = (e) => {
    this.setState({
      searchValue: e.target.value,
      account_id: null,
      carrier_id: null,
    });
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/accounts/search?query=${value}`,
    }).then((res) => {
      let isNewAccount =
        res.data.accounts.length === 0 && e.target.value.length > 1;
      this.setState({
        searchItems: isNewAccount
          ? [{ id: "create-new-account", name: e.target.value }]
          : res.data.accounts,
      });
    });
  };
  createNewAccountFromContact = (item) => {
    axios({
      method: "POST",
      url: `/accounts/create`,
      data: {
        owners: [
          {
            is_primary: true,
            owner_id: this.props.authUser.id,
          },
        ],
        name: item.name,
        source: null,
        state: null,
        zip: null,
        city: null,
        address: null,
        linkedin: null,
        country: null,
        email: null,
        formatted_phone: null,
        phone_ext: null,
        website: null,
        description: null,
        preferred_communication: [],
        custom_fields: this.state.customFieldsOfAccount,
      },
    })
      .then((res) => {
        this.setState({
          account_id: res.data.account.account_id,
          searchValue: item.name,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          NotificationManager.error("Account already created.");
        } else {
          NotificationManager.error("Error creating account.");
        }
      });
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      name: null,
      source: null,
      email: null,
      formatted_phone: null,
      phone_ext: null,
      role: null,
      formatted_mobile: null,
      date_of_birth: null,
      preferred_communication: [],
      frontendPreferredCommunicationArray: [],
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      isLoading: false,
      phoneValidationError: false,
      emailValidationError: false,
      zip: null,
      city: null,
      address: null,
      linkedin: null,
      state: null,
      country: "United States",
      searchItems: [],
      searchValue: null,
      account_id: null,
      carrier_id: null,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.fetchContactsData();
      this.fetchAccountData();
      this.fetchRequiredFields();
      this.setState({
        contactOwner: this.props.authUser.id,
        country: this.props.companyOnboarding?.country
          ? this.props.companyOnboarding?.country
          : "United States",
      });
    }
  }
  fetchAccountData = () => {
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    this.setState({
      customFieldsOfAccount: obj,
    });
  };
  fetchContactsData = () => {
    this.setState((prevState) => ({
      fieldsData: this.props.allContactCustomFields,
    }));
    const customField = this.props.allContactCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allContactCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("mousemove", this.mouseCoordinates, {
      passive: true,
    });
    this.fetchContactsData();
    this.fetchAccountData();
    this.fetchRequiredFields();
    this.setState({
      contactOwner: this.props.authUser.id,
      country: this.props.companyOnboarding?.country
        ? this.props.companyOnboarding?.country
        : "United States",
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getFields = () => {
    this.state.fields.fields.map((item, i) => {
      return console.log(item.label);
    });
  };
  changeHandler = (e) => {
    const { value, name } = e.target;
    if (name === "preferred_communication") {
      if (e.target.checked === true) {
        this.setState({
          preferred_communication: [
            ...this.state.preferred_communication,
            value,
          ],
        });
      } else {
        this.setState({
          preferred_communication: this.state.preferred_communication.filter(
            (pc) => pc !== value
          ),
        });
      }
    } else if (name === "formatted_mobile" || name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      const formattedValue = formatter.input(value); // Format the input value
      this.setState({
        [name]: formattedValue ? formattedValue : null,
      });
    } else {
      this.setState({
        [name]: value === "" ? null : value,
      });
    }
  };
  changeHandlerSelect = (name, value) => {
    this.setState({
      [name]: value.value,
    });
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      !phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        phoneValidationError: false,
      });
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      emailValidator.test(this.state.email)
    ) {
      this.setState({
        emailValidationError: false,
      });
    }
    const data = this.state;
    // if (data.formatted_phone !== null) data.formatted_phone = data.formatted_phone.replace(/-+ /g, "");
    if (data.name === null || data.name === "") {
      this.setState({ errorMessage: true, isLoading: false });
      return;
    }
    this.setState({ isLoading: true });
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    axios({
      method: "POST",
      url: `/contacts/create`,
      data: {
        contact_owner_id: this.state.contactOwner,
        account_id:
          this.props.accountId === undefined
            ? this.state.account_id
            : this.props.accountId,
        carrier_id:
          this.props.carrierId === undefined
            ? this.state.carrier_id
            : this.props.carrierId,
        opportunity_id:
          this.props.opportunityId === undefined
            ? null
            : this.props.opportunityId,
        name: this.state.name,
        role: this.state.role,
        formatted_phone: this.state.formatted_phone,
        phone_ext: this.state.phone_ext,
        formatted_mobile: this.state.formatted_mobile,
        date_of_birth: this.state.date_of_birth,
        email: this.state.email,
        source: this.state.source,
        preferred_communication: this.state.preferred_communication,
        zip: this.state.zip,
        city: this.state.city,
        address: this.state.address,
        state: this.state.state,
        linkedin:
          this.state.linkedin === null
            ? null
            : this.state.linkedin.startsWith("https://") ||
              this.state.linkedin.startsWith("http://")
            ? this.state.linkedin
            : "https://" + this.state.linkedin,
        country: this.state.country,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "contact",
          id: response.data.contact.contact_id,
          name: response.data.contact.name,
        };

        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
        this.handleCloseModal();
      })
      .then((response) => {
        NotificationManager.success("Contact created successfully!");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating contact.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => this.fetchContactsData());
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired
  ) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldRevenueChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
            multiline={true}
          />
        </div>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="number"
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={this.customFieldChangeHandler}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
        >
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            menuPlacement={this.state.menuOpen}
            value={{
              value: this.state.customFields[fieldName],
              label: this.state.customFields[fieldName],
            }}
            onChange={(selected) =>
              this.customFieldSelectChangeHandler(fieldName, selected.value)
            }
            isClearable={false}
            isSearchable={true}
            name={fieldName}
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value, label: value };
            })}
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px", marginBottom: "15px" }}>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left", marginBottom: "15px" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%",  marginBottom: "15px" }}
        >
          <DatePicker
            name={fieldName}
            className="datePicker"
            selected={Date.parse(this.state.customFields[fieldName])}
            value={Date.parse(this.state.customFields[fieldName])}
            onChange={(date) =>
              this.customFieldDateChangeHandler(fieldName, date)
            }
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            placeholderText={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/mm/yyyy"
                : "mm/dd/yyyy"
            }
            autoComplete="off"
            required={fieldRequired}
            popperPlacement="top"
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
        >
          <Select
            value={multiSelectDropdownValue}
            closeMenuOnSelect={false}
            menuPlacement={this.state.menuOpen}
            isMulti
            name={fieldName}
            styles={colourStyles}
            placeholder="-Select-"
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value: value, label: value };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOption) =>
              this.customFieldMultiDropdownChangeHandler(
                fieldName,
                selectedOption
              )
            }
          />
          <label className="activeLabel">
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    }
    return <div>{inputType}</div>;
  };

  dobChangeHandler = (date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      date_of_birth: offsetDate,
    });
  };
  render() {
    const {
      name,
      source,
      role,
      email,
      formatted_phone,
      phone_ext,
      formatted_mobile,
      // preferred_communication,
    } = this.state;
    const { fieldsData } = this.state;


    return (
      <div>
        <Modal
          open={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onClose={this.handleCloseModal}
          ariaHideApp={false}
          style={muiModalStyle}
        >
          <div className="muiModal">
            <div className="modal__content">
              <div className="modal__header">
                {/* <span>
                <div className="customerInfo-icon">
                    <img
                      src={AccountIcon}
                      alt=""
                      width="17px"
                      style={{ transform: "translateY(9px)" }}
                    />
                  </div> 
                </span> */}
                <div className="modal__head">
                  <h2 className="modal__heading">Create Contact</h2>
                </div>
                <button
                  onClick={this.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    height: "12px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  <ClearIcon fontSize="small" />
                </button>
              </div>
              <form
                className="modal__form"
                style={{
                  overflowY: "scroll",
                  maxHeight: "60vh",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <div style={{ marginBottom: "0px", marginTop: "15px" }}>
                  <MuiSelect
                    label="Contact Owner *"
                    value={this.state.contactOwner}
                    onChange={this.changeHandler}
                    name="contactOwner"
                    menuItem={this.props.allUsers?.map((user) => {
                      const { id, name } = user;
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  />
                </div>

                {/* <label>
                  Contact Owner{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label>
                <select
                  value={this.state.contactOwner}
                  onChange={this.changeHandler}
                  name="contactOwner"
                  style={{ width: "99%" }}
                >
                  {this.props.allUsers?.map((user) => {
                    const { id, name } = user;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select> */}
                {/* <label>
                  {this.props.carrierId || this.state.carrier_id
                    ? "Carrier"
                    : "Account"}
                </label> */}
                <div className="modal-searchable-contact-div">
                  {this.props.carrierName || this.props.accountName ? (
                    <InputField
                      label={
                        this.props.carrierId || this.state.carrier_id
                          ? "Carrier"
                          : "Account"
                      }
                      value={
                        this.props.carrierName
                          ? this.props.carrierName
                          : this.props.accountName
                      }
                      readOnly
                    />
                  ) : (
                    <Autocomplete
                      inputProps={{
                        style: {
                          border: "1px solid #c5c5d3",
                          borderRadius: "4px",
                          padding: "12px",
                          width: "93%",
                          margin: "5px 0",
                          display: "block",
                        },
                        placeholder: "Search Account",
                      }}
                      items={this.state?.searchItems}
                      menuStyle={{
                        borderRadius: "3px",
                        // left: "70px",
                        display: "block",
                        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                        background: "#252a2d",
                        padding: "3px 0",
                        position: "fixed",
                        overflow: "auto",
                        maxHeight: "250px",
                        zIndex: "2147483647",
                      }}
                      getItemValue={(item) => item.name}
                      renderItem={(item, highlighted) => {
                        const { type, id } = item;
                        let logo = (
                          <img
                            src={
                              type === "carrier"
                                ? CarrierGreenIcon
                                : AccountIcon
                            }
                            alt=""
                            width="13"
                            key={id + type}
                            style={{ transform: "translateY(9px)" }}
                          />
                        );
                        return (
                          <div
                            key={`${item.id}${item.type}`}
                            style={{
                              backgroundColor: highlighted
                                ? "#3B4043"
                                : "#252a2d",
                              color: "#CFCFD8",
                              zIndex: "2147483647",
                              fontSize: "16px",
                              cursor: "pointer",
                              borderBottom: "1px solid black",
                              padding: "5px 0",
                            }}
                          >
                            <div style={{ paddingLeft: "15px" }}>
                              {logo}
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  marginLeft: "15px",
                                }}
                              >
                                {item.id === "create-new-account" && "Create "}
                                {item.name.length > 23
                                  ? item.name.substring(0, 22)
                                  : item.name}
                              </span>
                            </div>
                            <div
                              style={{
                                marginLeft: "45px",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                fontWeight: "300",
                                color: "#A5A1AC",
                              }}
                            >
                              {item.id === "create-new-account"
                                ? "Create new Account"
                                : item.type === "carrier"
                                ? "Carrier"
                                : "Account"}
                            </div>
                          </div>
                        );
                      }}
                      value={this.state.searchValue}
                      onChange={this.searchValueChangeHandler}
                      onSelect={(value, item) => {
                        item.id === "create-new-account"
                          ? this.createNewAccountFromContact(item)
                          : this.setState({
                              ...(item.type === "carrier"
                                ? { carrier_id: item.id }
                                : { account_id: item.id }),
                              searchValue: item.name,
                            });
                      }}
                    />
                  )}
                </div>

                <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                  <InputField
                    label="Contact Name *"
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.changeHandler}
                    error={
                      this.state.errorMessage === true &&
                      (this.state.name === null || this.state.name === "")
                    }
                    helperText="Name is required"
                  />
                </div>
                {/* <label>
                  Contact Name{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  // required
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div> */}

                <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Phone number"
                    type="tel"
                    name="formatted_phone"
                    value={formatted_phone}
                    onChange={this.changeHandler}
                    required={this.state.requiredFields["phone"].required}
                  />
                </div>
                {/* <label>
                  Phone number{" "}
                  {this.state.requiredFields["phone"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  type="tel"
                  name="formatted_phone"
                  value={formatted_phone}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["phone"].required}
                />
                {this.state.phoneValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid phone number
                  </div>
                )} */}

                <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Phone Extension"
                    type="text"
                    name="phone_ext"
                    value={phone_ext}
                    onChange={this.changeHandler}
                    required={
                      this.state.requiredFields?.["phone_ext"]?.required
                    }
                  />
                </div>
                {/* <label>
                  Phone Extension{" "}
                  {this.state.requiredFields?.["phone_ext"]?.required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="phone_ext"
                  value={phone_ext}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields?.["phone_ext"]?.required}
                /> */}

                <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Mobile"
                    type="tel"
                    name="formatted_mobile"
                    // pattern="[0-9\-]+"
                    value={formatted_mobile}
                    onChange={this.changeHandler}
                    required={this.state.requiredFields["mobile"].required}
                  />
                </div>
                {/* <label>
                  Mobile{" "}
                  {this.state.requiredFields["mobile"]?.required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  type="tel"
                  name="formatted_mobile"
                  // pattern="[0-9\-]+"
                  value={formatted_mobile}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["mobile"].required}
                /> */}

                <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Email"
                    pattern='/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.changeHandler}
                    required={this.state.requiredFields["email"].required}
                    error={this.state.emailValidationError}
                    helperText="Please enter a valid email"
                  />
                </div>
                {/* <label>
                  Email{" "}
                  {this.state.requiredFields["email"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  pattern='/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["email"].required}
                />
                {this.state.emailValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid email
                  </div>
                )} */}

                <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Job Title"
                    type="text"
                    name="role"
                    value={role}
                    onChange={this.changeHandler}
                    required={this.state.requiredFields["role"].required}
                  />
                </div>
                {/* <label>
                  Job Title{" "}
                  {this.state.requiredFields["role"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="role"
                  value={role}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["role"].required}
                /> */}
                {/* <h3 className="modal__heading-sub">Work Address</h3> */}

                <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                  <InputField
                    label="Address"
                    type="text"
                    name="address"
                    value={this.state.address}
                    onChange={this.changeHandler}
                    required={this.state.requiredFields["address"].required}
                  />
                </div>
                {/* <label>
                  Address{" "}
                  {this.state.requiredFields["address"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="address"
                  value={this.state.address}
                  onChange={this.changeHandler}
                  required={this.state.requiredFields["address"].required}
                /> */}
                <div className="modal__address">
                  <div className="fieldSizing">
                    <InputField
                      label="City"
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.changeHandler}
                      className="modal__address-city"
                      required={this.state.requiredFields["city"].required}
                    />
                    {/* <label>
                      City{" "}
                      {this.state.requiredFields["city"].required && (
                        <span
                          style={{
                            color: "#4A9876",
                            fontSize: "17px",
                            lineHeight: "16px",
                          }}
                        >
                          *
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.changeHandler}
                      className="modal__address-city"
                      required={this.state.requiredFields["city"].required}
                    /> */}
                  </div>
                  <div>
                    {/* <label>
                      State{" "}
                      {this.state.requiredFields["state"].required && (
                        <span
                          style={{
                            color: "#4A9876",
                            fontSize: "17px",
                            lineHeight: "16px",
                          }}
                        >
                          *
                        </span>
                      )}
                    </label> */}
                    {/* <input
                        type="text"
                        name="state"
                        value={this.state.state}
                        onChange={this.changeHandler}
                        className="modal__address-state"
                        required={this.state.requiredFields["state"].required}
                      /> */}
                    {this.state.country !== "United States" ? (
                      <div
                        className="fieldSizing"
                        style={{ marginRight: "10px", marginBottom: "15px" }}
                      >
                        <InputField
                          label="State"
                          type="text"
                          name="state"
                          value={this.state.state}
                          onChange={this.changeHandler}
                          className="modal__address-state"
                          required={this.state.requiredFields["state"].required}
                        />
                      </div>
                    ) : (
                      <div style={{ marginRight: "10px" }}>
                        <MuiSelect
                          name="state"
                          label="State"
                          value={this.state.state}
                          onChange={this.changeHandler}
                          className="modal__address-state"
                          placeholder="State"
                          style={{
                            width: "80px",
                          }}
                          required={this.state.requiredFields["state"].required}
                          menuItem={usStatesList.map((list) => (
                            <MenuItem key={list} value={list}>
                              {list}
                            </MenuItem>
                          ))}
                          // required={this.state.requiredFields["state"].required}
                        />
                      </div>
                      // <select
                      //   name="state"
                      //   value={this.state.state}
                      //   onChange={this.changeHandler}
                      //   className="modal__address-state"
                      //   required={this.state.requiredFields["state"].required}
                      // >
                      //   <option value="" selected disabled>
                      //     -Select-
                      //   </option>
                      //   {usStatesList.map((list) => (
                      //     <option key={list} value={list}>
                      //       {list}
                      //     </option>
                      //   ))}
                      // </select>
                    )}
                  </div>
                  <div className="fieldSizing">
                    <InputField
                      label="Zip/Postal"
                      type="text"
                      name="zip"
                      value={this.state.zip}
                      onChange={this.changeHandler}
                      className="modal__address-zip"
                      required={this.state.requiredFields["zip"].required}
                    />
                    {/* <label>
                      Zip/Postal{" "}
                      {this.state.requiredFields["zip"].required && (
                        <span
                          style={{
                            color: "#4A9876",
                            fontSize: "17px",
                            lineHeight: "16px",
                          }}
                        >
                          *
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      name="zip"
                      value={this.state.zip}
                      onChange={this.changeHandler}
                      className="modal__address-zip"
                      required={this.state.requiredFields["zip"].required}
                    /> */}
                  </div>
                </div>

                <div style={{ marginBottom: "0px", marginTop: "0px" }}>
                  <MuiSelect
                    label="Country"
                    name="country"
                    className="basic-single"
                    value={this.state.country}
                    onChange={(selected) =>
                      this.changeHandlerSelect("country", selected.target)
                    }
                    menuItem={countriesList?.map((country, index) => (
                      <MenuItem
                        sx={{ fontSize: "14px" }}
                        key={country + index}
                        value={country}
                      >
                        {country}
                      </MenuItem>
                    ))}
                  />
                </div>
                {/* <label>
                  Country{" "}
                  {this.state.requiredFields["country"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  styles={colourStyles}
                  menuPlacement={this.state.menuOpen}
                  value={{
                    value: this.state.country,
                    label: this.state.country,
                  }}
                  placeholder="-Select-"
                  onChange={(selected) =>
                    this.changeHandlerSelect("country", selected)
                  }
                  isClearable={false}
                  isSearchable={true}
                  options={countriesList.map((country) => ({
                    value: country,
                    label: country,
                  }))}
                /> */}
                {/* <h3 className="modal__heading-sub">Additional Info</h3> */}
                {/* <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                  <label>
                    Preferred Communication{" "}
                    {this.state.requiredFields["preferred_communication"]
                      .required && (
                      <span
                        style={{
                          color: "#4A9876",
                          fontSize: "17px",
                          lineHeight: "16px",
                        }}
                      >
                        *
                      </span>
                    )}
                  </label>
                  <div
                    className="modal-radioButton"
                    style={{ marginTop: "10px" }}
                  >
                    <label
                      htmlFor="call"
                      style={
                        this.state?.preferred_communication.includes("call")
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              cursor: "pointer",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              cursor: "pointer",
                            }
                      }
                    >
                      Call
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="call"
                      id="call"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                    <label
                      htmlFor="email"
                      style={
                        this.state?.preferred_communication.includes("email")
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              cursor: "pointer",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              cursor: "pointer",
                            }
                      }
                    >
                      Email
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="email"
                      id="email"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                    <label
                      htmlFor="text"
                      style={
                        this.state?.preferred_communication.includes("text")
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              cursor: "pointer",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              cursor: "pointer",
                            }
                      }
                    >
                      Text
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="text"
                      id="text"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                  </div>
                </div> */}

                <div>
                  <div style={{ marginBottom: "0px" }}>
                    <MuiSelect
                      label="Source"
                      className="basic-single"
                      value={source}
                      onChange={(selected) =>
                        this.changeHandlerSelect("source", selected.target)
                      }
                      menuItem={this.props.allAccountSources?.map(
                        (source, index) => (
                          <MenuItem
                            sx={{ fontSize: "14px" }}
                            key={source.name + index}
                            value={source.name}
                          >
                            {source.name}
                          </MenuItem>
                        )
                      )}
                    />
                  </div>
                  {/* <label>
                    Source{" "}
                    {this.state.requiredFields["source"].required && (
                      <span
                        style={{
                          color: "#4A9876",
                          fontSize: "17px",
                          lineHeight: "16px",
                        }}
                      >
                        *
                      </span>
                    )}
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    styles={colourStyles}
                    menuPlacement={this.state.menuOpen}
                    value={{
                      value: source,
                      label: source,
                    }}
                    placeholder="-Select-"
                    onChange={(selected) =>
                      this.changeHandlerSelect("source", selected)
                    }
                    isClearable={false}
                    isSearchable={true}
                    options={this.props.allAccountSources?.map((source) => ({
                      value: source.name,
                      label: source.name,
                    }))}
                  /> */}
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <InputField
                    label="Linkedin"
                    type="text"
                    name="linkedin"
                    value={this.state.linkedin}
                    onChange={this.changeHandler}
                  />
                  {/* <label>Linkedin </label>
                  <input
                    type="text"
                    name="linkedin"
                    value={this.state.linkedin}
                    onChange={this.changeHandler}
                  /> */}
                </div>
                {/* <label>
                  Birthday{" "}
                  {this.state.requiredFields["date_of_birth"].required && (
                    <span
                      style={{
                        color: "#4A9876",
                        fontSize: "17px",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  )}
                </label> */}
                <div
                  className="controlsDashboard"
                  style={{ width: "100%", marginBottom: "15px" }}
                >
                  <DatePicker
                    name="date_of_birth"
                    className="datePicker"
                    selected={Date.parse(this.state.date_of_birth)}
                    value={Date.parse(this.state.date_of_birth)}
                    onChange={(date) => this.dobChangeHandler(date)}
                    dateFormat={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    placeholderText={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/mm/yyyy"
                        : "mm/dd/yyyy"
                    }
                    autoComplete="off"
                    required={
                      this.state.requiredFields["date_of_birth"].required
                    }
                    popperPlacement="top"
                  />
                  <label for="dashboardName" className="activeLabel">
                    <span style={{ fontSize: "12px" }}>Birthday</span>
                  </label>
                </div>
                {/* <div className="datePicker">
                  <DatePicker
                    name="date_of_birth"
                    className="datePicker"
                    selected={Date.parse(this.state.date_of_birth)}
                    value={Date.parse(this.state.date_of_birth)}
                    customInput={
                      <InputField
                        label="Birthday"
                        value={Date.parse(this.state.date_of_birth)}
                        onClick={this.handleClick}
                      />
                    }
                    onChange={(date) => this.dobChangeHandler(date)}
                    dateFormat={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    placeholderText={"Birthday"}
                    autoComplete="off"
                    required={
                      this.state.requiredFields["date_of_birth"].required
                    }
                  />
                </div> */}
                {fieldsData.map((group) => {
                  const { label, fields, meta, id } = group;
                  return (
                    <div key={id}>
                      {meta === null ? (
                        <div>
                          <h3 className="modal__heading-sub">{label}</h3>
                          {fields?.map((field) => {
                            const { id, label, type, choices, name, required } =
                              field;
                            return (
                              <div key={id}>
                                {this.showInput(
                                  name,
                                  label,
                                  type,
                                  choices,
                                  required
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </form>
              <div className="modal__buttons">
                <button
                  onClick={this.handleCloseModal}
                  type="reset"
                  style={{ bottom: "10px", left: "5px" }}
                  className="modal__cancel-button"
                >
                  Cancel
                </button>

                <MuiButton
                  type="button"
                  className="saveBtn"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "auto",
                    height: "35px",
                    color: "white",
                    backgroundColor: "#252a2d",
                  }}
                  disabled={this.state.isLoading}
                  loading={this.state.isLoading}
                  onClick={this.submitHandler}
                  endIcon={
                    <AddIcon
                      style={{
                        color: this.state.isHovered ? "black" : "#62CA9D",
                      }}
                    />
                  }
                  onMouseEnter={() => {
                    this.setState({ isHovered: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ isHovered: false });
                  }}
                >
                  {this.state.isLoading ? "Creating..." : "Create"}{" "}
                </MuiButton>
                {/* <button
                  type="button"
                  className="modal__confirm-button"
                  disabled={this.state.isLoading}
                  onClick={this.submitHandler}
                >
                  {this.state.isLoading ? "Saving" : "Save"}
                </button> */}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountCustomFields: state.allAccountCustomFields.fields,
    allContactCustomFields: state.allContactCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(withRouter(CreateContact));
