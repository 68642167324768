import React, { useState, useEffect } from "react";
import "../sass/CustomerIntro.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import StageReasonModal from "./Deals/StageReasonModal";
import OpportunityIcon from "../images/DealsTabIconDark.svg";
import SalesdashIcon from "../images/salesdashlogogrey.svg";
import MaximizeIcon from "../images/maximize.svg";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, companyOnboardingSet } from "../redux";
import { compose } from "recompose";
import { CurrencyList } from "../constants/currencylist";

const Opportunity = (props) => {
  const [stages, setStages] = useState([]);
  const [stageId, setStageId] = useState("");
  const [selectedFunnel, setSelectedFunnel] = useState({});
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [currentStageType, setCurrentStageType] = useState("");

  useEffect(() => {
    const myFunnel = props.funnels?.find(
      (funnel) => funnel.id === props.funnel_id
    );
    setSelectedFunnel(myFunnel);
  }, [props.funnel_id, props.funnels]);
  useEffect(() => {
    setStages(selectedFunnel?.stages);
  }, [selectedFunnel]);
  useEffect(() => {
    setStageId(props.stage_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setStageId(props.stage_id);
  }, [props.stage_id]);

  const handleOpenModal = () => {
    setShowOpportunityModal(true);
  };
  const handleCloseModal = () => {
    setShowOpportunityModal(false);
  };

  const stageSubmitHandler = (e) => {
    e.preventDefault();
    setStageId(e.target.value);
    const newStage = stages.find(
      (stage) => stage.id === parseInt(e.target.value)
    );
    setCurrentStageType(newStage.type);
    newStage.type === "won" || newStage.type === "lost"
      ? handleOpenModal()
      : axios({
          method: "PUT",
          url: `/opportunities/${props.id}`,
          data: {
            ...(props.customerId && { account_id: props.customerId }),
            name: props.name,
            revenue: props.revenue,
            stage_id: e.target.value,
            funnel_id: props.funnel_id,
            close_date: props.close_date,
            description: props.description,
            opportunity_owner_id: props.opportunity_owner_id,
            close_reason: null,
            custom_fields: props.custom_fields,
            email: props.email,
            formatted_phone:
              props.formatted_phone === null ||
              props.formatted_phone === undefined ||
              props.formatted_phone === "undefined"
                ? null
                : props.formatted_phone.toString(),
            website:
              props.website === null
                ? null
                : props.website.startsWith("https://") ||
                  props.website.startsWith("http://")
                ? props.website
                : "https://" + props.website,
          },
        })
          .then((response) =>
            NotificationManager.success("Stage successfully changed.")
          )
          .then((response) => props.fetchAllOpportunities())
          .catch((error) => {
            NotificationManager.error("Error changing stage.");
          });
  };
  const selectedCurrency = props.companyOnboarding.currency
    ? props.companyOnboarding.currency
    : "USD";
  const selectedCurrencyObject = CurrencyList.find(
    (currency) => currency.code === selectedCurrency
  );
  return (
    <div className="schedule__items-deal">
      {showOpportunityModal && (
        <StageReasonModal
          showOpportunityModal={showOpportunityModal}
          setShowOpportunityModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          stage_id={stageId}
          opportunityId={props.id}
          customerId={props.customerId}
          contactId={props.contactId}
          state={{
            ...props,
            customFields: props.custom_fields,
            stage_id: stageId,
          }}
          currentStageType={currentStageType}
          fetchOpportunitiesData={props.fetchAllOpportunities}
          getConfetti={props.getConfetti}
          funnels={props.funnels}
          funnelId={props.funnel_id}
          users={props.users}
          ownerId={props.opportunity_owner_id}
        />
      )}
      <div
        className="schedule__items-deal-name"
        onClick={() => {
          props.setActiveTabIndex(props.totalTabs + 1);
          props.setTab({
            type: "opportunity",
            id: props.id,
            name: props.name,
            account_id: props.customerId,
          });
          props.history.push("/active-tabs");
        }}
      >
        <img src={OpportunityIcon} alt="" />{" "}
        {props.name && props.name.length > 36
          ? props.name.substring(0, 35) + "..."
          : props.name}
        <span style={{ float: "right" }}>
          <img src={MaximizeIcon} alt="" height="12px" />
        </span>
      </div>
      <div className="schedule__items-deal-grid">
        <div className="schedule__items-deal-date">
          <img src={SalesdashIcon} alt="" style={{ marginRight: "7px" }} />
          {`${props.funnel_name}`}
        </div>
        <div>
          {props.authUser.role === "SUPERADMIN" ||
          props.authUser.permissions.includes("crm:closed:deal:editor") ? (
            <select
              style={{ marginTop: "0", marginLeft: "0" }}
              className={
                props.stage_name === "Won"
                  ? "c-icon-won width-100"
                  : props.stage_name === "Lost"
                  ? "c-icon-lost width-100"
                  : "c-icon-3 width-100"
              }
              name="stage_id"
              value={stageId}
              onChange={(e) => {
                stageSubmitHandler(e);
              }}
            >
              {stages?.map((stage) => {
                const { name, id } = stage;
                return (
                  <option key={id} value={id}>
                    {name}
                  </option>
                );
              })}
            </select>
          ) : props.stage_name === "Won" || props.stage_name === "Lost" ? (
            <div style={{ display: "inline-block" }}>
              <p
                className={props.stage_name === "Won" ? "c-icon-4" : "c-icon-5"}
              >
                {props.stage_name}
              </p>
            </div>
          ) : (
            <select
              style={{ marginTop: "0", marginLeft: "0" }}
              className="c-icon-3 width-100"
              value={stageId}
              onChange={(e) => {
                stageSubmitHandler(e);
              }}
            >
              {stages?.map((stage) => {
                const { name, id } = stage;
                return (
                  <option key={id} value={id}>
                    {name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      </div>
      <div
        className="schedule__items-deal-grid"
        style={{ marginTop: "0", marginBottom: "5px" }}
      >
        <div className="schedule__items-deal-date">
          <i className="fa fa-calendar-o" aria-hidden="true"></i>
          {`${props.detail}`}
        </div>
        <div>
          {stages
            ?.filter((stage) => stage.name !== "Lost")
            .map((stage, index) => {
              const selectedStage = stages.find(
                (st) => st.id === props.stage_id
              );
              return props.stage_name === "Lost" ? (
                ""
              ) : (
                <div
                  className="schedule__items-deal-bar"
                  key={stage.id}
                  style={
                    selectedStage && selectedStage.position < index + 1
                      ? {
                          backgroundColor: "#e3e3e3",
                        }
                      : {}
                  }
                ></div>
              );
            })}
        </div>
      </div>
      <div className="schedule__items-deal-revenue">
        <span style={{ marginRight: "15px", color: "#93939f" }}>
          {selectedCurrencyObject.symbol}
        </span>
        {`${props.revenue ? props.revenue : 0}`}
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(Opportunity);
