import React, { Component } from "react";
import "../../sass/Grid.scss";
import "../../sass/Body.scss";
import CustomIcon from "../../images/Customize.svg";
import ArrowIconDown from "../../images/ArrowDown.svg";
import ArrowIconUp from "../../images/ArrowUp.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import TargetsCustomFunction from "./TargetsCustomFunction";
import FunnelCustomFunction from "./FunnelCustomFunction";
import { v4 as uuidv4 } from "uuid";
import Collapsible from "react-collapsible";
import TaskTourDashCustom from "../Onboarding/TaskTourDashCustom";
import StatusChangeModal from "../Accounts/StatusChangeModal";
import SourceChangeModal from "../Accounts/SourceChangeModal";
import CloseReasonChangeModal from "./CloseReasonChangeModal";
import { NotificationManager } from "react-notifications";

// Redux stuff
import { connect } from "react-redux";
import {
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
  fetchAllModes,
  fetchAllLaneTypes,
  fetchAllAccountSources,
} from "../../redux";
import { Plus } from "lucide-react";

class DashboardCustom extends Component {
  state = {
    stages: [],
    close_reasons: [],
    targets: [],
    newWonReason: "",
    newLostReason: "",
    newStage: "",
    user_ids: "",
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    allFunnels: [],
    selectedFunnel: {},
    selectedFunnelName: "",
    submitedFunnel: [],
    emptyFunnelErrorMessage: false,
    showStatusModal: false,
    showSourceModal: false,
    showCloseResonModal: false,
    closeReasonLoading: false,
    statusType: "account",
  };

  setStages = (stagesData) => {
    this.setState((prevState) => ({
      stages: stagesData,
      submitedFunnel: prevState.submitedFunnel.map((funnel) => {
        let { stages, funnel_id } = funnel;
        if (funnel_id === this.state.selectedFunnel.id) {
          stages = stagesData;
        }
        return { ...funnel, stages };
      }),
    }));
  };
  handleOpenStatusModal = (statusType) => {
    this.setState({
      showStatusModal: true,
      statusType,
    });
  };
  handleCloseStatusModal = () => {
    this.setState({
      showStatusModal: false,
    });
  };
  handleOpenSourceModal = () => {
    this.setState({
      showSourceModal: true,
    });
  };
  handleCloseSourceModal = () => {
    this.setState({
      showSourceModal: false,
    });
  };
  handleOpenCloseResonModal = () => {
    this.setState({
      showCloseResonModal: true,
    });
  };
  handleCloseCloseResonModal = () => {
    this.setState({
      showCloseResonModal: false,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  handleUserId = (user_ids) => {
    this.setState({
      user_ids,
    });
  };
  getCloseReasons = () => {
    axios({
      method: "GET",
      url: `/close-reasons`,
    })
      .then((response) => {
        const close_reasons = response.data.reasons.map((reason) => {
          reason.destroy = false;
          return reason;
        });
        this.setState({
          close_reasons,
        });
      })
      .catch((error) => console.log("error", error));
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getAllFunnelsInitialized();
    this.getCloseReasons();
    axios({
      method: "GET",
      url: `/targets`,
    }).then((response) => {
      this.setState({
        targets: response.data,
      });
    });
  }
  changeTargetHandler = (type, e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      targets: {
        ...prevState.targets,
        [type]: { ...prevState.targets[type], [name]: value },
      },
    }));
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeReasonsHandler = (type, e) => {
    const { name, value } = e.target;
    const id = parseInt(name);

    this.setState((prevState) => ({
      close_reasons: prevState.close_reasons.map((reason) => {
        if (reason.id === id) reason.name = value;
        return reason;
      }),
    }));
  };
  changeStageHandler = (type, e) => {
    const { name, value } = e.target;
    const id = parseInt(name);

    this.setState((prevState) => ({
      stages: prevState.stages.map((stage) => {
        if (stage.id === id) stage.name = value;
        return stage;
      }),
      submitedFunnel: prevState.submitedFunnel.map((funnel) => {
        let { stages, funnel_id } = funnel;
        if (funnel_id === this.state.selectedFunnel.id) {
          stages = stages.map((stage) => {
            if (stage.id === id) stage.name = value;
            return stage;
          });
          return { ...funnel, stages };
        }
        return funnel;
      }),
    }));
  };
  changeFunnelName = (e) => {
    const { value } = e.target;

    this.setState((prevState) => ({
      selectedFunnelName: value,
      submitedFunnel: prevState.submitedFunnel.map((funnel) => {
        let { funnel_id, funnel_name } = funnel;
        if (funnel_id === this.state.selectedFunnel.id) {
          funnel_name = value;
          return { ...funnel, funnel_name };
        }
        return funnel;
      }),
    }));
  };
  getAllFunnels = (id) => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(id) && funnel;
        })
        .filter((funnel) => funnel !== false);
      this.setState({
        allFunnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        selectedFunnelName: selectedFunnel[0].name,
        submitedFunnel: res.data.funnels.map((funnel) => {
          const { id, name, is_default, stages } = funnel;
          return {
            funnel_id: id,
            funnel_name: name,
            is_default,
            _destroy: false,
            stages: stages.map((stage) => {
              stage.destroy = false;
              return stage;
            }),
          };
        }),
        stages: selectedFunnel[0].stages?.map((stage) => {
          stage.destroy = false;
          return stage;
        }),
      });
    });
  };
  getAllFunnelsInitialized = () => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      this.setState({
        allFunnels: res.data.funnels,
        submitedFunnel: res.data.funnels.map((funnel) => {
          const { id, name, is_default, stages } = funnel;
          return {
            funnel_id: id,
            funnel_name: name,
            is_default,
            _destroy: false,
            stages: stages.map((stage) => {
              stage.destroy = false;
              return stage;
            }),
          };
        }),
        selectedFunnel: selectedFunnel[0],
        selectedFunnelName: selectedFunnel[0].name,
        stages: selectedFunnel[0].stages.map((stage) => {
          stage.destroy = false;
          return stage;
        }),
      });
    });
  };
  getAllStages = (funnelId) => {
    axios({
      method: "GET",
      url: `/stages?funnel_id=${funnelId}`,
    }).then((response) => {
      const stages = response.data.stages.map((stage) => {
        stage.destroy = false;
        return stage;
      });

      this.setStages(stages);
    });
  };
  submitHandler = (e) => {
    const modifiedStagesFunnel = this.state.submitedFunnel.map((funnel) => {
      let { stages } = funnel;
      stages = stages
        .reduce((acc, val, idx) => {
          return [acc, { ...val, position: idx + 1 }];
        }, [])
        .flat(Infinity);
      return { ...funnel, stages };
    });
    const funnels = modifiedStagesFunnel.map((funnel) => {
      let { stages } = funnel;
      stages.map((stage) => {
        if (stage._new === true) {
          delete stage.id;
          delete stage._new;
        }
        return stage;
      });
      return { ...funnel, stages };
    });
    if (
      (this.state.targets?.activities.value,
      this.state.targets?.opportunities.value,
      this.state.targets?.won_opportunities.value) === ""
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    if (
      this.state.selectedFunnelName === "" ||
      this.state.selectedFunnelName === null
    ) {
      this.setState({ emptyFunnelErrorMessage: true, isLoading: false });
      return false;
    }
    let activitiesValue = this.state.targets?.activities.value || null;
    let oppValue = this.state.targets?.opportunities.value || null;
    let wonOppValue = this.state.targets?.won_opportunities.value || null;
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/dashboard/customization`,
      data: {
        funnels,
        close_reasons: this.state.close_reasons.map((reason) => {
          if (reason._new === true) {
            const { id, _new, ...finalStage } = reason;
            return finalStage;
          }
          return reason;
        }),
        targets: [
          {
            id: this.state.targets.activities.id,
            period: this.state.targets?.activities.period,
            value: activitiesValue,
          },
          {
            id: this.state.targets.opportunities.id,
            period: this.state.targets?.opportunities.period,
            value: oppValue,
          },
          {
            id: this.state.targets.won_opportunities.id,
            period: this.state.targets?.won_opportunities.period,
            value: wonOppValue,
            metric: this.state.targets?.won_opportunities.metric,
          },
        ],
      },
    })
      .then((response) => {
        window.location.href = "/";
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  submitCloseReasonHandler = (e) => {
    const modifiedStagesFunnel = this.state.submitedFunnel.map((funnel) => {
      let { stages } = funnel;
      stages = stages
        .reduce((acc, val, idx) => {
          return [acc, { ...val, position: idx + 1 }];
        }, [])
        .flat(Infinity);
      return { ...funnel, stages };
    });
    const funnels = modifiedStagesFunnel.map((funnel) => {
      let { stages } = funnel;
      stages.map((stage) => {
        if (stage._new === true) {
          delete stage.id;
          delete stage._new;
        }
        return stage;
      });
      return { ...funnel, stages };
    });
    if (
      (this.state.targets?.activities.value,
      this.state.targets?.opportunities.value,
      this.state.targets?.won_opportunities.value) === ""
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    if (
      this.state.selectedFunnelName === "" ||
      this.state.selectedFunnelName === null
    ) {
      this.setState({ emptyFunnelErrorMessage: true, isLoading: false });
      return false;
    }
    let activitiesValue = this.state.targets?.activities.value || null;
    let oppValue = this.state.targets?.opportunities.value || null;
    let wonOppValue = this.state.targets?.won_opportunities.value || null;
    this.setState({ closeReasonLoading: true });
    axios({
      method: "POST",
      url: `/dashboard/customization`,
      data: {
        funnels,
        close_reasons: this.state.close_reasons.map((reason) => {
          if (reason._new === true) {
            const { id, _new, ...finalStage } = reason;
            return finalStage;
          }
          return reason;
        }),
        targets: [
          {
            id: this.state.targets.activities.id,
            period: this.state.targets?.activities.period,
            value: activitiesValue,
          },
          {
            id: this.state.targets.opportunities.id,
            period: this.state.targets?.opportunities.period,
            value: oppValue,
          },
          {
            id: this.state.targets.won_opportunities.id,
            period: this.state.targets?.won_opportunities.period,
            value: wonOppValue,
            metric: this.state.targets?.won_opportunities.metric,
          },
        ],
      },
    })
      .then((response) => {
        NotificationManager.success("Close Reasons Updated.");
        this.getCloseReasons();
        this.setState({
          closeReasonLoading: false,
          showCloseResonModal: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error updating close reason.");
        this.setState({ closeReasonLoading: false });
      });
  };
  newReasonChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  newStageChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  addReasonHandler = (type, name) => {
    this.setState((prevState) => ({
      close_reasons: [
        ...prevState.close_reasons,
        { id: uuidv4(), _new: true, type, destroy: false, name },
      ],
      newWonReason: "",
      newLostReason: "",
    }));
  };
  addStageHandler = (type, editable, position, destroy, name) => {
    let newStages = this.state.stages;
    newStages.splice(this.state.stages.length - 3, 0, {
      id: uuidv4(),
      _new: true,
      type,
      editable,
      position,
      destroy: false,
      name,
    });
    this.setState((prevState) => ({
      stages: newStages,
      newStage: "",
      submitedFunnel: prevState.submitedFunnel.map((funnel) => {
        let { stages, funnel_id } = funnel;
        if (funnel_id === this.state.selectedFunnel.id) {
          stages = newStages;
        }
        return { ...funnel, stages };
      }),
    }));
  };
  isDefaultChangeHandler = (id) => {
    this.setState((prevState) => ({
      submitedFunnel: prevState.submitedFunnel.map((funnel) => {
        let { funnel_id, is_default } = funnel;
        if (funnel_id === id) {
          is_default = true;
          return { ...funnel, is_default };
        }
        return funnel;
      }),
    }));
  };
  deleteReasonHandler = (id) => {
    this.setState((prevState) => ({
      close_reasons: prevState.close_reasons.map((reason) => {
        if (reason.id === id) reason.destroy = true;
        return reason;
      }),
    }));
  };
  deleteFunnelHandler = (newFunnelId, stages_mapping, e) => {
    this.setState(
      (prevState) => ({
        submitedFunnel: prevState.submitedFunnel.map((funnel) => {
          let { funnel_id } = funnel;
          if (funnel_id === this.state.selectedFunnel.id) {
            return {
              ...funnel,
              _destroy: true,
              is_default: false,
              new_funnel: {
                funnel_id: newFunnelId,
                stages_mapping,
              },
            };
          }
          return funnel;
        }),
      }),
      this.submitHandler
    );
  };
  deleteStageHandler = (id, newStageId, e) => {
    this.setState(
      (prevState) => ({
        stages: prevState.stages.map((stage) => {
          if (stage.id === id) {
            stage.new_stage_id = newStageId;
            stage.destroy = true;
          }
          return stage;
        }),
        submitedFunnel: prevState.submitedFunnel.map((funnel) => {
          let { stages, funnel_id } = funnel;
          if (funnel_id === this.state.selectedFunnel.id) {
            stages = stages.map((stage) => {
              if (stage.id === id) {
                stage.new_stage_id = newStageId;
                stage.destroy = true;
              }
              return stage;
            });
          }
          return { ...funnel, stages };
        }),
      }),
      this.submitHandler
    );
  };
  deleteNewStageHandler = (id) => {
    this.setState((prevState) => ({
      stages: prevState.stages.filter((stage) => stage.id !== id),
      submitedFunnel: prevState.submitedFunnel.map((funnel) => {
        let { stages, funnel_id } = funnel;
        if (funnel_id === this.state.selectedFunnel.id) {
          stages = stages.filter((stage) => stage.id !== id);
        }
        return { ...funnel, stages };
      }),
    }));
  };
  render() {
    console.log("DashboardCustom", this.state);
    return (
      <div>
        <div className="body">
          <div
            // className="row tour-dashboard-custom"
            className="row"
          >
            <div className="col-1-of-3">
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "1px 20px",
                        marginRight: "20px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "1px 20px",
                        width: "88vw",
                      }
                }
              >
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ display: "inline-block" }}>
                    <img
                      src={CustomIcon}
                      alt=""
                      width="34px"
                      style={{
                        transform: "translateY(15px)",
                        marginLeft: "15px",
                        marginRight: "15px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "inline",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      Customize your Dashboard to your sales process.
                    </h2>
                    <Collapsible
                      className="collapseHeader"
                      classParentString="collapseHeader"
                      trigger={
                        <div>
                          Sales Pipeline
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              padding: "3px 10px",
                              border: "none",
                              borderRadius: "5px",
                              color: "white",
                              outline: "none",
                              cursor: "pointer",
                              position: "absolute",
                              top: "30%",
                              right: "2%",
                            }}
                          >
                            <img src={ArrowIconDown} alt="" height="10px" />
                          </button>
                        </div>
                      }
                      triggerWhenOpen={
                        <div>
                          Sales Pipeline
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              padding: "3px 10px",
                              border: "none",
                              borderRadius: "5px",
                              color: "white",
                              outline: "none",
                              cursor: "pointer",
                              position: "absolute",
                              top: "4%",
                              right: "2%",
                            }}
                          >
                            <img src={ArrowIconUp} alt="" height="10px" />
                          </button>
                        </div>
                      }
                      triggerStyle={{
                        fontSize: "15px",
                        lineHeight: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <p style={{ textAlign: "justify", fontSize: "14px" }}>
                        Add and remove stages to your Sales Pipeline. Think of
                        stages as the checkpoints in your customer’s journey for
                        you to provide them with the best experience. For
                        example, you may have a Discovery stage in which you get
                        to learn about them or their business. Demo, Proposal,
                        Contract, Offer, and Negotiation are all common stages.
                        We do recommend trying to keep this as simplified as
                        possible for you and your customer.
                      </p>
                    </Collapsible>
                    <Collapsible
                      className="collapseHeader"
                      classParentString="collapseHeader"
                      trigger={
                        <div>
                          Won & Lost Reasons
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              padding: "3px 10px",
                              border: "none",
                              borderRadius: "5px",
                              color: "white",
                              outline: "none",
                              cursor: "pointer",
                              position: "absolute",
                              top: "30%",
                              right: "2%",
                            }}
                          >
                            <img src={ArrowIconDown} alt="" height="10px" />
                          </button>
                        </div>
                      }
                      triggerWhenOpen={
                        <div>
                          Won & Lost Reasons
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              padding: "3px 10px",
                              border: "none",
                              borderRadius: "5px",
                              color: "white",
                              outline: "none",
                              cursor: "pointer",
                              position: "absolute",
                              top: "4%",
                              right: "2%",
                            }}
                          >
                            <img src={ArrowIconUp} alt="" height="10px" />
                          </button>
                        </div>
                      }
                      triggerStyle={{
                        fontSize: "15px",
                        lineHeight: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <p style={{ textAlign: "justify", fontSize: "14px" }}>
                        Located below the Sales Funnel custom area, you can add
                        and remove your Won and Lost Reasons. You will be asked
                        the reasons whenever you mark a deal as Won or Lost, and
                        these provide you with insights as to why customers are
                        purchasing, or why you stop pursuing customers and why
                        deals fall through.
                      </p>
                    </Collapsible>
                    <Collapsible
                      className="collapseHeader"
                      classParentString="collapseHeader"
                      trigger={
                        <div>
                          Targets
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              padding: "3px 10px",
                              border: "none",
                              borderRadius: "5px",
                              color: "white",
                              outline: "none",
                              cursor: "pointer",
                              position: "absolute",
                              top: "30%",
                              right: "2%",
                            }}
                          >
                            <img src={ArrowIconDown} alt="" height="10px" />
                          </button>
                        </div>
                      }
                      triggerWhenOpen={
                        <div>
                          Targets
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              padding: "3px 10px",
                              border: "none",
                              borderRadius: "5px",
                              color: "white",
                              outline: "none",
                              cursor: "pointer",
                              position: "absolute",
                              top: "4%",
                              right: "2%",
                            }}
                          >
                            <img src={ArrowIconUp} alt="" height="10px" />
                          </button>
                        </div>
                      }
                      triggerStyle={{
                        fontSize: "15px",
                        lineHeight: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <p style={{ textAlign: "justify", fontSize: "14px" }}>
                        The last custom feature is your Targets. These help you
                        hold yourself accountable and can be updated any time.
                        Simply set the time period and target that you aim for.
                      </p>
                    </Collapsible>
                    <p
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      If there is anything we can help with, please reach out to
                      us - <strong>info@salesdashcrm.com</strong>.
                    </p>
                    <Link
                      to="/"
                      style={{
                        marginRight: "19px",
                        textDecoration: "none",
                        marginBottom: "30px",
                        padding: "9px 16px",
                      }}
                      className="button-md-secondary"
                    >
                      Exit
                    </Link>
                    <button
                      style={{
                        marginBottom: "30px",
                      }}
                      type="button"
                      className="button-md"
                      onClick={this.submitHandler}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? "Saving" : "Save Changes"}
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Won/Lost Reasons ({this.state.close_reasons.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenCloseResonModal()}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Account Statuses ({this.props.allAccountStatuses.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenStatusModal("account")}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Carrier Statuses ({this.props.allCarrierStatuses.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenStatusModal("carrier")}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Contact Statuses ({this.props.allContactStatuses.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenStatusModal("contact")}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Lead Sources ({this.props.allAccountSources.length})
                </h2>
                <button
                  type="button"
                  onClick={this.handleOpenSourceModal}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Equipment Types ({this.props.allEquipmentTypes.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenStatusModal("equipment")}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Modes ({this.props.allModes.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenStatusModal("modes")}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Special Requirements (
                  {this.props.allSpecialRequirements.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenStatusModal("requirement")}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <div
                style={
                  this.state.windowWidth > 650
                    ? {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }
                    : {
                        boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                        height: "auto",
                        borderRadius: "11px",
                        background: "#FFFFFF",
                        padding: "5px 20px",
                        marginTop: "15px",
                        width: "88vw",
                      }
                }
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    display: "inline-block",
                  }}
                >
                  Lane Types ({this.props.allLaneTypes.length})
                </h2>
                <button
                  type="button"
                  onClick={() => this.handleOpenStatusModal("laneTypes")}
                  className="button-sm"
                  style={{
                    float: "right",
                    marginTop: "10px",
                  }}
                >
                  Add
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              </div>
              <StatusChangeModal
                showOpportunityModal={this.state.showStatusModal}
                handleCloseModal={this.handleCloseStatusModal}
                statusType={this.state.statusType}
              />
              <SourceChangeModal
                showOpportunityModal={this.state.showSourceModal}
                setShowOpportunityModal={this.handleOpenSourceModal}
                handleCloseModal={this.handleCloseSourceModal}
                getSources={this.props.fetchAllAccountSources}
                fromDashboard={true}
                allAccountSources={this.props.allAccountSources}
              />
              <CloseReasonChangeModal
                showOpportunityModal={this.state.showCloseResonModal}
                setShowOpportunityModal={this.handleOpenCloseResonModal}
                handleCloseModal={this.handleCloseCloseResonModal}
                changeHandler={this.changeReasonsHandler}
                reasons={this.state?.close_reasons}
                newWonReason={this.state.newWonReason}
                newLostReason={this.state.newLostReason}
                newReasonChangeHandler={this.newReasonChangeHandler}
                addReasonHandler={this.addReasonHandler}
                deleteHandler={this.deleteReasonHandler}
                submitCloseReasonHandler={this.submitCloseReasonHandler}
                isLoading={this.state.closeReasonLoading}
              />
            </div>
            <TaskTourDashCustom tourComplete={this.state.tourComplete} />
            <div className="col-1-of-3">
              <FunnelCustomFunction
                setStages={this.setStages}
                stages={this.state?.stages}
                stagesLength={this.state?.stages?.length}
                newStage={this.state.newStage}
                newStageChangeHandler={this.newStageChangeHandler}
                addStageHandler={this.addStageHandler}
                changeStageHandler={this.changeStageHandler}
                changeHandler={this.changeReasonsHandler}
                reasons={this.state?.close_reasons}
                newWonReason={this.state.newWonReason}
                newLostReason={this.state.newLostReason}
                newReasonChangeHandler={this.newReasonChangeHandler}
                addReasonHandler={this.addReasonHandler}
                deleteHandler={this.deleteReasonHandler}
                deleteStageHandler={this.deleteStageHandler}
                deleteNewStageHandler={this.deleteNewStageHandler}
                allFunnels={this.state.allFunnels}
                getAllFunnels={this.getAllFunnels}
                getAllFunnelsInitialized={this.getAllFunnelsInitialized}
                changeFunnelName={this.changeFunnelName}
                selectedFunnel={this.state.selectedFunnel}
                selectedFunnelName={this.state.selectedFunnelName}
                deleteFunnelHandler={this.deleteFunnelHandler}
                isDefaultChangeHandler={this.isDefaultChangeHandler}
                emptyFunnelErrorMessage={this.state.emptyFunnelErrorMessage}
              />
            </div>
            <div className="col-1-of-3">
              <TargetsCustomFunction
                handleUserId={this.handleUserId}
                targets={this.state?.targets}
                changeHandler={this.changeTargetHandler}
                errorMessage={this.state.errorMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allLaneTypes: state.allLaneTypes.laneTypes,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
    allCarrierStatuses: state.allCarrierStatuses.carrierStatuses,
    allContactStatuses: state.allContactStatuses.contactStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
    fetchAllModes: (modes) => dispatch(fetchAllModes(modes)),
    fetchAllLaneTypes: (types) => dispatch(fetchAllLaneTypes(types)),
    fetchAllAccountSources: (types) => dispatch(fetchAllAccountSources(types)),
  };
};

export default connect(MSP, MDP)(DashboardCustom);
