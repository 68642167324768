import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import DropdownSequenceTemplate from "../Dropdown/DropdownSequenceTemplate";
import EditTemplateModal from "../SendEmail/EditTemplateModal";
import SequenceTemplateViewModal from "./SequenceTemplateViewModal";

class SequenceCreateModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    allTemplates: [],
    showTemplateEditModal: false,
    showTemplateViewModal: false,
    templateData: {},
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenTemplateEditModal = () => {
    this.setState({
      showTemplateEditModal: true,
    });
  };
  handleCloseTemplateEditModal = () => {
    this.setState({
      showTemplateEditModal: false,
    });
  };
  handleOpenTemplateViewModal = () => {
    this.setState({
      showTemplateViewModal: true,
    });
  };
  handleCloseTemplateViewModal = () => {
    this.setState({
      showTemplateViewModal: false,
    });
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  getAllTemplates = () => {
    axios({
      method: "GET",
      url: `/emails/templates`,
    }).then((res) =>
      this.setState({
        allTemplates: res.data.templates,
      })
    );
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getAllTemplates();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.getAllTemplates();
    }
  }
  selectStep = (template) => {
    this.props.setSteps([
      ...this.props.steps,
      { template_id: template.id, name: template.name, delay: 0 },
    ]);
    this.props.handleCloseModal();
  };
  handleEditModalData = (temp) => {
    this.setState(
      {
        templateData: {
          id: temp.id,
          label: temp.name,
          subject: temp.subject,
          content: temp.body,
          personalizeVars: temp.personalize_vars,
          isPrivate: temp.is_private,
        },
      },
      () => this.handleOpenTemplateEditModal()
    );
  };
  handleViewModalData = (temp) => {
    this.setState(
      {
        templateData: {
          id: temp.id,
          label: temp.name,
          subject: temp.subject,
          content: temp.body,
          personalizeVars: temp.personalize_vars,
          isPrivate: temp.is_private,
        },
      },
      () => this.handleOpenTemplateViewModal()
    );
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "51.5vh", top: "15%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Choose Email Template</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <button
                type="button"
                onClick={() => this.props.createNewTemplate()}
                style={{
                  border: "none",
                  outline: "none",
                  color: "white",
                  background: "#61C99D",
                  borderRadius: "3px",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
              >
                Create new template
              </button>
              <p>Choose from existing templates</p>
              <div
                style={{
                  height: "auto",
                  maxHeight: "29vh",
                  overflowY: "auto",
                  margin: "5px",
                }}
              >
                {this.state.allTemplates?.map((temp) => (
                  <div
                    key={temp.id}
                    style={{
                      boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                      cursor: "pointer",
                      borderRadius: "5px",
                      padding: "7px 10px",
                      margin: "5px",
                      marginBottom: "6px",
                    }}
                  >
                    <span
                      style={{ color: "#4A9876", textDecoration: "underline" }}
                      onClick={() => this.selectStep(temp)}
                    >
                      {temp.name}
                    </span>
                    <span style={{ float: "right" }}>
                      <DropdownSequenceTemplate
                        handleEditModalData={this.handleEditModalData}
                        templateData={temp}
                        handleViewModalData={this.handleViewModalData}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
        <EditTemplateModal
          showOpportunityModal={this.state.showTemplateEditModal}
          setShowOpportunityModal={this.handleOpenTemplateEditModal}
          handleCloseModal={this.handleCloseTemplateEditModal}
          getAllTemplates={this.getAllTemplates}
          templateData={this.state.templateData}
          isBulkEmail={true}
        />
        <SequenceTemplateViewModal
          showOpportunityModal={this.state.showTemplateViewModal}
          setShowOpportunityModal={this.handleOpenTemplateViewModal}
          handleCloseModal={this.handleCloseTemplateViewModal}
          getAllTemplates={this.getAllTemplates}
          templateData={this.state.templateData}
          isBulkEmail={true}
          selectStep={this.selectStep}
        />
      </div>
    );
  }
}

export default SequenceCreateModal;
