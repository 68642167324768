import axios from "axios";
import React, { Component } from "react";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { withRouter } from "react-router-dom";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";
import DashboardLoader from "../Loaders/DashboardLoader";

class WonLostReason extends Component {
  state = {
    won_and_lost_reason_total_days:
      localStorage.getItem("won_and_lost_reason_total_days") ?? "all_days",
    lost_reasons: [],
    won_reasons: [],
  };
  fetchWonAndLostReasonsHandler = (funnelId, e) => {
    let date =
      e !== undefined
        ? e.target.value
        : this.state.won_and_lost_reason_total_days;
    this.setState({ reasonsLoader: true });
    let url = `/dashboard/close-reasons?total_days=${date}&user_ids=${this.props.userId}`;
    if (funnelId) {
      url += `&funnel_id=${funnelId}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          lost_reasons: response.data.lost_reasons,
          won_reasons: response.data.won_reasons,
          reasonsLoader: false,
        });
      })
      .catch((error) => {
        this.setState({ reasonsLoader: false });
      });
  };
  componentDidMount() {
    this.fetchWonAndLostReasonsHandler(this.props.selectedFunnel);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedFunnel !== this.props.selectedFunnel) {
      this.fetchWonAndLostReasonsHandler(this.props.selectedFunnel);
    }
  }
  render() {
    const { lost_reasons, won_reasons } = this.state;
    return (
      <div className="card">
        <div className="funnel-heading">
          <h4
            className="funnel-heading-sub-1"
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                this.props.setTab({
                  type: "dealStage",
                  id: `${this.props.userId}selTopThreeWonReasonsFunnel`,
                  name: `Top 3 Won Reasons`,
                  blank: true,
                  userId: this.props?.userId,
                  userName: this.props.userName,
                  stageId: this.props.wonStage,
                });
              } else {
                this.props.setActiveTabIndex(this.props.totalTabs + 1);
                this.props.setTab({
                  type: "dealStage",
                  id: `${this.props.userId}selTopThreeWonReasonsFunnel`,
                  name: `Top 3 Won Reasons`,
                  userId: this.props?.userId,
                  userName: this.props.userName,
                  stageId: this.props.wonStage,
                });
                this.props.history.push("/active-tabs");
              }
            }}
            data-tip="View all won deals."
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Top 3 Won Reasons
          </h4>
          <h6 className="funnel-heading-sub-c">
            <select
              className="funnel-select"
              name="won_and_lost_reason_total_days"
              value={localStorage.getItem("won_and_lost_reason_total_days")}
              defaultValue="all_days"
              onChange={(e) => {
                this.fetchWonAndLostReasonsHandler(
                  this.props.selectedFunnel,
                  e
                );
                localStorage.setItem(
                  "won_and_lost_reason_total_days",
                  e.target.value
                );
              }}
            >
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
              <option value="365">365 Days</option>
              <option value="all_days">All Time</option>
            </select>
          </h6>
          {/* <span
            data-tip='The top reasons for your Won & Lost deals. Add more reasons in <br/>"Dashboard Customization" in user settings to build the best insights over time.'
            style={{
              position: "absolute",
              top: "23px",
              left: "175px",
              transform: "translateY(-)",
              fontSize: "10px",
              fontWeight: "400",
              color: "#4A9876",
              padding: "0.2px 4.8px",
              borderRadius: "50%",
              border: "1px solid #4A9876",
            }}
            data-multiline={true}
          >
            i
          </span> */}
        </div>
        {this.state.reasonsLoader ? (
          <div class="load-wrapp-1">
            <div class="load-3">
              <div class="line-1"></div>
              <div class="line-1"></div>
              <div class="line-1"></div>
            </div>
          </div>
        ) : (
          <table style={{ paddingLeft: "14px" }}>
            <thead>
              <tr>
                <th>Reason</th>
                <th className="funnel-amounts">Amount</th>
                <th>% Won</th>
              </tr>
            </thead>
            <tbody>
              {won_reasons.length === 0 && (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    <DashboardLoader />
                  </td>
                </tr>
              )}
              {won_reasons?.map((reason) => {
                const { id, name, amount, won_percentage } = reason;
                return (
                  <tr key={id+name}>
                    <td className="funnel-reasons">{name}</td>
                    <td className="funnel-amounts">{amount}</td>
                    <td>
                      <Progress
                        percent={won_percentage}
                        theme={{
                          success: {
                            color: "#4A9876",
                          },
                          active: {
                            color: "#4A9876",
                          },
                          default: {
                            color: "#4A9876",
                          },
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div style={{ textAlign: "left", marginTop: "22px" }}>
          <h4
            className="funnel-heading-sub-2"
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                this.props.setTab({
                  type: "dealStage",
                  id: `${this.props.userId}selTopThreeLostReasonsFunnel`,
                  name: `Top 3 Lost Reasons`,
                  blank: true,
                  userId: this.props?.userId,
                  userName: this.props.userName,
                  stageId: this.props.lostStage,
                });
              } else {
                this.props.setActiveTabIndex(this.props.totalTabs + 1);
                this.props.setTab({
                  type: "dealStage",
                  id: `${this.props.userId}selTopThreeLostReasonsFunnel`,
                  name: `Top 3 Lost Reasons`,
                  userId: this.props?.userId,
                  userName: this.props.userName,
                  stageId: this.props.lostStage,
                });
                this.props.history.push("/active-tabs");
              }
            }}
            data-tip="View all lost deals."
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Top 3 Lost Reasons
          </h4>
        </div>

        {this.state.reasonsLoader ? (
          <DashboardLoader />
        ) : (
          <table style={{ paddingLeft: "14px" }}>
            <thead>
              <tr>
                <th>Reason</th>
                <th className="funnel-amounts">Amount</th>
                <th>% Lost</th>
              </tr>
            </thead>
            <tbody>
              {lost_reasons.length === 0 && (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    <DashboardLoader />
                  </td>
                </tr>
              )}
              {lost_reasons?.map((reason) => {
                const { id, name, amount, lost_percentage } = reason;
                return (
                  <tr key={id+name}>
                    <td className="funnel-reasons">{name}</td>
                    <td className="funnel-amounts">{amount}</td>
                    <td>
                      <Progress
                        percent={lost_percentage}
                        theme={{
                          success: {
                            color: "#4A9876",
                          },
                          active: {
                            color: "#4A9876",
                          },
                          default: {
                            color: "#4A9876",
                          },
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(WonLostReason));
