import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/ReactDraft.scss";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
import axios from "axios";
import { debounce } from "lodash";
import { Hidden, Visible } from "react-grid-system";
import {
  NotificationContainer,
  // NotificationManager,
} from "react-notifications";
// import SequenceIcon from "../../images/sequenceicon.svg";
import EmailSuccessModal from "./EmailSuccessModal";
import EmailFailureModal from "./EmailFailureModal";
import EditSignatureModal from "./EditSignatureModal";
import ReactTooltip from "react-tooltip";
import CreateTemplateModal from "./CreateTemplateModal";
import EditTemplateModal from "./EditTemplateModal";
import DeleteTemplateModal from "./DeleteTemplateModal";
import JoditBulkEmail from "./JoditBulkEmail";
import DropdownTemplate from "../Dropdown/DropdownTemplate";
import DefaultVariablesModal from "./DefaultVariablesModal";
import AllSequenceModal from "../Sequencing/AllSequenceModal";
import EditSequenceModal from "../Sequencing/EditSequenceModal";
import SendBulkSequenceModal from "../Sequencing/SendBulkSequenceModal";
import JoditEditor from "jodit-react";

ReactModal.defaultStyles.overlay.background = "transparent";
const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Enter Subject",
  zIndex: 2147483640,
  addNewLine: false,
  allowResizeY: false,
};

class SendEmailReportModal extends Component {
  state = {
    showModal: true,
    campaignName: "",
    uploadedImages: [],
    CC: false,
    BCC: false,
    fullScreen: false,
    subject: "",
    contentState: "",
    toEmail: [],
    toEmailFrontEnd: [],
    ccEmail: [],
    ccEmailFrontEnd: [],
    bccEmail: [],
    bccEmailFrontEnd: [],
    errorMessage: false,
    windowWidth: window.innerWidth,
    showSuccessModal: false,
    showFailureModal: false,
    showEditSignature: false,
    showTemplateModal: false,
    showTemplateEditModal: false,
    showTemplateDeleteModal: false,
    emailSignature: null,
    isLoading: false,
    content: "",
    selectedFile: "",
    attachments: [],
    fileNames: [],
    allTemplates: [],
    templateData: {},
    attachmentLoader: false,
    scheduleDate: "",
    scheduleTime: null,
    scheduleErrorMessage: false,
    scheduleIsLoading: false,
    successMessage: "sent",
    minimize: false,
    defaultPeronalizedVariables: [],
    showDefaultVarsModal: false,
    showAllSequenceModal: false,
    showSendSequenceModal: false,
    selectedSequence: {},
    showEditSequenceModal: false,
    uploaderErrorMessage: false,
  };
  fileInput = React.createRef();
  triggerInputFile = () => this.fileInput.current.click();
  updateSubject = (value) => {
    this.setState({ subject: value });
  };
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  setUploaderErrorMessage = () => {
    this.setState({
      uploaderErrorMessage: true,
    });
  };

  getSingleTemplateData = (
    id,
    label,
    subject,
    content,
    personalizeVars,
    isPrivate = true
  ) => {
    this.setState({
      templateData: {
        id,
        label,
        subject,
        content,
        personalizeVars,
        isPrivate,
      },
    });
  };

  dateChangeHandler = (date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      scheduleDate: offsetDate,
    });
  };
  timeChangeHandler = (date) => {
    this.setState({
      scheduleTime: date,
    });
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };
  handleInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    this.submitAttachment(event.target.files[0]);
  };
  onInputClick = (event) => {
    event.target.value = "";
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenSuccessModal = () => {
    this.setState({
      showSuccessModal: true,
    });
  };
  handleCloseSuccessModal = () => {
    this.setState({
      showSuccessModal: false,
    });
    this.props.handleCloseModal();
  };
  handleOpenSignatureModal = () => {
    this.setState({
      showEditSignature: true,
    });
  };
  handleCloseSignatureModal = () => {
    this.setState({
      showEditSignature: false,
    });
  };
  handleOpenDefaultVarsModal = () => {
    this.setState({
      showDefaultVarsModal: true,
    });
  };
  handleCloseDefaultVarsModal = () => {
    this.setState({
      showDefaultVarsModal: false,
    });
  };
  handleOpenTemplateModal = () => {
    this.setState({
      showTemplateModal: true,
    });
  };
  handleCloseTemplateModal = () => {
    this.setState({
      showTemplateModal: false,
    });
  };
  handleOpenTemplateEditModal = () => {
    this.setState({
      showTemplateEditModal: true,
    });
  };
  handleCloseTemplateEditModal = () => {
    this.setState({
      showTemplateEditModal: false,
    });
  };
  handleOpenTemplateDeleteModal = () => {
    this.setState({
      showTemplateDeleteModal: true,
    });
  };
  handleCloseTemplateDeleteModal = () => {
    this.setState({
      showTemplateDeleteModal: false,
    });
  };
  handleOpenAllSequenceModal = () => {
    this.setState({
      showAllSequenceModal: true,
    });
  };
  handleCloseAllSequenceModal = () => {
    this.setState({
      showAllSequenceModal: false,
    });
  };
  handleOpenSendSequenceModal = () => {
    this.setState({
      showSendSequenceModal: true,
    });
  };
  handleCloseSendSequenceModal = () => {
    this.setState({
      showSendSequenceModal: false,
    });
  };
  handleOpenEditSequenceModal = () => {
    this.setState({
      showEditSequenceModal: true,
      showAllSequenceModal: false,
      showSendSequenceModal: false,
    });
  };
  handleCloseEditSequenceModal = () => {
    this.setState({
      showEditSequenceModal: false,
      showAllSequenceModal: false,
      showSendSequenceModal: false,
    });
  };
  handleOpenFailureModal = () => {
    this.setState({
      showFailureModal: true,
    });
  };
  handleCloseFailureModal = () => {
    this.setState({
      showFailureModal: false,
    });
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  updateContent = (value) => {
    this.setState({ content: value });
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    if (this.state.windowWidth < 800) {
      this.setState({ fullScreen: true });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    if (this.state.windowWidth < 800) {
      this.setState({ fullScreen: true });
    }
    this.setState({
      subject: this.props.subjectReplied
        ? `Re: ${this.props.subjectReplied}`
        : "",
      toEmail: this.props.replyToEmail ? this.props.replyToEmail : [],
      campaignName: this.props.reportName,
      toEmailFrontEnd: this.props.replyToEmail
        ? this.props.replyToEmail?.map((e) => {
            return { label: e, value: e };
          })
        : [],
    });
    if (this.props.showOpportunityModal) {
      this.getSignature();
      this.getAllTemplates();
    }
  }
  getSignature = () => {
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        this.setState({
          content: `<br/><br/>` + res.data.email_signature,
          emailSignature: res.data.email_signature,
        });
      }
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.setState({
        subject: this.props.subjectReplied
          ? `Re: ${this.props.subjectReplied}`
          : "",
        toEmail: this.props.replyToEmail ? this.props.replyToEmail : [],
        toEmailFrontEnd: this.props.replyToEmail
          ? this.props.replyToEmail?.map((e) => {
              return { label: e, value: e };
            })
          : [],
      });
      this.getSignature();
      this.getAllTemplates();
    }
    if (
      this.state.uploaderErrorMessage &&
      this.state.uploaderErrorMessage !== prevState.uploaderErrorMessage
    ) {
      setTimeout(() => {
        this.setState({
          uploaderErrorMessage: false,
        });
      }, 5000);
    }
  }
  submitAttachment = (selectedFile) => {
    const formData = new FormData();
    formData.append("attachment", selectedFile);
    this.setState({ attachmentLoader: true });
    axios({
      method: "POST",
      url: `/email-campaigns/attachments`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        this.setState({ attachmentLoader: false });
        this.setState((prevState) => ({
          attachments: [...prevState.attachments, res.data.s3_url],
          fileNames: [
            ...prevState.fileNames,
            { name: selectedFile.name, url: res.data.s3_url },
          ],
          selectedFile: "",
        }));
      })
      .catch((err) => this.setState({ attachmentLoader: false }));
  };
  removeAttachment = (url) => {
    this.setState({
      attachments: this.state.attachments?.filter((attach) => attach !== url),
      fileNames: this.state.fileNames?.filter((file) => file.url !== url),
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getAllTemplates = debounce((query) => {
    let url = `/emails/templates`;
    if (query) {
      url += `?name=${encodeURIComponent(query)}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => this.setState({ allTemplates: res.data.templates }));
  }, 500);

  readTemplateValues = (tempId) => {
    const selectedTemplate = this.state.allTemplates?.find(
      (temp) => temp.id === tempId
    );
    let finalContent = selectedTemplate.body;
    let finalSubject = selectedTemplate.subject;
    for (let i = 0; i < selectedTemplate.personalize_vars.length; i++) {
      finalContent = finalContent.includes(
        `{{ ${selectedTemplate.personalize_vars[i].key} }}`
      )
        ? finalContent.replaceAll(
            `{{ ${selectedTemplate.personalize_vars[i].key} }}`,
            `<img src="" alt="${selectedTemplate.personalize_vars[i].label}" class="jodit-variable-img">`
          )
        : finalContent;
      finalSubject = finalSubject.includes(
        `{{ ${selectedTemplate.personalize_vars[i].key} }}`
      )
        ? finalSubject.replaceAll(
            `{{ ${selectedTemplate.personalize_vars[i].key} }}`,
            `<img src="" alt="${selectedTemplate.personalize_vars[i].label}" class="jodit-variable-img">`
          )
        : finalSubject;
    }
    this.setState(
      {
        subject: finalSubject,
        content: this.state.emailSignature
          ? finalContent + `<br/><br/>` + this.state.emailSignature
          : finalContent,
        defaultPeronalizedVariables: selectedTemplate.personalize_vars,
      },
      () => this.handleOpenDefaultVarsModal()
    );
  };
  readTemplateVarsValues = (defaultPeronalizedVariables, e) => {
    e.preventDefault();
    this.setState({
      defaultPeronalizedVariables,
      showDefaultVarsModal: false,
    });
  };
  setSequenceValue = (value) => {
    axios({
      method: "GET",
      url: `/sequences/${value}?${this.props.type}=${this.props.idSequence}`,
    }).then((res) => {
      this.setState(
        {
          selectedSequence: res.data.sequence,
        },
        () => {
          this.handleOpenSendSequenceModal();
          this.handleCloseAllSequenceModal();
        }
      );
    });
  };

  submitHandler = (schedule = false, e) => {
    e.preventDefault();
    const {
      subject,
      // toEmail,
      // ccEmail,
      // bccEmail,
      content,
      fileNames,
      scheduleDate,
      scheduleTime,
    } = this.state;
    const data = this.state;
    if (!data.subject || !data.toEmail) {
      this.setState({
        errorMessage: true,
        isLoading: false,
        scheduleIsLoading: false,
      });
      return false;
    }
    if (schedule === true && (!scheduleDate || !scheduleTime)) {
      this.setState({
        scheduleErrorMessage: true,
        isLoading: false,
        scheduleIsLoading: false,
      });
      return false;
    }
    this.setState({ isLoading: true });
    schedule === true && this.setState({ scheduleIsLoading: true });
    let finalContent = content;
    let finalSubject = subject?.replaceAll("&nbsp;", " ");
    for (let i = 0; i < this.state.defaultPeronalizedVariables.length; i++) {
      finalContent = finalContent.includes(
        `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`
      )
        ? finalContent.replaceAll(
            `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`,
            `{{ ${this.state.defaultPeronalizedVariables[i].key} }}`
          )
        : finalContent;
      finalSubject = finalSubject.includes(
        `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`
      )
        ? finalSubject.replaceAll(
            `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`,
            `{{ ${this.state.defaultPeronalizedVariables[i].key} }}`
          )
        : finalSubject;
    }
    const submittedSubject = this.extractPlainTextFromHTML(finalSubject);
    axios({
      method: "POST",
      url: `/email-campaigns/send-bulk-email`,
      data: {
        name: this.state.campaignName,
        report_id: this.props.reportId,
        records_type: this.props.source,
        excluded_emails: this.props.excludeEmail?.filter(
          (email) => email !== null
        ),
        email_data: {
          subject: submittedSubject,
          email_body: finalContent,
          attachments: fileNames,
          ...(this.state.defaultPeronalizedVariables.length !== 0 && {
            personalized_vars: this.state.defaultPeronalizedVariables?.map(
              (pers) => {
                return {
                  key: pers.key,
                  label: pers.label,
                  substitute: pers.value,
                };
              }
            ),
          }),
        },
      },
    })
      .then((res) => {
        const successMessage = schedule === false ? "sent" : "scheduled";
        this.setState({ successMessage }, () => this.handleOpenSuccessModal());
        this.setState({
          isLoading: false,
          toEmail: [],
          toEmailFrontEnd: [],
          ccEmail: [],
          ccEmailFrontEnd: [],
          bccEmail: [],
          bccEmailFrontEnd: [],
          errorMessage: false,
          subject: "",
          scheduleDate: "",
          scheduleTime: null,
          scheduleErrorMessage: false,
          scheduleIsLoading: false,
        });
      })
      .catch((error) => {
        this.handleOpenFailureModal();
        this.setState({ isLoading: false, scheduleIsLoading: false });
      });
  };

  extractPlainTextFromHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  recordsSubmitHandler = (schedule = false, e) => {
    e.preventDefault();
    const { subject, content, fileNames, scheduleDate, scheduleTime } =
      this.state;
    const data = this.state;
    if (!data.subject || !data.toEmail || !data.campaignName) {
      this.setState({
        errorMessage: true,
        isLoading: false,
        scheduleIsLoading: false,
      });
      return false;
    }
    if (schedule === true && (!scheduleDate || !scheduleTime)) {
      this.setState({
        scheduleErrorMessage: true,
        isLoading: false,
        scheduleIsLoading: false,
      });
      return false;
    }
    this.setState({ isLoading: true });
    schedule === true && this.setState({ scheduleIsLoading: true });
    let finalContent = content;
    let finalSubject = subject;
    for (let i = 0; i < this.state.defaultPeronalizedVariables.length; i++) {
      finalContent = finalContent.includes(
        `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`
      )
        ? finalContent.replaceAll(
            `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`,
            `{{ ${this.state.defaultPeronalizedVariables[i].key} }}`
          )
        : finalContent;
      finalSubject = finalSubject.includes(
        `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`
      )
        ? finalSubject.replaceAll(
            `<img src="" alt="${this.state.defaultPeronalizedVariables[i].label}" class="jodit-variable-img">`,
            `{{ ${this.state.defaultPeronalizedVariables[i].key} }}`
          )
        : finalSubject;
    }
    const submittedSubject = this.extractPlainTextFromHTML(finalSubject);
    axios({
      method: "POST",
      url: `/email-campaigns/send-bulk-email`,
      data: {
        name: this.state.campaignName,
        records_type: this.props.source,
        email_data: {
          to_emails: this.props.senderEmail?.filter(
            (email) => email !== null && email?.email !== null
          ),
          subject: submittedSubject,
          email_body: finalContent,
          attachments: fileNames,
          ...(this.state.defaultPeronalizedVariables.length !== 0 && {
            personalized_vars: this.state.defaultPeronalizedVariables?.map(
              (pers) => {
                return {
                  key: pers.key,
                  label: pers.label,
                  substitute: pers.value,
                };
              }
            ),
          }),
        },
      },
    })
      .then((res) => {
        const successMessage = schedule === false ? "sent" : "scheduled";
        this.setState({ successMessage }, () => this.handleOpenSuccessModal());
        this.setState({
          isLoading: false,
          toEmail: [],
          toEmailFrontEnd: [],
          ccEmail: [],
          ccEmailFrontEnd: [],
          bccEmail: [],
          bccEmailFrontEnd: [],
          errorMessage: false,
          subject: "",
          scheduleDate: "",
          scheduleTime: null,
          scheduleErrorMessage: false,
          scheduleIsLoading: false,
        });
      })
      .catch((error) => {
        this.handleOpenFailureModal();
        this.setState({ isLoading: false, scheduleIsLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    console.log("bulk email", this.state, this.props);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          // onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div
            className="modalEmail"
            style={this.state.minimize ? { height: "10vh", top: "73%" } : {}}
          >
            <div
              className="modal__content"
              style={
                this.state.minimize
                  ? {
                      height: "10vh",
                      width: "55vw",
                      top: "83%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    }
                  : this.state.fullScreen
                  ? this.state.windowWidth < 600
                    ? {
                        height: "84vh",
                        width: "87vw",
                        top: "5%",
                        left: "2%",
                        border: "none",
                        boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                      }
                    : {
                        height: "84vh",
                        width: "90vw",
                        top: "7%",
                        left: "2%",
                        border: "none",
                        boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                      }
                  : this.state.BCC || this.state.CC
                  ? {
                      height: "76vh",
                      width: "55vw",
                      top: "18%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    }
                  : {
                      height: "73vh",
                      width: "55vw",
                      top: "20%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    }
              }
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "transparent",
                      transform: "translateY(-10px)",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      src={SendEmailIcon}
                      alt="Icon"
                      width="20px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head" style={{ marginLeft: "8px" }}>
                  <h2 className="modal__heading" style={{ fontSize: "18px" }}>
                    Compose Bulk Email
                  </h2>
                </div>
                <NotificationContainer />
                <button
                  className="modal__restore"
                  type="button"
                  onClick={() =>
                    this.setState({ minimize: !this.state.minimize })
                  }
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    right: "11.5%",
                  }}
                >
                  <i className="fa fa-window-minimize" aria-hidden="true"></i>
                </button>
                {this.state.windowWidth > 800 && (
                  <button
                    onClick={() =>
                      this.setState({ fullScreen: !this.state.fullScreen })
                    }
                    className="modal__restore"
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      right: "8%",
                    }}
                  >
                    {this.state.fullScreen ? (
                      <i
                        className="fa fa-window-restore"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-window-maximize"
                        aria-hidden="true"
                      ></i>
                    )}
                  </button>
                )}
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <EmailSuccessModal
                showOpportunityModal={this.state.showSuccessModal}
                setShowOpportunityModal={this.handleOpenSuccessModal}
                handleCloseModal={this.handleCloseSuccessModal}
                message={this.state.successMessage}
              />
              <EmailFailureModal
                showOpportunityModal={this.state.showFailureModal}
                setShowOpportunityModal={this.handleOpenFailureModal}
                handleCloseModal={this.handleCloseFailureModal}
              />
              <EditSignatureModal
                showOpportunityModal={this.state.showEditSignature}
                setShowOpportunityModal={this.handleOpenSignatureModal}
                handleCloseModal={this.handleCloseSignatureModal}
                getSignature={this.getSignature}
                accountId={this.props.accountId}
                opportunityId={this.props.opportunityId}
                contactId={this.props.contactId}
              />
              <CreateTemplateModal
                showOpportunityModal={this.state.showTemplateModal}
                setShowOpportunityModal={this.handleOpenTemplateModal}
                handleCloseModal={this.handleCloseTemplateModal}
                accountId={this.props.accountId}
                opportunityId={this.props.opportunityId}
                contactId={this.props.contactId}
                getAllTemplates={this.getAllTemplates}
                isBulkEmail={true}
              />
              <EditTemplateModal
                showOpportunityModal={this.state.showTemplateEditModal}
                setShowOpportunityModal={this.handleOpenTemplateEditModal}
                handleCloseModal={this.handleCloseTemplateEditModal}
                accountId={this.props.accountId}
                opportunityId={this.props.opportunityId}
                contactId={this.props.contactId}
                getAllTemplates={this.getAllTemplates}
                templateData={this.state?.templateData}
                isBulkEmail={true}
              />
              <DeleteTemplateModal
                showOpportunityModal={this.state.showTemplateDeleteModal}
                setShowOpportunityModal={this.handleOpenTemplateDeleteModal}
                handleCloseModal={this.handleCloseTemplateDeleteModal}
                getAllTemplates={this.getAllTemplates}
                templateData={this.state?.templateData}
              />
              <DefaultVariablesModal
                showOpportunityModal={this.state.showDefaultVarsModal}
                setShowOpportunityModal={this.handleOpenDefaultVarsModal}
                handleCloseModal={this.handleCloseDefaultVarsModal}
                readTemplateVarsValues={this.readTemplateVarsValues}
                personalizedVariablesArray={
                  this.state.defaultPeronalizedVariables
                }
              />
              <AllSequenceModal
                showOpportunityModal={this.state.showAllSequenceModal}
                setShowOpportunityModal={this.handleOpenAllSequenceModal}
                handleCloseModal={this.handleCloseAllSequenceModal}
                setSequenceValue={this.setSequenceValue}
                handleOpenEditSequenceModal={this.handleOpenEditSequenceModal}
              />
              <SendBulkSequenceModal
                showOpportunityModal={this.state.showSendSequenceModal}
                setShowOpportunityModal={this.handleOpenSendSequenceModal}
                handleCloseModal={this.handleCloseSendSequenceModal}
                selectedSequence={this.state.selectedSequence}
                toEmail={this.props.senderEmail}
                fromEmail={this.props.fromEmail}
                handleCloseEmailModal={this.props.handleCloseModal}
                emailType={this.props.type}
                reportId={this.props.reportId}
                excludeEmail={this.props.excludeEmail}
                source={this.props.source}
                emailSignature={this.state.emailSignature}
              />
              <EditSequenceModal
                showOpportunityModal={this.state.showEditSequenceModal}
                setShowOpportunityModal={this.handleOpenEditSequenceModal}
                handleCloseModal={this.handleCloseEditSequenceModal}
                selectedSequence={this.state.selectedSequence}
              />
              <form
                onSubmit={(e) =>
                  this.props.senderEmail.length === 0
                    ? this.submitHandler(false, e)
                    : this.recordsSubmitHandler(false, e)
                }
              >
                <div
                  className="card-email-modal"
                  style={{
                    margin: "10px 0",
                    padding: "4px 10px",
                    display: "flex",
                  }}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      display: "inline-block",
                      marginRight: "5px",
                      fontWeight: "500",
                      transform: "translateY(4px)",
                    }}
                  >
                    Name
                  </label>
                  <input
                    name="campaignName"
                    type="text"
                    style={{
                      width: "86%",
                      padding: "5px",
                      borderRadius: "4px",
                      border: "none",
                      outline: "none",
                      fontSize: "14px",
                      display: "inline-block",
                    }}
                    value={this.state.campaignName}
                    onChange={this.changeHandler}
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.errorMessage === true &&
                    (this.state.campaignName === null ||
                      this.state.campaignName === "")
                      ? "Campaign Name is required"
                      : null}
                  </div>
                </div>
                <div
                  className="card-email-modal"
                  style={{ marginBottom: "10px" }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      transform: "translateY(-9px)",
                      marginRight: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    To
                  </label>
                  <div
                    style={{
                      display: "inline-block",
                      width: "90%",
                      transform: "translateY(-9px)",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.props.reportName}{" "}
                      <span
                        style={{
                          background: "#e7e7e7",
                          padding: "2px 5px",
                          borderRadius: "4px",
                        }}
                      >
                        {this.props.senderEmail.length === 0
                          ? this.props.excludeEmail.length > 0
                            ? this.props.totalRecords -
                              this.props.excludeEmail.length
                            : this.props.totalRecords
                          : this.props.senderEmail.length}{" "}
                        records
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  className="card-email-modal"
                  style={{
                    margin: "10px 0",
                    padding: "4px 10px",
                    display: "flex",
                  }}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      display: "inline-block",
                      marginRight: "5px",
                      fontWeight: "500",
                      transform: "translateY(8px)",
                    }}
                  >
                    Subject
                  </label>
                  <div style={{ width: "100%", fontSize: "14px" }}>
                    <JoditEditor
                      value={this.state.subject}
                      config={config}
                      editorRef={this.setRef}
                      onChange={(content) => {
                        this.updateSubject(content);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.subject === null || this.state.subject === "")
                    ? "Subject is required"
                    : null}
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.uploaderErrorMessage === true
                    ? "Unable to paste image. Please save your image and upload it to the email body."
                    : null}
                </div>
                <div
                  className="card-email-modal"
                  style={
                    this.state.fullScreen
                      ? { height: "45vh", maxHeight: "45vh", minHeight: "45vh" }
                      : {
                          height: "38vh",
                          maxHeight: "38vh",
                          minHeight: "36.2vh",
                          padding: "4px 10px 17px",
                        }
                  }
                >
                  <JoditBulkEmail
                    content={this.state.content}
                    updateContent={this.updateContent}
                    triggerInputFile={this.triggerInputFile}
                    accountId={this.props.accountId}
                    opportunityId={this.props.opportunityId}
                    contactId={this.props.contactId}
                    handleOpenSignatureModal={this.handleOpenSignatureModal}
                    windowWidth={this.state.windowWidth}
                    isBulk={true}
                    setUploaderErrorMessage={this.setUploaderErrorMessage}
                  />
                </div>
                <div
                  style={
                    this.state.fullScreen
                      ? {
                          marginTop: "5px",
                          width: "auto",
                          maxWidth: "80vw",
                          height: "auto",
                          maxHeight: "30px",
                          overflow: "auto",
                          position: "absolute",
                          bottom: "8.5vh",
                        }
                      : {
                          marginTop: "5px",
                          width: "auto",
                          maxWidth: "53vw",
                          height: "auto",
                          maxHeight: "30px",
                          overflow: "auto",
                          position: "absolute",
                          bottom: "8.5vh",
                        }
                  }
                >
                  <ReactTooltip />
                  <input
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    ref={this.fileInput}
                    onChange={this.handleInputChange}
                    onClick={this.onInputClick}
                  />
                  {this.state.fileNames?.map((file) => (
                    <span
                      style={{
                        background: "#C0EAD8",
                        width: "auto",
                        maxWidth: "180px",
                        fontSize: "14px",
                        marginRight: "10px",
                        padding: "2px",
                        borderRadius: "2px",
                      }}
                      data-tip={file.name}
                    >
                      {file.name.length > 20
                        ? file.name.substring(0, 20)
                        : file.name}{" "}
                      <button
                        type="button"
                        onClick={() => this.removeAttachment(file.url)}
                        style={{
                          background: "transparent",
                          border: "none",
                          outline: "none",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        x
                      </button>
                    </span>
                  ))}
                  {this.state.attachmentLoader && (
                    <span
                      style={{
                        background: "#C0EAD8",
                        width: "auto",
                        maxWidth: "180px",
                        fontSize: "14px",
                        marginRight: "10px",
                        padding: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      Loading ...
                    </span>
                  )}
                </div>
                <div className="modal__buttons" style={{ bottom: "2.5%" }}>
                  <div
                    style={
                      this.state.fullScreen
                        ? { position: "relative", width: "89vw" }
                        : { position: "relative", width: "55vw" }
                    }
                  >
                    <div
                      className="button-sm-secondary modal__addButton modal__addButton-email-sequence"
                      onClick={this.handleOpenAllSequenceModal}
                      style={{ padding: "6px 8px 2px" }}
                    >
                      Sequence
                    </div>
                    <div
                      className="button-sm-secondary modal__addButton modal__addButton-email-template"
                      style={{ padding: "4px 8px" }}
                    >
                      <DropdownTemplate
                        handleOpenTemplateModal={this.handleOpenTemplateModal}
                        handleOpenTemplateEditModal={
                          this.handleOpenTemplateEditModal
                        }
                        handleOpenTemplateDeleteModal={
                          this.handleOpenTemplateDeleteModal
                        }
                        allTemplates={this.state.allTemplates}
                        getAllTemplates={this.getAllTemplates}
                        readTemplateValues={this.readTemplateValues}
                        getSingleTemplateData={this.getSingleTemplateData}
                      />
                    </div>
                    <Hidden sm xs>
                      <button
                        type="submit"
                        disabled={this.state.isLoading}
                        className="button-md modal__confirm-button"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "18px",
                          padding: "2px 17px",
                          width: "80.22px",
                          height: "35px",
                        }}
                      >
                        {this.state.isLoading ? "Sending" : "Send"}
                      </button>
                    </Hidden>
                    <Visible sm xs>
                      <div>
                        <button
                          type="submit"
                          className="button-md modal__confirm-button"
                          disabled={this.state.isLoading}
                          style={{
                            position: "absolute",
                            bottom: "-2px",
                            left: "0px",
                            padding: "2px 17px",
                            width: "80.22px",
                            height: "35px",
                          }}
                        >
                          {this.state.isLoading ? "Sending" : "Send"}
                        </button>
                      </div>
                    </Visible>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SendEmailReportModal;
