import React, { Component } from "react";
import ReactModal from "react-modal";
import ReactTooltip from "react-tooltip";
import SendEmailIcon from "../../images/SendEmailIcon.svg";
import JoditEditor from "jodit-react";

const config = {
  height: "inherit",
  maxHeight: "inherit",
  minHeight: "inherit",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  toolbarButtonSize: "small",
  readonly: true,
  toolbar: false,
  link: {
    modeClassName: false,
    processVideoLink: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Type your message...",
  zIndex: 2147483640,
};

class SequenceTemplateViewModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    label: "",
    subject: "",
    content: "",
    errorMessage: false,
    isLoading: false,
    personalizeVars: [],
    accountCustomFields: [],
    dealCustomFields: [],
    contactCustomFields: [],
  };
  updateContent = (value) => {
    this.setState({ content: value });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      label: this.props.templateData?.label,
      subject: this.props.templateData?.subject,
      content: this.props.templateData?.content,
      personalizeVars: this.props.templateData?.personalizeVars,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.templateData !== this.props.templateData) {
      let finalContent = this.props.templateData?.content;
      let finalSubject = this.props.templateData?.subject;
      for (
        let i = 0;
        i < this.props.templateData?.personalizeVars.length;
        i++
      ) {
        finalContent = finalContent.includes(
          `{{ ${this.props.templateData?.personalizeVars[i].key} }}`
        )
          ? finalContent.replaceAll(
              `{{ ${this.props.templateData?.personalizeVars[i].key} }}`,
              `<img src="" alt="${this.props.templateData?.personalizeVars[i].label}" class="jodit-variable-img">`
            )
          : finalContent;
        finalSubject = finalSubject.includes(
          `{{ ${this.props.templateData?.personalizeVars[i].key} }}`
        )
          ? finalSubject.replaceAll(
              `{{ ${this.props.templateData?.personalizeVars[i].key} }}`,
              `<img src="" alt="${this.props.templateData?.personalizeVars[i].label}" class="jodit-variable-img">`
            )
          : finalSubject;
      }
      this.setState({
        label: this.props.templateData?.label,
        subject: finalSubject,
        content: finalContent,
        personalizeVars: this.props.templateData?.personalizeVars,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={
                this.state.windowWidth < 600
                  ? {
                      height: "75vh",
                      width: "95vw",
                      top: "13%",
                      left: "0%",
                      border: "none",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    }
                  : {
                      height: "60vh",
                      width: "55vw",
                      top: "23%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    }
              }
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "#61C99D",
                      transform: "translateY(-10px)",
                    }}
                  >
                    <img
                      src={SendEmailIcon}
                      alt="Icon"
                      width="16px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">View Email Template</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div>
                <label
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    marginRight: "5px",
                    fontWeight: "500",
                  }}
                >
                  Template Name
                </label>
                <input
                  name="label"
                  type="text"
                  style={{
                    width: "80%",
                    padding: "5px",
                    borderRadius: "4px",
                    border: "none",
                    outline: "none",
                    fontSize: "14px",
                    display: "inline-block",
                  }}
                  value={this.state.label}
                  readOnly={true}
                />
              </div>
              <div
                style={{
                  borderTop: "1px solid #EDF0F2",
                  borderBottom: "1px solid #EDF0F2",
                  padding: "3px 0",
                  display: "flex",
                }}
              >
                <label
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    marginRight: "5px",
                    fontWeight: "500",
                    transform: "translateY(8px)",
                  }}
                >
                  Subject
                </label>
                <div style={{ width: "100%", fontSize: "14px" }}>
                  <JoditEditor value={this.state.subject} config={config} />
                </div>
              </div>
              <div
                style={
                  this.state.fullScreen
                    ? { height: "45vh", maxHeight: "45vh", minHeight: "45vh" }
                    : {
                        height: "36.2vh",
                        maxHeight: "36.2vh",
                        minHeight: "36vh",
                      }
                }
              >
                <JoditEditor value={this.state.content} config={config} />
              </div>
              <div
                style={{
                  marginTop: "5px",
                  width: "auto",
                  maxWidth: "420px",
                  height: "auto",
                  maxHeight: "40px",
                  overflow: "auto",
                  position: "absolute",
                  bottom: "3vh",
                }}
              >
                <ReactTooltip />
                <input
                  style={{ display: "none" }}
                  id="file-upload"
                  type="file"
                  ref={this.fileInput}
                  onChange={this.handleInputChange}
                />
                {this.state.fileNames?.map((file) => (
                  <span
                    style={{
                      background: "#C0EAD8",
                      width: "auto",
                      maxWidth: "180px",
                      fontSize: "14px",
                      marginRight: "10px",
                      padding: "2px",
                      borderRadius: "2px",
                    }}
                    data-tip={file.name}
                  >
                    {file.name.length > 20
                      ? file.name.substring(0, 20)
                      : file.name}{" "}
                    <button
                      type="button"
                      onClick={() => this.removeAttachment(file.url)}
                      style={{
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                    >
                      x
                    </button>
                  </span>
                ))}
              </div>
              <div className="modal__buttons">
                <div
                  style={
                    this.state.windowWidth < 600
                      ? { position: "relative", width: "95vw" }
                      : { position: "relative", width: "55vw" }
                  }
                >
                  <button
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.props.selectStep({
                        id: this.props.templateData?.id,
                        name: this.props.templateData?.label,
                      });
                      this.props.handleCloseModal();
                    }}
                    className="modal__confirm-button"
                    style={
                      this.state.windowWidth < 600
                        ? {
                            bottom: "-24px",
                            position: "absolute",
                            left: "0",
                            padding: "2px 17px",
                            background: "#BFE9D7",
                            width: "80.22px",
                            height: "35px",
                          }
                        : {
                            position: "absolute",
                            bottom: "1px",
                            right: "18px",
                            padding: "2px 17px",
                            background: "#BFE9D7",
                            width: "80.22px",
                            height: "35px",
                          }
                    }
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SequenceTemplateViewModal;
